import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationPanel from 'app/components/confirmationPanel/ConfirmationPanel';

interface IProps {
  isShowPanel: boolean;
  setIsShowPanel: (isShowPanel: string) => void;
  handleDeleteConfirm: () => void;
}

const CaseDeletePanel = (props: IProps): React.JSX.Element => {
  const { isShowPanel, setIsShowPanel, handleDeleteConfirm } = props;
  const { t } = useTranslation();

  const handleDelete = async (): Promise<void> => {
    handleClose();
    handleDeleteConfirm();
  };

  const handleCancel = async (): Promise<void> => {
    handleClose();
  };

  const handleClose = (): void => {
    setIsShowPanel('');
  };

  return (
    <ConfirmationPanel
      title={t('caseDeletePanelTitle')}
      buttons={[
        {
          classNames: ['delete-case-confirm-button'],
          buttonText: t('caseDeletePanelYes'),
          onClick: handleDelete,
          variant: 'outlined',
          closeOnClick: true,
          key: 'yes'
        },
        {
          buttonText: t('back'),
          onClick: handleCancel,
          variant: 'secondary',
          closeOnClick: true,
          key: 'no'
        }
      ]}
      isShowPanel={isShowPanel}
      setIsShowPanel={isTrue => {
        if (isTrue) {
          return;
        }
        handleClose();
      }}
    />
  );
};

export default CaseDeletePanel;
