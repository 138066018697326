import React from 'react';
import Form from '../form/Form';
import type { Control, FormState } from 'react-hook-form/dist/types/form';
import type { IDynamicField, IDynamicFieldValues } from 'app/mobxStore/types';
import { observer } from 'mobx-react-lite';
import { getIsPatientField } from '../../../utils/form/shared';

interface IProps {
  fields: IDynamicField[];
  control: Control<IDynamicFieldValues>;
  allValues: IDynamicFieldValues;
  setFocus: (id: string) => void;
  formState: FormState<IDynamicFieldValues>;
  submitChange: boolean;
  showPatientFields: boolean;
}

const BriefForm = (props: IProps): React.JSX.Element => {
  const { formState, setFocus, allValues, fields, control, submitChange, showPatientFields } =
    props;

  const getFields = (): IDynamicField[] => {
    if (showPatientFields) {
      return fields;
    }

    return fields.filter(field => !getIsPatientField(field.id));
  };

  return (
    <>
      <Form
        formState={formState}
        setFocus={setFocus}
        allValues={allValues}
        fields={getFields()}
        control={control}
        submitChange={submitChange}
      />
    </>
  );
};

export default observer(BriefForm);
