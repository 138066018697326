import type { ICase } from 'app/mobxStore/types';
import type { UniqueIdentifier } from '@dnd-kit/core/dist/types';

type Direction = 'up' | 'down';
export const calcIndexInDay = (
  allCases: ICase[],
  caseId: UniqueIdentifier,
  direction: Direction
): number => {
  const overCaseIdx = allCases.findIndex(item => item.id === caseId);
  const overCase = allCases[overCaseIdx];

  // begin
  if (overCaseIdx === 0) {
    return overCase.data.indexInDay - 1;
  }
  // end
  if (overCaseIdx === allCases.length - 1) {
    return overCase.data.indexInDay + 1;
  }

  const c = direction === 'up' ? allCases[overCaseIdx + 1] : allCases[overCaseIdx - 1];

  return (overCase.data.indexInDay + c.data.indexInDay) / 2;
};
