import React, { type MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import type { IUserSelectOption } from 'app/mobxStore/types';
import ModalScreen from 'app/components/modalScreen/ModalScreen';
import Button from 'app/components/buttons/Button';
import variables from 'app/utils/variables.module.scss';
import AddUserIcon from 'app/assets/icons/PeoplePlusOne_icon';
import clsx from 'clsx';
import { ROLES } from 'app/consts';
import { useRootStore } from '../../mobxStore';
import PanelContent from './PanelContent';

interface IProps {
  options: IUserSelectOption[];
  isReadOnly?: boolean;
  onChange: (value: IUserSelectOption[]) => void;
  handleOpenSelectPanel: (isOpen: boolean) => void;
  isPanelOpen: boolean;
}

const FollowersSelectPanel = (props: IProps): React.JSX.Element => {
  const { isReadOnly, options, onChange, handleOpenSelectPanel, isPanelOpen } = props;
  const { t } = useTranslation();
  const { userStore } = useRootStore();

  if (
    options.length === 0 ||
    (options.length === 1 && options[0].userId === userStore.loggedInUser.data.id)
  ) {
    return <></>;
  }

  const getEditFollowersButtonText = (): string => {
    const nurseManagerRole = userStore.loggedInUser.data.roles.find(
      r => r.role === ROLES.NURSE_MANAGER
    );
    if (nurseManagerRole) {
      return t('assignTeamMembers').toString();
    }

    return t('editHuddleTeam').toString();
  };

  return (
    <div className="followers-select-panel">
      {!isReadOnly && (
        <Button
          disabled={isReadOnly}
          classNames={clsx('btn secondary add-user-button')}
          startIcon={<AddUserIcon height={20} stroke={variables.black} />}
          buttonText={getEditFollowersButtonText()}
          onClick={async (e: MouseEvent) => {
            handleOpenSelectPanel(true);
          }}
        />
      )}
      <ModalScreen
        isOpen={isPanelOpen}
        classNames={'full-height followers-modal'}
        content={
          <PanelContent
            options={options}
            onChange={onChange}
            isOpen={isPanelOpen}
            setIsOpen={handleOpenSelectPanel}
          />
        }
        handleClose={() => {
          handleOpenSelectPanel(false);
        }}
      />
    </div>
  );
};

export default FollowersSelectPanel;
