import React from 'react';
import type { ICase } from 'app/mobxStore/types';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import Chip from 'app/components/chip/Chip';
import { useTranslation } from 'react-i18next';
import { getUserRoleInCase } from '../../../utils/form/shared';
import { useRootStore } from '../../mobxStore';
import { USER_CASE_ROLES } from '../../consts';

const CaseStatusChip = (props: { case: ICase }): React.JSX.Element | null => {
  const { t } = useTranslation();
  const { case: kase } = props;
  const { userStore } = useRootStore();

  const onClick = (): void => {
    const userRoleInCase = getUserRoleInCase(kase.data, userStore.loggedInUser.data);
    if (userRoleInCase === USER_CASE_ROLES.NONE) {
      return;
    }

    void kase.setStandardProcedureToServer(false);
    kase.setStandardProcedureToStore(false);
    kase.setIsReady(false);
    toast.success(t('notStandardProcedure'));
  };

  if (!kase.data.isReady) {
    return null;
  }

  if (kase.data.standardProcedure) {
    return (
      <Chip
        classNames={'case-status standard-proc'}
        text={'standardProcedureChip'}
        color={'success'}
        onClick={onClick}
      />
    );
  }

  return <Chip classNames={'case-status'} text={'huddled'} color={'success'} />;
};

export default observer(CaseStatusChip);
