import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PenguinsIceberg } from 'app/assets/penguins/penguin_2_animated_no_internet_connection.svg';
import './OfflinePage.scss';

const OfflinePage = (): React.JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="offline-page">
      <div className="content">
        <PenguinsIceberg className="image" />
        <div className="title">{t('offlineTitle1')}</div>
        <div className="title">{t('offlineTitle2')}</div>
        <div className="subtitle">{t('offlineSubtitle')}</div>
      </div>
    </div>
  );
};

export default OfflinePage;
