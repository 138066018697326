import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import type { Control } from 'react-hook-form/dist/types/form';
import clsx from 'clsx';
import { FORM_FIELDS_ENUM, type IFormData } from 'app/components/fields/types';
import type { ICase, IProcedureSelectOption, ISelectOption } from 'app/mobxStore/types';
import useProcedureSuggestions from 'app/components/caseViews/hooks/useProcedureSuggestions';
import ProcedureSelect from '../fields/procedureSelect/ProcedureSelect';
import TextField from '@mui/material/TextField';
import Button from 'app/components/buttons/Button';
import FabButton from 'app/components/fabButton/FabButton';
import ArrowLeftIcon from 'app/assets/icons/ArrowLeft_icon';
import variables from 'app/utils/variables.module.scss';
import apiService from '../../services/apiService';
import { useRootStore } from '../../mobxStore';
import AmplService from '../../services/amplService/amplService';

interface IProps {
  control: Control<IFormData>;
  options: IProcedureSelectOption[];
  allValues: IFormData;
  setIsOpen: (isOpen: boolean) => void;
  onChange: (option: IProcedureSelectOption | null) => void;
}

const createMName = (str: string): string => {
  const fixedStr = str.replace(/\s/g, '_');
  return fixedStr.trim();
};

const AddTemplatePanelContent = (props: IProps): React.JSX.Element => {
  const { control, allValues, options, setIsOpen, onChange } = props;
  const { t } = useTranslation();
  const caseTitle = allValues?.title;
  const [templateName, setTemplateName] = useState(caseTitle);
  const [selectedProcedureForAddNew, setSelectedProcedureForAddNew] =
    useState<ISelectOption | null>(null);
  const [newTemplate, setNewTemplate] = useState('');
  const { openedCase } = useRootStore().caseStore;

  const { metaDataStore, caseStore, careTeamDefaultsStore, userStore } = useRootStore();
  const userId = userStore.loggedInUser.data.id;
  const { procSuggestions, loaded } = useProcedureSuggestions({
    procedures: options,
    procedureTitle: allValues[FORM_FIELDS_ENUM.PROCEDURE]?.label ?? '',
    title: allValues[FORM_FIELDS_ENUM.TITLE],
    description: templateName,
    siteId: allValues[FORM_FIELDS_ENUM.SERVICE]?.value ?? '',
    attendingId: allValues[FORM_FIELDS_ENUM.SURGEON]?.value ?? ''
  });

  const handleSubmit = async (): Promise<void> => {
    if (!selectedProcedureForAddNew || !templateName) {
      return;
    }
    try {
      const procedureId = selectedProcedureForAddNew.value;
      const procedure = metaDataStore.procedures.find(p => p.id === procedureId);
      if (!procedure) {
        throw new Error('Procedure not found in MetaDataStore');
      }

      const specialtyId = procedure.specialtyId;
      const specialtyTitle = procedure.specialtyTitle;
      const newProcedureId = createMName(templateName);

      const procedureData = await caseStore.getCaseMetaData(
        procedure.id,
        Boolean(procedure.isUserTemplate)
      );
      procedureData.id = newProcedureId;

      const templateData = {
        user_id: allValues[FORM_FIELDS_ENUM.SURGEON]?.value,
        procedureName: templateName,
        procedureId: newProcedureId,
        specialtyId,
        specialtyTitle,
        template: procedureData
      };

      await apiService.createUserTemplate(templateData);
      const savedDefault = careTeamDefaultsStore.getDefault(userId, procedure.id);
      if (savedDefault) {
        await careTeamDefaultsStore.saveRawDefault(userId, newProcedureId, savedDefault);
      }
      await metaDataStore.loadUserTemplates();

      setNewTemplate(templateData.procedureId);

      AmplService.sendCaseEvent(
        AmplService.EVENTS.ADD_TEMPLATE_SUBMIT,
        openedCase?.basicCase as ICase,
        {
          [AmplService.ATTRIBUTES.SRC_TEMPLATE]: procedureId,
          [AmplService.ATTRIBUTES.NEW_TEMPLATE_NAME]: templateName
        }
      );
    } catch (error) {
      console.error('Error submitting template:', error);
    }

    await handleClose();
  };

  // If newTemplate is set, select it and open the edit template dialog
  useEffect(() => {
    if (newTemplate) {
      const newOption = options.find(o => o.value === newTemplate);
      if (newOption) {
        onChange(newOption);
        setNewTemplate('');
        setIsOpen(false);
        caseStore.setIsEditTemplateDialogOpen(true);
      }
    }
  }, [options]);

  const handleClose = async (): Promise<void> => {
    if (newTemplate) {
      return;
    }
    setIsOpen(false);
  };

  return (
    <div className="add-template-panel-content">
      <div className="header">
        <Button
          classNames="btn circle-button close-button"
          startIcon={<ArrowLeftIcon height={14} stroke={variables.grey6} />}
          onClick={handleClose}
        />
        <div className="title">{t('addATemplate')}</div>
      </div>
      <div className="content-wrapper">
        <div className="template-name-container">
          <div className="title">{t('templateNameLabel')}</div>
          <div className="text-field-container">
            <TextField
              className="text-field"
              placeholder={t('templateNamePlaceholder').toString()}
              value={templateName}
              onChange={e => {
                setTemplateName(e.target.value);
              }}
            />
          </div>
        </div>
        <ProcedureSelect
          required={false}
          control={control}
          options={options}
          procSuggestions={procSuggestions}
          allValues={allValues}
          loaded={loaded}
          onChange={(option: ISelectOption | null): void => {
            setSelectedProcedureForAddNew(option);
          }}
          isAddTemplate
          addNewTemplateName={templateName}
        />
      </div>
      <FabButton
        onClick={handleSubmit}
        classNames={clsx('submit-button submit right-align', {
          disabled: !selectedProcedureForAddNew || !templateName
        })}
        buttonContent={<p className="button-content">{t('addTemplateButton')}</p>}
      />
    </div>
  );
};

export default observer(AddTemplatePanelContent);
