import React from 'react';
import ModalScreen from '../modalScreen/ModalScreen';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../mobxStore';
import EditTemplateContent from './EditTemplateContent';

interface IProps {
  handleClose: () => Promise<void>;
  applyPrefs: () => void;
}

const EditTemplateModel = (props: IProps): React.JSX.Element => {
  const { handleClose, applyPrefs } = props;
  const { caseStore } = useRootStore();
  return (
    <ModalScreen
      classNames={'full-height edit-template-modal'}
      isOpen={caseStore.isEditTemplateDialogOpen}
      content={<EditTemplateContent handleClose={handleClose} applyPrefs={applyPrefs} />}
      onCloseEnd={() => {}}
      handleClose={handleClose}
    />
  );
};

export default observer(EditTemplateModel);
