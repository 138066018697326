import React, { type MouseEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { Trans, useTranslation } from 'react-i18next';
import Button from 'app/components/buttons/Button';
import { ReactComponent as PenguinWelcome } from 'app/assets/penguins/penguin_4_animated.svg';
import ChiefyLogoIcon from 'app/assets/icons/ChiefyLogo_icon';
import '../onboarding/Onboarding.scss';
import { setLastWhatsNewViewed } from '../../mobxStore/storage';
import redirectRoute from 'app/utils/useRedirectTo';

const WhatsNew = (): React.JSX.Element => {
  const { t } = useTranslation();

  const redirect = redirectRoute();

  const handleDone = (): void => {
    setLastWhatsNewViewed(parseInt(t('whatsNewVersion')));
    redirect();
  };

  return (
    <div className="page-container whats-new">
      <div className="onboarding-page">
        <div className="onboarding-header">
          <div className="flex">
            <ChiefyLogoIcon className="logo" height={24} />
          </div>
          <div className="flex">
            <Button
              classNames="btn skip-button"
              buttonText="done"
              onClick={async (e: MouseEvent) => {
                handleDone();
              }}
            />
          </div>
        </div>
        <div className="screen-conainer current">
          <>
            <div className="image">
              <PenguinWelcome />
            </div>
            <div className="screen-content">
              <div className="title">
                <Trans i18nKey="whatsNewTitle" />
              </div>
              <div className="text">
                <Trans
                  i18nKey="whatsNewText"
                  components={{ bullet: <span className="bullet" /> }}
                  parent="div"
                />
              </div>
              <div className="text contact">
                <Trans
                  i18nKey="whatsNewContact"
                  components={{
                    contact: (
                      <button
                        onClick={() => {
                          window.location.assign('mailto:hello@chiefyteam.com');
                        }}
                      />
                    )
                  }}
                />
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default observer(WhatsNew);
