import React from 'react';
import { authRoles } from 'app/auth';
import AddToHomeScreen from 'app/pages/landing/AddToHomeScreen';
import FuseAuthorization from '@fuse/core/FuseAuthorization';
import { MetaData } from 'app/metaData';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from 'app/pages/errorPage/ErrorPage';

const AddToHomeScreenConfig = {
  settings: {
    layout: {
      config: {
        footer: {
          display: true
        }
      }
    }
  },
  auth: authRoles.needPwa,
  routes: [
    {
      path: '/add-to-home-screen',
      element: (
        <ErrorBoundary fallback={<ErrorPage />}>
          <FuseAuthorization>
            <MetaData>
              <AddToHomeScreen />
            </MetaData>
          </FuseAuthorization>
        </ErrorBoundary>
      )
    }
  ]
};

export default AddToHomeScreenConfig;
