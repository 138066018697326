import { observer } from 'mobx-react-lite';
import React from 'react';
import type { Control } from 'react-hook-form/dist/types/form';
import type { IDynamicField } from 'app/mobxStore/types';
import { useController } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import TextInput from '@mui/material/TextField';
import Button from 'app/components/buttons/Button';
import CheckIcon from 'app/assets/icons/Check_icon';
import TrashIcon from 'app/assets/icons/Trash_icon';
import variables from 'app/utils/variables.module.scss';
import './OtherTextField.scss';

interface IProps {
  control: Control<any>;
  field: IDynamicField;
  doneEditing: (count: number) => void;
  index: number;
  value: string;
}

const splitOtherValue = (value: string): string[] => {
  return value
    .split(',')
    .map(v => v.trim())
    .filter(v => v !== '');
};

const OtherMultiTextField = (props: IProps): React.JSX.Element => {
  const { doneEditing, value, control, field: dField } = props;
  const { field } = useController({
    name: `${dField.id}-other`,
    control
  });
  const { onChange } = field;
  const onDoneEditing = (): void => {
    const curValue = field.value[props.index];
    // if user creating new chip and not typing anything, simply stop editing

    if (curValue === undefined) {
      doneEditing(0);
      return;
    }
    // if user editing existing chip and erasing string or creating new, typing and then erasing, then remove from array and stop editing
    if (curValue.trim() === '' && props.index === field.value.length - 1) {
      onChange([...field.value.slice(0, props.index), ...field.value.slice(props.index + 1)]);
      doneEditing(0);
      return;
    }
    if (curValue.trim() === '') {
      onChange([...field.value.slice(0, props.index), ...field.value.slice(props.index + 1)]);
      doneEditing(-1);
      return;
    }
    const splitValues = splitOtherValue(curValue);
    onChange([
      ...field.value.slice(0, props.index),
      ...splitValues,
      ...field.value.slice(props.index + 1)
    ]);
    doneEditing(splitValues.length);
  };
  return (
    <FormControl fullWidth>
      <div className="other-text-field-container multi">
        <TextInput
          className="other-text-field"
          multiline
          fullWidth
          {...field}
          value={value}
          onChange={e => {
            onChange([
              ...field.value.slice(0, props.index),
              e.target.value,
              ...field.value.slice(props.index + 1)
            ]);
          }}
          autoFocus
          onFocus={e => {
            setTimeout(() => {
              e.target.select();
            });
          }}
          onBlur={() => {
            onDoneEditing();
          }}
        />
        <Button
          // Button onClick is currently not needed and also is overriden by onBlur
          // onClick={async (): Promise<void> => {
          //   onDoneEditing();
          // }}
          classNames="btn circle-button save-button"
          startIcon={
            value === '' ? (
              <TrashIcon height={10} fill={variables.primaryPurple} />
            ) : (
              <CheckIcon height={10} stroke={variables.primaryPurple} />
            )
          }
        />
      </div>
    </FormControl>
  );
};

export default observer(OtherMultiTextField);
