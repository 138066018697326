import { useEffect } from 'react';
import { useRootStore } from 'app/mobxStore';
import { toast } from 'react-toastify';
import { t } from 'i18next';

const useTimezoneToast = (): void => {
  const { metaDataStore } = useRootStore();
  const { tz, isSameTimezone } = metaDataStore;

  useEffect(() => {
    if (isSameTimezone) {
      return;
    }
    const deviceTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    toast.info(t('different_timezone', { deviceTz, siteTz: tz }));
  }, []);
};

export default useTimezoneToast;
