import React from 'react';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import { OTHER_OPTION } from '../../../utils/form/shared';
import PencilIcon from '../../assets/icons/Pencil_icon';
import type { ISelectOption } from '../../mobxStore/types';
import type { ControllerRenderProps } from 'react-hook-form';

interface IProps {
  option: ISelectOption;
  isSelected: boolean;
  value: string;
  toggleValue: (
    option: ISelectOption,
    value: string[],
    setValue: (...event: any[]) => void
  ) => void;
  setIsEditing: (isEditing: boolean) => void;
  field: ControllerRenderProps<any, string>;
}
const AddYourOwnChip = (props: IProps): React.JSX.Element => {
  const { field, option, isSelected, value, toggleValue, setIsEditing } = props;
  const displayOther = value !== '';
  return (
    <Button
      key={option.value}
      className={clsx('btn chip-button toggle-chip other-chip', {
        selected: isSelected && value
      })}
      endIcon={option.value === OTHER_OPTION && <PencilIcon className="other-icon" height={14} />}
      onClick={() => {
        if (!isSelected) {
          toggleValue(option, field.value, field.onChange);
        }
        setIsEditing(true);
      }}
    >
      {displayOther ? value : option.label}
    </Button>
  );
};

export default AddYourOwnChip;
