import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRootStore } from 'app/mobxStore';
import { getLoggedInUserData, getUserDataFromToken } from 'app/apiHelper';
import { observer } from 'mobx-react-lite';
import ffService from '../services/ffService/ffService';
import { getAccessToken, lite } from '../services/apiService/apiService';
import { isInstalled, isStandAlone } from '../mobxStore/caseStoreHelper';
import { ROLES } from '../consts';
import oneSignalService from '../services/oneSignalService';
import apiService from '../services/apiService';
import AmplService from '../services/amplService/index';
import ErrorMonitor from '../services/errorMonitor/errorMonitor';
import type { IAmplSessionData, IAmplUserData } from '../mobxStore/types';

const LOGIN_PATH = '/login';

const MetaData = (props: { children: React.JSX.Element }): React.JSX.Element => {
  const { children } = props;
  const { careTeamDefaultsStore, userStore, metaDataStore } = useRootStore();
  const location = useLocation();
  const navigate = useNavigate();
  const loggedIn = userStore.loggedInUser.loggedIn;

  const { loaded, loading } = metaDataStore;
  const { pathname } = location;
  const pending = useRef(false);

  useEffect(() => {
    const loadingState = !(pathname === LOGIN_PATH && !loggedIn);
    metaDataStore.setLoading(loadingState);
    if (pathname === LOGIN_PATH && loggedIn) {
      navigate('/');
    }
  }, []);
  useEffect(() => {
    const handle = async (): Promise<void> => {
      if (pending.current) {
        return;
      }
      pending.current = true;
      const { userId, roles, sitesIds } = getUserDataFromToken(getAccessToken());
      if (!roles.includes(ROLES.CURRENT_USER)) {
        navigate('/logout');
        return;
      }

      const loggedInUserData = await getLoggedInUserData(roles);
      if (loggedInUserData === undefined) {
        ErrorMonitor.captureException(new Error('Error: No user data returned from server'));
        return;
      }

      userStore.loggedInUser.setData(loggedInUserData);
      if (lite && !isStandAlone()) {
        userStore.loggedInUser.setRoles([ROLES.NEED_PWA]);
      }
      userStore.loggedInUser.setLoggedIn(true);

      metaDataStore.setCurOntVersion(apiService.currentOntVersion());

      await ffService.init(userId);
      try {
        void oneSignalService.init();
      } catch (error) {
        ErrorMonitor.captureException(error as Error);
      }

      await metaDataStore.setEnum(sitesIds, roles);
      void careTeamDefaultsStore.loadDefaults();
      metaDataStore.setLoaded(true);
      metaDataStore.setLoading(false);

      const sites = metaDataStore.sites.map(site => site.name);
      const siteIds = metaDataStore.sites.map(site => site.id);

      const amplUserData: IAmplUserData = {
        roles,
        services: sites,
        serviceIds: siteIds,
        isContributor: userStore.loggedInUser.data.isNurseLeader,
        id: userId,
        email: userStore.loggedInUser.data.email
      };
      const amplSessionData: IAmplSessionData = {
        isPwa: isInstalled(),
        isOneSignalEnabled: oneSignalService.enabled(),
        isPushOptIn: await oneSignalService.isOptedIn()
      };
      AmplService.setData(amplUserData, amplSessionData);
    };

    if (loggedIn && !loaded) {
      void handle();
    }
  }, [loggedIn, loading]);
  return !loaded ? (
    <div style={{ backgroundColor: 'white' }} />
  ) : (
    <div style={{ width: '100%' }}>{children}</div>
  );
};

export default observer(MetaData);
