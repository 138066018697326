import React, { type MouseEvent } from 'react';
import { useRootStore } from 'app/mobxStore';
import { observer } from 'mobx-react-lite';
import { format, getDate, isSameDay, isToday } from 'date-fns';
import clsx from 'clsx';
import './WeekDayPicker.scss';
import MomentAdapter from '@date-io/moment';
import Button from 'app/components/buttons/Button';

const ID_DATE_FORMAT = 'M/D/YYYY';

interface IProps {
  day: Date;
  handleClickDay: (day: Date) => void;
}

const WeekDayComponent = (props: IProps): React.JSX.Element => {
  const { day, handleClickDay } = props;
  const { caseStore } = useRootStore();
  const isSelected = isSameDay(day, caseStore.selectedDateFilter);
  const isHaveCases = caseStore.hasCaseInDateByFilter(day);
  const isHaveNeedsAttentionCases = caseStore.hasNeedAttentionFilteredCasesInDay(day);
  const isHaveUnreadMessages = caseStore.hasUnreadMessagesFilteredCasesInDay(day);
  const moment = new MomentAdapter();
  const key = moment.moment(day).format(ID_DATE_FORMAT);
  return (
    <div className="day-container">
      <div className={clsx('day-label', { selected: isSelected })}>{format(day, 'iii')}</div>
      <Button
        id={`wdc-${key}`}
        key={key}
        classNames={clsx(
          'select-day-button',
          'day',
          isSelected && 'selected',
          isToday(day) && 'today',
          isHaveCases && 'hasCases',
          (isHaveNeedsAttentionCases || isHaveUnreadMessages) && 'hasNeedsAttention'
        )}
        onClick={async (e: MouseEvent) => {
          handleClickDay(day);
        }}
        buttonText={getDate(day).toString()}
      />
    </div>
  );
};

export default observer(WeekDayComponent);
