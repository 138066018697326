import React from 'react';
import { authRoles } from 'app/auth';
import UNRegister from './UNRegister';

const RegisterConfig = {
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: '/register/:inviteId',
      element: <UNRegister />
    }
  ]
};

export default RegisterConfig;
