import React, { type MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { type FileInfo, Widget } from '@uploadcare/react-widget';
// @ts-expect-error no types
import uploadcare from 'uploadcare-widget';
import { useRootStore } from 'app/mobxStore';
import { Dialog } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import MuiButton from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import Button from 'app/components/buttons/Button';
import PlusCircleIcon from 'app/assets/icons/PlusCircle_icon';
import { toast } from 'react-toastify';
import { log } from 'debug';
import type { IFullCase } from '../../mobxStore/types';
import ErrorMonitor from '../../services/errorMonitor/errorMonitor';

const MAX_FILE_SIZE =
  process.env.REACT_APP_UPLOADCARE_MAX_FILE_SIZE !== '' &&
  process.env.REACT_APP_UPLOADCARE_MAX_FILE_SIZE !== undefined
    ? parseInt(process.env.REACT_APP_UPLOADCARE_MAX_FILE_SIZE)
    : 25;

const checkMaxFileSize = (size: number) => {
  return (fileInfo: FileInfo): void => {
    if (fileInfo.size !== null && fileInfo.size > size * 1024 * 1024) {
      throw new Error('fileMaximumSize');
    }
  };
};
const getPreviewSrc = (asset: any): string => {
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  return `${asset.file.cdnUrl}-/resize/x160/-/format/auto/?token=${asset.token}`;
};
const UploadImageButton = (): React.JSX.Element => {
  const { metaDataStore, caseStore } = useRootStore();
  const openedCase = caseStore.openedCase as IFullCase;
  const uploadcareKey = metaDataStore.uploadcareKey;
  const widgetRef = useRef();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const [asset, setAsset] = useState({
    file: undefined,
    desc: '',
    token: '',
    id: ''
  });
  const [token, setToken] = useState({
    signature: '',
    expire: 0
  });

  const handleGetSignature = useCallback(async () => {
    const tokenData = await caseStore.getUploadcareSignature();
    setToken(tokenData);
    return tokenData;
  }, []);

  useEffect(() => {
    void handleGetSignature();
  }, []);

  const handleClose = (): void => {
    setAsset({
      file: undefined,
      desc: '',
      token: '',
      id: ''
    });
    setOpen(false);
  };

  const handleDesc = (desc: string): void => {
    setAsset({
      file: asset.file,
      desc,
      id: asset.id,
      token: asset.token
    });
  };
  const handleUpload = async (fileInfo: FileInfo): Promise<void> => {
    try {
      const uploadedAsset = await openedCase.basicCase.addAsset(fileInfo.uuid ?? '');

      const file = uploadcare.fileFrom('uploaded', fileInfo.uuid, {
        // publicKey: 'demopublickey'
        publicKey: uploadcareKey,
        cdnBase: process.env.REACT_APP_UPLOAD_CARE_CDN,
        secureExpire: token.expire,
        secureSignature: token.signature,
        validators: [checkMaxFileSize(MAX_FILE_SIZE)],
        imagesOnly: true
      });
      const buildPreviewUrl = (originalUrl: string): string => {
        return `${originalUrl}?token=${uploadedAsset.token}`;
      };
      const cropWidget = uploadcare.openDialog(file, 'preview', {
        crop: 'free, 16:9, 4:3, 5:4, 1:1',
        effects: 'crop,rotate',
        tabs: '',
        publicKey: uploadcareKey,
        cdnBase: process.env.REACT_APP_UPLOAD_CARE_CDN,
        previewUrlCallback: buildPreviewUrl
      });

      cropWidget.done(
        (res: { done: (result: (croppedFileInfo: any) => Promise<void>) => void }) => {
          res.done(async croppedFileInfo => {
            try {
              const cropAsset = await openedCase.basicCase.updateAssetMeta(
                uploadedAsset.id,
                croppedFileInfo.uuid,
                croppedFileInfo
              );
              // const cropAsset = await openedCase.updateAssetMeta({
              //   id: uploadedAsset.id,
              //   ...asset,
              //   file: croppedFileInfo
              // });
              setAsset({
                token: cropAsset.token,
                id: uploadedAsset.id,
                file: croppedFileInfo,
                desc: ''
              });
              setOpen(true);
            } catch (error) {
              ErrorMonitor.captureException(error as Error);
              toast.error(`Sorry, that didn't work... 🤷. Please try again.`);
              log(error);
            }
          });
        }
      );
    } catch (error) {
      log(error);
      ErrorMonitor.captureException(error as Error);
      toast.error(`Sorry, that didn't work... 🤷. Please try again.`);
    }
  };

  // @ts-expect-error no types
  const hasCdnUrl = asset.file?.cdnUrl !== undefined && asset.file?.cdnUrl !== '';

  const previewSrc = !hasCdnUrl ? '' : getPreviewSrc(asset);

  const handleSubmit = async (): Promise<void> => {
    if (asset.file === undefined) {
      return;
    }
    // @ts-expect-error no types
    void openedCase.basicCase.updateAssetDescription(asset.id, asset.file.uuid, asset.desc);
    handleClose();
  };

  // Wait for the key & token to be ready.
  // Also renders a new widget between uploads.
  // @ts-expect-error no types
  const hasFile = asset?.file?.uuid !== undefined;
  const renderWidget = uploadcareKey !== '' && token.expire > 0 && !hasFile;
  return (
    <>
      <Button
        classNames="btn add-button"
        onClick={async (e: MouseEvent) => {
          if (widgetRef.current === null || widgetRef.current === undefined) {
            return;
          }
          // @ts-expect-error no types
          widgetRef.current.openDialog(null);
        }}
        startIcon={<PlusCircleIcon height={20} />}
      />
      {renderWidget && (
        <Widget
          // @ts-expect-error no types
          ref={widgetRef}
          imageShrink="1600x1600"
          imagesOnly
          onChange={handleUpload}
          systemDialog
          publicKey={uploadcareKey}
          cdnBase={process.env.REACT_APP_UPLOAD_CARE_CDN}
          secureExpire={token.expire}
          secureSignature={token.signature}
          validators={[checkMaxFileSize(MAX_FILE_SIZE)]}
        />
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
        className="image-confirm-dialog"
      >
        <DialogTitle>{t('Add image to case')}</DialogTitle>
        <DialogContent>
          {hasCdnUrl && <img src={previewSrc} alt="preview" />}
          <div className="text-field-container">
            <TextField
              placeholder="Description"
              className="text-field"
              value={asset.desc ?? ''}
              onChange={e => {
                handleDesc(e.target.value);
              }}
              type="text"
              multiline
              rows={3}
              fullWidth
            />
          </div>
          {/* <DialogContentText className="text-xs">{t('Add a description.')}</DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <MuiButton autoFocus onClick={handleSubmit} className="btn secondary">
            {t('Done')}
          </MuiButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UploadImageButton;
