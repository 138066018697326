import { useEffect } from 'react';
import { useRootStore } from 'app/mobxStore';
import { isInstalled } from 'app/mobxStore/caseStoreHelper';
import ffService from 'app/services/ffService/ffService';
import oneSignalService from 'app/services/oneSignalService';
import {
  getIsShownPushPopup,
  getIsShownPushPopupDates,
  setShownPushPopupDates
} from '../../../mobxStore/storage';

const useShowPushPopup = (): void => {
  const { userStore } = useRootStore();

  const shouldShow = async (): Promise<boolean> => {
    if (!isInstalled() || !ffService.isFlagOnPushNotifications()) {
      return false;
    }

    const isOpt = await oneSignalService.isOptedIn();
    if (isOpt) {
      return false;
    }

    const pnPopupShownDates = getIsShownPushPopupDates(); // new per-date algo

    const setBackwardCompat = (): void => {
      // If didn't show before, nothing to adjust
      const pnPopupShown = getIsShownPushPopup(); // old boolean algo
      if (!pnPopupShown) {
        return;
      }

      // If shown in the old format and not in the new format, treat as if shown now
      if (pnPopupShown && pnPopupShownDates.length === 0) {
        addShownDate();
      }
    };

    setBackwardCompat();

    if (pnPopupShownDates.length === 0) {
      return true;
    }

    const checkDaysPassed = (date: string, days: number): boolean => {
      const lastShownDate = new Date(date);
      const currentDate = new Date();
      const diff = currentDate.getTime() - lastShownDate.getTime();

      const milliSecPerDay = 1000 * 60 * 60 * 24;
      return diff > milliSecPerDay * days;
    };

    if (pnPopupShownDates.length === 1) {
      return checkDaysPassed(pnPopupShownDates[0], 2);
    }

    if (pnPopupShownDates.length === 2) {
      return checkDaysPassed(pnPopupShownDates[1], 7);
    }

    if (pnPopupShownDates.length === 3) {
      return checkDaysPassed(pnPopupShownDates[2], 30);
    }

    return false;
  };

  const addShownDate = (): void => {
    const pnPopupShownDates = getIsShownPushPopupDates();
    const currentDate = new Date();
    pnPopupShownDates.push(currentDate.toISOString());
    setShownPushPopupDates(pnPopupShownDates);
  };

  useEffect(() => {
    const f = async (): Promise<void> => {
      const should = await shouldShow();
      if (!should) {
        return;
      }

      setTimeout(async () => {
        const success = await oneSignalService.showOneSignalPopup(
          userStore.loggedInUser.data.id,
          userStore.loggedInUser.data.email,
          false
        );
        if (!success) {
          return;
        }
        addShownDate();
      }, 10000);
    };

    void f();
  }, []);
};

export default useShowPushPopup;
