/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import type { IUserSelectOption } from 'app/mobxStore/types';
import { getFirstCareTeamRoleGroupMeta } from 'app/consts';
import clsx from 'clsx';
import Avatar from '@mui/material/Avatar';
import variables from 'app/utils/variables.module.scss';
import './UserAvatar.scss';
import ErrorMonitor from '../../services/errorMonitor/errorMonitor';

const getAvatarContent = (user: IUserSelectOption, nameInitials: string): React.JSX.Element => {
  if (user.avatar !== '' && user.avatar !== null && user.avatar !== undefined) {
    return <img className="avatar-image" src={user.avatar} />;
  }
  return <div className="avatar-text">{nameInitials}</div>;
};

const getAvatarSize = (size: string | undefined): string => {
  if (size === 'small') {
    return '22px';
  }
  if (size === 'medium') {
    return '28px';
  }
  if (size === 'large') {
    return '68px';
  }
  return '40px';
};

const UserAvatar = (props: { user: IUserSelectOption; size?: string }): React.JSX.Element => {
  const { user, size } = props;
  const [isWithPrefix, setIsWithPrefix] = useState(false);

  useEffect(() => {
    setIsWithPrefix(user.label?.toLowerCase().startsWith('dr.') ?? false);
  }, [user.label]);

  const getNameInitials = (): string => {
    if (!user.label) {
      return user.firstName && user.lastName
        ? `${user.firstName[0]}${user.lastName[0]}`.toUpperCase()
        : '?';
    }
    const words = user.label.split(' ').filter(word => word.length > 0);
    if (words[0].toLowerCase() === 'dr.' && words.length > 1) {
      return `Dr.${words[1][0].toUpperCase()}`;
    }
    return (
      words
        .slice(0, 2)
        .map(word => word[0])
        .join('')
        .toUpperCase() || '?'
    );
  };

  const nameInitials = getNameInitials();
  if (nameInitials === '?') {
    ErrorMonitor.captureException(
      new Error('UserAvatar: cannot generate initials, strings are empty')
    );
  }

  const avatarSize = getAvatarSize(size);

  const getBgColor = (): string => {
    const roleGroup = getFirstCareTeamRoleGroupMeta(user.roles);
    return roleGroup.color;
  };

  return (
    <div className={clsx('avatar-container', [size, { 'with-prefix': isWithPrefix }])}>
      <Avatar
        sx={{
          bgcolor: getBgColor(),
          height: avatarSize,
          width: avatarSize
        }}
        style={{ borderColor: variables.primaryYellow }}
      >
        {getAvatarContent(user, nameInitials)}
      </Avatar>
    </div>
  );
};

export default React.memo(UserAvatar);
