import React, { type MouseEvent } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import './Button.scss';

interface ButtonProps {
  id?: string;
  classNames?: string;
  buttonText?: string;
  buttonContent?: React.JSX.Element;
  startIcon?: React.JSX.Element;
  endIcon?: React.JSX.Element | null;
  onClick?: (e: MouseEvent) => Promise<void>;
  disabled?: boolean;
  isShowMandatory?: boolean;
  buttonRef?: React.RefObject<HTMLButtonElement>;
  disableTouchRipple?: boolean;
}

const ButtonComponent = (props: ButtonProps): React.JSX.Element => {
  const {
    id,
    classNames,
    buttonText,
    buttonContent,
    startIcon,
    endIcon,
    disabled,
    isShowMandatory,
    onClick,
    buttonRef,
    disableTouchRipple
  } = props;
  const { t } = useTranslation();

  return (
    <Button
      ref={buttonRef}
      id={id}
      disableFocusRipple
      className={clsx('btn', [classNames], { disabled })}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={onClick}
      disabled={disabled}
      disableTouchRipple={disableTouchRipple}
    >
      {buttonText != null && t(buttonText)}
      {buttonContent != null && buttonContent}
      {(isShowMandatory ?? false) && <div className="mandatory">*</div>}
    </Button>
  );
};

export default ButtonComponent;
