import _ from 'lodash';
import { useEffect, useRef } from 'react';

const useDebounce = (
  func: any,
  wait: number,
  options: _.DebounceSettings
): { refreshFunc: () => void; debounceFunc: _.DebouncedFunc<any> } => {
  const refreshFunc = (): void => {
    debounceRef.current = _.debounce(func, wait, options);
  };
  const debounceRef = useRef(_.debounce(func, wait, options));
  useEffect(() => {
    debounceRef.current = _.debounce(func, wait, options);
  }, [func]);
  return { debounceFunc: debounceRef.current, refreshFunc };
};

export default useDebounce;
