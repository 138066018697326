import React from 'react';
import type { IUserSelectOption } from 'app/mobxStore/types';
import clsx from 'clsx';
import AvatarGroup from '@mui/material/AvatarGroup';
import UserAvatar from 'app/components/userAvatar/UserAvatar';
import './UserAvatarGroup.scss';

const getAvatarSize = (size: string | undefined): string => {
  if (size === 'small') {
    return '22px';
  }
  if (size === 'medium') {
    return '28px';
  }
  return '40px';
};

const UserAvatarGroup = (props: {
  users: IUserSelectOption[];
  maxUsers: number;
  size?: string;
}): React.JSX.Element => {
  const { users, maxUsers, size } = props;

  const avatarSize = getAvatarSize(size);
  const maxAvatars = maxUsers + 1;
  return (
    <div className="avatar-group-container">
      <AvatarGroup
        className={clsx('avatar-group', [size], {
          'double-digit': users.length - maxAvatars + 1 > 9
        })}
        max={maxAvatars}
        sx={{
          '& .MuiAvatarGroup-avatar': { width: avatarSize, height: avatarSize, fontSize: 15 }
        }}
      >
        {users.map(user => (
          <UserAvatar key={user.userId} user={user} size={size} />
        ))}
      </AvatarGroup>
    </div>
  );
};

export default UserAvatarGroup;
