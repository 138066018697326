import * as React from 'react';
const SvgComponent = props => (
  <svg
    className="svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 14"
    fill={props.fill || 'none'}
    {...props}
  >
    <path
      d="M10.75 10.75L13.25 13.25M12.4167 6.58333C12.4167 9.80499 9.80499 12.4167 6.58333 12.4167C3.36167 12.4167 0.75 9.80499 0.75 6.58333C0.75 3.36167 3.36167 0.75 6.58333 0.75C9.80499 0.75 12.4167 3.36167 12.4167 6.58333Z"
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgComponent;
