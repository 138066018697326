import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'app/components/buttons/Button';
import EnvelopeIcon from 'app/assets/icons/Envelope_icon';
import variables from 'app/utils/variables.module.scss';
import './MyAccount.scss';
import AmplService from '../../services/amplService/amplService';

const ContactUsButton = (): React.JSX.Element => {
  const { t } = useTranslation();

  const handleOpenMailTo = async (): Promise<void> => {
    window.location.assign('mailto:hello@chiefyteam.com');
    AmplService.sendEvent(AmplService.EVENTS.ACCOUNT_CONTACT_US);
  };

  return (
    <Button
      classNames="btn action-button"
      buttonContent={
        <div className="button-content">
          <div className="button-icon">
            <EnvelopeIcon height={14} fill={variables.black} />
          </div>
          {t('contactUs')}
        </div>
      }
      onClick={handleOpenMailTo}
    />
  );
};

export default ContactUsButton;
