import React from 'react';
import type { Control } from 'react-hook-form/dist/types/form';
import type { IUserSelectOption } from 'app/mobxStore/types';
import { FORM_FIELDS_ENUM, type IFormData } from './types';
import UserSelect from './UserSelect';

interface IProps {
  control: Control<IFormData>;
  options: IUserSelectOption[];
  allValues: IFormData;
  isReadOnly: boolean;
}

const SurgeonSelect = (props: IProps): React.JSX.Element => {
  return (
    <UserSelect
      control={props.control}
      options={props.options}
      allValues={props.allValues}
      name={FORM_FIELDS_ENUM.SURGEON}
      required={true}
      placeholder="surgeon"
      isReadOnly={props.isReadOnly}
    />
  );
};

export default SurgeonSelect;
