import * as React from 'react';
const SvgComponent = props => (
  <svg
    className="svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 12"
    fill={props.fill || 'none'}
    {...props}
  >
    <path
      d="M1 11.5C0.867387 11.5 0.740237 11.4473 0.64642 11.3536C0.55267 11.2598 0.5 11.1326 0.5 11V0.99999C0.5 0.82134 0.595313 0.656307 0.75 0.56699C0.904687 0.477673 1.09532 0.477667 1.25 0.56699C1.40468 0.656313 1.5 0.821356 1.5 0.99999V11C1.5 11.1326 1.44733 11.2598 1.35358 11.3536C1.25977 11.4473 1.13262 11.5 1 11.5Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M17 11.5C16.8674 11.5 16.7402 11.4473 16.6464 11.3536C16.5527 11.2598 16.5 11.1326 16.5 11V0.99999C16.5 0.82134 16.5953 0.656307 16.75 0.56699C16.9047 0.477673 17.0953 0.477667 17.25 0.56699C17.4047 0.656313 17.5 0.821356 17.5 0.99999V11C17.5 11.1326 17.4473 11.2598 17.3536 11.3536C17.2598 11.4473 17.1326 11.5 17 11.5Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M17 1.5H0.99999C0.82134 1.5 0.656307 1.40469 0.56699 1.25C0.477673 1.09531 0.477667 0.904683 0.56699 0.75C0.656313 0.595317 0.821357 0.5 0.99999 0.5H17C17.1786 0.5 17.3437 0.595313 17.433 0.75C17.5223 0.904687 17.5223 1.09532 17.433 1.25C17.3437 1.40468 17.1786 1.5 17 1.5Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M17 11.5H0.99999C0.82134 11.5 0.656307 11.4047 0.56699 11.25C0.477673 11.0953 0.477667 10.9047 0.56699 10.75C0.656313 10.5953 0.821357 10.5 0.99999 10.5H17C17.1786 10.5 17.3437 10.5953 17.433 10.75C17.5223 10.9047 17.5223 11.0953 17.433 11.25C17.3437 11.4047 17.1786 11.5 17 11.5Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M8.99963 7.49999C8.89155 7.50006 8.78634 7.46497 8.70001 7.39999L0.700009 1.39999C0.557106 1.29283 0.482243 1.11751 0.503593 0.94016C0.524943 0.76281 0.639334 0.610343 0.803593 0.54016C0.967851 0.470043 1.15711 0.49283 1.30001 0.59999L9.30001 6.59999C9.4127 6.6845 9.48451 6.81262 9.49779 6.95292C9.51107 7.09316 9.46453 7.23247 9.36973 7.33664C9.27488 7.44081 9.14051 7.50012 8.99963 7.49999Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M9.0004 7.49999C8.85952 7.50012 8.72514 7.44081 8.63029 7.33664C8.5355 7.23248 8.48895 7.09316 8.50223 6.95292C8.51551 6.81262 8.58732 6.68451 8.70001 6.59999L16.7 0.599991C16.8429 0.49283 17.0322 0.470043 17.1964 0.540161C17.3607 0.610343 17.4751 0.762811 17.4964 0.940161C17.5178 1.11751 17.4429 1.29283 17.3 1.39999L9.30001 7.4C9.21362 7.46497 9.10849 7.50005 9.0004 7.49999Z"
      fill={props.fill || '#B33B94'}
    />
  </svg>
);
export default SvgComponent;
