import _ from 'lodash';

const camelize = (string: string): string => {
  if (_.isNumber(string)) {
    return string;
  }
  string = string.replace(/[-_\s]+(.)?/g, function (match, chr) {
    return chr !== '' ? chr.toUpperCase() : '';
  });
  return string.substring(0, 1).toLowerCase() + string.substring(1);
};
export const camelizeKeys = (object: object): object => {
  return _processKeys(object, camelize);
};
export const decamelizeKeys = (object: object): object => {
  return _processKeys(object, decamelize);
};

const decamelize = (string: string): string => {
  return separateWords(string).toLowerCase();
};

const separateWords = (string: string): string => {
  const separator = '_';
  const split = /(?=[A-Z])/;

  return string.split(split).join(separator);
};

const _processKeys = (obj: object, convert: (string: string) => string): object => {
  if (
    !_.isObject(obj) ||
    _.isDate(obj) ||
    _.isRegExp(obj) ||
    _.isBoolean(obj) ||
    _.isFunction(obj)
  ) {
    return obj;
  }

  let output;
  let i = 0;
  let l = 0;

  if (_.isArray(obj)) {
    output = [];
    for (l = obj.length; i < l; i++) {
      output.push(_processKeys(obj[i], convert));
    }
  } else {
    output = {};
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        // @ts-expect-error change to map
        output[convert(key)] = _processKeys(obj[key], convert);
      }
    }
  }
  return output;
};
