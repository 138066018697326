import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../mobxStore';
import clsx from 'clsx';
import Button from 'app/components/buttons/Button';
import TrashIcon from 'app/assets/icons/Trash_icon';
import ImagesSwiper from './ImagesSwiper';
import './HuddleImages.scss';

interface CustomImage {
  src: string;
  index: number;
}

interface IProps {
  images: string[];
  thumbs: string[];
  handleDelete: (index: number) => void;
}

const ImageGallery = (props: IProps): React.JSX.Element => {
  const { images, thumbs, handleDelete } = props;
  const { canUpdate } = useRootStore();
  const [imagesArray, setImagesArray] = useState<CustomImage[]>([]);
  const [thumbsArray, setThumbsArray] = useState<CustomImage[]>([]);
  const [initialSlide, setInitialSlide] = useState<{ index: null | number }>({ index: null });

  const populateGallery = (): void => {
    const galleryList = images.map((src, index) => {
      const galleryImage = {
        src,
        index: index + 1
      };
      return galleryImage;
    });
    const thumbsList = thumbs.map((src, index) => {
      const galleryImage = {
        src,
        index: index + 1
      };
      return galleryImage;
    });

    setImagesArray(galleryList);
    setThumbsArray(thumbsList);
  };

  useEffect(() => {
    populateGallery();
  }, [images]);

  return (
    <div className="images-gallery">
      <div className={clsx('image-grid', { 'edit-mode': canUpdate })}>
        {thumbsArray.map((img, index) => {
          return (
            <div
              key={img.index}
              className="thumbnail"
              onClick={() => {
                setInitialSlide({ index: img.index });
              }}
            >
              {canUpdate && (
                <Button
                  classNames="circle-button delete-button"
                  endIcon={<TrashIcon height={16} />}
                  onClick={async e => {
                    e.stopPropagation();
                    handleDelete(index);
                  }}
                />
              )}
              <img className="image" src={img.src}></img>
            </div>
          );
        })}
      </div>
      {initialSlide.index !== null && (
        <ImagesSwiper
          setInitialSlide={setInitialSlide}
          initialSlide={initialSlide.index}
          imagesArray={imagesArray}
        />
      )}
    </div>
  );
};

export default observer(ImageGallery);
