import * as React from 'react';
const SvgComponent = props => (
  <svg
    className="svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 42 36"
    fill={props.fill || 'none'}
    {...props}
  >
    <path
      d="M12 1.00146C5.92487 1.00146 1 5.92636 1 12.0015C1 23.0015 14 33.0015 21 35.3277C28 33.0015 41 23.0015 41 12.0015C41 5.92636 36.0751 1.00146 30 1.00146C26.2797 1.00146 22.9907 2.84837 21 5.67527C19.0093 2.84837 15.7203 1.00146 12 1.00146Z"
      stroke={props.stroke || '#B33B94'}
      fill={props.fill || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgComponent;
