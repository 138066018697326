import React from 'react';
import { useRootStore } from 'app/mobxStore';
import { observer } from 'mobx-react-lite';
import type { ICase } from '../../../mobxStore/types';
import { useTranslation, Trans } from 'react-i18next';
import { isDateInPast } from '../../../mobxStore/caseStoreHelper';
import AmplService from 'app/services/amplService';
import IconButton from '@mui/material/IconButton';
import variables from 'app/utils/variables.module.scss';
import SuccessIcon from 'app/assets/icons/Success_icon';
import RadarIcon from 'app/assets/icons/RadarTwo_icon';
import InviteUserIcon from 'app/assets/icons/InviteUser_icon';

const DailyHuddleStats = (): React.JSX.Element => {
  const { t } = useTranslation();
  const { caseStore, userStore, metaDataStore } = useRootStore();

  if (!userStore.loggedInUser.isAttending) {
    return <></>;
  }

  // @todo move to getter
  const myTotalCases = caseStore.sortedCases.filter(
    (c: ICase): boolean => c.data.attendingId === userStore.loggedInUser.data.id
  );
  // @todo move to getter
  const myDoneCases = myTotalCases.filter((c: ICase): boolean => c.data.isReady);

  const totalCount = myTotalCases.length;
  const doneCount = myDoneCases.length;

  const empty = totalCount === 0;
  const done = doneCount === totalCount;
  const single = totalCount === 1;
  const isPast = isDateInPast(caseStore.selectedDateFilter, caseStore.rootStore.tz);
  const isInviteBanner = metaDataStore.users.length === 1 && userStore.loggedInUser.isInviter;

  let notDoneText = '';
  if (!empty && !done) {
    if (isPast) {
      notDoneText = 'dailyStatsNotDonePast';
    } else if (single) {
      notDoneText = 'dailyStatsNotDoneSingle';
    } else {
      notDoneText = 'dailyStatsNotDonePlural';
    }
  }

  const getBannerContent = (): React.JSX.Element => {
    let text = notDoneText;
    let icon = <RadarIcon stroke={variables.notification} />;
    if (isInviteBanner) {
      text = 'dailyStatsOnlyUser';
      icon = <></>;
    } else {
      if (empty) {
        text = 'dailyStatsEmpty';
        icon = <SuccessIcon stroke={variables.success} />;
      } else if (done) {
        text = 'dailyStatsDone';
        icon = <SuccessIcon stroke={variables.success} />;
      }
    }
    return (
      <div className="content">
        {icon}
        <Trans
          i18nKey={text}
          values={{ doneCount, totalCount }}
          components={{ bold: <span style={{ fontWeight: 900, margin: '0px 4px' }} /> }}
        />
        {isInviteBanner && (
          <div className="button-container">
            <IconButton
              className="invite-button"
              onClick={() => {
                caseStore.setIsInviteDialogOpen(true);
                AmplService.sendEvent(AmplService.EVENTS.HOME_INVITE);
              }}
            >
              <div className="button-icon">
                <InviteUserIcon />
              </div>
              <div className="text-expand-container">
                <div className="button-text">{t('inviteTeam')}</div>
              </div>
            </IconButton>
          </div>
        )}
      </div>
    );
  };

  const bannerContent = getBannerContent();
  return (
    <div className="huddle-stats">
      <div className="content">{bannerContent}</div>
    </div>
  );
};

export default observer(DailyHuddleStats);
