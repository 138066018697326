import React, { type MouseEvent } from 'react';
import type { IProcedureSelectOption } from 'app/mobxStore/types';
import Button from 'app/components/buttons/Button';

interface IProps {
  procedure: IProcedureSelectOption;
  handleClickSuggestion: (procedure: IProcedureSelectOption) => void;
}

const ProcedureSuggestionButton = (props: IProps): React.JSX.Element => {
  const { procedure, handleClickSuggestion } = props;

  // calculate button width by text length (to avoid blank space when text wraps)
  // useEffect(() => {
  //   const range = document.createRange();
  //   const p = document.getElementById(procedure.value);
  //   const text = p?.childNodes[0];
  //   if (text) {
  //     range.setStartBefore(text);
  //     range.setEndAfter(text);
  //   }
  //   const clientRect = range.getBoundingClientRect();
  //   if (p) {
  //     p.style.width = `${clientRect.width}px`;
  //   }
  // }, []);

  return (
    <Button
      classNames="chip-button suggestion"
      buttonContent={<p id={procedure.value}>{procedure.label}</p>}
      onClick={async (e: MouseEvent) => {
        handleClickSuggestion(procedure);
      }}
    />
  );
};

export default ProcedureSuggestionButton;
