import React from 'react';
import { observer } from 'mobx-react-lite';
import NonSurgeonCasesToggle from './NonSurgeonCasesToggle';
import './Filters.scss';

const FiltersNonSurgeon = (): React.JSX.Element => {
  return (
    <div className="case-filters">
      <NonSurgeonCasesToggle />
    </div>
  );
};

export default observer(FiltersNonSurgeon);
