import React from 'react';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { COMMENT_FORM_FIELDS_ENUM, type ICommentFormData } from '../fields/types';
import { useTranslation } from 'react-i18next';
import CommentTitle from '../fields/CommentTitle';
import SubmitButton from 'app/components/buttons/SubmitButton';
import { useRootStore } from '../../mobxStore';
import type { IFullCase, IWillNotifyInfo } from '../../mobxStore/types';
import variables from 'app/utils/variables.module.scss';
import RightIcon from 'app/assets/icons/Right_icon';
import ClockIcon from 'app/assets/icons/Clock_icon';
import AmplService from '../../services/amplService';

interface IProps {
  willNotify: IWillNotifyInfo;
  onSendMessage: () => void;
}

const CommentForm = (props: IProps): React.JSX.Element => {
  const { willNotify } = props;
  const { reset, control, handleSubmit } = useForm<ICommentFormData>({});
  const { caseStore } = useRootStore();
  const { t } = useTranslation();
  const openedCase = caseStore.openedCase as IFullCase;
  const submitFunction = async (data: ICommentFormData): Promise<void> => {
    // clear temp comment from store on submit
    caseStore.setTempComment('');
    void openedCase.commentsStore.addComment(data.comment);
    reset({
      [COMMENT_FORM_FIELDS_ENUM.COMMENT]: ''
    });
    props.onSendMessage();
  };

  return (
    <form className="comment-form">
      {willNotify.notify && !willNotify.isNow && (
        <div className="notifications">
          <ClockIcon stroke={variables.grey6} />
          <div className="notification-text">{t(willNotify.msgKey, willNotify.msgOptions)}</div>
        </div>
      )}
      <div
        className="input-container"
        onKeyDown={e => {
          /* Submit on Enter + Alt or Enter + Cmd */
          if (e.key === 'Enter' && (e.altKey || e.metaKey)) {
            void handleSubmit(submitFunction)();
          }
        }}
      >
        <CommentTitle control={control} />
        <SubmitButton
          id="comment-form-submit-button"
          submitFunction={async (): Promise<void> => {
            await handleSubmit(submitFunction)();
            AmplService.sendCaseEvent(AmplService.EVENTS.CHAT_SEND, openedCase.basicCase);
          }}
          content={<RightIcon fill={variables.white} height={16} />}
          classNames="submit-message-button"
        />
      </div>
    </form>
  );
};

export default observer(CommentForm);
