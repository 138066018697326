import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const SITE_FULL_DATA = gql`
  fragment SiteFullData on sites {
    id
    name
    specialties
    timezone
  }
`;
