import React, { forwardRef, useState, useEffect } from 'react';
import clsx from 'clsx';
import { useRootStore } from 'app/mobxStore';
import { observer } from 'mobx-react-lite';
import { TabsEnum } from 'app/mobxStore/types';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import Fade from '@mui/material/Fade';

import './ModalScreen.scss';

interface IProps {
  content: React.JSX.Element | null;
  isOpen: boolean;
  onCloseEnd?: () => void;
  handleClose: () => void;
  classNames?: string;
  transitionType?: string;
}

interface TransitionProps {
  children: React.ReactElement<any, any>;
}

const slideTransition = forwardRef(function Transition(props: TransitionProps, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const fadeTransition = forwardRef(function Transition(props: TransitionProps, ref) {
  return <Fade in {...props} ref={ref} />;
});

const ModalScreen = (props: IProps): React.JSX.Element | null => {
  const { content, isOpen, onCloseEnd, handleClose, classNames, transitionType } = props;
  const { caseStore } = useRootStore();
  const [isBlured, setIsBlured] = useState(false);

  const scrollRef = useBottomScrollListener<HTMLDivElement>(
    () => {
      if (caseStore.selectedTabInHuddle === TabsEnum.CASE && isOpen) {
        caseStore.setHuddleScrolledToBottom(true);
      }
    },
    { offset: 100, debounce: 100 }
  );

  useEffect(() => {
    if (isOpen) {
      setIsBlured(true);
    } else {
      caseStore.setLastHuddleScrollPosition(0);
      setIsBlured(false);
    }
  }, [isOpen]);

  useEffect(() => {
    return () => {
      caseStore.setHuddleScrolled(false);
      caseStore.setHuddleScrolledToBottom(false);
    };
  }, []);

  const onClose = (): void => {
    handleClose();
  };

  const handleScroll = (): void => {
    if (scrollRef.current !== null && caseStore.selectedTabInHuddle === TabsEnum.CASE) {
      caseStore.setLastHuddleScrollPosition(scrollRef.current.scrollTop);
    }
    if (scrollRef.current !== null && scrollRef.current.scrollTop > 20) {
      caseStore.setHuddleScrolled(true);
    } else {
      caseStore.setHuddleScrolled(false);
    }
  };

  useEffect(() => {
    if (caseStore.selectedTabInHuddle === TabsEnum.CASE && scrollRef.current !== null) {
      scrollRef.current.scrollTop = caseStore.lastHuddleScrollPosition;
    }
  }, [caseStore.selectedTabInHuddle]);

  const trans = (): React.ForwardRefExoticComponent<
    TransitionProps & React.RefAttributes<unknown>
  > => {
    if (transitionType === 'fade') {
      return fadeTransition;
    }
    return slideTransition;
  };
  return (
    <>
      <Dialog
        className={clsx('modal-dialog', { blur: isBlured }, [classNames])}
        open={isOpen}
        onClose={onClose}
        TransitionComponent={trans()}
        transitionDuration={300}
        TransitionProps={{
          onExited: () => {
            if (onCloseEnd != null) {
              onCloseEnd();
            }
          }
        }}
      >
        <div
          className="modal-container"
          style={{ overflowY: 'auto', overflowAnchor: 'none' }}
          onScroll={handleScroll}
          ref={scrollRef}
        >
          {content}
        </div>
      </Dialog>
    </>
  );
};

export default observer(ModalScreen);
