import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ConfirmationPanel from 'app/components/confirmationPanel/ConfirmationPanel';
import { isToday, isTomorrow } from '../../../mobxStore/timeHelper';
import { t } from 'i18next';
import { lite } from '../../../services/apiService/apiService';
import { useRootStore } from '../../../mobxStore';

interface IProps {
  isShowPanel: boolean;
  setIsShowPanel: (isShowPanel: string) => void;
  handlePostponeConfirm: () => void;
  origCaseDate: Date;
  dateToPostponeCase: Date;
}

const dateToString = (date: Date, tz: string): string => {
  if (isToday(date, tz)) {
    return t('today');
  }
  if (isTomorrow(date, tz)) {
    return t('tomorrow');
  }
  const dateStr = date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  // if date is in the future and is in the coming sunday or before, return day name
  if (new Date().getDay() < date.getDay() && date.getDay() <= 6) {
    return `this ${date.toLocaleDateString('locale', { weekday: 'long' })}, ${dateStr}`;
  }

  return dateStr;
};

const CasePostponePanel = (props: IProps): React.JSX.Element => {
  const { isShowPanel, setIsShowPanel, handlePostponeConfirm, dateToPostponeCase, origCaseDate } =
    props;
  const { tz } = useRootStore();
  const { t } = useTranslation();

  const handlePostpone = async (): Promise<void> => {
    handleClose();
    handlePostponeConfirm();
  };

  const handleCancel = async (): Promise<void> => {
    handleClose();
  };

  const handleClose = (): void => {
    setIsShowPanel('');
  };

  const date = dateToString(dateToPostponeCase, tz);

  const shouldNotify = (): boolean => {
    return isToday(origCaseDate, tz) || isTomorrow(origCaseDate, tz);
  };

  const getText = (): string => {
    if (lite) {
      return t('casePostponePanelTextWithoutNotify');
    }
    if (shouldNotify()) {
      return t('casePostponePanelTextWithNotify');
    }
    return t('casePostponePanelTextWithoutNotify');
  };

  const getButtonText = (): string => {
    if (shouldNotify()) {
      return t('casePostponePanelYesWithNotify');
    }
    return t('casePostponePanelYesWithoutNotify');
  };

  return (
    <ConfirmationPanel
      title={
        <Trans
          i18nKey="casePostponePanelTitle"
          values={{
            date
          }}
        />
      }
      text={getText()}
      buttons={[
        {
          buttonText: getButtonText(),
          onClick: handlePostpone,
          variant: 'outlined',
          closeOnClick: true,
          key: 'yes'
        },
        {
          buttonText: t('back'),
          onClick: handleCancel,
          variant: 'secondary',
          closeOnClick: true,
          key: 'no'
        }
      ]}
      isShowPanel={isShowPanel}
      setIsShowPanel={isTrue => {
        if (isTrue) {
          return;
        }
        handleClose();
      }}
    />
  );
};

export default CasePostponePanel;
