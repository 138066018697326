import React, { useEffect, useState } from 'react';
import { useRootStore } from 'app/mobxStore';
import { observer } from 'mobx-react-lite';
import { type CaptionProps, DayPicker, type DayProps } from 'react-day-picker';
import DayComponent from 'app/components/datePickers/DayComponent';
import CaptionComponent from 'app/components/datePickers/CaptionComponent';
import 'react-day-picker/dist/style.css';
import './ClassicDatePicker.scss';
import MomentAdapter from '@date-io/moment';
import { toZonedTime } from 'date-fns-tz';

const scrollToDay = (day: string): void => {
  const element = document.getElementById(day);
  if (element != null) {
    element.scrollIntoView({ behavior: 'smooth', inline: 'center' });
  }
};

const ClassicDatePicker = (props: {
  handleClickDay: (day: Date) => void;
  selectedDay: Date;
  hideHasCases?: boolean;
  navigateToToday: () => void;
  shouldScroll?: boolean;
  disablePast?: boolean;
}): React.JSX.Element => {
  const { handleClickDay, selectedDay, hideHasCases, navigateToToday, shouldScroll, disablePast } =
    props;
  const { caseStore, metaDataStore } = useRootStore();
  const [month, setMonth] = useState(selectedDay);

  const handleClick = (day: Date): void => {
    handleClickDay(day);
    if (shouldScroll) {
      scrollToDay(day.toLocaleDateString('locale'));
    }
  };
  const handleClickToday = (): void => {
    navigateToToday();
    if (shouldScroll) {
      const today = new Date();
      const zonedToday = toZonedTime(today, metaDataStore.tz);
      scrollToDay(zonedToday.toLocaleDateString());
    }
  };

  useEffect(() => {
    setMonth(selectedDay);
  }, [selectedDay]);

  const dateLib = new MomentAdapter();

  const dayPickerDisabled = (date: Date): boolean => {
    const momentDate = dateLib.date(date);
    if (momentDate === null) {
      throw new Error('momentDate is null');
    }
    if (disablePast) {
      return momentDate.isBefore(dateLib.date()?.subtract(1, 'days'));
    }
    return momentDate.isBefore(dateLib.date(caseStore.minDate));
  };

  return (
    <div className="classic-date-picker">
      <div className="picker-container">
        <DayPicker
          selected={selectedDay}
          disabled={dayPickerDisabled}
          month={month}
          weekStartsOn={1}
          components={{
            Day: (dayProps: DayProps) => (
              <DayComponent
                dayProps={dayProps}
                month={month}
                handleClickDay={handleClick}
                selectedDay={selectedDay}
                hideHasCases={hideHasCases}
              />
            ),
            Caption: (captionProps: CaptionProps) => (
              <CaptionComponent
                captionProps={captionProps}
                month={month}
                setMonth={setMonth}
                navigateToToday={handleClickToday}
              />
            )
          }}
        />
      </div>
    </div>
  );
};

export default observer(ClassicDatePicker);
