import * as Sentry from '@sentry/react';
import apiService from 'app/services/apiService';
import React, { useCallback, useState } from 'react';
import AppContext from 'app/AppContext';
import { Auth } from 'app/auth';
import routes from 'app/fuse-configs/routesConfig';
import Router from 'app/components/router/Router';
import debug from 'debug';
// import Div100vh from 'react-div-100vh';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Auth0Provider } from '@auth0/auth0-react';
import AUTH_CONFIG from './services/auth0Service/auth0ServiceConfig';
import { getAud } from './services/helper';
import { Detector } from 'react-detect-offline';
import { useDebounce } from '../@fuse/hooks';
import OfflinePage from './pages/offlinePage/OfflinePage';
import ErrorMonitor from './services/errorMonitor/errorMonitor';

debug('cr-client');

ErrorMonitor.init();

const first = window.location.host.split('.')[0];

const aud = getAud(first);
if (aud === undefined) {
  throw new Error('aud is not defined');
}

const App = (): React.JSX.Element => {
  const [isOnline, setIsOnline] = useState(true);
  const debounceFunction = useCallback((online: boolean) => {
    setIsOnline(online);
  }, []);
  const debounceSetIsOnline = useDebounce(debounceFunction, 1100, {
    leading: false,
    trailing: true
  }).debounceFunc;

  return (
    <Auth0Provider
      clientId={AUTH_CONFIG.clientId}
      domain={AUTH_CONFIG.domain}
      authorizationParams={{
        audience: AUTH_CONFIG.aud,
        connection: AUTH_CONFIG.connection,
        redirect_uri: AUTH_CONFIG.callbackUrl,
        scope: 'email',
        login_hint: AUTH_CONFIG.loginHint
      }}
    >
      <AppContext.Provider
        value={{
          routes,
          // err logger
          Sentry,
          // api
          apiService
        }}
      >
        <Auth>
          {/* <Div100vh className="app-container"> */}
          <div className="app-container">
            <Detector
              onChange={online => {
                const handle = async (): Promise<void> => {
                  if (!online) {
                    const fallbackResult = await apiService.checkNetworkFallback();
                    if (fallbackResult) {
                      return;
                    }
                  }
                  debounceSetIsOnline(online);
                };
                void handle();
              }}
              render={() => null}
            />
            {!isOnline && <OfflinePage />}
            <Router />
            {/* </Div100vh> */}
          </div>
          <ToastContainer
            className="custom-toast"
            closeOnClick
            hideProgressBar
            icon={false}
            closeButton={false}
            autoClose={3000}
            position="top-center"
            transition={Slide}
            draggable={true}
            draggableDirection={'y'}
            draggablePercent={50}
          />
        </Auth>
      </AppContext.Provider>
    </Auth0Provider>
  );
};

export default App;
