import React, { type MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { ISelectOption } from 'app/mobxStore/types';
import { rawTimeZones } from '@vvo/tzdb';
import ModalScreen from 'app/components/modalScreen/ModalScreen';
import Button from 'app/components/buttons/Button';
import './TimezoneSelect.scss';
import TimezoneModalContent from './TimezoneModalContent';

interface TimezoneSelectProps {
  onChange: (value: string) => void;
}

const TimezoneSelect = ({ onChange }: TimezoneSelectProps): React.JSX.Element | null => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [isOpen, seIsOpen] = useState(false);
  const timezones = rawTimeZones.map(i => ({
    label: i.rawFormat,
    value: i.name
  }));
  const browserTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const curTzOption = timezones.find(tz => tz.value === browserTz);
  const [selectedTimezone, setSelectedTimezone] = useState<ISelectOption | undefined>(curTzOption);

  useEffect(() => {
    if (selectedTimezone) {
      onChange(selectedTimezone.value);
    }
  }, []);

  const handleChange = (selectedValue: ISelectOption): void => {
    if (selectedValue) {
      setSelectedTimezone(selectedValue);
      onChange(selectedValue.value);
    }
  };

  const handleClose = async (e: React.SyntheticEvent): Promise<void> => {
    seIsOpen(false);
  };

  const getButtonContent = (proc: ISelectOption | undefined): React.JSX.Element => {
    if (proc === undefined) {
      return (
        <div className="select-field-content">
          <div className="placeholder">{t('selectFromAList')}</div>
        </div>
      );
    }
    return <div className="proc-name">{proc.value}</div>;
  };

  return (
    <div className="timezone-select">
      <Button
        classNames="select-field"
        buttonContent={getButtonContent(selectedTimezone)}
        endIcon={<div className="triangle"></div>}
        onClick={async (e: MouseEvent) => {
          setInputValue('');
          seIsOpen(true);
        }}
      />
      <ModalScreen
        isOpen={isOpen}
        classNames={'full-height timezone-modal'}
        content={
          <TimezoneModalContent
            handleChange={handleChange}
            handleClose={handleClose}
            inputValue={inputValue}
            setInputValue={setInputValue}
            timezones={timezones}
          />
        }
        handleClose={() => {
          seIsOpen(false);
        }}
      />
    </div>
  );
};

export default TimezoneSelect;
