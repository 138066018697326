import FillCaseMetaForm from '../fillMetaForm/FillCaseMetaForm';
import React, { useEffect } from 'react';
import { useFillMetaForm } from '../fillMetaForm/useFillMetaForm';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../mobxStore';
import type { IFullCase } from '../../mobxStore/types';

const HuddleFillMeta = (): React.JSX.Element => {
  const { handleProcedureChange, control, formState, allValues, handleMetaDataChange } =
    useFillMetaForm();
  const rootStore = useRootStore();
  const { caseStore } = rootStore;
  const openedCase = caseStore.openedCase as IFullCase;
  const handleClose = async (): Promise<void> => {
    caseStore.setIsHuddleDialogOpen(false);
    openedCase.basicCase.updateCaseEditLock(false);
  };
  useEffect(() => {
    rootStore.setCurEditMode(true);
  }, []);
  useEffect(() => {
    openedCase.basicCase.updateCaseEditLock(true);
  }, []);
  return (
    <div>
      <FillCaseMetaForm
        handleProcedureChange={handleProcedureChange}
        handleClose={handleClose}
        control={control}
        formState={formState}
        allValues={allValues}
        showSubmit={true}
        handleMetaDataChange={handleMetaDataChange}
      />
    </div>
  );
};

export default observer(HuddleFillMeta);
