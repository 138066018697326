import * as React from 'react';
const SvgComponent = props => (
  <svg
    className="svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21 20"
    fill={props.fill || 'none'}
    {...props}
  >
    <path
      d="M5.91602 5.17951V3.25488C5.91602 2.60767 6.44068 2.08301 7.08789 2.08301H17.2441C17.8913 2.08301 18.416 2.60767 18.416 3.25488V13.4111C18.416 14.0583 17.8913 14.583 17.2441 14.583H15.2978"
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M13.9121 5.41699H3.75586C3.10865 5.41699 2.58398 5.94166 2.58398 6.58887V16.7451C2.58398 17.3923 3.10865 17.917 3.75586 17.917H13.9121C14.5593 17.917 15.084 17.3923 15.084 16.7451V6.58887C15.084 5.94166 14.5593 5.41699 13.9121 5.41699Z"
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);
export default SvgComponent;
