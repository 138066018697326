import React, { type MouseEvent, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Trans, useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Button from 'app/components/buttons/Button';
import ArrowLeftIcon from 'app/assets/icons/ArrowLeft_icon';
import ArrowRightIcon from 'app/assets/icons/ArrowRight_icon';
import variables from 'app/utils/variables.module.scss';
import { ReactComponent as PenguinWelcome } from 'app/assets/penguins/penguin_4_animated.svg';
import { ReactComponent as PenguinSkating } from 'app/assets/penguins/penguins_3_in_a_bobsleigh_animated.svg';
import { ReactComponent as PenguinMagicAnimated } from 'app/assets/penguins/penguin_5_animated_happy_magic_penguin.svg';
import ChiefyLogoIcon from 'app/assets/icons/ChiefyLogo_icon';
import './Onboarding.scss';
import { setClickedViewersTooltip, setLastWhatsNewViewed } from '../../mobxStore/storage';
import { useNavigate } from 'react-router-dom';
import ProfileScreen from './ProfileScreen';
import apiService from '../../services/apiService/apiService';
import { useRootStore } from '../../mobxStore';

const onboardingScreens = [
  {
    name: 'onboarding-1',
    icon: <PenguinWelcome />,
    title: 'onboarding1Title',
    text: 'onboarding1Text'
  },
  {
    name: 'onboarding-2',
    icon: <PenguinSkating />,
    title: 'onboarding2Title',
    text: 'onboarding2Text'
  },
  {
    name: 'onboarding-3',
    icon: <PenguinMagicAnimated />,
    title: 'onboarding3Title',
    text: 'onboarding3Text'
  },
  {
    name: 'profile-screen'
  }
];

const Onboarding = (): React.JSX.Element => {
  const { t } = useTranslation();
  const { userStore } = useRootStore();
  const [currentScreen, setCurrentScreen] = useState(onboardingScreens[0]);

  const navigate = useNavigate();
  const handleNavToScreen = (index: number): void => {
    if (index < onboardingScreens.length) {
      setCurrentScreen(onboardingScreens[index]);
    }
  };

  const closeOnboarding = async (): Promise<void> => {
    setLastWhatsNewViewed(parseInt(t('whatsNewVersion')));
    /* Show viewers tooltip only to existing users */
    setClickedViewersTooltip(true);

    const data = {
      onboardingDone: true
    };
    await apiService.updateCurrentUser(data);

    userStore.loggedInUser.setData({
      ...userStore.loggedInUser.data,
      ...data
    });

    navigate('/');
  };

  const handleSkip = (): void => {
    if (currentScreen.name === 'profile-screen') {
      void closeOnboarding();
      return;
    }
    handleNavToScreen(onboardingScreens.length - 1);
  };

  return (
    <div className="page-container onboarding">
      <div className="onboarding-page">
        {currentScreen.name !== 'profile-screen' && (
          <div className="onboarding-header">
            <div className="flex">
              <ChiefyLogoIcon className="logo" height={24} />
            </div>
            <div className="progress flex">
              {onboardingScreens.map((screen, index) => (
                <div
                  key={screen.name}
                  className={clsx('bar', { current: currentScreen === screen })}
                ></div>
              ))}
            </div>
            <div className="flex">
              <Button
                classNames="btn skip-button"
                buttonText="skip"
                onClick={async (e: MouseEvent) => {
                  handleSkip();
                }}
              />
            </div>
          </div>
        )}
        {onboardingScreens.map((screen, index) => {
          return (
            <div
              key={screen.name}
              className={clsx('screen-conainer', {
                current: currentScreen === screen,
                profile: screen.name === 'profile-screen'
              })}
              style={{ zIndex: index }}
            >
              {screen.name === 'profile-screen' ? (
                <ProfileScreen closeOnboarding={closeOnboarding} />
              ) : (
                <>
                  <div className="image">{screen.icon}</div>
                  <div className="screen-content">
                    <div className="title">
                      <Trans i18nKey={screen.title} />
                    </div>
                    <div className="text">
                      <Trans
                        i18nKey={screen.text}
                        components={{ bullet: <span className="bullet" /> }}
                      />
                    </div>
                  </div>
                  <div className="actions">
                    {index !== 0 && (
                      <Button
                        classNames="btn circle-button back"
                        startIcon={<ArrowLeftIcon height={10} stroke={variables.black} />}
                        onClick={async (e: MouseEvent) => {
                          handleNavToScreen(index - 1);
                        }}
                      />
                    )}
                    {index < onboardingScreens.length - 1 && (
                      <Button
                        classNames="btn circle-button next"
                        startIcon={<ArrowRightIcon height={10} stroke={variables.white} />}
                        onClick={async (e: MouseEvent) => {
                          handleNavToScreen(index + 1);
                        }}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default observer(Onboarding);
