import React from 'react';
import { observer } from 'mobx-react-lite';
import Rating from '@mui/material/Rating';

import HeartFullIcon from 'app/assets/icons/HeartFull_icon';
import HeartEmptyIcon from 'app/assets/icons/HeartEmpty_icon';
import './Feedback.scss';

interface IProps {
  value: number;
  isCompact?: boolean;
}

const RatingView = (props: IProps): React.JSX.Element => {
  const { value } = props;

  return (
    <Rating
      className="rating"
      value={value}
      max={5}
      icon={<HeartFullIcon />}
      emptyIcon={<HeartEmptyIcon />}
      sx={{
        '& .MuiRating-icon': {
          display: 'flex',
          justifyContent: 'center'
        }
      }}
    />
  );
};

export default observer(RatingView);
