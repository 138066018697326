import * as React from 'react';
const SvgComponent = props => (
  <svg
    className="svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 10 8"
    fill={props.fill || 'none'}
    {...props}
  >
    <path
      d="M9.43454 0.695068L4.04778 7.30492L0.56543 3.99997"
      stroke={props.stroke || '#65708F'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgComponent;
