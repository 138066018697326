import * as React from 'react';
const SvgComponent = props => (
  <svg
    className="svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
    fill={props.fill || 'none'}
    {...props}
  >
    <path
      d="M6.41602 8.24984L11.916 13.7498L21.0827 0.916504"
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6662 1.60406C12.5859 0.792193 10.295 0.693862 8.15278 1.32449C6.01058 1.95512 4.13832 3.27903 2.82972 5.08853C1.52111 6.89803 0.850205 9.09074 0.922243 11.3227C0.99428 13.5546 1.80519 15.6995 3.22775 17.4208C4.65032 19.1422 6.60405 20.3426 8.78246 20.8338C10.9609 21.325 13.2407 21.0791 15.2643 20.1348C17.2879 19.1905 18.9408 17.6011 19.9637 15.6161C20.9865 13.631 21.3215 11.3625 20.9161 9.16656"
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgComponent;
