import {
  FORM_FIELDS_ENUM,
  type IFormData,
  SERVER_FORM_FIELDS_ENUM
} from 'app/components/fields/types';
import { useRootStore } from 'app/mobxStore';
import type {
  IFullCase,
  IProcedure,
  IProcedureSelectOption,
  ISelectOption
} from 'app/mobxStore/types';
import { useForm } from 'react-hook-form';
import type { Control, FormState, UseFormHandleSubmit } from 'react-hook-form/dist/types/form';
import { getDefaultValues } from './helper';
import { useEffect, useState } from 'react';

interface IUseFillMetaForm {
  handleSubmit: UseFormHandleSubmit<IFormData>;
  control: Control<IFormData>;
  formState: FormState<IFormData>;
  allValues: IFormData;
  handleMetaDataChange: (field: SERVER_FORM_FIELDS_ENUM, value: string | Date) => Promise<void>;
  handleProcedureChange: (
    selectedProcedure: IProcedureSelectOption,
    approved: boolean
  ) => Promise<boolean>;
}

export const useFillMetaForm = (): IUseFillMetaForm => {
  const { caseStore, metaDataStore } = useRootStore();
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const { surgeonsOptions, servicesOptions, careTeamOptions, proceduresOptions } = metaDataStore;

  const openedCase = caseStore.openedCase as IFullCase;
  const defaultValues = getDefaultValues(
    openedCase,
    surgeonsOptions,
    careTeamOptions,
    servicesOptions,
    proceduresOptions(openedCase.basicCase.data.siteId, openedCase.basicCase.data.attendingId)
  );
  const { reset, watch, control, handleSubmit, formState, setValue } = useForm<IFormData>({
    defaultValues
  });
  const caseFollowers = openedCase.basicCase.data.caseFollowers;
  useEffect(() => {
    if (isInitialLoad) {
      setIsInitialLoad(false);
      return;
    }

    const followers = careTeamOptions.filter(option =>
      caseFollowers.some(follower => follower.userId === option.value)
    );
    setValue(FORM_FIELDS_ENUM.FOLLOWERS, followers);
  }, [caseFollowers]);
  const allValues = watch();

  /* When case is postponed, need to refresh the UI */
  useEffect(() => {
    const values = allValues;
    values[FORM_FIELDS_ENUM.DATE] = new Date(openedCase.basicCase.data.caseDate);
    reset(values);
  }, [openedCase.basicCase.data.caseDate]);

  const handleMetaDataChange = async (
    field: SERVER_FORM_FIELDS_ENUM,
    value: string | Date
  ): Promise<void> => {
    try {
      const dateChanged = field === SERVER_FORM_FIELDS_ENUM.DATE;
      const data = {
        [field]: value
      };

      // If date changes, update indexInDay to current time to put case last in the new date
      const parsedData = dateChanged ? { ...data, indexInDay: new Date().getTime() } : data;
      await openedCase.basicCase.updateCaseMetaDataToServer(parsedData);
    } catch (error) {
      // todo: handle error
    }
  };

  const hasProcedure = (): boolean => {
    return (
      openedCase.referencedData.procedureData !== undefined &&
      openedCase.referencedData.procedureData !== null
    );
  };

  const needsFormChange = async (
    option: IProcedureSelectOption
  ): Promise<{
    isNeeded: boolean;
    procedureMetaData: IProcedure;
  }> => {
    const procedureMetaData = await caseStore.getCaseMetaData(option.value, option.isUserTemplate);
    const isNeeded = caseStore.checkForFormChange(
      openedCase.referencedData.procedureData as IProcedure,
      procedureMetaData
    );

    return { isNeeded, procedureMetaData };
  };

  const updateTitleOnProcedureChange = async (selectedProcedure: ISelectOption): Promise<void> => {
    if (
      allValues[FORM_FIELDS_ENUM.TITLE] &&
      allValues[FORM_FIELDS_ENUM.TITLE] !== allValues[FORM_FIELDS_ENUM.PROCEDURE]?.label
    ) {
      return;
    }

    await handleMetaDataChange(SERVER_FORM_FIELDS_ENUM.TITLE, selectedProcedure.label);
    openedCase.basicCase.setTitle(selectedProcedure.label);
    setValue(FORM_FIELDS_ENUM.TITLE, selectedProcedure.label);
  };

  const handleProcedureChange = async (
    selectedProcedure: IProcedureSelectOption,
    approved: boolean
  ): Promise<boolean> => {
    if (!hasProcedure()) {
      await updateTitleOnProcedureChange(selectedProcedure);
      const updatedCase = await caseStore.updateCaseProcedureToServer(
        openedCase.basicCase.id,
        selectedProcedure,
        metaDataStore.curOntVersion
      );
      caseStore.updateCase(updatedCase.basicCase);
      caseStore.setOpenedCase(updatedCase);

      const updatedDefaultValues = getDefaultValues(
        updatedCase,
        surgeonsOptions,
        careTeamOptions,
        servicesOptions,
        proceduresOptions(openedCase.basicCase.data.siteId, updatedCase.basicCase.data.attendingId)
      );
      reset(updatedDefaultValues);
      return true;
    }

    /* Procedure was not changed - update only procedure data */
    const { isNeeded, procedureMetaData } = await needsFormChange(selectedProcedure);
    if (!isNeeded) {
      await handleMetaDataChange(SERVER_FORM_FIELDS_ENUM.PROCEDURE, selectedProcedure.value);
      openedCase.setProcedure(procedureMetaData);
      setValue(FORM_FIELDS_ENUM.PROCEDURE, selectedProcedure);
      await updateTitleOnProcedureChange(selectedProcedure);
      return true;
    }

    /* isNeeded === true */
    if (approved) {
      await handleProcedureAndFormChange(selectedProcedure);
      await updateTitleOnProcedureChange(selectedProcedure);
      return true;
    }

    /* isNeeded === true && !approved */
    return false;
  };

  const handleProcedureAndFormChange = async (
    selectedProcedure: IProcedureSelectOption
  ): Promise<void> => {
    const updatedCase = await caseStore.updateCaseProcedureToServer(
      openedCase.basicCase.id,
      selectedProcedure,
      metaDataStore.curOntVersion
    );

    setValue(FORM_FIELDS_ENUM.PROCEDURE, selectedProcedure);
    caseStore.updateCase(updatedCase.basicCase);
    caseStore.setOpenedCase(updatedCase);
    caseStore.setRefreshForm(true);
  };

  return {
    handleSubmit,
    formState,
    control,
    allValues,
    handleMetaDataChange,
    handleProcedureChange
  };
};
