import React from 'react';
import type { Control } from 'react-hook-form/dist/types/form';
import type { IDynamicField } from 'app/mobxStore/types';
import { observer } from 'mobx-react-lite';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { useController } from 'react-hook-form';
interface IProps {
  control: Control<any>;
  field: IDynamicField;
}
const CheckBox = (props: IProps): React.JSX.Element => {
  const { control, field: dField } = props;
  const { field } = useController({
    name: dField.id,
    control
  });
  return (
    <FormControl>
      <InputLabel>{dField.label}</InputLabel>
      <input type="checkbox" {...field} placeholder={dField.placeholder} />
    </FormControl>
  );
};

export default observer(CheckBox);
