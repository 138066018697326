import React, { type Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import ModalScreen from 'app/components/modalScreen/ModalScreen';
import IconButton from '@mui/material/IconButton';
import Button from 'app/components/buttons/Button';
import RatingEditable from './RatingEditable';
import CloseIcon from 'app/assets/icons/Close_icon';

import variables from 'app/utils/variables.module.scss';
import './Feedback.scss';
import type { IFullCase } from '../../mobxStore/types';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { FEEDBACK_FORM_FIELDS_ENUM, type IFeedbackFormData } from '../fields/types';
import TextFeedback from './TextFeedback';

interface IProps {
  isFeedbackOpen: boolean;
  setIsFeedbackOpen: Dispatch<boolean>;
  case: IFullCase;
}

const FeedbackDialog = (props: IProps): React.JSX.Element => {
  const { case: kase, isFeedbackOpen, setIsFeedbackOpen } = props;
  const { t } = useTranslation();

  const planFeedback = kase.getCurrentUserPlanFeedback();
  const defaultValues: IFeedbackFormData = {
    rating: planFeedback.rating,
    text: planFeedback.text
  };
  const { control, handleSubmit, formState, watch } = useForm<IFeedbackFormData>({
    defaultValues
  });
  const allValues = watch();

  const getDateLabel = (): string | null => {
    const caseDate = new Date(kase.basicCase.data.caseDate);
    const date = caseDate.toLocaleString('default', { day: 'numeric' });
    const month = caseDate.toLocaleString('default', { month: 'long' });
    const year = caseDate.toLocaleString('default', { year: 'numeric' });
    return (
      date + ' ' + month + ', ' + year + ' - ' + `${kase.basicCase.data.attending?.nickName ?? ''}`
    );
  };

  const onSubmit = (data: IFeedbackFormData): void => {
    void kase.upsertPlanFeedbackToServer(data.rating, data.text);
    kase.upsertPlanFeedbackToStore(data.rating, data.text);
    setIsFeedbackOpen(false);
  };

  const handleClose = (): void => {
    setIsFeedbackOpen(false);
  };

  const dateLabel = getDateLabel();

  return (
    <ModalScreen
      isOpen={isFeedbackOpen}
      classNames={'centered feedback-modal'}
      transitionType="fade"
      content={
        <div className="modal-content">
          <IconButton className="close-button" onClick={handleClose}>
            <CloseIcon stroke={variables.grey6} height={14} />
          </IconButton>
          <div className="modal-header">
            <div className="case-date">{dateLabel}</div>
            <div className="case-title">
              {kase.basicCase.data.displayId || kase.basicCase.data.procedureTitle}
            </div>
          </div>
          <div className="nursing-title">{t('nursingFeedbackTitle')}</div>
          <div className="nursing-subtitle">{t('nursingSubtitleEmpty')}</div>
          <RatingEditable control={control} />
          {allValues[FEEDBACK_FORM_FIELDS_ENUM.RATING] > 0 && <TextFeedback control={control} />}
          <Button
            disabled={!formState.isDirty}
            classNames="primary submit"
            buttonText="send"
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      }
      handleClose={handleClose}
    />
  );
};

export default observer(FeedbackDialog);
