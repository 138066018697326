import React, { type MouseEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'app/components/buttons/Button';
import { ReactComponent as PenguinHat } from 'app/assets/penguins/penguin_12_animated_less_something_went_wrong.svg';
import './ErrorPage.scss';
import AmplService from '../../services/amplService/amplService';
import { setErrorExpireTime, getErrorExpireTime } from 'app/mobxStore/storage';
import { useNavigate } from 'react-router-dom';

const ErrorPage = (): React.JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleIsAutoLogout = (): boolean => {
    const lastErrorExpirationTime = getErrorExpireTime();
    if (!lastErrorExpirationTime) {
      return false;
    }
    const parsedErrorExpirationTime = JSON.parse(lastErrorExpirationTime);
    const now = new Date().getTime();
    if (now > parsedErrorExpirationTime) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (handleIsAutoLogout()) {
      navigate('/logout');
      return;
    }
    setErrorExpireTime(new Date());
  }, []);

  useEffect(() => {
    AmplService.sendEvent(AmplService.EVENTS.ERROR_PAGE_VIEWED);
  }, []);

  return (
    <div className="error-page">
      <div className="content">
        <PenguinHat className="image" />
        <div className="title">{t('errorTitle')}</div>
        <div className="subtitle">{t('errorSubtitle')}</div>
      </div>
      <div className="actions">
        <Button
          classNames="primary try-again"
          buttonText={t('returnToBoard').toString()}
          onClick={async (e: MouseEvent) => {
            AmplService.sendEvent(AmplService.EVENTS.ERROR_PAGE_RETURN_TO_BOARD);
            window.location.assign('/');
          }}
        />
        <Button
          classNames="primary report-issue"
          buttonText={t('reportIssue').toString()}
          onClick={async (e: MouseEvent) => {
            AmplService.sendEvent(AmplService.EVENTS.ERROR_PAGE_REPORT_ISSUE);
            window.location.assign(t('reportIssueEmailUrl').toString());
          }}
        />
      </div>
    </div>
  );
};

export default ErrorPage;
