import type {
  ICareTeamDefaults,
  ICase,
  IDynamicField,
  IFullCase,
  IGetFieldValuesCount,
  IRoleData
} from 'app/mobxStore/types';
import { getFormFields } from 'utils/form/form';

const isICase = (openedCase: ICase | null): openedCase is ICase => {
  return openedCase !== null;
};

export const getFields = (
  openedCase: IFullCase | null,
  userRoles: IRoleData[],
  editMode: boolean,
  getFieldValuesCount: (fieldId: string) => IGetFieldValuesCount,
  savedDefaults: ICareTeamDefaults
): IDynamicField[] => {
  if (!openedCase) {
    return [];
  }
  const basicCase = openedCase.basicCase;
  if (!isICase(basicCase)) {
    return [];
  }

  return getFormFields(
    openedCase.basicCase.data,
    openedCase.referencedData,
    userRoles,
    editMode,
    getFieldValuesCount,
    savedDefaults
  );
};
