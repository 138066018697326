import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationPanel from 'app/components/confirmationPanel/ConfirmationPanel';

interface IProps {
  isShowPanel: boolean;
  setIsShowPanel: (isShowPanel: boolean) => void;
  onApprove: () => void;
}

const ProcedureChangeConfirmation = (props: IProps): React.JSX.Element => {
  const { isShowPanel, setIsShowPanel, onApprove } = props;
  const { t } = useTranslation();

  const handleApprove = async (): Promise<void> => {
    handleClose();
    onApprove();
  };

  const handleCancel = async (): Promise<void> => {
    handleClose();
  };

  const handleClose = (): void => {
    setIsShowPanel(false);
  };

  return (
    <ConfirmationPanel
      title={t('procedureChangeConfirmationTitle')}
      buttons={[
        {
          buttonText: t('procedureChangeConfirmationYes'),
          onClick: handleApprove,
          variant: 'outlined',
          closeOnClick: true,
          key: 'yes'
        },
        {
          buttonText: t('procedureChangeConfirmationNo'),
          onClick: handleCancel,
          variant: 'secondary',
          closeOnClick: true,
          key: 'no'
        }
      ]}
      isShowPanel={isShowPanel}
      setIsShowPanel={setIsShowPanel}
    />
  );
};

export default ProcedureChangeConfirmation;
