import _ from 'lodash';
import apiService from 'app/services/apiService';
import {
  type ICareTeamDefaults,
  type ICase,
  type IDynamicFieldValue,
  type IFieldRecommendation,
  IFieldRecommendationType
} from '../../../mobxStore/types';

export const NUM_OF_RECENT_CASES_TO_ANALYZE = 20;
const SUGGEST_RATIO_THRESHOLD_ADD = 0.7;
const SUGGEST_RATIO_THRESHOLD_REMOVE = 0.3;

type IFieldsSummary = Record<
  string,
  {
    count: number;
    options: Record<string, number>;
    others: Record<string, number>;
  }
>;

export const initTemplateRecommendations = async (
  kase: ICase,
  careTeamDefaults: ICareTeamDefaults
): Promise<any> => {
  const fs: IFieldsSummary = {};

  if (!kase.data.procedureId) {
    return fs;
  }

  // Go over past cases, and create a summary: count of each field, and count of each option
  const cases = await apiService.getAttReadyCasesForTemplateRecommendations(
    kase.data.attendingId,
    kase.data.procedureId,
    NUM_OF_RECENT_CASES_TO_ANALYZE
  );

  cases.forEach(pc => {
    for (const [fieldId, field] of Object.entries(pc.fieldValues)) {
      if (field.value.length === 0) {
        continue;
      }
      fs[fieldId] ||= {
        count: 0,
        options: {},
        others: {}
      };
      fs[fieldId].count += 1;

      field.value.forEach(v => {
        fs[fieldId].options[v] = (fs[fieldId].options[v] || 0) + 1;
      });
      if (field.hasOther && field.otherValue?.length > 0) {
        fs[fieldId].others[field.otherValue[0]] =
          (fs[fieldId].others[field.otherValue[0]] || 0) + 1;
      }
    }
  });

  return fs;
};

const shouldRecommendTemplateFieldValAdd = (
  fieldsSummary: IFieldsSummary,
  procedureTitle: string,
  fieldId: string,
  value: string
): IFieldRecommendation | null => {
  const count = fieldsSummary[fieldId]?.options[value] || 0;
  const total = fieldsSummary[fieldId]?.count || 0;
  const ratio = count / total;

  if (total <= 1) {
    console.debug('Not enough cases:', total);
    return null;
  }
  console.debug('fieldId:', fieldId, count, '/', total);
  if (ratio > SUGGEST_RATIO_THRESHOLD_ADD) {
    const rec = {
      procedureTitle,
      fieldId,
      value,
      multi: true,
      recommendation: IFieldRecommendationType.ADD
    };
    console.debug('Recommendation: add');

    return rec;
  }

  return null;
};

const shouldRecommendTemplateFieldValRemove = (
  fieldsSummary: IFieldsSummary,
  procedureTitle: string,
  fieldId: string,
  value: string
): IFieldRecommendation | null => {
  const count = fieldsSummary[fieldId]?.options[value] || 0;
  const total = fieldsSummary[fieldId]?.count || 0;
  const ratio = count / total;

  if (total <= 1) {
    console.debug('Not enough cases:', total);
    return null;
  }
  console.debug('fieldId:', fieldId, count, '/', total);
  if (ratio <= SUGGEST_RATIO_THRESHOLD_REMOVE) {
    const rec = {
      procedureTitle,
      fieldId,
      value,
      multi: true,
      recommendation: IFieldRecommendationType.REMOVE
    };
    console.debug('Recommendation: remove');

    return rec;
  }

  return null;
};

const recommendTemplateCheckSkip = (
  attendingDefaults: ICareTeamDefaults,
  fieldId: string,
  value: string
): boolean => {
  if (value === 'Other') {
    console.debug('Added other. Ignoring.');
    return false;
  }
  if (attendingDefaults.stopInsights[fieldId]?.includes(value)) {
    console.debug('Stop insights', value);
    return false;
  }

  return true;
};

export const recommendTemplateAdd = (
  attendingDefaults: ICareTeamDefaults,
  fieldsSummary: IFieldsSummary,
  procedureTitle: string,
  fieldId: string,
  values: IDynamicFieldValue,
  oldValue: IDynamicFieldValue
): IFieldRecommendation | null => {
  // remove 'values' from 'oldValue'
  const addedArr = _.difference(values, oldValue);
  console.debug('Added', addedArr);
  if (addedArr.length !== 1) {
    // unexpected
    console.debug('addedArr.length != 1');
    return null;
  }

  const addedValue = addedArr[0];

  if (!recommendTemplateCheckSkip(attendingDefaults, fieldId, addedValue)) {
    return null;
  }

  if (attendingDefaults?.values[fieldId]?.values.includes(addedValue)) {
    console.debug('Already in template', addedValue);
    return null;
  }

  return shouldRecommendTemplateFieldValAdd(fieldsSummary, procedureTitle, fieldId, addedValue);
};

export const recommendTemplateRemove = (
  attendingDefaults: ICareTeamDefaults,
  fieldsSummary: IFieldsSummary,
  procedureTitle: string,
  fieldId: string,
  values: IDynamicFieldValue,
  oldValue: IDynamicFieldValue
): IFieldRecommendation | null => {
  // remove 'values' from 'oldValue'
  const removedArr = _.difference(oldValue, values);
  console.debug('Removed', removedArr);
  if (removedArr.length !== 1) {
    return null;
  }

  const removedValue = removedArr[0];
  if (!recommendTemplateCheckSkip(attendingDefaults, fieldId, removedValue)) {
    return null;
  }

  if (!attendingDefaults?.values[fieldId]?.values.includes(removedValue)) {
    console.debug('Not in template', removedValue);
    return null;
  }

  return shouldRecommendTemplateFieldValRemove(
    fieldsSummary,
    procedureTitle,
    fieldId,
    removedValue
  );
};

export const recommendTemplateReplaceText = (
  attendingDefaults: ICareTeamDefaults,
  fieldsSummary: IFieldsSummary,
  procedureTitle: string,
  fieldId: string,
  values: IDynamicFieldValue,
  oldValue: IDynamicFieldValue
): IFieldRecommendation | null => {
  console.debug('recommendTemplateReplaceText: DID NOT IMPLEMENT');
  // if (!recommendTemplateCheckSkip(attendingDefaults, fieldId, values)) {
  //   return null;
  // }
  //
  // if (attendingDefaults?.values[fieldId]?.values.includes(addedValue)) {
  //   console.debug('Already in template', addedValue);
  //   return null;
  // }
  //
  // return shouldRecommendTemplateFieldValAdd(fieldsSummary, procedureTitle, fieldId, addedValue);
  return null;
};

export const recommendTemplateReplace = (
  attendingDefaults: ICareTeamDefaults,
  fieldsSummary: IFieldsSummary,
  procedureTitle: string,
  fieldId: string,
  values: IDynamicFieldValue,
  oldValue: IDynamicFieldValue
): IFieldRecommendation | null => {
  if (values.length === 1 && oldValue.length <= 1) {
    const res = recommendTemplateAdd(
      attendingDefaults,
      fieldsSummary,
      procedureTitle,
      fieldId,
      values,
      oldValue
    );
    if (res) {
      res.multi = false;
    }
    return res;
  }
  if (values.length === 0) {
    const res = recommendTemplateRemove(
      attendingDefaults,
      fieldsSummary,
      procedureTitle,
      fieldId,
      values,
      oldValue
    );
    if (res) {
      res.multi = false;
    }
    return res;
  }
  throw new Error('Unexpected');
  // @todo add toast and Sentry instead of error
};
