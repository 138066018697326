import React, { type MouseEvent, useEffect, useState } from 'react';
import Tooltip, { type TooltipProps } from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Button from 'app/components/buttons/Button';
import CloseIcon from 'app/assets/icons/Close_icon';
import './TooltipWrapper.scss';
import variables from 'app/utils/variables.module.scss';
import { getLastTooltipViewedDate, setLastTooltipViewedDate } from 'app/mobxStore/storage';
import { differenceInDays } from 'date-fns';
import AmplService from '../../services/amplService/amplService';

const TOOLTIP_COOLDOWN_DAYS = 2;

interface IProps {
  comp: React.JSX.Element;
  tooltipContent: React.JSX.Element | string;
  open: boolean;
  handleCloseTooltip: () => void;
  handleSetTooltipSeen: () => void;
  isCloseOnClickAway?: boolean;
  placement?: TooltipProps['placement'];
  offset?: [number, number];
  name: string;
}

const TooltipWrapper = (props: IProps): React.JSX.Element => {
  const {
    name,
    comp,
    open,
    handleCloseTooltip,
    handleSetTooltipSeen,
    tooltipContent,
    placement,
    offset,
    isCloseOnClickAway
  } = props;
  const [isOpen, setIsOpen] = useState(open);

  const shouldShow = (): boolean => {
    if (!open) {
      return false;
    }
    const lastTooltipViewDate = getLastTooltipViewedDate();
    if (!lastTooltipViewDate) {
      return true;
    }
    const parsedDate = new Date(JSON.parse(lastTooltipViewDate));
    const now = new Date();
    const diffInDays = differenceInDays(now, parsedDate);
    if (diffInDays < TOOLTIP_COOLDOWN_DAYS) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    const show = shouldShow();
    if (!show) {
      setIsOpen(false);
      return;
    }
    setIsOpen(open);
    handleSetTooltipSeen();
    setLastTooltipViewedDate(new Date());
    AmplService.sendEvent(AmplService.EVENTS.TOOLTIP_VIEWED, { tooltipName: name });
  }, [open]);

  return (
    <Tooltip
      className={`tooltip-wrapper-${name}`}
      placement={placement ?? 'top'}
      TransitionComponent={Zoom}
      title={
        <ClickAwayListener
          onClickAway={() => {
            if (!isCloseOnClickAway) {
              return;
            }
            handleCloseTooltip();
            AmplService.sendEvent(AmplService.EVENTS.TOOLTIP_CLOSE_ON_CLICK_AWAY, {
              tooltipName: name
            });
          }}
        >
          <div
            className="tooltip-contnet"
            onClick={e => {
              e.stopPropagation();
              handleCloseTooltip();
              AmplService.sendEvent(AmplService.EVENTS.TOOLTIP_CLOSE_ON_CLICK, {
                tooltipName: name
              });
            }}
          >
            {tooltipContent}
            <Button
              classNames="btn close-tooltip-button"
              startIcon={<CloseIcon height={10} stroke={variables.black} />}
              onClick={async (e: MouseEvent) => {
                e.stopPropagation();
                handleCloseTooltip();
                AmplService.sendEvent(AmplService.EVENTS.TOOLTIP_CLOSE_ON_CLICK, {
                  tooltipName: name
                });
              }}
            />
          </div>
        </ClickAwayListener>
      }
      PopperProps={{
        // fix placement top bug
        disablePortal: true,
        popperOptions: {
          modifiers: [
            {
              name: 'flip',
              options: {
                // limit tooltip position to top of anchor
                fallbackPlacements: []
              }
            },
            {
              name: 'offset',
              options: {
                offset: offset ?? [0, -8]
              }
            }
          ]
        }
      }}
      arrow
      open={isOpen}
    >
      {comp}
    </Tooltip>
  );
};

export default TooltipWrapper;
