import * as React from 'react';
const SvgComponent = props => (
  <svg
    className="svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill={props.fill || 'none'}
    {...props}
  >
    <path
      d="M3.00294 8.75593C2.96395 8.34946 3.3174 8 3.76745 8H5.23261C5.65644 8 6 8.31144 6 8.69565V15.3043C6 15.6886 5.65644 16 5.23261 16H4.40138C4.00333 16 3.67136 15.7241 3.63687 15.3646L3.00294 8.75593Z"
      fill={props.fill || '#B33B94'}
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 9.17089C8 8.90699 8.17735 8.67078 8.43545 8.55826C8.99833 8.31286 9.9575 7.81909 10.3902 7.15174C10.9478 6.29165 11.0529 4.73767 11.0701 4.38172C11.0724 4.33189 11.0709 4.282 11.0783 4.2326C11.1707 3.61615 12.4574 4.33629 12.951 5.09772C13.2189 5.51096 13.2533 6.05389 13.2251 6.47778C13.195 6.93132 13.0512 7.36944 12.9101 7.80473L12.6096 8.73195H16.3167C16.7696 8.73195 17.097 9.13215 16.9739 9.53519L15.1402 15.5401C15.0571 15.8121 14.7887 16 14.483 16H8.6829C8.30574 16 8 15.7172 8 15.3684V9.17089Z"
      fill={props.fill || '#B33B94'}
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgComponent;
