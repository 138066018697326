import type { Control } from 'react-hook-form/dist/types/form';
import { useController } from 'react-hook-form';
import { useRootStore } from 'app/mobxStore';
import React, { useEffect } from 'react';
import { COMMENT_FORM_FIELDS_ENUM, type ICommentFormData } from './types';
import { useTranslation } from 'react-i18next';
import TextInput from '@mui/material/TextField';

interface IProps {
  control: Control<ICommentFormData>;
  isReadOnly?: boolean;
}

const CommentTitle = (props: IProps): React.JSX.Element => {
  const { control, isReadOnly } = props;
  const { caseStore } = useRootStore();
  const { field } = useController({
    name: COMMENT_FORM_FIELDS_ENUM.COMMENT,
    control,
    rules: {
      required: true
    }
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (caseStore.tempComment !== '' && caseStore.tempComment !== field.value) {
      field.onChange(caseStore.tempComment);
    }
  }, [caseStore.tempComment]);

  return (
    <>
      <TextInput
        variant="standard"
        disabled={isReadOnly}
        className="comment-title"
        {...field}
        onChange={(e): void => {
          // set the temp comment in the store
          caseStore.setTempComment(e.target.value);
          field.onChange(e);
        }}
        value={caseStore.tempComment}
        // when the input is focused, the cursor should be at the end of the text
        onFocus={e => {
          e.currentTarget.setSelectionRange(
            e.currentTarget.value.length,
            e.currentTarget.value.length
          );
        }}
        placeholder={t('askAQuestion').toString()}
        multiline
        InputProps={{
          disableUnderline: true
        }}
      />
    </>
  );
};

export default CommentTitle;
