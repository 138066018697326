import React, { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';
import Input from '@mui/material/Input';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TextMaskCustom = forwardRef<HTMLInputElement, CustomProps>(function TextMaskCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        '#': /[1-9]/
      }}
      inputRef={ref}
      onAccept={(value: string) => {
        onChange({ target: { name: props.name, value } });
      }}
      overwrite
    />
  );
});

interface IProps {
  // @todo fix any type
  field: any;
  placeholder: string;
}

const PhoneNumberInput = (props: IProps): React.JSX.Element => {
  const { field, placeholder } = props;

  return (
    <Input
      {...field}
      inputComponent={TextMaskCustom as any}
      className="phone-input"
      disableUnderline
      placeholder={placeholder}
    />
  );
};

export default PhoneNumberInput;
