import React, { type MouseEvent } from 'react';
import Button from 'app/components/buttons/Button';
import CloseIcon from 'app/assets/icons/Close_icon';
import variables from 'app/utils/variables.module.scss';
import './Button.scss';

interface IProps {
  handleClose: (e: MouseEvent) => Promise<void>;
}
const CloseButton = (props: IProps): React.JSX.Element => {
  const { handleClose } = props;
  return (
    <Button
      classNames="btn circle-button close-button"
      startIcon={<CloseIcon stroke={variables.black} height={10} />}
      onClick={handleClose}
    />
  );
};

export default CloseButton;
