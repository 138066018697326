import React from 'react';
import Button from '../buttons/Button';
import DiamondIcon from 'app/assets/icons/Diamond_icon';
import variables from 'app/utils/variables.module.scss';
import { t } from 'i18next';

interface IProps {
  setIsInviteDialogOpen: (value: boolean) => void;
}

const NewSiteInviteButton = (props: IProps): React.JSX.Element => {
  const { setIsInviteDialogOpen } = props;

  const handleInviteUser = async (): Promise<void> => {
    setIsInviteDialogOpen(true);
  };

  return (
    <Button
      classNames="btn action-button new-site"
      onClick={handleInviteUser}
      buttonContent={
        <div className="button-content">
          <div className="button-icon">
            <DiamondIcon fill={variables.black} />
          </div>
          {t('newSiteInvite')}
        </div>
      }
    />
  );
};
export default NewSiteInviteButton;
