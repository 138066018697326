import React from 'react';
import { observer } from 'mobx-react-lite';
import type { ICase, IUserDataWithRoles } from '../../mobxStore/types';
import FabButton from 'app/components/fabButton/FabButton';
import DoneIcon from 'app/assets/icons/Done_icon';
import PencilIcon from 'app/assets/icons/Pencil_icon';
import variables from 'app/utils/variables.module.scss';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import AmplService from '../../services/amplService/amplService';
import { useRootStore } from '../../mobxStore';
import clsx from 'clsx';

interface IProps {
  toggleEditMode: () => void;
  hasLock: boolean;
  editedByUser: IUserDataWithRoles | null;
  curEditMode: boolean;
}

const ToggleEditModeButton = (props: IProps): React.JSX.Element => {
  const { toggleEditMode, hasLock, editedByUser, curEditMode } = props;
  const { t } = useTranslation();
  const { openedCase } = useRootStore().caseStore;

  const onClick = (): void => {
    if (hasLock) {
      toast.info(t('caseIsLockedToast', { nickname: editedByUser?.nickName }));
      AmplService.sendCaseEvent(
        AmplService.EVENTS.EDIT_HUDDLE_LOCKED,
        openedCase?.basicCase as ICase
      );
      return;
    }
    toggleEditMode();
  };

  return (
    <FabButton
      onClick={onClick}
      classNames={'toggle-edit-fab right-align'}
      startIcon={
        <div className="button-icon">
          {!curEditMode ? (
            <PencilIcon fill={variables.white} />
          ) : (
            <DoneIcon stroke={variables.white} />
          )}
        </div>
      }
      buttonContent={
        <div className="text-expand-container">
          <div className={clsx('button-text', { isFull: !curEditMode })}>{t('editHuddle')}</div>
        </div>
      }
    />
  );
};

export default observer(ToggleEditModeButton);
