/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { useRootStore } from 'app/mobxStore';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import PhoneNumberInput from './PhoneNumberInput';
import SubmitButton from 'app/components/buttons/SubmitButton';
import apiService from '../../services/apiService/apiService';
import { toast } from 'react-toastify';

const fields = [
  {
    name: 'nickName',
    placeholder: 'nickNamePlaceholder'
  },
  { name: 'firstName' },
  { name: 'lastName' },
  {
    name: 'phone',
    isShowError: false,
    errorMessage: 'invalidPhoneNumber',
    placeholder: 'phonePlaceholder'
  }
];

enum SETTINGS_FORM_FIELDS_ENUM {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  DISPLAY_NAME = 'nickName',
  PHONE_NUMBER = 'phone'
}

interface FormFields {
  [SETTINGS_FORM_FIELDS_ENUM.FIRST_NAME]: string;
  [SETTINGS_FORM_FIELDS_ENUM.LAST_NAME]: string;
  [SETTINGS_FORM_FIELDS_ENUM.DISPLAY_NAME]: string;
  [SETTINGS_FORM_FIELDS_ENUM.PHONE_NUMBER]: string;
}

const AccountSettingsForm = (): React.JSX.Element => {
  const { t } = useTranslation();
  const { userStore } = useRootStore();
  const userData = userStore.loggedInUser.data;

  const handleValidateField = (field: any, value: string): boolean => {
    if (field.name !== 'phone') return true;
    // eslint-disable-next-line no-useless-escape
    const re = /^(\([0-9]{3}\)|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$|^$/;
    if (!re.test(value)) {
      fields[fields.indexOf(field)].isShowError = true;
    } else {
      fields[fields.indexOf(field)].isShowError = false;
    }
    return re.test(value);
  };

  const defaultValues = {
    [SETTINGS_FORM_FIELDS_ENUM.FIRST_NAME]: userData.firstName,
    [SETTINGS_FORM_FIELDS_ENUM.LAST_NAME]: userData.lastName,
    [SETTINGS_FORM_FIELDS_ENUM.DISPLAY_NAME]: userData.nickName,
    [SETTINGS_FORM_FIELDS_ENUM.PHONE_NUMBER]: userData.phone
  };
  const {
    handleSubmit,
    formState: { isDirty, isValid, isSubmitSuccessful },
    reset,
    getValues,
    control
  } = useForm<FormFields>({
    defaultValues
  });
  const isSubmitEnabled = isDirty && isValid;
  const onSubmit = async (data: FormFields): Promise<void> => {
    // unmask patterned phone number
    data.phone = data.phone.replace(/\D/g, '');
    const newUserData = await apiService.updateCurrentUser(data);
    toast.success(t('updated'));
    userStore.loggedInUser.setData({
      ...newUserData,
      phone: data.phone,
      email: userData.email,
      roles: userData.roles,
      reminderDays: userData.reminderDays,
      onboardingDone: userData.onboardingDone
    });
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(getValues());
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <div className="account-settings-form">
      <div className="email-address">{userData.email}</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {fields.map(formField => (
          <div className="text-field-container" key={formField.name}>
            <div className="text-field-label">{t(formField.name)}</div>
            <Controller
              name={formField.name as SETTINGS_FORM_FIELDS_ENUM}
              control={control}
              rules={{ validate: value => handleValidateField(formField, value) }}
              render={({ field }) => {
                const placeholder = t(formField.placeholder ?? '');
                return field.name === 'phone' ? (
                  <PhoneNumberInput field={field} placeholder={placeholder} />
                ) : (
                  <TextField {...field} placeholder={placeholder} className="text-field" />
                );
              }}
            />
            {formField.errorMessage && formField.isShowError && (
              <div className="error-message">{t(formField.errorMessage)}</div>
            )}
          </div>
        ))}
        <SubmitButton
          id="account-settings-form-submit-button"
          classNames="submit-button"
          title="saveChanges"
          submitFunction={handleSubmit(onSubmit)}
          isDisabled={!isSubmitEnabled}
        />
      </form>
    </div>
  );
};

export default observer(AccountSettingsForm);
