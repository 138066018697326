import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../mobxStore';
import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import QRCode from 'react-qr-code';
import Button from 'app/components/buttons/Button';
import Spinner from 'app/components/spinner/Spinner';
import CreateInviteButton from './CreateInviteButton';
import ShareIcon from 'app/assets/icons/Share_icon';
import CopyIcon from 'app/assets/icons/Copy_icon';
import RestoreIcon from 'app/assets/icons/Restore_icon';
import variables from 'app/utils/variables.module.scss';
import './Invite.scss';
import AmplService from '../../services/amplService';
import { t } from 'i18next';

const InviteContent = (): React.JSX.Element => {
  const { userStore, caseStore } = useRootStore();
  const [isShowQr, setIsShowQr] = useState(true);
  const inviteId = userStore.inviteId;
  const newSite = caseStore.isNewSiteInvite;

  const handleCreateAnother = async (): Promise<void> => {
    setIsShowQr(false);
    const inviteId = await userStore.inviteUser(newSite);
    setTimeout(() => {
      setIsShowQr(true);
    }, 1500);
    AmplService.sendEvent(AmplService.EVENTS.INVITE_CREATE_ANOTHER, {
      [AmplService.ATTRIBUTES.INVITE_ID]: inviteId,
      [AmplService.ATTRIBUTES.IS_NEW_SITE]: newSite
    });
  };

  const getInviteText = (): string => {
    const text = newSite ? 'inviteContentNewSite' : 'inviteContentExistingTeam';
    return t(text, { url });
  };

  const handleShare = async (): Promise<void> => {
    const text = getInviteText();
    const shareData = {
      title: t('inviteTitle'),
      text
    };
    if ('share' in navigator) {
      void navigator.share(shareData);
    }

    AmplService.sendEvent(AmplService.EVENTS.INVITE_SHARE, {
      [AmplService.ATTRIBUTES.INVITE_ID]: inviteId
    });
  };

  const handleCopy = async (): Promise<void> => {
    toast.success(t('inviteCopied'));
    const text = getInviteText();
    await navigator.clipboard.writeText(text);
    AmplService.sendEvent(AmplService.EVENTS.INVITE_COPY, {
      [AmplService.ATTRIBUTES.INVITE_ID]: inviteId
    });
  };

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const url = `${window.location.origin}/register/${inviteId}`;

  // TODO: handle expiration time from invite. Currently hardcoded to +24 hours

  const expirationTime = new Date();
  expirationTime.setHours(expirationTime.getHours() + 24);
  const qrExpTime = expirationTime.toLocaleString('en-US', {
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });

  return (
    <div className={clsx('expand-container', { max: inviteId })}>
      {inviteId ? (
        <div className="invite-content">
          <div className="qr-box">{isShowQr ? <QRCode value={url} size={200} /> : <Spinner />}</div>
          <div className="qr-actions">
            <div className="qr-disclaimer">
              <Trans
                i18nKey="qrExpirationText"
                // @todo handle expiration time
                values={{ expirationTime: qrExpTime }}
              />
            </div>
            <div className="row">
              <Button
                classNames="btn primary share-button"
                buttonText="share"
                startIcon={<ShareIcon />}
                onClick={handleShare}
              />
              <Button
                classNames="btn primary copy-button"
                buttonText="copy"
                startIcon={<CopyIcon />}
                onClick={handleCopy}
              />
            </div>
            <Button
              classNames="btn primary create-another-button"
              buttonText="anotherInvite"
              endIcon={<RestoreIcon stroke={variables.white} />}
              onClick={handleCreateAnother}
            />
          </div>
        </div>
      ) : (
        <CreateInviteButton />
      )}
    </div>
  );
};

export default observer(InviteContent);
