import React, { useEffect, useState } from 'react';
import apiService from 'app/services/apiService';
import { useRootStore } from 'app/mobxStore';
import { getUserDataFromToken } from 'app/apiHelper';
import { useAuth0 } from '@auth0/auth0-react';
import { lite, setAccessToken, setAccessTokenGetter } from '../services/apiService/apiService';
import { isStandAlone } from '../mobxStore/caseStoreHelper';
import { ROLES } from '../consts';
import AmplService from '../services/amplService';
import ErrorMonitor from '../services/errorMonitor/errorMonitor';

interface Props {
  children: React.JSX.Element | React.JSX.Element[];
}

const DEBUG_AUTH = true;

const Auth = (props: Props): React.JSX.Element => {
  const [waitAuthCheck, setWaitAuthCheck] = useState(true);
  const { userStore } = useRootStore();
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const [hasActiveTokenResult, setHasActiveTokenResult] = useState(false);
  const getAccessToken = async (): Promise<string> => {
    const token = await getAccessTokenSilently();
    return token;
  };
  useEffect(() => {
    setAccessTokenGetter(getAccessToken);
  }, []);

  useEffect(() => {
    if (hasActiveTokenResult) {
      return;
    }
    if (isLoading) {
      return;
    }

    const handleAccessToken = async (): Promise<void> => {
      try {
        const accessTokenResult = await getAccessTokenSilently();
        setAccessToken(accessTokenResult);
        setHasActiveTokenResult(true);
      } catch (error) {
        setHasActiveTokenResult(false);
      }
    };

    if (isAuthenticated) {
      void handleAccessToken();
      return;
    }

    setWaitAuthCheck(false);
  }, [isAuthenticated, isLoading, hasActiveTokenResult]);
  useEffect(() => {
    if (!hasActiveTokenResult) {
      return;
    }
    const handleLogin = async (): Promise<void> => {
      apiService.init();

      userStore.loggedInUser.setLoggedIn(true);
      AmplService.sendEvent(AmplService.EVENTS.LOGIN);

      const token = await getAccessTokenSilently();

      const { roles } = getUserDataFromToken(token);

      if (roles.length > 0) {
        if (lite && !isStandAlone()) {
          if (DEBUG_AUTH) {
            ErrorMonitor.captureMessage('lite and not stand add need_pwa role');
          }
          userStore.loggedInUser.setRoles([ROLES.NEED_PWA]);
        } else {
          userStore.loggedInUser.setRoles(roles);
        }
      }

      ErrorMonitor.setUser(userStore.loggedInUser.data.id, userStore.loggedInUser.data.email);
      ErrorMonitor.setTag('roles', roles.join(','));

      setWaitAuthCheck(false);
    };
    void handleLogin();
  }, [hasActiveTokenResult]);

  return waitAuthCheck ? <></> : <>{props.children}</>;
};

export default Auth;
