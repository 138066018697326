import { createContext, useContext } from 'react';
import RootStore from 'app/mobxStore/RootStore';
import type { IRootStore, IStoreData } from './types';

const store: IStoreData = {
  rootStore: new RootStore()
};

const StoreContext = createContext(store);

export const useStore = (): IStoreData => {
  return useContext(StoreContext);
};

export const useRootStore = (): IRootStore => {
  return useStore().rootStore;
};

export default useStore;
