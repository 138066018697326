export const getAudPrefix = (): string => {
  let prefix = window.location.host.split('.')[0];
  if (window.location.host.endsWith(':3000')) {
    prefix = process.env.REACT_APP_LOCALHOST_AUD_PREFIX ?? '';
  }
  if (prefix === 'stage-app' || prefix === 'r-stage-app') {
    prefix = 'stage';
  }
  if (prefix.endsWith('chf-tay-dev') || prefix.endsWith('r-chiefy')) {
    prefix = 'dev';
  }
  if (prefix.endsWith('zipora-app')) {
    prefix = 'zipora';
  }
  if (prefix.endsWith('zipora')) {
    prefix = 'zipora';
  }
  if (prefix.endsWith('zipora-prod')) {
    prefix = 'zipora';
  }
  if (prefix.startsWith('r-')) {
    prefix = prefix.slice(2);
  }
  return prefix;
};

export const getAudPrefixForEnvVar = (): string => {
  return getAudPrefix().toUpperCase().replace('-', '_');
};
