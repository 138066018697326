import { useEffect, useState } from 'react';
import { usePageVisibility } from 'react-page-visibility';
import { useRootStore } from 'app/mobxStore';

const useHandleVisibilityChange = (): void => {
  const { caseStore } = useRootStore();
  const isVisible = usePageVisibility();
  const [lastVisible, setLastVisible] = useState(Infinity);
  const [isPageVisible, setIsPageVisible] = useState(true);

  useEffect(() => {
    const handleVisibilityChange = async (): Promise<void> => {
      try {
        if (!isVisible) {
          setLastVisible(Date.now());
        }
        if (isVisible && !isPageVisible && Date.now() - lastVisible > 1000 * 3) {
          console.log('returning from background');
          await caseStore.loadAllCases(true, false);
          caseStore.unSubscribeFromCases();

          setLastVisible(Infinity);
          caseStore.setRefreshSubscriptions(true);
          setTimeout(async () => {
            void caseStore.subscribeToCases();
            caseStore.setRefreshSubscriptions(false);
          }, 1000);
        }
        setIsPageVisible(isVisible);
      } catch (err) {
        console.error(err);
      }
    };
    void handleVisibilityChange();
  }, [isVisible]);
};

export default useHandleVisibilityChange;
