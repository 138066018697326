import * as React from 'react';
const SvgComponent = props => (
  <svg
    className="svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 6 10"
    fill={props.fill || 'none'}
    {...props}
  >
    <path
      d="M5.08317 9.16671L0.916504 5.00004L5.08317 0.833374"
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgComponent;
