import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationPanel from 'app/components/confirmationPanel/ConfirmationPanel';

interface IProps {
  isShowPanel: boolean;
  setIsShowPanel: (isShowPanel: boolean) => void;
  handleDiscardConfirm: () => void;
}

const FollowersDiscardPanel = (props: IProps): React.JSX.Element => {
  const { isShowPanel, setIsShowPanel, handleDiscardConfirm } = props;
  const { t } = useTranslation();

  const handleConfirm = async (): Promise<void> => {
    handleClose();
    handleDiscardConfirm();
  };

  const handleCancel = async (): Promise<void> => {
    handleClose();
  };

  const handleClose = (): void => {
    setIsShowPanel(false);
  };

  return (
    <ConfirmationPanel
      title={t('followersDiscardTitle')}
      buttons={[
        {
          buttonText: t('followersDiscardConfirm'),
          onClick: handleConfirm,
          variant: 'outlined',
          key: 'yes',
          closeOnClick: true
        },
        {
          buttonText: t('followersDiscardBack'),
          onClick: handleCancel,
          variant: 'secondary',
          key: 'no',
          closeOnClick: true
        }
      ]}
      isShowPanel={isShowPanel}
      setIsShowPanel={setIsShowPanel}
    />
  );
};

export default FollowersDiscardPanel;
