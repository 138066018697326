import * as React from 'react';
const SvgComponent = props => (
  <svg
    className="svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill={props.fill || 'none'}
    {...props}
  >
    <path
      d="M15.4163 16.875H12.083C11.7413 16.875 11.458 16.5917 11.458 16.25C11.458 15.9083 11.7413 15.625 12.083 15.625H15.4163C15.758 15.625 16.0413 15.9083 16.0413 16.25C16.0413 16.5917 15.758 16.875 15.4163 16.875Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M13.75 18.5418C13.4083 18.5418 13.125 18.2585 13.125 17.9168V14.5835C13.125 14.2418 13.4083 13.9585 13.75 13.9585C14.0917 13.9585 14.375 14.2418 14.375 14.5835V17.9168C14.375 18.2585 14.0917 18.5418 13.75 18.5418Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M10.1333 9.68317C10.1083 9.68317 10.0916 9.68317 10.0666 9.68317C10.0249 9.67484 9.96658 9.67484 9.91658 9.68317C7.49992 9.60817 5.67492 7.70817 5.67492 5.3665C5.66659 4.2165 6.11658 3.13317 6.93325 2.3165C7.74992 1.49984 8.83325 1.0415 9.99158 1.0415C12.3749 1.0415 14.3166 2.98317 14.3166 5.3665C14.3166 7.70817 12.4916 9.59984 10.1583 9.68317C10.1499 9.68317 10.1416 9.68317 10.1333 9.68317ZM9.99158 2.2915C9.16658 2.2915 8.39992 2.6165 7.81659 3.1915C7.24159 3.77484 6.92492 4.5415 6.92492 5.35817C6.92492 7.02484 8.22492 8.37484 9.88325 8.42484C9.93325 8.4165 10.0416 8.4165 10.1499 8.42484C11.7916 8.34984 13.0666 7.00817 13.0666 5.35817C13.0666 3.67484 11.6833 2.2915 9.99158 2.2915Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M9.99147 18.8001C8.29147 18.8001 6.68314 18.3584 5.46647 17.5417C4.30814 16.7667 3.6748 15.7084 3.6748 14.5667C3.6748 13.4251 4.31647 12.3751 5.46647 11.6084C7.95814 9.94173 12.0081 9.94173 14.4998 11.6084C14.7831 11.8001 14.8665 12.1917 14.6748 12.4751C14.4831 12.7667 14.0915 12.8417 13.8081 12.6501C11.7331 11.2667 8.23314 11.2667 6.15814 12.6501C5.35814 13.1834 4.9248 13.8584 4.9248 14.5667C4.9248 15.2751 5.35814 15.9667 6.15814 16.5001C7.16647 17.1751 8.5248 17.5417 9.98314 17.5417C10.3248 17.5417 10.6081 17.8251 10.6081 18.1667C10.6081 18.5084 10.3331 18.8001 9.99147 18.8001Z"
      fill={props.fill || '#B33B94'}
    />
  </svg>
);
export default SvgComponent;
