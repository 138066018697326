import * as React from 'react';
const SvgComponent = props => (
  <svg
    className="svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fill={props.fill || 'none'}
    {...props}
  >
    <path
      d="M5.53849 13.5385C5.37527 13.5385 5.21877 13.4737 5.10331 13.3583C4.98792 13.2428 4.9231 13.0863 4.9231 12.9231V5.53847C4.9231 5.31859 5.0404 5.11547 5.23079 5.00555C5.42117 4.89562 5.6558 4.89561 5.84618 5.00555C6.03656 5.11548 6.15387 5.31861 6.15387 5.53847V12.9231C6.15387 13.0863 6.08904 13.2428 5.97366 13.3583C5.85819 13.4737 5.70171 13.5385 5.53849 13.5385Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M7.99991 13.5385C7.83669 13.5385 7.6802 13.4737 7.56473 13.3583C7.44935 13.2428 7.38452 13.0863 7.38452 12.9231V5.53847C7.38452 5.31859 7.50183 5.11547 7.69221 5.00555C7.8826 4.89562 8.11722 4.89561 8.3076 5.00555C8.49798 5.11548 8.61529 5.31861 8.61529 5.53847V12.9231C8.61529 13.0863 8.55047 13.2428 8.43508 13.3583C8.31962 13.4737 8.16313 13.5385 7.99991 13.5385Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M10.4616 13.5385C10.2984 13.5385 10.1419 13.4737 10.0264 13.3583C9.91102 13.2428 9.84619 13.0863 9.84619 12.9231V5.53847C9.84619 5.31859 9.9635 5.11547 10.1539 5.00555C10.3443 4.89562 10.5789 4.89561 10.7693 5.00555C10.9597 5.11548 11.077 5.31861 11.077 5.53847V12.9231C11.077 13.0863 11.0121 13.2428 10.8968 13.3583C10.7813 13.4737 10.6248 13.5385 10.4616 13.5385Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M12.923 16.0001H3.0768C2.85692 16.0001 2.6538 15.8828 2.54388 15.6924C2.43395 15.502 2.43394 15.2674 2.54388 15.077C2.65381 14.8866 2.85694 14.7693 3.0768 14.7693H12.923C13.1429 14.7693 13.346 14.8866 13.4559 15.077C13.5658 15.2674 13.5658 15.502 13.4559 15.6924C13.346 15.8827 13.1428 16.0001 12.923 16.0001Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M12.923 16C12.7598 16 12.6033 15.9352 12.4878 15.8198C12.3724 15.7044 12.3076 15.5479 12.3076 15.3846V3.07692C12.3076 2.85704 12.4249 2.65393 12.6153 2.544C12.8057 2.43407 13.0403 2.43406 13.2307 2.544C13.4211 2.65393 13.5384 2.85706 13.5384 3.07692V15.3846C13.5384 15.5479 13.4736 15.7044 13.3582 15.8198C13.2427 15.9352 13.0862 16 12.923 16Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M3.07682 16C2.9136 16 2.7571 15.9352 2.64164 15.8198C2.52625 15.7044 2.46143 15.5479 2.46143 15.3846V3.07692C2.46143 2.85704 2.57873 2.65393 2.76912 2.544C2.9595 2.43407 3.19413 2.43406 3.38451 2.544C3.57489 2.65393 3.6922 2.85706 3.6922 3.07692V15.3846C3.6922 15.5479 3.62737 15.7044 3.51199 15.8198C3.39652 15.9352 3.24004 16 3.07682 16Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M15.3846 3.69232H0.615374C0.395496 3.69232 0.192378 3.57501 0.0824495 3.38463C-0.0274791 3.19424 -0.0274873 2.95962 0.0824495 2.76924C0.192386 2.57886 0.395517 2.46155 0.615374 2.46155H15.3846C15.6045 2.46155 15.8076 2.57886 15.9176 2.76924C16.0275 2.95963 16.0275 3.19425 15.9176 3.38463C15.8076 3.57501 15.6045 3.69232 15.3846 3.69232Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M5.53849 3.69231C5.37527 3.69231 5.21877 3.62748 5.10331 3.5121C4.98792 3.39664 4.9231 3.24014 4.9231 3.07692V0.615374C4.9231 0.395496 5.0404 0.192378 5.23079 0.0824495C5.42117 -0.0274791 5.6558 -0.0274872 5.84618 0.0824495C6.03656 0.192386 6.15387 0.395517 6.15387 0.615374V3.07692C6.15387 3.24014 6.08904 3.39663 5.97366 3.5121C5.85819 3.62748 5.70171 3.69231 5.53849 3.69231Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M10.4616 3.69231C10.2984 3.69231 10.1419 3.62748 10.0264 3.5121C9.91102 3.39664 9.84619 3.24014 9.84619 3.07692V0.615374C9.84619 0.395496 9.9635 0.192378 10.1539 0.0824495C10.3443 -0.0274791 10.5789 -0.0274872 10.7693 0.0824495C10.9597 0.192386 11.077 0.395517 11.077 0.615374V3.07692C11.077 3.24014 11.0121 3.39663 10.8968 3.5121C10.7813 3.62748 10.6248 3.69231 10.4616 3.69231Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M10.4616 1.23077H5.53847C5.31859 1.23077 5.11547 1.11346 5.00555 0.923079C4.89562 0.732695 4.89561 0.498073 5.00555 0.307693C5.11548 0.117313 5.31861 0 5.53847 0H10.4616C10.6814 0 10.8846 0.117309 10.9945 0.307693C11.1044 0.498077 11.1044 0.732699 10.9945 0.923079C10.8845 1.11346 10.6814 1.23077 10.4616 1.23077Z"
      fill={props.fill || '#B33B94'}
    />
  </svg>
);
export default SvgComponent;
