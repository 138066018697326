import { gql } from '@apollo/client';

const upsertPlanFeedback = gql`
  mutation CareTeamCreatePlanFeedback($case_id: uuid!, $rating: Float!, $text: String) {
    insert_plan_feedbacks_one(
      object: { case_id: $case_id, rating: $rating, text: $text }
      on_conflict: { constraint: pf_case_id_by_id_, update_columns: [rating, text] }
    ) {
      id
    }
  }
`;

const deletePlanFeedback = gql`
  mutation CareTeamDeletePlanFeedback($case_id: uuid!, $created_by_id: uuid!) {
    delete_plan_feedbacks(
      where: { case_id: { _eq: $case_id }, created_by_id: { _eq: $created_by_id } }
    ) {
      affected_rows
    }
  }
`;

const queries = {};

const mutations = {
  deletePlanFeedback,
  upsertPlanFeedback
};

const api = {
  queries,
  mutations
};

export default api;
