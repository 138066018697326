import React from 'react';
import NewCaseFab from 'app/components/orBoard/newCaseFab/NewCaseFab';
import { useTranslation } from 'react-i18next';

import './EmptyList.scss';
import { ReactComponent as PenguinSkating } from '../../../assets/penguins/penguin_skating_animated.svg';
import { observer } from 'mobx-react-lite';

const CatchUpEmptyList = (): React.JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="empty-list">
      <div className="empty-container">
        <div className="image-container">
          <PenguinSkating />
        </div>
        <div className="text">{t('catchUpEmptyList')}</div>
      </div>
      <NewCaseFab />
    </div>
  );
};

export default observer(CatchUpEmptyList);
