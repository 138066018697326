import * as React from 'react';
const SvgComponent = props => (
  <svg
    className="svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill={props.fill || 'none'}
    {...props}
  >
    <path
      d="M4.66675 7.49996C4.66675 4.55444 7.05456 2.16663 10.0001 2.16663C12.9456 2.16663 15.3334 4.55444 15.3334 7.49996V15.3333H4.66675V7.49996Z"
      stroke={props.stroke || '#B33B94'}
    />
    <path
      d="M4.16675 15.8333V7.49996C4.16675 4.27829 6.77841 1.66663 10.0001 1.66663C13.2217 1.66663 15.8334 4.27829 15.8334 7.49996V15.8333M1.66675 15.8333H18.3334"
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0001 18.3334C11.1507 18.3334 12.0834 17.4006 12.0834 16.25V15.8334H7.91675V16.25C7.91675 17.4006 8.8495 18.3334 10.0001 18.3334Z"
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgComponent;
