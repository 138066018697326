/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import type { ICase, ICommentData, IFullCase, IUserDataWithRoles } from '../../mobxStore/types';
import { observer } from 'mobx-react-lite';
import { useRootStore } from 'app/mobxStore';
import { parseUserToSelectOption } from 'app/mobxStore/MetaDataStore';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import UserAvatar from 'app/components/userAvatar/UserAvatar';
import Likes from './Likes';

interface IProps {
  comment: ICommentData;
  readByUsers: IUserDataWithRoles[];
  isCurrentUserLastRead: boolean;
}

const Comment = (props: IProps): React.JSX.Element => {
  const { comment, readByUsers, isCurrentUserLastRead } = props;

  const { t } = useTranslation();
  const { user, comment: commentText, updatedAt } = comment;
  const { metaDataStore, userStore, caseStore } = useRootStore();
  const openedCase = caseStore.openedCase as IFullCase;
  const isSelf = userStore.loggedInUser.data.id === user.id;
  const commentTime = metaDataStore.formatDateInSiteTZ(new Date(updatedAt), 'hh:mm a');

  return (
    <div className={clsx('comment', { self: isSelf })}>
      {!isSelf && (
        <div className="comment-user">
          <UserAvatar user={parseUserToSelectOption(user)} size={'medium'} />
          <div className="user-name">{user.nickName}</div>
        </div>
      )}
      <div className="comment-bubble">
        <div className="comment-text">{commentText}</div>
        <div className="comment-time">{commentTime}</div>
        <Likes comment={comment} openedCase={openedCase} />
      </div>
      {readByUsers.length > 0 && (
        <div className="read-receipts">
          {readByUsers.map(follower => (
            <div key={follower.id} className="receipt">
              <UserAvatar user={parseUserToSelectOption(follower)} size={'small'} />
            </div>
          ))}
        </div>
      )}
      {isCurrentUserLastRead && (
        <div className="unread-separator">
          <div className="separator-text">{t('unreadMessages')}</div>
        </div>
      )}
    </div>
  );
};

export default observer(Comment);
