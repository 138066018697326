import React from 'react';
import { Navigate } from 'react-router-dom';
import RegisterConfig from 'app/pages/register/RegisterConfig';
import LogoutConfig from 'app/pages/logout/LogoutConfig';
import LoginConfig from 'app/pages/login/LoginConfig';
import CallbackConfig from 'app/pages/callback/CallbackConfig';
import CasesConfig from 'app/pages/cases/CasesConfig';
import OnboardingConfig from 'app/pages/onboarding/OnboardingConfig';
import WhatsNewConfig from 'app/pages/whatsNew/WhatsNewConfig';
import AddToHomeScreenConfig from 'app/pages/landing/AddToHomeScreenConfig';
import _ from 'lodash';
const generateRoutesFromConfigs = (configs, defaultAuth) => {
  let allRoutes = [];
  configs.forEach(config => {
    allRoutes = [...allRoutes, ...setRoutes(config, defaultAuth)];
  });
  return allRoutes;
};
const setRoutes = (config, defaultAuth) => {
  let routes = [...config.routes];

  routes = routes.map(route => {
    let auth = config.auth || config.auth === null ? config.auth : defaultAuth || null;
    auth = route.auth || route.auth === null ? route.auth : auth;
    const settings = _.merge({}, config.settings, route.settings);

    return {
      ...route,
      settings,
      auth
    };
  });

  return [...routes];
};
const routeConfigs = [
  RegisterConfig,
  LoginConfig,
  CallbackConfig,
  CasesConfig,
  LogoutConfig,
  OnboardingConfig,
  WhatsNewConfig,
  AddToHomeScreenConfig
];
const routes = [
  ...generateRoutesFromConfigs(routeConfigs, ['admin']),
  {
    path: '/',
    element: <Navigate to="/cases" replace />
  }
];

export default routes;
