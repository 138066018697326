import React from 'react';
import Paper from '@mui/material/Paper';
import FormLabel from '@mui/material/FormLabel';
import type { IDynamicField } from 'app/mobxStore/types';
import { getTextFieldValue } from 'utils/form/form';
import { observer } from 'mobx-react-lite';
interface IProps {
  field: IDynamicField;
}
const InfoBox = (props: IProps): React.JSX.Element => {
  const { field: dField } = props;

  const value = getTextFieldValue(dField);
  return (
    <Paper square>
      {dField.label !== '' && <FormLabel component="legend">{dField.label}</FormLabel>}
      <div>{value}</div>
    </Paper>
  );
};

export default observer(InfoBox);
