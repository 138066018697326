import * as React from 'react';
const SvgComponent = props => (
  <svg
    className="svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fill={props.fill || 'none'}
    {...props}
  >
    <path
      d="M7.99999 7.94222V7.76906C7.99999 7.20823 8.3258 6.9113 8.6516 6.67213C8.96964 6.4412 9.28763 6.1443 9.28763 5.59997C9.28763 4.8412 8.71363 4.23086 7.99999 4.23086C7.28634 4.23086 6.71235 4.8412 6.71235 5.59997M7.9965 9.91338H8.00348M1 15V2.6495C1 1.73851 1.69459 1 2.5514 1H13.4486C14.3054 1 15 1.7385 15 2.6495V10.9029C15 11.8139 14.3054 12.5524 13.4486 12.5524H4.09675L1 15Z"
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgComponent;
