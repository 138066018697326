import React, { type MouseEvent, useState } from 'react';
import type { Control } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';
import { FORM_FIELDS_ENUM, type IFormData } from './types';
import clsx from 'clsx';
import ClassicDatePicker from 'app/components/datePickers/classicDatePicker/ClassicDatePicker';
import Button from 'app/components/buttons/Button';
import ModalScreen from 'app/components/modalScreen/ModalScreen';
import MomentAdapter from '@date-io/moment';
import IconButton from '@mui/material/IconButton';
import CalendarIcon from 'app/assets/icons/Calendar_icon';
import CloseIcon from 'app/assets/icons/Close_icon';
import variables from 'app/utils/variables.module.scss';
import { useRootStore } from '../../mobxStore';
import { toZonedTime } from 'date-fns-tz';

interface IProps {
  control: Control<IFormData>;
  allValues: IFormData;
  isReadOnly?: boolean;
  disablePast?: boolean;
  setDateToPostponeCase?: (date: Date) => void;
}

const CaseDate = (props: IProps): React.JSX.Element => {
  const { isReadOnly, disablePast, control, setDateToPostponeCase } = props;
  const { metaDataStore } = useRootStore();
  const {
    field: { value, onChange }
  } = useController({
    rules: { required: true },
    name: FORM_FIELDS_ENUM.DATE,
    control
  });
  const { t } = useTranslation();

  const [isOpen, seIsOpen] = useState(false);
  const navigateToToday = (onChange: (...event: any[]) => void): void => {
    const dateLib = new MomentAdapter();
    const defaultDate = toZonedTime(new Date(), metaDataStore.tz);
    defaultDate.setHours(24, 0, 0, 0);
    const fixedDate = dateLib.moment(defaultDate).subtract(1, 'days');
    onChange(fixedDate.toDate());
  };

  const content = (
    <div className="modal-content case-date">
      <div className="modal-header">
        <IconButton
          className="close-button"
          onClick={() => {
            seIsOpen(false);
          }}
        >
          <CloseIcon stroke={variables.grey6} />
        </IconButton>
        <div>{t('pickDate')}</div>
      </div>
      <ClassicDatePicker
        handleClickDay={(day: Date) => {
          if (setDateToPostponeCase !== undefined) {
            setDateToPostponeCase(day);
          } else {
            onChange(day);
          }
          seIsOpen(false);
        }}
        selectedDay={value}
        hideHasCases
        navigateToToday={() => {
          navigateToToday(onChange);
        }}
        disablePast={disablePast}
      />
    </div>
  );
  const getButtonText = (date: Date | undefined): string => {
    if (date === undefined) {
      return 'date';
    }
    return date.toLocaleDateString();
  };
  const buttonText = getButtonText(value);
  return (
    <div className="date-select">
      <Button
        classNames={clsx('chip-button', { 'has-value': value !== undefined })}
        buttonText={buttonText}
        disabled={isReadOnly}
        startIcon={<CalendarIcon />}
        onClick={async (e: MouseEvent) => {
          seIsOpen(true);
        }}
      />
      <ModalScreen
        isOpen={isOpen}
        classNames={'centered date-modal'}
        transitionType="fade"
        content={content}
        handleClose={() => {
          seIsOpen(false);
        }}
      />
    </div>
  );
};

export default CaseDate;
