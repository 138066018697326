import React from 'react';
import { useRootStore } from 'app/mobxStore';
import HuddleMessage from 'app/components/emptyHuddle/HuddleMessage';
import FillCaseMetaForm from '../fillMetaForm/FillCaseMetaForm';
import { useFillMetaForm } from '../fillMetaForm/useFillMetaForm';
import { observer } from 'mobx-react-lite';

const CaseWithMessage = (): React.JSX.Element => {
  const { caseStore } = useRootStore();
  const {
    control: metaFormControl,
    formState: metaFormState,
    allValues: metaFormAllValues,
    handleMetaDataChange,
    handleProcedureChange
  } = useFillMetaForm();

  const handleClose = async (): Promise<void> => {
    caseStore.setIsHuddleDialogOpen(false);
  };
  return (
    <div className="not-ready-case">
      <FillCaseMetaForm
        handleProcedureChange={handleProcedureChange}
        handleMetaDataChange={handleMetaDataChange}
        handleClose={handleClose}
        showSubmit={false}
        control={metaFormControl}
        allValues={metaFormAllValues}
        formState={metaFormState}
      />
      <HuddleMessage />
    </div>
  );
};

export default observer(CaseWithMessage);
