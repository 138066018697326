import React from 'react';
import { useRootStore } from 'app/mobxStore';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import CaseListItem from 'app/components/orBoard/caseListItem/CaseListItem';
import CatchUpEmptyList from 'app/components/orBoard/emptyList/CatchUpEmptyList';
import NewCaseFab from 'app/components/orBoard/newCaseFab/NewCaseFab';
import './CasesList.scss';
import SuggestToInvitePanel from './SuggestToInvitePanel';
import _ from 'lodash';
import { format } from 'date-fns';

const CatchUpList = (): React.JSX.Element => {
  const { caseStore } = useRootStore();

  const hasCases = caseStore.sortedCases.length > 0;
  const groupedByDate = _.groupBy(caseStore.sortedCases, 'data.caseDate');

  if (!hasCases) {
    return <CatchUpEmptyList />;
  }

  return (
    <div className="cases-list-container">
      <div className={clsx('cases-list catch-up')}>
        {Object.keys(groupedByDate).map(date => (
          <div className="case-list-group" key={date}>
            <div className="case-list-group-label">{format(date, 'd MMMM, yyyy')}</div>
            {groupedByDate[date].map(kase => (
              <div key={kase.id} id={kase.id}>
                <CaseListItem key={kase.id} kase={kase} isDragging={false} />
              </div>
            ))}
          </div>
        ))}
        <NewCaseFab />
        <SuggestToInvitePanel />
      </div>
    </div>
  );
};

export default observer(CatchUpList);
