import apiService from 'app/services/apiService';
import {
  FIELD_TYPE,
  type IDynamicField,
  type IDynamicFieldValues,
  type IPMMFields,
  type IServerProcessedField
} from 'app/mobxStore/types';
import { isOtherSelected } from '../../app/components/form/helper';

export const submitBriefForm = async (
  caseId: string,
  fields: IDynamicField[],
  values: IDynamicFieldValues
): Promise<void> => {
  const pmmValues = processData(caseId, fields, values);

  await apiService.submitBriefForm(caseId, pmmValues);
};

export const submitPmmField = async (
  caseId: string,
  field: IDynamicField,
  values: IDynamicFieldValues
): Promise<IServerProcessedField> => {
  const parseFunction = processField(caseId, values);
  const pmmValue = parseFunction(field);
  await apiService.updatePmmValue(caseId, field.id, pmmValue);
  return pmmValue;
};

const processData = (
  caseId: string,
  fields: IDynamicField[],
  values: IDynamicFieldValues
): IServerProcessedField[] => {
  return filterPmmFields(fields).map(processField(caseId, values));
};

const processDataForDefault = (
  fields: IDynamicField[],
  values: IDynamicFieldValues
): Record<string, IPMMFields> => {
  const acc: Record<string, IPMMFields> = {};
  filterPmmFields(fields).reduce(
    (previousValue: Record<string, IPMMFields>, field: IDynamicField, currentIndex: number) => {
      previousValue[field.id] = processFieldForDefault(field, values);
      return previousValue;
    },
    acc
  );
  return acc;
};

const filterPmmFields = (fields: IDynamicField[]): IDynamicField[] => {
  return fields.filter(field => field.isPmm);
};

const assertArray = (value: any): any[] => {
  if (value === null) {
    return [];
  }
  if (Array.isArray(value)) {
    return value;
  }
  return [value];
};

const getValuesFromField = (
  field: IDynamicField,
  values: IDynamicFieldValues
): { values: string[]; otherValues: string[] } => {
  const fVals = values[field.id];
  const arrayValue = assertArray(fVals);

  const parsedValues = getValueFromModel(arrayValue, field.type);
  const otherSelected = isOtherSelected(field, arrayValue);
  // remove this brake things, need to check why and remove.
  const otherValue = assertArray(values[`${field.id}-other`]);

  const hasOtherValue =
    otherSelected && otherValue !== undefined && otherValue.length !== 0 && otherValue[0] !== '';
  const otherValues = otherSelected && hasOtherValue ? otherValue : [];

  return {
    values:
      !hasOtherValue && otherSelected ? parsedValues.filter(val => val !== 'Other') : parsedValues,
    otherValues
  };
};

const processField = (
  caseId: string,
  origValues: IDynamicFieldValues
): ((field: IDynamicField) => IServerProcessedField) => {
  return (field: IDynamicField) => {
    const { values, otherValues } = getValuesFromField(field, origValues);
    const processedValues = parseToTextArray(values);
    const processedOtherValues = parseToTextArray(otherValues);
    return {
      caseId,
      fieldId: field.id,
      values: processedValues,
      otherValues: processedOtherValues
    };
  };
};

export const submitCaseProcedureDefaults = async (
  caseId: string,
  defaults: IPMMFields[]
): Promise<void> => {
  const pmmValues = defaults.map(defaultValue => {
    return {
      caseId,
      fieldId: defaultValue.id,
      values: parseToTextArray(defaultValue.values),
      otherValues: parseToTextArray(defaultValue.otherValues)
    };
  });
  await apiService.submitBriefForm(caseId, pmmValues);
};

const processFieldForDefault = (
  field: IDynamicField,
  origValues: IDynamicFieldValues
): IPMMFields => {
  const { values, otherValues } = getValuesFromField(field, origValues);
  return {
    id: field.id,
    values,
    otherValues
  };
};

const parseToTextArray = (array: string[]): string =>
  JSON.stringify(array).replace('[', '{').replace(']', '}').replace(/\\n/g, '\n');

export const reverseParseToTextArray = (str: string): string[] => {
  const replacedStr = str.replace('\n', '\\n').replace('{', '[').replace('}', ']');
  return JSON.parse(replacedStr);
};

const getValueFromModel = (modelValue: string[], type: FIELD_TYPE): string[] => {
  return type === FIELD_TYPE.SELECT
    ? getSelectValueFromModel(modelValue)
    : getTextValueFromModel(modelValue);
};

const getSelectValueFromModel = (modelValue: string[]): string[] => {
  return modelValue;
};

const getTextValueFromModel = (modelValue: string[]): string[] => {
  if (modelValue.length > 0) {
    return [modelValue[0]];
  }
  return [];
};

export const submitDefault = async (
  userId: string,
  procedureId: string,
  fields: IDynamicField[],
  values: IDynamicFieldValues
): Promise<void> => {
  const parsedValues = processDataForDefault(fields, values);

  await apiService.submitCareTeamDefaults(userId, procedureId, parsedValues);
};

export const submitRawDefault = async (
  userId: string,
  procedureId: string,
  values: Record<string, IPMMFields>
): Promise<void> => {
  await apiService.submitCareTeamDefaults(userId, procedureId, values);
};
