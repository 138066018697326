import { useEffect, useState } from 'react';
import type { ICase } from 'app/mobxStore/types';
import { useRootStore } from 'app/mobxStore';
import { log } from 'debug';
import { toast } from 'react-toastify';

interface IProps {
  case: ICase;
}

const useFollow = (
  props: IProps
): {
  toggleFollow: () => Promise<void>;
  submitting: boolean;
  isFollowed: boolean;
} => {
  const { case: kase } = props;
  const { userStore } = useRootStore();
  const userId = userStore.loggedInUser.data.id;
  const isCaseFollowed = kase.data.caseFollowers.some(cf => cf.userId === userId);
  const [isFollowed, setIsFollowed] = useState(isCaseFollowed);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (isCaseFollowed !== isFollowed) {
      setIsFollowed(isCaseFollowed);
    }
  }, [isCaseFollowed]);
  const handleFollow = async (follow: boolean): Promise<void> => {
    if (follow) {
      await kase.upsertFollowerToServer(userId);
      kase.upsertFollowerWithLastSeenToStore(userId, null);
    } else {
      await kase.removeFollowerFromServer(userId);
      kase.removeFollowerFromStore(userId);
    }
  };

  const toggleFollow = async (): Promise<void> => {
    setSubmitting(true);
    try {
      const newFollowState = !isFollowed;
      await handleFollow(newFollowState);

      setIsFollowed(newFollowState);
    } catch (err) {
      log(err);
      toast.error(`Sorry, that didn't work... 🤷. Please try again.`);
    }
    setSubmitting(false);
  };

  return {
    toggleFollow,
    submitting,
    isFollowed
  };
};

export default useFollow;
