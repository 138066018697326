import TextField from '@mui/material/TextField';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import type { Control } from 'react-hook-form/dist/types/form';
import { useController } from 'react-hook-form';
import { FEEDBACK_FORM_FIELDS_ENUM, type IFeedbackFormData } from '../fields/types';

interface IProps {
  control: Control<IFeedbackFormData>;
}

const TextFeedback = (props: IProps): React.JSX.Element => {
  const { t } = useTranslation();
  const { field } = useController({
    name: FEEDBACK_FORM_FIELDS_ENUM.TEXT,
    control: props.control
  });

  return (
    <div className="text-field-container">
      <div className="text-field-label">{t('feedbackInputLabel')}</div>
      <TextField
        placeholder={t('feedbackInputPlaceHolder').toString()}
        className="text-field"
        type="text"
        multiline
        {...field}
      />
    </div>
  );
};

export default observer(TextFeedback);
