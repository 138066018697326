import React, { useEffect, useState } from 'react';
import { useRootStore } from '../../mobxStore';
import clsx from 'clsx';
import Comment from './Comment';
import HuddleMessage from 'app/components/emptyHuddle/HuddleMessage';
import { observer } from 'mobx-react-lite';
import CommentForm from './CommentForm';
import FormHeader from '../fillMetaForm/FormHeader';
import { useFillMetaForm } from 'app/components/fillMetaForm/useFillMetaForm';
import type { IFullCase, IUserDataWithRoles } from '../../mobxStore/types';
import { getWillNotifyChatMessage } from 'app/utils/willNotifyHelper';
import './Comments.scss';
import { getLastReadCommentPerUserMap } from './unreadCommentsHelper';

const Comments = (): React.JSX.Element => {
  const { caseStore } = useRootStore();
  const {
    control: metaFormControl,
    formState: metaFormState,
    allValues: metaFormAllValues
  } = useFillMetaForm();
  const openedCase = caseStore.openedCase as IFullCase;

  if (openedCase === null) {
    return <></>;
  }

  const messagesEndRef = React.useRef<any>(null);
  const [lastReadComment, setLastReadComment] = useState<string>(
    openedCase.commentsStore.lastReadCommentId
  );
  const [lastReadCommentPerUserMap, setLastReadCommentPerUserMap] = useState<
    Map<string, IUserDataWithRoles[]>
  >(new Map());
  const { comments } = openedCase.referencedData;

  const handleClose = async (): Promise<void> => {
    caseStore.setIsHuddleDialogOpen(false);
  };

  const onSendMessage = (): void => {
    setLastReadComment(openedCase.commentsStore.lastReadCommentId);
  };

  useEffect(() => {
    if (comments.length === 0) return;
    messagesEndRef.current?.scrollIntoView({ behavior: 'instant', block: 'start' });
    void openedCase.commentsStore.commentsMarkAllAsRead();
  }, [comments.length]);

  useEffect(() => {
    setLastReadCommentPerUserMap(getLastReadCommentPerUserMap(openedCase));
  }, [comments]);

  const willNotify = getWillNotifyChatMessage();

  return (
    <div className={clsx('fill-case-container comments', { hasComments: comments.length > 0 })}>
      <FormHeader
        handleClose={handleClose}
        formState={metaFormState}
        allValues={metaFormAllValues}
        control={metaFormControl}
        isCommentsHeader
      />
      <div
        className={clsx('comments-container', {
          hasNotification: willNotify.notify && !willNotify.isNow
        })}
      >
        {comments.map(comment => (
          <Comment
            key={comment.id}
            comment={comment}
            readByUsers={lastReadCommentPerUserMap.get(comment.id) ?? []}
            isCurrentUserLastRead={lastReadComment === comment.id}
          />
        ))}
        <CommentForm willNotify={willNotify} onSendMessage={onSendMessage} />
      </div>
      {comments.length === 0 && <HuddleMessage />}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default observer(Comments);
