import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import type { ICase } from 'app/mobxStore/types';
import ModalScreen from 'app/components/modalScreen/ModalScreen';
import ClassicDatePicker from 'app/components/datePickers/classicDatePicker/ClassicDatePicker';
import IconButton from '@mui/material/IconButton';
import MomentAdapter from '@date-io/moment';
import CloseIcon from 'app/assets/icons/Close_icon';
import UserAvatar from 'app/components/userAvatar/UserAvatar';
import { parseUserToSelectOption } from 'app/mobxStore/MetaDataStore';
import variables from 'app/utils/variables.module.scss';
import { toZonedTime } from 'date-fns-tz';
import { useRootStore } from '../../../mobxStore';

interface IProps {
  isPostponeDatePickerOpen: boolean;
  setIsPostponeDatePickerOpen: (isPostponeDatePickerOpen: boolean) => void;
  selectPostponeDate: (date: Date) => void;
  caseToApplyAction: ICase;
  defaultDate: Date;
}

const PostponeDatePicker = (props: IProps): React.JSX.Element => {
  const {
    isPostponeDatePickerOpen,
    setIsPostponeDatePickerOpen,
    selectPostponeDate,
    caseToApplyAction: { data: caseData },
    defaultDate
  } = props;
  const { t } = useTranslation();
  const [selectedDay, setSelectedDay] = useState<Date>(defaultDate);
  const { metaDataStore } = useRootStore();

  const navigateToToday = (): void => {
    const dateLib = new MomentAdapter();
    const defaultDate = toZonedTime(new Date(), metaDataStore.tz);
    defaultDate.setHours(24, 0, 0, 0);
    const fixedDate = dateLib.moment(defaultDate).subtract(1, 'days');
    setSelectedDay(fixedDate.toDate());
  };

  const handleSelectDate = (day: Date): void => {
    setSelectedDay(day);
    selectPostponeDate(day);
  };

  const content = (
    <div className="modal-content case-date postpone-picker">
      <div className="modal-header">
        <IconButton
          className="close-button"
          onClick={() => {
            setIsPostponeDatePickerOpen(false);
          }}
        >
          <CloseIcon stroke={variables.grey6} />
        </IconButton>
        <div className="header-title">
          <div>{t('postponeDatePickerTitle')}</div>
          <div className="case-details">
            <div className="case-title">{caseData.displayId}</div>
            {caseData.attending !== null && (
              <div className="attending">
                <UserAvatar user={parseUserToSelectOption(caseData.attending)} size={'small'} />
                <div className="name">{caseData.attending.nickName}</div>
              </div>
            )}
          </div>
        </div>
      </div>
      <ClassicDatePicker
        handleClickDay={(day: Date) => {
          handleSelectDate(day);
          setIsPostponeDatePickerOpen(false);
        }}
        selectedDay={selectedDay}
        hideHasCases
        navigateToToday={() => {
          navigateToToday();
        }}
        disablePast
      />
    </div>
  );

  return (
    <div
      className="postpone-date-picker-container"
      onClick={e => {
        // stop propagation to prevent opening parent case
        e.stopPropagation();
      }}
    >
      <ModalScreen
        isOpen={isPostponeDatePickerOpen}
        classNames={'centered date-modal'}
        transitionType="fade"
        content={content}
        handleClose={() => {
          setIsPostponeDatePickerOpen(false);
        }}
      />
    </div>
  );
};

export default observer(PostponeDatePicker);
