import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'app/components/buttons/Button';
import './MyAccount.scss';
import HelpIcon from '../../assets/icons/Help_icon';
import { toast } from 'react-toastify';
import { getIsDebugMode } from '../../mobxStore/storage';
import ErrorMonitor from '../../services/errorMonitor';

const SendLogsButton = (): React.JSX.Element => {
  const { t } = useTranslation();

  if (!getIsDebugMode()) {
    return <></>;
  }

  const sendLogs = async (): Promise<void> => {
    ErrorMonitor.captureException(new Error('Send logs button clicked'));
    toast.success(t('logsSentToast'));
  };

  return (
    <Button
      classNames="btn action-button"
      buttonContent={
        <div className="button-content">
          <div className="button-icon">
            <HelpIcon height={14} />
          </div>
          {t('sendDebugLogs')}
        </div>
      }
      onClick={sendLogs}
    />
  );
};

export default SendLogsButton;
