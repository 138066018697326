import * as React from 'react';
const SvgComponent = props => (
  <svg
    className="svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="10 10 80 80"
    fill={props.fill || 'none'}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m65.699 90 24.301-80-80 24.301 33.199 18.5 35.402-31.402-31.402 35.402z"
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgComponent;
