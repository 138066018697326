import { CASE_STATE } from '../../../consts';
import apiService from '../../../services/apiService';
import type { ICaseFieldValueData } from '../../../mobxStore/types';
const SEC = 1000;
const MIN = 60 * SEC;
const NOTIFY_CACHE_THROTTLE = 10 * MIN;
const notifyCache = new Map<string, number>();
const isCacheExpired = (caseId: string): boolean => {
  if (!notifyCache.has(caseId)) {
    return false;
  }
  const cacheItem = notifyCache.get(caseId) as number;
  return Date.now() - cacheItem > NOTIFY_CACHE_THROTTLE;
};
const shouldNotifyOnChanges = (caseId: string, caseState: CASE_STATE): boolean => {
  if (caseState !== CASE_STATE.READY) {
    return false;
  }
  if (isCacheExpired(caseId)) {
    notifyCache.delete(caseId);
  }
  return !notifyCache.has(caseId);
};

const useNotifyCaseChanges = (): {
  handleNotifyOnChanges: (
    caseId: string,
    caseState: CASE_STATE,
    oldValues: ICaseFieldValueData[]
  ) => Promise<void>;
} => {
  const handleNotifyOnChanges = async (
    caseId: string,
    caseState: CASE_STATE,
    oldValues: ICaseFieldValueData[]
  ): Promise<void> => {
    if (shouldNotifyOnChanges(caseId, caseState)) {
      notifyCache.set(caseId, Date.now());
      await apiService.updateCaseValues(caseId, {
        old_values: { caseFieldValue: oldValues, ts: Date.now() }
      });
    }
  };
  return { handleNotifyOnChanges };
};

export default useNotifyCaseChanges;
