import React, { type MouseEvent, useState } from 'react';
import { useRootStore } from '../../mobxStore';
import { type IFieldRecommendation, IFieldRecommendationType } from 'app/mobxStore/types';
import { Trans, useTranslation } from 'react-i18next';
import Slide from '@mui/material/Slide';
import Button from 'app/components/buttons/Button';
import LightIcon from 'app/assets/icons/Light_icon';
import variables from 'app/utils/variables.module.scss';
import { USER_CASE_ROLES } from '../../consts';

interface IProps {
  recommendation: IFieldRecommendation | null;
  setRecommendation: (recommendation: IFieldRecommendation | null) => void;
}

const RecommendationsModal = (props: IProps): React.JSX.Element | null => {
  const { recommendation, setRecommendation } = props;
  const { careTeamDefaultsStore, userRoleInCase, caseStore } = useRootStore();
  const { openedCase } = caseStore;
  const { t } = useTranslation();
  const [show, setShow] = useState(true);

  const handleClose = (isAccepted: boolean): void => {
    if (!recommendation) {
      return;
    }
    void careTeamDefaultsStore.handleFieldRecommendation(recommendation, isAccepted);
    setShow(false);
  };

  const handleOnTransitionEnd = (): void => {
    setRecommendation(null);
  };

  const getText = (): string => {
    if (userRoleInCase === USER_CASE_ROLES.ATTENDING) {
      return recommendation?.recommendation === IFieldRecommendationType.ADD
        ? 'recommendationModalTextAddYou'
        : 'recommendationModalTextRemoveYou';
    }

    return recommendation?.recommendation === IFieldRecommendationType.ADD
      ? 'recommendationModalTextAddSurgeonName'
      : 'recommendationModalTextRemoveSurgeonName';
  };

  return (
    <Slide direction="up" in={show} onExited={handleOnTransitionEnd}>
      <div className="recommendations-panel">
        <div className="header">
          <div className="icon-container">
            <LightIcon stroke={variables.notification} />
          </div>
          <div className="text-container">
            <div className="title">{t('recommendationModalTitle')}</div>
            <div className="text">
              <Trans
                i18nKey={getText()}
                values={{
                  recommendation: recommendation?.value,
                  attending: openedCase?.basicCase.data.attending?.nickName
                }}
                components={{ italic: <span className="strong" />, bold: <strong /> }}
              />
            </div>
          </div>
        </div>
        <div className="actions">
          <Button
            classNames="outlined"
            buttonText="declineRecommendation"
            onClick={async (e: MouseEvent) => {
              handleClose(false);
            }}
          />
          <Button
            classNames="primary"
            buttonText="acceptRecommendation"
            onClick={async (e: MouseEvent) => {
              handleClose(true);
            }}
          />
        </div>
      </div>
    </Slide>
  );
};
export default RecommendationsModal;
