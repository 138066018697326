import type { ISearchParams, TabsEnum } from '../../../mobxStore/types';
import { useSearchParams } from 'react-router-dom';
import MomentAdapter from '@date-io/moment';
import { useRootStore } from '../../../mobxStore';

const CASE_ID_PARAM = 'caseId';
const CASE_TAB_PARAM = 'tab';
const CASES_DATE_PARAM = 'date';
const CASES_DATE_PARAM_FORMAT = 'YYYY-MM-DD';

function useOurSearchParams(): {
  ourSearchParams: ISearchParams;
  setOurSearchParams: (params: ISearchParams, reset: boolean) => void;
} {
  const [searchParams, setSearchParams] = useSearchParams();
  const { liteNonSurgeon } = useRootStore();

  const setOurSearchParams = (params: ISearchParams, reset: boolean): void => {
    setSearchParams(currentParams => {
      const newParams = reset ? new URLSearchParams() : new URLSearchParams(searchParams);
      if (params.caseId !== undefined) {
        newParams.set(CASE_ID_PARAM, params.caseId);
      }
      if (params.tab !== undefined) {
        newParams.set(CASE_TAB_PARAM, params.tab);
      }
      if (!liteNonSurgeon && params.date !== undefined) {
        newParams.set(
          CASES_DATE_PARAM,
          new MomentAdapter().moment(params.date).format(CASES_DATE_PARAM_FORMAT)
        );
      }
      return newParams;
    });
  };

  const ourSearchParams: ISearchParams = {};
  if (searchParams.has(CASE_ID_PARAM)) {
    ourSearchParams.caseId = searchParams.get(CASE_ID_PARAM) as string;
  }
  if (searchParams.has(CASE_TAB_PARAM)) {
    ourSearchParams.tab = searchParams.get(CASE_TAB_PARAM) as TabsEnum;
  }
  if (!liteNonSurgeon && searchParams.has(CASES_DATE_PARAM)) {
    ourSearchParams.date = new MomentAdapter()
      .moment(searchParams.get(CASES_DATE_PARAM) as string)
      .toDate();
  }

  return { ourSearchParams, setOurSearchParams };
}

export default useOurSearchParams;
