import * as React from 'react';
const SvgComponent = props => (
  <svg
    className="svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fill={props.fill || 'none'}
    {...props}
  >
    <path
      d="M14.6668 7.16671C14.463 5.70022 13.7827 4.34142 12.7307 3.29962C11.6787 2.25782 10.3133 1.59081 8.84489 1.40133C7.37648 1.21186 5.88651 1.51044 4.6045 2.25108C3.32248 2.99171 2.31954 4.13332 1.75016 5.50004M1.3335 2.16671V5.50004H4.66683M1.3335 8.83336C1.5373 10.2998 2.21761 11.6586 3.26963 12.7005C4.32166 13.7423 5.68703 14.4093 7.15544 14.5987C8.62384 14.7882 10.1138 14.4896 11.3958 13.749C12.6778 13.0084 13.6808 11.8668 14.2502 10.5M14.6668 13.8334V10.5H11.3335M7.16683 8.00003C7.16683 8.22104 7.25463 8.43301 7.41091 8.58929C7.56719 8.74557 7.77915 8.83336 8.00016 8.83336C8.22118 8.83336 8.43314 8.74557 8.58942 8.58929C8.7457 8.43301 8.8335 8.22104 8.8335 8.00003C8.8335 7.77902 8.7457 7.56706 8.58942 7.41077C8.43314 7.25449 8.22118 7.1667 8.00016 7.1667C7.77915 7.1667 7.56719 7.25449 7.41091 7.41077C7.25463 7.56706 7.16683 7.77902 7.16683 8.00003Z"
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgComponent;
