import { log } from 'debug';
import { flow, makeAutoObservable } from 'mobx';
import {
  type IAssetMeta,
  type ICase,
  type ICaseAsset,
  type ICaseBasicData,
  type ICaseFollowersInput,
  type ICaseStore,
  SavingStatus
} from './types';
import apiService from 'app/services/apiService';
import { isToday } from './timeHelper';
import { getImagePath, getThumbPath } from '../components/huddleImages/helper';
import { CASE_STATE, caseStateInBriefToReady, FORM_STAGE, USER_CASE_ROLES } from '../consts';
import { lite } from '../services/apiService/apiService';
import { fromZonedTime, toZonedTime } from 'date-fns-tz';
import { getUserRoleInCase, needsBriefApproval } from '../../utils/form/shared';

import { isDateInPast } from './caseStoreHelper';

class Case implements ICase {
  store: ICaseStore;

  id: string = '';
  isDeleted: boolean = false;
  data: ICaseBasicData;
  assets: ICaseAsset[] = [];
  isResetting: boolean = false;

  constructor(store: ICaseStore, id: string, data: ICaseBasicData) {
    makeAutoObservable(this, {
      store: false
    });
    this.store = store;
    this.id = id;
    this.data = data;
  }

  setIsResetting = (isResetting: boolean): void => {
    this.isResetting = isResetting;
  };

  setState = (state: CASE_STATE): void => {
    this.data.state = state;
    if (state === CASE_STATE.READY) {
      this.setIsReady(true);
      this.store.updateCaseIsReady(this.id, true);
    }
    this.store.updateCaseState(this.id, state);
  };

  isInPast = (): boolean => {
    return isDateInPast(this.data.caseDate, this.store.rootStore.tz);
  };

  setIsReady = (isReady: boolean): void => {
    this.data.isReady = isReady;
  };

  setIntraopText = (intraopText: string): void => {
    this.data.intraopText = intraopText;
    this.store.updateCaseIntraopText(this.id, intraopText);
  };

  setIndexInDayToStore = (indexInDay: number): void => {
    this.data.indexInDay = indexInDay;
    void this.store.updateCaseIndexInDay(this.id, indexInDay);
  };

  handleDateChange = flow(function* (this: ICase, date: Date) {
    if (!lite) {
      return;
    }
    const tz = this.store.rootStore.tz;
    const caseDateTz = toZonedTime(new Date(date), tz);
    const todayTz = toZonedTime(new Date(), tz);
    const diffTime = caseDateTz.getTime() - todayTz.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    yield apiService.changeCaseStatus(this.id, diffDays);
  });

  updateCaseMetaDataToServer = flow(function* (this: ICase, values: Record<string, any>) {
    // this.updateCaseEditLock(true); // @todo why is this needed? metadata can be updated in view mode
    if (values.caseDate) {
      const fixedTimezoneDate = fromZonedTime(
        values.caseDate,
        this.store.rootStore.metaDataStore.tz
      );
      void this.handleDateChange(fixedTimezoneDate);

      const parsedDate = lite ? new Date() : fixedTimezoneDate;
      const overrideDate = lite ? fixedTimezoneDate : undefined;
      values.caseDate = parsedDate;
      this.store.setSavingStatus(SavingStatus.SAVING);
      this.store.setIsSaving(true);
      yield apiService.updateCaseValues(this.id, values, overrideDate);
      this.store.setIsSaving(false);
    } else {
      this.store.setSavingStatus(SavingStatus.SAVING);
      this.store.setIsSaving(true);
      yield apiService.updateCaseValues(this.id, values);
      this.store.setIsSaving(false);
    }
  });

  setFollowersToServer = flow(function* (this: ICase, followers: ICaseFollowersInput[]) {
    const followersIds = followers.map(follower => follower.userId);
    yield apiService.setCaseFollowers(this.id, followersIds, followers);
  });

  upsertFollowerToServer = flow(function* (this: ICase, userId: string) {
    yield apiService.followCase(this.id, userId);
  });

  removeFollowerFromServer = flow(function* (this: ICase, userId: string) {
    yield apiService.unfollowCase(this.id, userId);
  });

  updateFollowersInStore(userIds: string[]): void {
    const prevFollowers = this.data.caseFollowers;
    this.data.caseFollowers = [];
    userIds.forEach(userId => {
      const lastSeen = prevFollowers.find(f => f.userId === userId)?.lastSeen ?? null;
      this.upsertFollowerWithLastSeenToStore(userId, lastSeen);
    });
  }

  upsertFollowerWithLastSeenToStore(userId: string, date: Date | null): void {
    const user = this.store.rootStore.findUserById(userId);
    if (user === undefined) {
      throw new Error(`User not found: ${userId}`);
    }
    // Remove if exists, and add with new lastSeen
    this.data.caseFollowers = this.data.caseFollowers.filter(
      follower => follower.userId !== userId
    );
    this.data.caseFollowers.push({ userId, user, lastSeen: date });
  }

  updateLastSeenToServer = flow(function* (this: ICase) {
    const now = new Date();
    const currentUserId = this.store.rootStore.userStore.loggedInUser.data.id;
    yield apiService.updateLastSeen(this.id, currentUserId, now);
  });

  removeFollowerFromStore(userId: string): void {
    this.data.caseFollowers = this.data.caseFollowers.filter(
      follower => follower.userId !== userId
    );
  }

  setStandardProcedureToServer = flow(function* (this: ICase, standardProcedure: boolean) {
    if (standardProcedure) {
      yield this.updateCaseMetaDataToServer({
        standardProcedure: true,
        state: CASE_STATE.READY,
        isReady: true
      });
    } else {
      yield this.updateCaseMetaDataToServer({
        standardProcedure: false,
        state: CASE_STATE.BRIEF_IN_REVIEW,
        isReady: false
      });
      let deleted;
      deleted = yield apiService.deleteCaseStateLogs(
        this.id,
        this.store.rootStore.userStore.loggedInUser.data.id,
        CASE_STATE.BRIEF_IN_REVIEW,
        CASE_STATE.READY
      );
      console.debug('deleted BRIEF_IN_REVIEW -> READY', deleted);
      deleted = yield apiService.deleteCaseStateLogs(
        this.id,
        this.store.rootStore.userStore.loggedInUser.data.id,
        CASE_STATE.READY,
        CASE_STATE.BRIEF_IN_REVIEW
      );
      console.debug('deleted READY -> BRIEF_IN_REVIEW', deleted);
    }
  });

  setStandardProcedureToStore(standardProcedure: boolean): void {
    this.data.standardProcedure = standardProcedure;
    const state = standardProcedure ? CASE_STATE.READY : CASE_STATE.BRIEF_IN_REVIEW;
    this.setState(state);
  }

  deleteCase = flow(function* (this: ICase) {
    this.isDeleted = true;
    yield apiService.updateCaseValues(this.id, { isDeleted: true });
  });

  cancelCase = flow(function* (this: ICase) {
    yield apiService.updateCaseValues(this.id, { isCanceled: true });
  });

  loadAssets = flow(function* (this: ICase) {
    try {
      const assets = yield apiService.getCaseAssets(this.id);
      this.setAssets(assets);
    } catch (error) {
      console.log(error);
    }
  });

  deleteAsset = flow(function* (this: ICase, assetId: string) {
    try {
      yield apiService.deleteCaseAsset(assetId);
      this.setAssets(this.assets.filter(asset => asset.id !== assetId));
    } catch (error) {
      console.log(error);
    }
  });

  updateAssetMeta = flow(function* (this: ICase, id: string, uuid: string, meta: IAssetMeta) {
    yield apiService.updateCaseAsset(id, { meta });
    const assets = yield apiService.getCaseAssets(this.id);
    return assets.find((a: ICaseAsset) => a.externalId === uuid);
  });

  updateAssetDescription = flow(function* (
    this: ICase,
    id: string,
    uuid: string,
    description: string
  ) {
    yield apiService.updateCaseAsset(id, { description });
    const assets = yield apiService.getCaseAssets(this.id);
    this.setAssets(assets);
    return assets.find((a: ICaseAsset) => a.externalId === uuid);
  });

  setAssets(assets: ICaseAsset[]): void {
    this.assets = assets;
  }

  get isToday(): boolean {
    return isToday(new Date(this.data.caseDate), this.store.rootStore.tz);
  }

  get fullImages(): string[] {
    return this.assets.map(asset =>
      getImagePath(asset.externalId, asset.token, asset.meta?.cdnUrlModifiers ?? '')
    );
  }

  get thumbImages(): string[] {
    return this.assets.map(asset =>
      getThumbPath(asset.externalId, asset.token, asset.meta?.cdnUrlModifiers ?? '')
    );
  }

  setTitle(title: string): void {
    this.data.displayId = title;
  }

  setData(data: ICaseBasicData): void {
    this.data = data;
  }

  setDate(date: Date): void {
    const data = this.data;
    data.caseDate = date;
    this.setData(data);
  }

  addAsset = flow(function* (this: ICase, uuid: string) {
    try {
      const stage = caseStateInBriefToReady(this.data.state)
        ? FORM_STAGE.BRIEF
        : FORM_STAGE.DEBRIEF;
      yield apiService.createCaseAsset(this.id, uuid, stage);
      const assets = yield apiService.getCaseAssets(this.id);
      return assets.find((a: ICaseAsset) => a.externalId === uuid);
    } catch (error) {
      log(error);
    }
  });

  needsAttention(lastSeen: Date | null, isCatchUp: boolean): boolean {
    if (this.isInPast()) {
      return false;
    }

    if (this.store.recentlyCreatedCaseId === this.id) {
      return false;
    }
    const loggedInUser = this.store.rootStore.userStore.loggedInUser;
    const userRoleInCase = getUserRoleInCase(this.data, loggedInUser.data);
    if (!isCatchUp && needsBriefApproval(this.data.isReady, this.isInPast(), userRoleInCase)) {
      return true;
    }

    if (this.data.state !== CASE_STATE.READY) {
      return false;
    }

    if (this.data.standardProcedure) {
      return false;
    }

    const currentUserFollower = this.data.caseFollowers.find(
      f => f.userId === loggedInUser.data.id
    );

    // if a user is not following and is not the surgeon or contributor, they should not see the case
    // in case of catch up, contributors that are not following should also not see the case
    if (!currentUserFollower && (userRoleInCase === USER_CASE_ROLES.NONE || isCatchUp)) {
      return false;
    }

    if (!lastSeen) {
      return true;
    }

    const lastValueUpdate = this.data.lastValueUpdate;
    if (!lastValueUpdate || this.data.lastValueUpdatedById === loggedInUser.data.id) {
      return false;
    }
    const lastSeenTime = new Date(lastSeen).getTime();
    const fieldValueChangedAfterSeen = lastValueUpdate.getTime() > lastSeenTime;
    if (fieldValueChangedAfterSeen) {
      return true;
    }

    return false;
  }

  getCurrentUserLastSeen(): Date | null {
    const loggedInUser = this.store.rootStore.userStore.loggedInUser;
    return this.getUserLastSeen(loggedInUser.data.id);
  }

  getUserLastSeen(userId: string): Date | null {
    const follower = this.data.caseFollowers.find(follower => follower.userId === userId);
    return follower?.lastSeen ?? null;
  }

  setRoomToStore(room: string): void {
    this.data.room = room;
  }

  setPatientInitialsToStore(pi: string): void {
    this.data.patientInitials = pi;
  }

  setMrnToStore(mrn: string): void {
    this.data.mrn = mrn;
  }

  async setRoomToServer(room: string): Promise<void> {
    await this.updateCaseMetaDataToServer({ room });
    this.setRoomToStore(room);
  }

  async setPatientInitialsToServer(patientInitials: string): Promise<void> {
    await this.updateCaseMetaDataToServer({ patientInitials });
    this.setPatientInitialsToStore(patientInitials);
  }

  async setMrnToServer(mrn: string): Promise<void> {
    await this.updateCaseMetaDataToServer({ mrn });
    this.setMrnToStore(mrn);
  }

  updateCaseEditLock = flow(function* (this: ICase, isLocked: boolean) {
    if (this.isDeleted) {
      return;
    }
    if (isLocked) {
      const now = new Date();
      yield apiService.updateCaseValues(this.id, {
        editedBy: this.store.rootStore.userStore.loggedInUser.data.id,
        editedAt: now
      });
      this.data.editedBy = this.store.rootStore.userStore.loggedInUser.data.id;
      this.data.editedAt = now.toISOString();
      return;
    }

    yield apiService.updateCaseValues(this.id, {
      editedBy: null,
      editedAt: null
    });
    this.data.editedBy = null;
    this.data.editedAt = null;
  });
}

export default Case;
