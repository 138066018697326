import React, { type MouseEvent, type SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { ISelectOption } from 'app/mobxStore/types';
import clsx from 'clsx';
import Autocomplete from '@mui/material/Autocomplete';
import Button from 'app/components/buttons/Button';
import ArrowLeftIcon from 'app/assets/icons/ArrowLeft_icon';
import CloseIcon from 'app/assets/icons/Close_icon';
import variables from 'app/utils/variables.module.scss';
import './TimezoneSelect.scss';

interface TimezoneSelectProps {
  handleChange: (selectedValue: ISelectOption) => void;
  handleClose: (e: React.SyntheticEvent) => Promise<void>;
  inputValue: string;
  setInputValue: (value: string) => void;
  timezones: ISelectOption[];
}

const TimezoneModalContent = (props: TimezoneSelectProps): React.JSX.Element | null => {
  const { handleChange, handleClose, inputValue, setInputValue, timezones } = props;
  const { t } = useTranslation();
  const [isInputFocused, setIsInputFocused] = useState(false);

  return (
    <div className="modal-content">
      <div className="autocomplete-container">
        <Autocomplete
          freeSolo
          open
          disablePortal
          autoHighlight={true}
          onChange={(e, selectedValue) => {
            handleChange(selectedValue as ISelectOption);
            void handleClose(e);
          }}
          options={timezones}
          inputValue={inputValue}
          onInputChange={(event, value) => {
            setInputValue(value);
          }}
          ListboxProps={{
            // set list height to screen height - input container plus 16px list padding
            style: {
              maxHeight: 'calc(100vh - 124px)',
              overflow: 'auto'
            }
          }}
          renderInput={params => {
            return (
              <div ref={params.InputProps.ref} className="input-container">
                <Button
                  classNames="btn circle-button back-button"
                  startIcon={<ArrowLeftIcon stroke={variables.grey6} height={10} />}
                  onClick={async (e: SyntheticEvent<Element, Event>): Promise<void> => {
                    void handleClose(e);
                  }}
                />
                <input
                  type="text"
                  onClick={() => {
                    setIsInputFocused(true);
                  }}
                  {...params.inputProps}
                  className={clsx('text-input', { focused: isInputFocused })}
                  placeholder={t('searchProcedure').toString()}
                />
                {inputValue !== '' && (
                  <Button
                    classNames="btn circle-button clear-button"
                    startIcon={<CloseIcon height={10} stroke={variables.grey6} />}
                    onClick={async (e: MouseEvent) => {
                      setInputValue('');
                    }}
                  />
                )}
              </div>
            );
          }}
          renderOption={(props, option: ISelectOption) => {
            return (
              <li {...props} className="list-option">
                {option.label}
              </li>
            );
          }}
        />
      </div>
    </div>
  );
};

export default TimezoneModalContent;
