import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImagesGallery from './ImageGallery';
import './HuddleImages.scss';
import type { IFullCase } from 'app/mobxStore/types';
import { useRootStore } from 'app/mobxStore';
import { observer } from 'mobx-react-lite';
import { log } from 'debug';
import UploadImageButton from './UploadImageButton';
import ImageDeletePanel from './ImageDeletePanel';

const HuddleImages = (): React.JSX.Element => {
  const { t } = useTranslation();
  const { canUpdate, caseStore, curEditMode } = useRootStore();

  const [isShowDeletePanel, setIsShowDeletePanel] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState<number>(-1);

  const openedCase = caseStore.openedCase as IFullCase;
  const initialImages: string[] = [];
  const [images, setImages] = useState(initialImages);
  const [thumbs, setThumbs] = useState(initialImages);

  useEffect(() => {
    const fetchAssets = async (): Promise<void> => {
      await openedCase.basicCase.loadAssets();
    };
    void fetchAssets();
  }, [openedCase]);

  useEffect(() => {
    setImages(openedCase.basicCase.fullImages);
    setThumbs(openedCase.basicCase.thumbImages);
  }, [openedCase.basicCase.fullImages]);

  const handleDeleteImage = (index: number): void => {
    setIsShowDeletePanel(true);
    setDeleteIndex(index);
  };

  const handleDeleteConfirm = (): void => {
    deleteImage(deleteIndex);
    setIsShowDeletePanel(false);
    setDeleteIndex(-1);
  };

  const deleteImage = (index: number): void => {
    try {
      if (openedCase.basicCase.assets[index] === undefined) {
        return;
      }
      void openedCase.basicCase.deleteAsset(openedCase.basicCase.assets[index].id);
    } catch (error) {
      log(error);
    }
  };

  const show = images.length > 0 || (canUpdate && curEditMode);

  return show ? (
    <div>
      <div className="huddle-images">
        <div className="form-group-label">{t('images')}</div>
        <div className="gallery-container">
          {canUpdate && <UploadImageButton />}
          <ImagesGallery images={images} thumbs={thumbs} handleDelete={handleDeleteImage} />
        </div>
      </div>
      <ImageDeletePanel
        isShowPanel={isShowDeletePanel}
        setIsShowPanel={setIsShowDeletePanel}
        handleDeleteConfirm={handleDeleteConfirm}
      />
    </div>
  ) : (
    <></>
  );
};

export default observer(HuddleImages);
