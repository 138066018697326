import MomentAdapter from '@date-io/moment';

const moment = new MomentAdapter();

interface SilentPeriod {
  silent: boolean;
  nextActive?: moment.Moment;
}

type WeekDays = 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday';

const weekDays: WeekDays[] = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
];

const silentHours: Record<WeekDays, string[][]> = {
  Monday: [
    ['00:00', '06:30'],
    ['22:00', '24:00']
  ],
  Tuesday: [
    ['00:00', '06:30'],
    ['22:00', '24:00']
  ],
  Wednesday: [
    ['00:00', '06:30'],
    ['22:00', '24:00']
  ],
  Thursday: [
    ['00:00', '06:30'],
    ['22:00', '24:00']
  ],
  Friday: [
    ['00:00', '06:30'],
    ['22:00', '24:00']
  ],
  Saturday: [['00:00', '24:00']],
  Sunday: [
    ['00:00', '16:00'],
    ['22:00', '24:00']
  ]
};

const getNextDay = (day: WeekDays): WeekDays => {
  const nextDayIndex = (weekDays.indexOf(day) + 1) % weekDays.length;
  return weekDays[nextDayIndex];
};

const getNextActiveTime = (
  currentDay: WeekDays,
  endTime: string,
  currentTime: moment.Moment
): moment.Moment | undefined => {
  let daysAdded = 0;
  let nextDay = currentDay;

  if (endTime === '24:00') {
    nextDay = getNextDay(currentDay);
    daysAdded++;
  }

  for (let i = 0; i < weekDays.length; i++) {
    const nextDaySilentHours = silentHours[nextDay];
    if (nextDaySilentHours[0][0] === '00:00' && nextDaySilentHours[0][1] !== '24:00') {
      const nextActiveStart = nextDaySilentHours[0][1];
      const [hours, minutes] = nextActiveStart.split(':').map(Number);
      return moment
        .moment(currentTime)
        .add(daysAdded, 'days')
        .startOf('day')
        .add({ hours, minutes });
    }

    nextDay = getNextDay(nextDay);
    daysAdded++;
  }

  return undefined;
};

export const isSilent = (time: moment.Moment = moment.moment()): SilentPeriod => {
  const day = time.format('dddd') as WeekDays;
  const currentTime = time.format('HH:mm');

  for (const period of silentHours[day]) {
    const [start, end] = period;

    if (currentTime >= start && currentTime < end) {
      return { silent: true, nextActive: getNextActiveTime(day, end, time) };
    }
  }

  return { silent: false };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const testIsSilent = (): void => {
  const testTimes: Array<{ date: moment.Moment; expected: SilentPeriod; description: string }> = [
    {
      date: moment.moment('2024-04-02T03:30:00'),
      expected: { silent: true, nextActive: moment.moment('2024-04-02T06:40:00') },
      description: 'Early Tuesday morning, expecting silent with next active at Tuesday 06:40'
    },
    {
      date: moment.moment('2024-04-02T07:00:00'),
      expected: { silent: false },
      description: 'Tuesday morning after silent hours, expecting not silent'
    },
    {
      date: moment.moment('2024-04-07T23:00:00'),
      expected: { silent: true, nextActive: moment.moment('2024-04-08T06:40:00') },
      description:
        'Sunday evening, after the silent period starts, expecting silent with next active at Monday 06:40'
    },
    {
      date: moment.moment('2024-04-05T23:00:00'),
      expected: { silent: true, nextActive: moment.moment('2024-04-07T16:30:00') },
      description: 'Friday late night, expecting silent with next active at Sunday 16:30'
    },
    {
      date: moment.moment('2024-04-06T10:00:00'),
      expected: { silent: true, nextActive: moment.moment('2024-04-07T16:30:00') },
      description:
        'Saturday morning, during a full day of silence, expecting silent with next active at Sunday 16:30'
    }
  ];

  testTimes.forEach(test => {
    console.log(`Running test: ${test.description}`);
    const result = isSilent(test.date);
    const nextActiveMatch = result.nextActive
      ? result.nextActive.isSame(test.expected.nextActive)
      : test.expected.nextActive === result.nextActive;
    const passed = result.silent === test.expected.silent && nextActiveMatch;

    console.log(
      `Test for ${test.date.format()}: Silent: ${result.silent.toString()} - Next Active: ${
        result.nextActive ? result.nextActive.format() : 'undefined'
      } | Expected: Silent: ${test.expected.silent.toString()} - Next Active: ${
        test.expected.nextActive ? test.expected.nextActive.format() : 'undefined'
      }`
    );

    if (!passed) {
      console.error(`Test failed: ${test.description}`);
    } else {
      console.log(`Test passed: ${test.description}`);
    }
  });
};

// testIsSilent();
