import type { ICommentData, IFullCase, IUserDataWithRoles } from '../../mobxStore/types';

const getLastReadCommentId = (comments: ICommentData[], user: IUserDataWithRoles): string => {
  let lastReadCommentId = '';
  for (const comment of comments) {
    const userMetaData = comment.metaData.find(meta => meta.createdById === user.id);
    if (userMetaData?.readAt) {
      lastReadCommentId = comment.id;
      continue;
    }

    return lastReadCommentId;
  }
  return lastReadCommentId;
};

export const getLastReadCommentPerUserMap = (
  openedCase: IFullCase
): Map<string, IUserDataWithRoles[]> => {
  const map = new Map<string, IUserDataWithRoles[]>();
  const usersMap = new Map<string, IUserDataWithRoles>();
  openedCase.referencedData.comments.forEach(comment => {
    comment.metaData.forEach(meta => {
      usersMap.set(meta.user.id, meta.user);
    });
  });
  for (const u of usersMap.values()) {
    const commentId = getLastReadCommentId(openedCase.referencedData.comments, u);
    if (commentId === '') {
      continue;
    }
    const users = map.get(commentId) ?? [];
    users.push(u);
    map.set(commentId, users);
  }

  return map;
};
