import site from './site';
import user from './user';
import caseApi from './case';
import comment from './comment';
import caseAsset from './caseAsset';
import planFeedback from './planFeedback';

const api = {
  site,
  user,
  case: caseApi,
  comment,
  caseAsset,
  planFeedback
};

export default api;
