import React from 'react';
import type { IDynamicField, IUserDataWithRoles } from 'app/mobxStore/types';
import { useRootStore } from '../../mobxStore';
import { observer } from 'mobx-react-lite';
import { differenceInMinutes, format } from 'date-fns';
import { isToday, isYesterday } from 'app/mobxStore/timeHelper';
import { useTranslation } from 'react-i18next';
import UserAvatar from 'app/components/userAvatar/UserAvatar';
import { parseUserToSelectOption } from 'app/mobxStore/MetaDataStore';
import { t } from 'i18next';

interface IProps {
  field: IDynamicField;
  submittedTime: number;
}

const getUpdatedAt = (tz: string, field: IDynamicField, submittedTime: number): string => {
  const updatedAt = submittedTime ? new Date(submittedTime) : new Date(field.updatedAt as string);
  const isDateToday = isToday(updatedAt, tz);
  const isDateYesterday = isYesterday(updatedAt, tz);
  const date = isDateToday
    ? t('today')
    : isDateYesterday
    ? t('yesterday')
    : format(updatedAt, 'M/d/yyyy');
  const diffInMinutes = differenceInMinutes(new Date(), updatedAt);
  let dateAndTime: string = `${date} ${format(updatedAt, 'h:mm a')}`;
  if (diffInMinutes < 4) {
    dateAndTime = t('justNow');
  } else if (diffInMinutes < 30) {
    dateAndTime = t('minutesAgo', { minutes: diffInMinutes });
  }
  return dateAndTime;
};

const FieldLastUpdatedRow = (props: IProps): React.JSX.Element => {
  const { tz, userStore } = useRootStore();
  const { t } = useTranslation();
  const { field } = props;
  const getUserName = (): string => {
    if (props.submittedTime !== 0) {
      return t('you');
    }
    if (!field.updatedBy) {
      return '';
    }
    return field.updatedBy.id === userStore.loggedInUser.data.id
      ? t('you')
      : field.updatedBy.nickName;
  };
  const getLastUpdatedText = (): React.JSX.Element | null => {
    if ((!field.updatedAt || !field.updatedBy) && props.submittedTime === 0) {
      return null;
    }

    const user = props.submittedTime ? userStore.loggedInUser.data : field.updatedBy;
    const userName = getUserName();
    const dateAndTime = getUpdatedAt(tz, field, props.submittedTime);

    return (
      <>
        <UserAvatar user={parseUserToSelectOption(user as IUserDataWithRoles)} size={'small'} />
        <div className="user-and-time">{`${userName}, ${dateAndTime}`}</div>
      </>
    );
  };
  const lastUpdated = getLastUpdatedText();

  return <div className="last-updated-row">{lastUpdated}</div>;
};

export default observer(FieldLastUpdatedRow);
