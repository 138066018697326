import React from 'react';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../mobxStore';
import { useTranslation } from 'react-i18next';
import { parseUserToSelectOption } from '../../mobxStore/MetaDataStore';
import Button from 'app/components/buttons/Button';
import ArrowLeftIcon from 'app/assets/icons/ArrowLeft_icon';
import UserAvatar from 'app/components/userAvatar/UserAvatar';
import InviteContent from './InviteContent';
import variables from 'app/utils/variables.module.scss';
import './Invite.scss';
import AmplService from '../../services/amplService';

interface IProps {
  handleCloseInviteDialog: () => void;
}

interface Strings {
  title: string;
  subtitle: string;
  disclaimer: string;
}

const Invite = (props: IProps): React.JSX.Element => {
  const { handleCloseInviteDialog } = props;
  const { userStore, caseStore } = useRootStore();
  const { t } = useTranslation();

  const handleClose = async (): Promise<void> => {
    handleCloseInviteDialog();
    AmplService.sendEvent(AmplService.EVENTS.INVITE_BACK);
  };

  const getStrings = (): Strings => {
    const isNewSite = caseStore.isNewSiteInvite;
    const strings = {
      title: isNewSite ? 'newSiteInvite' : 'inviteDialogTitle',
      subtitle: isNewSite ? 'newSiteInviteDialogSubtitle' : 'inviteDialogSubtitle',
      disclaimer: isNewSite ? 'newSiteInviteDisclaimer' : 'inviteDisclaimer'
    };
    return strings;
  };

  const strings = getStrings();
  return (
    <div className="page-container">
      <div className="invite-page">
        <div className="header">
          <div className="row">
            <Button
              classNames="btn circle-button close-button"
              startIcon={<ArrowLeftIcon height={14} stroke={variables.grey6} />}
              onClick={handleClose}
            />
            <div className="title">{t(strings.title)}</div>
          </div>
          <div className="subtitle">{t(strings.subtitle)}</div>
        </div>
        <div className="inner-container">
          <UserAvatar user={parseUserToSelectOption(userStore.loggedInUser.data)} />
          <div className="user-name">{userStore.loggedInUser.data.nickName}</div>
          <InviteContent />
        </div>
        <div className="invite-disclaimer">{t(strings.disclaimer)}</div>
      </div>
    </div>
  );
};

export default observer(Invite);
