import React, { type Dispatch, useState, type MouseEvent } from 'react';
import CloseButton from 'app/components/buttons/CloseButton';
import { observer } from 'mobx-react-lite';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Zoom, Navigation, Thumbs, FreeMode } from 'swiper/modules';
import type { Swiper as SwiperTypes } from 'swiper';
import 'swiper/css';
import 'swiper/css/zoom';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

interface CustomImage {
  src: string;
  index: number;
}

interface IProps {
  setInitialSlide: Dispatch<{ index: number | null }>;
  initialSlide: number;
  imagesArray: CustomImage[];
}

const ImageGallery = (props: IProps): React.JSX.Element => {
  const { setInitialSlide, initialSlide, imagesArray } = props;
  const [swiperInstance, setSwiperInstance] = useState<SwiperTypes | null>(null);

  const handleClose = async (e: MouseEvent): Promise<void> => {
    setInitialSlide({ index: null });
  };

  return (
    <div className="swiper-container">
      <CloseButton handleClose={handleClose} />
      <Swiper
        initialSlide={initialSlide - 1}
        loop={true}
        spaceBetween={10}
        navigation={true}
        zoom={true}
        thumbs={{ swiper: swiperInstance }}
        modules={[FreeMode, Navigation, Thumbs, Zoom]}
        className="slides"
      >
        {imagesArray.map(img => (
          <SwiperSlide key={img.index}>
            <div className="swiper-zoom-container">
              <img src={img.src} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        onSwiper={setSwiperInstance}
        loop={true}
        spaceBetween={14}
        slidesPerView={3}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="tumbnails"
      >
        {imagesArray.map(img => (
          <SwiperSlide key={img.index}>
            <div className="swiper-zoom-container">
              <img src={img.src} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default observer(ImageGallery);
