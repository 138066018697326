import * as React from 'react';
const SvgComponent = props => (
  <svg
    className="svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 14"
    fill={props.fill || 'none'}
    {...props}
  >
    <path
      d="M13.2158 7.7777C13.0953 7.7777 12.9765 7.74958 12.8689 7.69545L0.437262 1.47964C0.250909 1.38869 0.108575 1.22702 0.0419881 1.03065C-0.0244797 0.83418 -0.00970918 0.619291 0.0830618 0.433853C0.175833 0.248308 0.338817 0.107678 0.535884 0.0430335C0.732962 -0.0215125 0.947733 -0.00471964 1.13226 0.0898747L13.5639 6.30569C13.7743 6.4109 13.9258 6.60565 13.9757 6.83551C14.0258 7.06536 13.969 7.30545 13.8214 7.48856C13.6737 7.67157 13.451 7.7779 13.2158 7.7777L13.2158 7.7777Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M0.784821 13.9935C0.549604 13.9937 0.326923 13.8874 0.179323 13.7044C0.031617 13.5213 -0.0251381 13.2812 0.0248397 13.0513C0.0749184 12.8215 0.226367 12.6267 0.436793 12.5215L12.8684 6.30569C13.053 6.2111 13.2676 6.19431 13.4647 6.25885C13.6618 6.3235 13.8249 6.46412 13.9176 6.64967C14.0104 6.83512 14.0252 7.05 13.9586 7.24647C13.8921 7.44284 13.7498 7.6045 13.5634 7.69546L1.1318 13.9113C1.02405 13.9654 0.905301 13.9935 0.784821 13.9935Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M0.784816 13.9936C0.515593 13.9933 0.265821 13.8537 0.124489 13.6246C-0.0169441 13.3957 -0.0297928 13.1098 0.0903951 12.869L3.1983 6.65318C3.32244 6.40481 3.5697 6.24162 3.84679 6.22504C4.12389 6.20835 4.38886 6.34088 4.54182 6.57256C4.69479 6.80423 4.71249 7.09984 4.58836 7.34821L1.48046 13.564C1.41581 13.6931 1.31656 13.8018 1.19365 13.8776C1.07083 13.9535 0.929178 13.9937 0.784816 13.9936Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M3.89265 7.77776C3.74829 7.77786 3.60665 7.7377 3.48383 7.66182C3.36091 7.58594 3.26167 7.47729 3.19703 7.3482L0.0891201 1.13239C-0.0350149 0.884023 -0.0173109 0.588404 0.135658 0.356736C0.288624 0.125059 0.553594 -0.00745929 0.83069 0.00921981C1.10779 0.0258114 1.35505 0.188997 1.47918 0.43736L4.58709 6.65317C4.70727 6.89396 4.69443 7.17986 4.55299 7.40881C4.41166 7.63786 4.16185 7.77745 3.89265 7.77776Z"
      fill={props.fill || '#B33B94'}
    />
  </svg>
);
export default SvgComponent;
