import React, { useEffect } from 'react';
import { useRootStore } from 'app/mobxStore';
import NewCaseForm from 'app/components/newCase/NewCaseForm';
import HuddleFillMeta from 'app/components/huddle/HuddleFillMeta';
import { observer } from 'mobx-react-lite';
import Case from 'app/components/case/Case';
import CaseWithMessage from 'app/components/case/CaseWithMessage';
import { useShowBriefNotReady } from '../case/useShowBriefNotReady';
import ModalScreen from 'app/components/modalScreen/ModalScreen';
import './Huddle.scss';
import { type IFullCase, TabsEnum } from '../../mobxStore/types';
import Comments from '../commments/Comments';
import useOurSearchParams from '../caseViews/hooks/useOurSearchParams';

const Huddle = (): React.JSX.Element | null => {
  const rootStore = useRootStore();
  const { caseStore, userStore } = rootStore;
  const { refreshSubscriptions, selectedTabInHuddle, isNewCase, needFillHuddleMeta } = caseStore;
  const showMetaAndMessage = useShowBriefNotReady();
  const { setOurSearchParams } = useOurSearchParams();
  const openedCase = caseStore.openedCase as IFullCase;
  // const [curHasLock, setCurHasLock] = React.useState(hasLock);
  // useEffect(() => {
  //   if (!openedCase) {
  //     return;
  //   }
  //   const prevHasLock = curHasLock;
  //   setCurHasLock(hasLock);
  //   if (prevHasLock === hasLock) {
  //     return;
  //   }
  //
  //   if (hasLock) {
  //     const nickname = openedCase?.basicCase?.data?.editedByUser?.nickName ?? '';
  //     toast.info(t('caseIsLockedToast', { nickname }));
  //     return;
  //   }
  //   toast.info(t('caseIsFreeToast'));
  // }, [hasLock]);
  useEffect(() => {
    if (selectedTabInHuddle === TabsEnum.CHAT) {
      void caseStore.openCase(openedCase.basicCase.id);
    }
  }, [selectedTabInHuddle]);

  useEffect(() => {
    if (!openedCase) {
      return;
    }
    openedCase.commentsStore.subscribeToComments();
    return (): void => {
      openedCase.commentsStore.unsubscribeFromComments();
    };
  }, [openedCase]);

  useEffect(() => {
    if (!openedCase) {
      return;
    }
    if (refreshSubscriptions) {
      openedCase.commentsStore.unsubscribeFromComments();
      openedCase.commentsStore.subscribeToComments();
    }
  }, [refreshSubscriptions]);

  const getCmp = (
    isNewCase: boolean,
    needFillHuddleMeta: boolean,
    hasOpenedCase: boolean,
    selectedTab: TabsEnum
  ): React.JSX.Element | null => {
    if (isNewCase) {
      return <NewCaseForm />;
    }
    if (selectedTab === TabsEnum.CHAT) {
      return <Comments />;
    }
    if (needFillHuddleMeta) {
      return showMetaAndMessage ? <CaseWithMessage /> : <HuddleFillMeta />;
    }
    if (hasOpenedCase) {
      return showMetaAndMessage || openedCase?.basicCase.data.standardProcedure ? (
        <CaseWithMessage />
      ) : (
        <Case />
      );
    }

    return null;
  };

  const cmp = getCmp(isNewCase, needFillHuddleMeta, openedCase !== null, selectedTabInHuddle);

  const handleClose = (): void => {
    caseStore.setIsHuddleDialogOpen(false);
  };
  const onCloseEnd = (): void => {
    // clear temp comment on closing case
    if (caseStore.tempComment !== '') {
      caseStore.setTempComment('');
    }
    const date = caseStore.openedCase?.basicCase.data.caseDate;
    caseStore.setIsNewCase(false);
    if (openedCase?.basicCase.data.editedBy === userStore.loggedInUser.data.id) {
      openedCase.basicCase.updateCaseEditLock(false);
    }
    caseStore.setOpenedCase(null);
    caseStore.setSelectedTab(TabsEnum.CASE);
    if (date !== undefined) {
      setOurSearchParams({ date }, true);
    }
  };

  const classNames = needFillHuddleMeta || openedCase !== null ? 'full-height' : '';
  return (
    <ModalScreen
      isOpen={caseStore.isHuddleDialogOpen}
      content={cmp}
      onCloseEnd={onCloseEnd}
      handleClose={handleClose}
      classNames={classNames}
    />
  );
};

export default observer(Huddle);
