import React from 'react';
import type { Control } from 'react-hook-form/dist/types/form';
import { useController } from 'react-hook-form';
import type { IFormData } from './types';
import { FORM_FIELDS_ENUM } from './types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import TextInput from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import DocAddIcon from 'app/assets/icons/DocAdd_icon';

interface IProps {
  control: Control<IFormData>;
  isReadOnly?: boolean;
}

const CaseDescription = (props: IProps): React.JSX.Element => {
  const { control, isReadOnly } = props;
  const { t } = useTranslation();
  const { field } = useController({
    name: FORM_FIELDS_ENUM.DESCRIPTION,
    control
  });
  return (
    <div className="text-field-container case-description">
      <TextInput
        className={clsx('text-field description', { 'read-only': isReadOnly })}
        multiline
        disabled={isReadOnly}
        {...field}
        placeholder={t(FORM_FIELDS_ENUM.DESCRIPTION).toString()}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <DocAddIcon height={16} />
            </InputAdornment>
          )
        }}
      />
    </div>
  );
};

export default CaseDescription;
