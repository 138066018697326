import * as React from 'react';
const SvgComponent = props => (
  <svg
    className="svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 11 14"
    fill={props.fill || 'none'}
    {...props}
  >
    <path
      d="M4 13H7M9.99989 5.30258C10.0054 4.53985 9.79588 3.78961 9.39298 3.12975C8.99007 2.46989 8.40851 1.92446 7.70866 1.5501C7.00881 1.17573 6.21619 0.986065 5.41308 1.0008C4.60998 1.01553 3.82566 1.23412 3.14156 1.63387C2.45746 2.03363 1.89852 2.59998 1.52275 3.27412C1.14699 3.94826 0.9681 4.70563 1.00467 5.46758C1.04123 6.22954 1.29191 6.96832 1.73069 7.60722C2.16947 8.24611 2.78034 8.76184 3.49989 9.10086V10.5252C3.49989 10.6511 3.55257 10.7719 3.64634 10.8609C3.74011 10.95 3.86728 11 3.99989 11H6.99989C7.1325 11 7.25968 10.95 7.35345 10.8609C7.44721 10.7719 7.49989 10.6511 7.49989 10.5252V9.10086C8.24737 8.75116 8.87733 8.21043 9.31896 7.53945C9.7606 6.86847 9.99641 6.09382 9.99989 5.30258Z"
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgComponent;
