import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'app/components/buttons/Button';
import FeedbackDialog from './FeedbackDialog';
import PencilIcon from 'app/assets/icons/Pencil_icon';

import './Feedback.scss';
import { useRootStore } from '../../mobxStore';
import type { IFullCase } from '../../mobxStore/types';
import { observer } from 'mobx-react-lite';
import RatingView from './RatingView';
import { lite } from '../../services/apiService/apiService';

const Feedback = (): React.JSX.Element => {
  if (lite) {
    return <></>;
  }

  const { t } = useTranslation();
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);

  const { caseStore } = useRootStore();
  const openedCase = caseStore.openedCase as IFullCase;
  const planFeedback = openedCase.getCurrentUserPlanFeedback();
  const isRated = planFeedback.rating !== 0;

  return (
    <>
      <div
        className="nursing-feedback-container"
        onClick={() => {
          if (!isRated) {
            setIsFeedbackOpen(true);
          }
        }}
      >
        <div className="nursing-feedback">
          <div className="nursing-title">{t('nursingFeedbackTitle')}</div>
          {!isRated && <div className="nursing-subtitle">{t('nursingSubtitleEmpty')}</div>}
          <RatingView value={planFeedback.rating} />
          {isRated && planFeedback.text && (
            <div className="feedback-comment">{planFeedback.text}</div>
          )}
          {isRated && (
            <Button
              classNames="outlined edit-button"
              buttonText="edit"
              endIcon={<PencilIcon height={34} />}
              onClick={async () => {
                setIsFeedbackOpen(true);
              }}
            />
          )}
        </div>
      </div>
      {isFeedbackOpen && (
        <FeedbackDialog
          isFeedbackOpen={isFeedbackOpen}
          setIsFeedbackOpen={setIsFeedbackOpen}
          case={openedCase}
        />
      )}
    </>
  );
};

export default observer(Feedback);
