import React, { type Dispatch, type SetStateAction, type MouseEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { addMonths, format } from 'date-fns';
import type { CaptionProps } from 'react-day-picker';
import IconButton from '@mui/material/IconButton';
import Button from 'app/components/buttons/Button';
import ArrowLeft from 'app/assets/icons/ArrowLeft_icon';
import Arrowright from 'app/assets/icons/ArrowRight_icon';
import 'react-day-picker/dist/style.css';
import { dayNames } from 'app/consts';

const CaptionComponent = (props: {
  captionProps: CaptionProps;
  month: Date;
  setMonth: Dispatch<SetStateAction<Date>>;
  navigateToToday: () => void;
}): React.JSX.Element => {
  const { captionProps, month, setMonth, navigateToToday } = props;

  return (
    <div className="header">
      <div className="header-row">
        <IconButton
          id="prev-month-button"
          className="nav-button"
          onClick={() => {
            setMonth(addMonths(month, -1));
          }}
        >
          <ArrowLeft className="arrow-icon" />
        </IconButton>
        <div className="label">{format(captionProps.displayMonth, 'MMM yyy')}</div>
        <IconButton
          id="next-month-button"
          className="nav-button"
          onClick={() => {
            setMonth(addMonths(month, 1));
          }}
        >
          <Arrowright className="arrow-icon" />
        </IconButton>
        <Button
          buttonText="today"
          classNames="secondary today-button"
          onClick={async (e: MouseEvent) => {
            navigateToToday();
          }}
        />
      </div>
      <div className="day-name-row">
        {dayNames.map(day => (
          <div key={day} className="day-name">
            {day}
          </div>
        ))}
      </div>
    </div>
  );
};

export default observer(CaptionComponent);
