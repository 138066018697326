import { log } from 'debug';
import { flow, makeAutoObservable } from 'mobx';
import type { ICaseCommentsStore, ICommentData, ICommentMetaData, IFullCase } from './types';
import apiService from 'app/services/apiService';
import type { Subscription } from 'zen-observable-ts';
import {
  commentMetaDatasColumnReaction,
  commentMetaDatasColumnReadAt
} from '../services/apiService/apiService';

class CaseCommentsStore implements ICaseCommentsStore {
  kase: IFullCase;
  commentsSubscription: null | Subscription = null;

  constructor(kase: IFullCase) {
    makeAutoObservable(this, {});
    this.kase = kase;
  }

  addComment = flow(function* (this: ICaseCommentsStore, comment: string) {
    try {
      const newComment = yield apiService.createComment(this.kase.basicCase.id, comment);
      this.kase.referencedData.comments.push(newComment);
    } catch (error) {
      log(error);
    }
  });

  subscribeToComments(): void {
    const sub = apiService.subscribeToCaseComments(
      this.kase.basicCase.id,
      (data: ICommentData[]) => {
        this.setComments(data);
      }
    );
    this.setCommentsSubscription(sub);
  }

  unsubscribeFromComments(): void {
    if (this.commentsSubscription === null) {
      return;
    }
    this.commentsSubscription.unsubscribe();
    this.clearCommentsSubscription();
  }

  setCommentsSubscription(subscription: Subscription): void {
    this.commentsSubscription = subscription;
  }

  clearCommentsSubscription(): void {
    this.commentsSubscription = null;
  }

  setComments(data: ICommentData[]): void {
    this.kase.referencedData.comments = data;
  }

  getCommentsUnreadCount = (): number => {
    const currentUserId = this.kase.basicCase.store.rootStore.userStore.loggedInUser.data.id;
    const unreadComments = this.kase.referencedData.comments.filter(comment => {
      if (comment.user.id === currentUserId) {
        return false;
      }
      const curUserMetas = comment.metaData.find(mt => mt.createdById === currentUserId);
      if (!curUserMetas) {
        return true;
      }
      return curUserMetas.readAt === null;
    });
    return unreadComments.length;
  };

  commentsMarkAllAsRead = flow(function* (this: ICaseCommentsStore) {
    const currentUserId = this.kase.basicCase.store.rootStore.userStore.loggedInUser.data.id;
    const metasObjsToUpsert: Array<Record<string, any>> = [];
    for (const comment of this.kase.referencedData.comments) {
      const isExistMetaData =
        comment.metaData.find(mt => mt.createdById === currentUserId) !== undefined;
      const metadata = isExistMetaData
        ? (comment.metaData.find(mt => mt.createdById === currentUserId) as ICommentMetaData)
        : {
            user: this.kase.basicCase.store.rootStore.userStore.loggedInUser.data,
            createdById: currentUserId
          };
      if (metadata?.readAt) {
        continue;
      }
      // tz: not date sensitive ok to use local tz
      metadata.readAt = new Date();
      if (!isExistMetaData) {
        comment.metaData.push(metadata);
      }
      metasObjsToUpsert.push({
        comment_id: comment.id,
        read_at: metadata.readAt
      });
    }

    yield apiService.upsertCommentMetaDatas(metasObjsToUpsert, commentMetaDatasColumnReadAt);
  });

  commentLike = flow(function* (this: ICaseCommentsStore, commentId: string, reaction: string) {
    const comment = this.kase.referencedData.comments.find(c => c.id === commentId) as ICommentData;
    const currentUserId = this.kase.basicCase.store.rootStore.userStore.loggedInUser.data.id;
    const isExistMetaData =
      comment.metaData.find(mt => mt.createdById === currentUserId) !== undefined;
    const metadata = isExistMetaData
      ? (comment.metaData.find(mt => mt.createdById === currentUserId) as ICommentMetaData)
      : {
          user: this.kase.basicCase.store.rootStore.userStore.loggedInUser.data,
          createdById: currentUserId
        };
    metadata.reaction = reaction;
    if (!isExistMetaData) {
      comment.metaData.push(metadata);
    }
    yield apiService.upsertCommentMetaDatas(
      [
        {
          comment_id: comment.id,
          reaction
        }
      ],
      commentMetaDatasColumnReaction
    );
  });

  get lastReadCommentId(): string {
    const currentUserId = this.kase.basicCase.store.rootStore.userStore.loggedInUser.data.id;
    for (let i = this.kase.referencedData.comments.length - 1; i >= 0; i--) {
      const comment = this.kase.referencedData.comments[i];
      if (comment.user.id === currentUserId) {
        if (i === this.kase.referencedData.comments.length - 1) {
          return '';
        }
        return comment.id;
      }
      const myMetaData = comment.metaData.find(meta => meta.createdById === currentUserId);
      if (myMetaData?.readAt) {
        if (i === this.kase.referencedData.comments.length - 1) {
          return '';
        }
        return comment.id;
      }
    }

    return '';
  }
}

export default CaseCommentsStore;
