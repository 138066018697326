import * as React from 'react';
const SvgComponent = props => (
  <svg
    className="svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fill={props.fill || 'none'}
    {...props}
  >
    <path
      d="M6.66699 7.99984C6.66699 8.35346 6.80747 8.6926 7.05752 8.94265C7.30757 9.19269 7.6467 9.33317 8.00033 9.33317C8.35395 9.33317 8.69309 9.19269 8.94313 8.94265C9.19318 8.6926 9.33366 8.35346 9.33366 7.99984C9.33366 7.64622 9.19318 7.30708 8.94313 7.05703C8.69309 6.80698 8.35395 6.6665 8.00033 6.6665C7.6467 6.6665 7.30757 6.80698 7.05752 7.05703C6.80747 7.30708 6.66699 7.64622 6.66699 7.99984Z"
      stroke={props.stroke || '#767676'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 8C12.4 10.6667 10.4 12 8 12C5.6 12 3.6 10.6667 2 8C3.6 5.33333 5.6 4 8 4C10.4 4 12.4 5.33333 14 8Z"
      stroke={props.stroke || '#767676'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgComponent;
