import React from 'react';
import { useRootStore } from 'app/mobxStore';
import Button from 'app/components/buttons/Button';
import variables from 'app/utils/variables.module.scss';
import AmplService from '../../services/amplService/amplService';
import UserAvatar from 'app/components/userAvatar/UserAvatar';
import { parseUserToSelectOption } from 'app/mobxStore/MetaDataStore';
import PencilIcon from 'app/assets/icons/Pencil_icon';

const AccountSettingsButton = (): React.JSX.Element => {
  const { caseStore, userStore } = useRootStore();
  const userData = userStore.loggedInUser.data;

  const handleOpenSettingsDialog = async (): Promise<void> => {
    caseStore.setIsSettingsDialogOpen(true);
    AmplService.sendEvent(AmplService.EVENTS.ACCOUNT_USER_PROFILE);
  };

  return (
    <div className="profile-picture-container">
      <Button
        classNames="btn account-settings-button"
        buttonContent={<UserAvatar user={parseUserToSelectOption(userData)} size={'large'} />}
        onClick={handleOpenSettingsDialog}
      />
      <Button
        classNames="btn circle-button edit-icon-button"
        buttonContent={<PencilIcon className="edit-icon" fill={variables.primaryPurple} />}
        onClick={handleOpenSettingsDialog}
      />
    </div>
  );
};

export default AccountSettingsButton;
