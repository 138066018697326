import React, { Fragment } from 'react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import { observer } from 'mobx-react-lite';
import type { Control } from 'react-hook-form/dist/types/form';
import type { IDynamicField, ISelectOption } from 'app/mobxStore/types';
import { useController } from 'react-hook-form';
interface IProps {
  control: Control<any>;
  field: IDynamicField;
}
const DynamicRadioGroup = (props: IProps): React.JSX.Element => {
  const { control, field: dField } = props;
  const { field } = useController({
    name: dField.id,
    control
  });
  const changeValue = (value: any, setValue: (...event: any[]) => void): void => {
    const newVal = dField.options.find(o => o.value === value);
    setValue(newVal);
  };

  const isSelected = (option: ISelectOption, value: ISelectOption | null): boolean => {
    if (value === null) {
      return false;
    }
    return option.value === value.value;
  };

  return (
    <FormControl component="fieldset">
      {dField.label !== '' && <FormLabel component="legend">{dField.label}</FormLabel>}
      <RadioGroup
        value={field.value}
        onChange={event => {
          changeValue(event.target.value, field.onChange);
        }}
      >
        {dField.options.map((option, i) => (
          <FormControlLabel
            key={`${option.value}${i}`}
            value={option.value}
            control={<Radio color="primary" />}
            checked={isSelected(option, field.value)}
            label={<Fragment>{option.label}</Fragment>}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default observer(DynamicRadioGroup);
