import { getAudPrefixForEnvVar } from '../../utils/audPrefix';

export const getAud = (first: string): string => {
  if (window.location.host.endsWith(':3000')) {
    assertStringSet(process.env.REACT_APP_LOCALHOST_AUD, 'REACT_APP_LOCALHOST_AUD');
    return process.env.REACT_APP_LOCALHOST_AUD as string;
  }
  if (first === 'stage-app' || first === 'r-stage-app') {
    return 'stage';
  }
  if (first.endsWith('chf-tay-dev') || first.endsWith('r-chiefy')) {
    return 'chf-tay-dev';
  }
  if (first.endsWith('zipora-app') || first.endsWith('zipora')) {
    return 'zipora';
  }
  if (first.startsWith('r-')) {
    return first.slice(2);
  }
  return first;
};

export const isStringSet = (val: string | undefined | null): boolean => {
  return val !== undefined && val !== null && val !== '';
};

export const assertStringSet = (val: string | undefined | null, message: string): void => {
  if (!isStringSet(val)) {
    throw new Error('String is not set: ' + message);
  }
};

export const getEnvVarWithAssert = (envVar: string): string => {
  const val = process.env[envVar];
  assertStringSet(val, envVar);
  return val as string;
};

/* Replace '%AUD%' with the runtime aud prefix */
export const getEnvVarPerAud = (envVar: string, assert: boolean): string | undefined => {
  if (!envVar.includes('%AUD%')) {
    throw new Error('envVar does not contain %AUD%: ' + envVar);
  }

  const audPrefixForEnvVar = getAudPrefixForEnvVar();
  const varName = envVar.replace('%AUD%', audPrefixForEnvVar);
  const val = process.env[varName];

  if (!isStringSet(val) && assert) {
    console.log(
      `${varName} is not defined`,
      'audPrefixForEnvVar:',
      audPrefixForEnvVar,
      'envVar:',
      envVar
    );
    throw new Error(`${varName} is not defined`);
  }

  return val;
};
