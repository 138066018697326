import { genOptionFromStr, OTHER_OPTION } from './shared';
import type { IGetFieldValuesCount, IPMMFields, ISelectOption } from 'app/mobxStore/types';
import { clusterUsingAssi } from './simliarStrings';
import { getIsDebugMode } from '../../app/mobxStore/storage';
import ErrorMonitor from '../../app/services/errorMonitor/errorMonitor';

const DEBUG = (): boolean => {
  return getIsDebugMode();
};

interface IOptionEntry {
  value: number;
  own: boolean;
  curProc: boolean;
  default: boolean;
  savedDefaults?: boolean;
}

const mergeSimilarOptions = (map: Map<string, IOptionEntry>): Map<string, IOptionEntry> => {
  const arr = Array.from(map).map(([key, value]) => key);
  const similarArr = clusterUsingAssi(arr, 0);
  const mergedMap = new Map<string, IOptionEntry>();
  similarArr.forEach(cluster => {
    let total = 0;
    const merged = cluster.reduce(
      (acc, str) => {
        const entry = map.get(str);
        const count = entry?.value ?? 0;
        total += count;
        if (count > acc.count) {
          acc.count = count;
          acc.str = str;
          acc.own ||= entry?.own ?? false;
          acc.default ||= entry?.default ?? false;
          acc.curProc ||= entry?.curProc ?? false;
        }
        return acc;
      },
      { count: -1, str: '', own: false, default: false, curProc: false }
    );
    mergedMap.set(merged.str, {
      value: total,
      own: merged.own,
      default: merged.default,
      curProc: merged.curProc
    });
  });

  return mergedMap;
};

const filterOptionsMap = (
  mapAll: Map<string, number>,
  mapAttendingAllProcs: Map<string, number>,
  mapAttendingCurProc: Map<string, number>,
  baseOptions: ISelectOption[],
  otherValue: string[],
  savedDefaults: IPMMFields | undefined,
  maxOptionLength: number,
  nofOptionsToTrim: number
): Array<[string, number]> => {
  if (DEBUG()) {
    console.log('** filterOptionsMap start');
    console.log('mapAll', JSON.stringify(mapAll));
    console.log('mapAttendingAllProcs', JSON.stringify(mapAttendingAllProcs));
    console.log('mapAttendingCurProc', JSON.stringify(mapAttendingCurProc));
    console.log('baseOptions', baseOptions);
    console.log('savedDefaults', savedDefaults);
  }
  const otherValueSplit =
    otherValue.length === 0
      ? []
      : otherValue
          .join(',')
          .split(',')
          .map(str => str.trim());

  // merge two maps to a single map, then add base options with count 0 if not already in the map
  const map = new Map<string, IOptionEntry>();
  let maxCount = 0;

  mapAttendingCurProc.forEach((value, key) => {
    if (key.length < maxOptionLength && !otherValueSplit.includes(key)) {
      map.set(key, { value, own: true, curProc: true, default: false });
      if (value > maxCount) {
        maxCount = value;
      }
    }
  });
  mapAttendingAllProcs.forEach((value, key) => {
    if (!map.has(key) && key.length < maxOptionLength && !otherValueSplit.includes(key)) {
      map.set(key, { value, own: true, curProc: false, default: false });
      if (value > maxCount) {
        maxCount = value;
      }
    }
  });
  mapAll.forEach((value, key) => {
    if (
      !map.has(key) &&
      key.length < maxOptionLength &&
      !otherValueSplit.includes(key) &&
      value >= 2
    ) {
      map.set(key, { value, own: false, curProc: true, default: false });
      if (value > maxCount) {
        maxCount = value;
      }
    }
  });

  // Add base options if:
  // Not already in the map
  // We have not reached the limit
  for (const option of baseOptions) {
    if (map.size >= nofOptionsToTrim) {
      break;
    }
    if (!map.has(option.value) && !otherValueSplit.includes(option.value)) {
      map.set(option.value, { value: 0, own: false, curProc: true, default: true });
    }
  }

  for (const option of savedDefaults?.values ?? []) {
    if (!map.has(option) && !otherValueSplit.includes(option) && option !== 'Other') {
      map.set(option, {
        value: 0,
        own: false,
        curProc: false,
        default: false,
        savedDefaults: true
      });
    }
  }

  const mergedMap = mergeSimilarOptions(map);

  if (mergedMap.size > 30) {
    ErrorMonitor.captureMessage('More than 30 options per field', {
      inAll: JSON.stringify(mapAll),
      inAttending: JSON.stringify(mapAttendingAllProcs),
      result: JSON.stringify(mergedMap)
    });
  }

  const sorted: Array<[string, IOptionEntry]> = Array.from(mergedMap).sort(compareOptions);
  const res: Array<[string, number]> = sorted.map(([key, value]) => [key, value.value]);
  if (DEBUG()) {
    console.log('sorted', JSON.stringify(sorted));
    console.log('res', JSON.stringify(res));
  }
  return res;
};

const compareOptions = (a: [string, IOptionEntry], b: [string, IOptionEntry]): number => {
  // 1. own && curProc
  if (a[1].own && a[1].curProc && (!b[1].own || !b[1].curProc)) {
    return -1;
  }
  if (b[1].own && b[1].curProc && (!a[1].own || !a[1].curProc)) {
    return 1;
  }

  // 2. !own && curProc
  if (!a[1].own && a[1].curProc && (b[1].own || !b[1].curProc)) {
    return -1;
  }
  if (!b[1].own && b[1].curProc && (a[1].own || !a[1].curProc)) {
    return 1;
  }

  // 3. default
  if (a[1].default && !b[1].default) {
    return -1;
  }
  if (b[1].default && !a[1].default) {
    return 1;
  }

  // 4. own && !curProc
  if (a[1].own && !a[1].curProc && (!b[1].own || b[1].curProc)) {
    return -1;
  }
  if (b[1].own && !b[1].curProc && (!a[1].own || a[1].curProc)) {
    return 1;
  }

  // 5. alphabetical
  return a[0].localeCompare(b[0]);
};

export const getOptions = (
  parsedOptions: string[],
  fvc: IGetFieldValuesCount,
  otherValue: string[],
  savedDefaults: IPMMFields | undefined
): ISelectOption[] => {
  const baseOptions = parsedOptions.map(genOptionFromStr);

  const maxOptionLength = 50;
  const nofOptionsToTrim = 25;
  const filteredFvcMerged = filterOptionsMap(
    fvc.all,
    fvc.attendingAllProcs,
    fvc.attendingCurProc,
    baseOptions.filter(option => option.value !== OTHER_OPTION),
    otherValue,
    savedDefaults,
    maxOptionLength,
    nofOptionsToTrim
  );

  const dynamicOptions: ISelectOption[] = filteredFvcMerged.map(option => {
    return {
      label: option[0],
      value: option[0]
    };
  });

  const otherOption = baseOptions.find(option => option.value === OTHER_OPTION);
  if (otherOption !== undefined) {
    dynamicOptions.push(otherOption);
  }
  if (DEBUG()) {
    console.log('dynamicOptions', JSON.stringify(dynamicOptions));
  }
  return dynamicOptions;
};
