import * as React from 'react';
const SvgComponent = props => (
  <svg
    className="svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 18"
    fill={props.fill || 'none'}
    {...props}
  >
    <path
      d="M15 17.5C14.8674 17.5 14.7402 17.4473 14.6464 17.3536C14.5527 17.2598 14.5 17.1326 14.5 17V0.99999C14.5 0.82134 14.5953 0.656307 14.75 0.56699C14.9047 0.477673 15.0953 0.477667 15.25 0.56699C15.4047 0.656313 15.5 0.821357 15.5 0.99999V17C15.5 17.1326 15.4473 17.2598 15.3536 17.3536C15.2598 17.4473 15.1326 17.5 15 17.5Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M15 17.5H0.99999C0.82134 17.5 0.656307 17.4047 0.56699 17.25C0.477673 17.0953 0.477667 16.9047 0.56699 16.75C0.656313 16.5953 0.821357 16.5 0.99999 16.5H15C15.1786 16.5 15.3437 16.5953 15.433 16.75C15.5223 16.9047 15.5223 17.0953 15.433 17.25C15.3437 17.4047 15.1786 17.5 15 17.5Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M1 17.5C0.867387 17.5 0.740237 17.4473 0.64642 17.3536C0.55267 17.2598 0.5 17.1326 0.5 17V0.99999C0.5 0.82134 0.595313 0.656307 0.75 0.56699C0.904687 0.477673 1.09532 0.477667 1.25 0.56699C1.40468 0.656313 1.5 0.821357 1.5 0.99999V17C1.5 17.1326 1.44733 17.2598 1.35358 17.3536C1.25977 17.4473 1.13262 17.5 1 17.5Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M15 1.5H0.99999C0.82134 1.5 0.656307 1.40469 0.56699 1.25C0.477673 1.09531 0.477667 0.904683 0.56699 0.75C0.656313 0.595317 0.821357 0.5 0.99999 0.5H15C15.1786 0.5 15.3437 0.595313 15.433 0.75C15.5223 0.904687 15.5223 1.09532 15.433 1.25C15.3437 1.40468 15.1786 1.5 15 1.5Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M11 5.5H4.99999C4.82134 5.5 4.65631 5.40469 4.56699 5.25C4.47767 5.09531 4.47767 4.90468 4.56699 4.75C4.65631 4.59532 4.82136 4.5 4.99999 4.5H11C11.1786 4.5 11.3437 4.59531 11.433 4.75C11.5223 4.90469 11.5223 5.09532 11.433 5.25C11.3437 5.40468 11.1786 5.5 11 5.5Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M11 9.5H4.99999C4.82134 9.5 4.65631 9.40469 4.56699 9.25C4.47767 9.09531 4.47767 8.90468 4.56699 8.75C4.65631 8.59532 4.82136 8.5 4.99999 8.5H11C11.1786 8.5 11.3437 8.59531 11.433 8.75C11.5223 8.90469 11.5223 9.09532 11.433 9.25C11.3437 9.40468 11.1786 9.5 11 9.5Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M8.99999 13.5H4.99999C4.82134 13.5 4.65631 13.4047 4.56699 13.25C4.47767 13.0953 4.47767 12.9047 4.56699 12.75C4.65631 12.5953 4.82136 12.5 4.99999 12.5H8.99999C9.17864 12.5 9.34367 12.5953 9.43299 12.75C9.52231 12.9047 9.52231 13.0953 9.43299 13.25C9.34367 13.4047 9.17862 13.5 8.99999 13.5Z"
      fill={props.fill || '#B33B94'}
    />
  </svg>
);
export default SvgComponent;
