import React from 'react';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import type { Control } from 'react-hook-form/dist/types/form';
import type { IDynamicField, IDynamicFieldValues, ISelectOption } from 'app/mobxStore/types';
import { useController } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import { log } from 'debug';
import { OTHER_OPTION } from '../../../utils/form/shared';
import OtherChips from './OtherChips';
import { fieldNeedsAttention } from './helper';
import { useRootStore } from '../../mobxStore';
import { useLongPress } from 'use-long-press';
import AmplService from 'app/services/amplService';

interface IProps {
  control: Control<any>;
  field: IDynamicField;
  allValues: IDynamicFieldValues;
}

const Chips = (props: IProps): React.JSX.Element => {
  const { allValues, control, field: dField } = props;
  const { field } = useController({
    name: dField.id,
    control
  });
  const fieldValue = field.value as string[];
  const { caseStore, userStore } = useRootStore();
  const { openedCase } = caseStore;

  if (!openedCase) {
    throw new Error('No case found');
  }

  const currentUserLastSeen = openedCase.basicCase.getUserLastSeen(userStore.loggedInUser.data.id);

  const isOptionSelected = (option: ISelectOption, value: string[]): boolean => {
    if (value === null || value === undefined) {
      log('value is null');
      log(dField);

      return false;
    }
    return value.length > 0 && value.findIndex(o => o === option.value) > -1;
  };

  const toggleValue = (
    option: ISelectOption,
    value: string[],
    setValue: (event: string[]) => void
  ): void => {
    const nextState = isOptionSelected(option, value)
      ? value.filter(el => el !== option.value)
      : [...value, option.value];

    setValue(nextState);
  };

  const bindLongPress = useLongPress(
    () => {
      AmplService.sendEvent(AmplService.EVENTS.CASE_OPTION_LONG_PRESS);
    },
    { threshold: 600 }
  );

  const buttons = dField.options.map(option => {
    if (option.value === OTHER_OPTION) {
      return (
        <OtherChips
          allValues={allValues}
          control={control}
          dField={dField}
          key={option.value}
          field={field}
          option={option}
          isOptionSelected={isOptionSelected}
          toggleValue={toggleValue}
        />
      );
    }
    return (
      <Button
        key={option.value}
        className={clsx('btn chip-button toggle-chip', {
          selected: isOptionSelected(option, fieldValue)
        })}
        onClick={() => {
          toggleValue(option, fieldValue, field.onChange);
        }}
        {...bindLongPress()}
      >
        {option.label}
      </Button>
    );
  });

  return (
    <FormControl className="form-group">
      <div
        className={clsx('form-group-label', {
          needsAttention: fieldNeedsAttention(
            dField,
            userStore.loggedInUser.data.id,
            currentUserLastSeen
          )
        })}
      >
        <div className="label-container">{dField.label}</div>
      </div>
      <>{buttons}</>
    </FormControl>
  );
};

export default observer(Chips);
