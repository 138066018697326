import * as React from 'react';
const SvgComponent = props => (
  <svg
    className="svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 17"
    fill={props.fill || 'none'}
    {...props}
  >
    <path d="M4.40698 5.5448H6.26331" stroke={props.stroke || '#B33B94'} />
    <path d="M5.32446 11.7112H7.18079" stroke={props.stroke || '#B33B94'} />
    <path d="M10.4453 11.7112H12.3016" stroke={props.stroke || '#B33B94'} />
    <path d="M5.33521 4.6167L5.3352 6.47303" stroke={props.stroke || '#B33B94'} />
    <path
      d="M0.97168 3.77383L5.68718 1C5.68718 1 6.92474 1.89616 8.81307 1.89616C10.7014 1.89616 11.939 1 11.939 1L16.5478 3.77383L15.3102 8.36131L13.7099 7.67852L13.9873 16H3.63882L3.87353 7.67852L2.37993 8.36131L0.97168 3.77383Z"
      stroke={props.stroke || '#B33B94'}
    />
    <path d="M5.58057 1L8.75743 5.60882L11.9817 1" stroke={props.stroke || '#B33B94'} />
    <path d="M8.78101 15.9787V5.65149" stroke={props.stroke || '#B33B94'} />
  </svg>
);
export default SvgComponent;
