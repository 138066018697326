import { observer } from 'mobx-react-lite';
import React, { type ReactElement } from 'react';
import type { Control } from 'react-hook-form/dist/types/form';
import type { IDynamicField } from 'app/mobxStore/types';
import { type ControllerRenderProps, useController } from 'react-hook-form';
import clsx from 'clsx';
import FormControl from '@mui/material/FormControl';
import TextInput from '@mui/material/TextField';
import { t } from 'i18next';
import { fieldNeedsAttention } from './helper';
import { useRootStore } from 'app/mobxStore';

interface IProps {
  control: Control<any>;
  field: IDynamicField;
}

const getTextField = (
  field: ControllerRenderProps<any, string>,
  dField: IDynamicField,
  props: IProps,
  currentUserId: string,
  currentUserLastSeen: Date | null
): ReactElement<any, any> => {
  if (dField.editMode) {
    return (
      <>
        {dField.label !== '' && (
          <div
            className={clsx('text-field-label', {
              needsAttention: fieldNeedsAttention(dField, currentUserId, currentUserLastSeen)
            })}
          >
            <div className="label-container">{dField.label}</div>
          </div>
        )}
        <TextInput
          className={clsx('text-field', { multiline: dField.multiline, withHint: dField.withHint })}
          {...field}
          placeholder={t(props.field.placeholder) ?? ''}
          multiline={dField.multiline}
        />
      </>
    );
  }
  if (dField.value === null) {
    return <> </>;
  }

  return (
    <>
      {dField.label !== '' && (
        <div
          className={clsx('text-field-label', {
            needsAttention: fieldNeedsAttention(dField, currentUserId, currentUserLastSeen)
          })}
        >
          <div className="label-container">{dField.label}</div>
        </div>
      )}
      <div className="text-field-read-only">{field.value}</div>
    </>
  );
};

const TextField = (props: IProps): React.JSX.Element => {
  const { control, field: dField } = props;
  const { field } = useController({
    name: dField.id,
    control
  });
  const { caseStore, userStore } = useRootStore();
  const { openedCase } = caseStore;

  if (!openedCase) {
    throw new Error('No case found');
  }

  const tfield = getTextField(
    field,
    dField,
    props,
    userStore.loggedInUser.data.id,
    caseStore.currentUserPrevLastSeen
  );
  return (
    <FormControl>
      <div className="text-field-container">{tfield}</div>
    </FormControl>
  );
};

export default observer(TextField);
