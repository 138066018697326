import i18n from 'app/services/i18n';
import { CASE_STATE, USER_CASE_ROLES } from 'app/consts';
import type { ICaseBasicData, ILoggedInUserData, ISelectOption } from 'app/mobxStore/types';

export const OTHER_OPTION = 'Other';
export const NA_OPTION = 'N/A';

export enum DynamicField {
  COMMENTS_TO_ANESTH = 'c_df_comment_to_anesthesia',
  COMMENTS_TO_NURSING = 'c_df_comment_to_nursing',
  INTRAOP_IMAGING = 'c_df_intraop_imaging',
  ESTIMATED_CASE_LENGTH = 'c_df_estimated_case_length',
  INSTRUCTIONS_FOR_2ND_PART = 'c_df_instructions_for_second_part_of_the_case',
  VENDOR = 'c_df_vendor'
}

export const genOptionFromStr = (str: string): ISelectOption => ({
  label: str === OTHER_OPTION ? i18n.t('addYourOwn') : str,
  value: str
});

export const getUserRoleInCase = (
  kase: ICaseBasicData,
  user: ILoggedInUserData
): USER_CASE_ROLES => {
  switch (user.id) {
    case kase.attendingId:
      return USER_CASE_ROLES.ATTENDING;
    default:
      if (user.isNurseLeader) {
        return USER_CASE_ROLES.CONTRIBUTOR;
      } else {
        return USER_CASE_ROLES.NONE;
      }
  }
};

export const needsBriefApproval = (
  isReady: boolean,
  isPast: boolean,
  userRoleInCase: USER_CASE_ROLES
): boolean => {
  if (isPast || isReady) {
    return false;
  }
  return [USER_CASE_ROLES.ATTENDING, USER_CASE_ROLES.CONTRIBUTOR].includes(userRoleInCase);
};

export const canUpdate = (caseData: ICaseBasicData, userRoleInCase: USER_CASE_ROLES): boolean => {
  return (
    caseData.state !== CASE_STATE.ARCHIVED &&
    [USER_CASE_ROLES.ATTENDING, USER_CASE_ROLES.CONTRIBUTOR].includes(userRoleInCase)
  );
};

export const showStandardProcedure = (caseData: ICaseBasicData): boolean => {
  if (
    caseData.site.specialties.length === 1 &&
    caseData.site.specialties.includes('c_spec_orthopedic_surgery')
  ) {
    return true;
  }

  if (caseData.specialtyId === 'c_spec_orthopedic_surgery') {
    return true;
  }

  if (!caseData.procedureId) {
    return false;
  }

  const neuroProcs = [
    'c_proc_neuro_craniotomy_for_insertion_of_dbs_electrode',
    'c_proc_neuro_dbs_generator_placement',
    'c_proc_neuro_dbs_generator_change',
    'c_proc_neuro_ventriculo_peritoneal_shunt_placement',
    'c_proc_neuro_ventriculo_peritoneal_shunt_revision',
    'c_proc_neuro_ventriculo_peritoneal_shunt_removal'
  ];

  return neuroProcs.includes(caseData.procedureId);
};

export const getIsPatientField = (fieldId: string): boolean => {
  const patientFields = ['c_df_pertinent_pmh', 'c_df_laterality', 'c_df_smoking_history'];

  return patientFields.includes(fieldId);
};
