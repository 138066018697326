import { gql } from '@apollo/client';
import { CASE_ASSET_FULL_DATA } from './fragments/caseAsset';

const create = gql`
  mutation CareTeamCreateCaseAsset($object: case_assets_insert_input!) {
    insert_case_assets_one(object: $object) {
      id
    }
  }
`;

const update = gql`
  mutation CareTeamUpdateCaseAsset($id: uuid!, $set: case_assets_set_input!) {
    update_case_assets_by_pk(pk_columns: { id: $id }, _set: $set) {
      id
    }
  }
`;

const deleteAsset = gql`
  mutation CareTeamDeleteCaseAsset($id: uuid!) {
    delete_case_assets_by_pk(id: $id) {
      id
    }
  }
`;

const getUploadcareSignature = gql`
  query CareTeamUploadcareSignature($case_id: uuid!) {
    uploadcareSignature(case_id: $case_id) {
      signature
      expire
    }
  }
`;

const getCaseAssets = gql`
  query CareTeamGetCaseAssets($case_id: uuid!) {
    GetCaseAssets(case_id: $case_id) {
      ...CaseAssetFullData
    }
  }
  ${CASE_ASSET_FULL_DATA}
`;
const getCaseAssetsCount = gql`
  query CareTeamGetCaseAssetsCount($case_id: uuid!) {
    case_assets_aggregate(where: { case_id: { _eq: $case_id } }) {
      aggregate {
        count
      }
    }
  }
`;

const queries = {
  getUploadcareSignature,
  getCaseAssets,
  getCaseAssetsCount
};

const mutations = {
  create,
  update,
  delete: deleteAsset
};

const api = {
  queries,
  mutations
};

export default api;
