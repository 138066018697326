import React from 'react';
import type { IDynamicField } from 'app/mobxStore/types';
import clsx from 'clsx';
import { fieldNeedsAttention } from './helper';
import { useRootStore } from 'app/mobxStore';

interface IProps {
  field: IDynamicField;
}

const getTextValue = (field: IDynamicField): string => {
  if (field.value.length === 0) {
    return '';
  }

  return field.value[0];
};

const TextDisplayWidget = (props: IProps): React.JSX.Element | null => {
  const { field } = props;
  const { caseStore, userStore } = useRootStore();
  const { openedCase } = caseStore;

  const value = getTextValue(field);
  if (value === '') {
    return null;
  }

  if (!openedCase) {
    throw new Error('No case found');
  }

  return (
    <div className="form-group">
      <div
        className={clsx('form-group-label', {
          needsAttention: fieldNeedsAttention(
            field,
            userStore.loggedInUser.data.id,
            caseStore.currentUserPrevLastSeen
          )
        })}
      >
        <div className="label-container">{field.label}</div>
      </div>
      <div className="text-display-only">{value}</div>
    </div>
  );
};

export default TextDisplayWidget;
