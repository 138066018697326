import config from './config';

const getUploadCareUrl = (uuid: string): string => `${config.cdn}/${uuid}/`;

const buildImageUrl = (token: string, cdnUrlModifiers: string, imageSize: string): string => {
  if (cdnUrlModifiers === '') {
    return `-/preview/${imageSize}/?token=${token}`;
  }
  return `${cdnUrlModifiers}-/preview/${imageSize}/?token=${token}`;
};
export const getImagePath = (uuid: string, token: string, cdnUrlModifiers: string): string =>
  getUploadCareUrl(uuid) + buildImageUrl(token, cdnUrlModifiers, config.imageSize);
export const getThumbPath = (uuid: string, token: string, cdnUrlModifiers: string): string =>
  getUploadCareUrl(uuid) + buildImageUrl(token, cdnUrlModifiers, config.thumbSize);
