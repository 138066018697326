/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useRootStore } from 'app/mobxStore';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import ReloadIcon from 'app/assets/icons/Reload_icon';
import SuccessIcon from 'app/assets/icons/Success_icon';
import variables from 'app/utils/variables.module.scss';
import './FillCaseMetaForm.scss';
import { type IFullCase, SavingStatus } from '../../mobxStore/types';
import { CASE_STATE } from '../../consts';

const SavingStatusCmp = (): React.JSX.Element => {
  const { caseStore } = useRootStore();
  const { savingStatusInHuddle } = caseStore;
  const openedCase = caseStore.openedCase as IFullCase;
  const { t } = useTranslation();

  if (savingStatusInHuddle === SavingStatus.NONE) {
    return <> </>;
  }

  if (savingStatusInHuddle === SavingStatus.SAVING) {
    return (
      <div className="save-indicator">
        <ReloadIcon stroke={variables.grey6} />
        <div className={clsx('text')}>{t('saving')}</div>
      </div>
    );
  }

  if (savingStatusInHuddle === SavingStatus.SAVED) {
    const savedText =
      openedCase.basicCase.data.state === CASE_STATE.READY ? t('savedWithUpdate') : t('saved');

    return (
      <div className="save-indicator">
        <SuccessIcon stroke={variables.grey6} strokeWidth={1.5} />
        <div className={clsx('text', { saved: true })}>{savedText}</div>
      </div>
    );
  }

  throw new Error('SavingStatusCmp: Unknown saving status');
};

export default observer(SavingStatusCmp);
