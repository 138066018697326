import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Button from 'app/components/buttons/Button';
import AccountSettingsForm from './AccountSettingsForm';
import ArrowLeftIcon from 'app/assets/icons/ArrowLeft_icon';
import './AccountSettings.scss';
import variables from 'app/utils/variables.module.scss';

interface IProps {
  handleCloseSettingsDialog: () => void;
}

const AccountSettings = (props: IProps): React.JSX.Element => {
  const { handleCloseSettingsDialog } = props;
  const { t } = useTranslation();

  const handleClose = async (): Promise<void> => {
    handleCloseSettingsDialog();
  };

  return (
    <div className="account-settings-container">
      <div className="header">
        <Button
          classNames="btn circle-button close-button"
          startIcon={<ArrowLeftIcon height={14} stroke={variables.grey6} />}
          onClick={handleClose}
        />
        <div className="title">{t('accountSettings')}</div>
      </div>
      <AccountSettingsForm />
    </div>
  );
};

export default observer(AccountSettings);
