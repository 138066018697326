import * as React from 'react';
const SvgComponent = props => (
  <svg
    className="svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 14"
    fill={props.fill || 'none'}
    {...props}
  >
    <path
      d="M8.99999 1.5H0.99999C0.82134 1.5 0.656307 1.40469 0.56699 1.25C0.477673 1.09531 0.477667 0.904683 0.56699 0.75C0.656313 0.595317 0.821357 0.5 0.99999 0.5H8.99999C9.17864 0.5 9.34367 0.595313 9.43299 0.75C9.52231 0.904687 9.52231 1.09532 9.43299 1.25C9.34367 1.40468 9.17862 1.5 8.99999 1.5Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M8.99999 13.5H0.99999C0.82134 13.5 0.656307 13.4047 0.56699 13.25C0.477673 13.0953 0.477667 12.9047 0.56699 12.75C0.656313 12.5953 0.821357 12.5 0.99999 12.5H8.99999C9.17864 12.5 9.34367 12.5953 9.43299 12.75C9.52231 12.9047 9.52231 13.0953 9.43299 13.25C9.34367 13.4047 9.17862 13.5 8.99999 13.5Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M1 13.5C0.867387 13.5 0.740237 13.4473 0.64642 13.3536C0.55267 13.2598 0.5 13.1326 0.5 13V0.99999C0.5 0.82134 0.595313 0.656307 0.75 0.56699C0.904687 0.477673 1.09532 0.477667 1.25 0.56699C1.40468 0.656313 1.5 0.821357 1.5 0.99999V13C1.5 13.1326 1.44733 13.2598 1.35358 13.3536C1.25977 13.4473 1.13262 13.5 1 13.5Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M13 7.50001C12.8674 7.50014 12.7401 7.44747 12.6465 7.35353L10.6465 5.35353C10.5231 5.22664 10.4759 5.04403 10.5225 4.87326C10.5691 4.70249 10.7025 4.56909 10.8733 4.52254C11.044 4.47593 11.2266 4.52307 11.3535 4.6465L13.3535 6.6465C13.4473 6.74025 13.4999 6.8674 13.4999 7.00002C13.4999 7.13264 13.4473 7.25979 13.3535 7.35354C13.2598 7.44729 13.1326 7.50001 13 7.50001Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M10.9999 9.50002C10.8673 9.50002 10.7402 9.44728 10.6464 9.35353C10.5527 9.25978 10.5 9.13263 10.5 9.00002C10.5 8.8674 10.5527 8.74025 10.6464 8.6465L12.6464 6.6465C12.7733 6.52306 12.9559 6.47593 13.1267 6.52254C13.2975 6.56909 13.4309 6.70249 13.4774 6.87326C13.524 7.04403 13.4769 7.22664 13.3534 7.35352L11.3534 9.35352C11.2598 9.44747 11.1326 9.50015 10.9999 9.50002Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M13 7.5H4.99999C4.82134 7.5 4.65631 7.40469 4.56699 7.25C4.47767 7.09531 4.47767 6.90468 4.56699 6.75C4.65631 6.59532 4.82136 6.5 4.99999 6.5H13C13.1786 6.5 13.3437 6.59531 13.433 6.75C13.5223 6.90469 13.5223 7.09532 13.433 7.25C13.3437 7.40468 13.1786 7.5 13 7.5Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M9 3.49999C8.86739 3.49999 8.74024 3.44732 8.64642 3.35357C8.55267 3.25976 8.5 3.13261 8.5 2.99999V0.99999C8.5 0.82134 8.59531 0.656307 8.75 0.56699C8.90469 0.477673 9.09532 0.477667 9.25 0.56699C9.40468 0.656313 9.5 0.821357 9.5 0.99999V2.99999C9.5 3.13261 9.44733 3.25976 9.35358 3.35357C9.25976 3.44732 9.13262 3.49999 9 3.49999Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M9 13.5C8.86739 13.5 8.74024 13.4473 8.64642 13.3536C8.55267 13.2598 8.5 13.1326 8.5 13V11C8.5 10.8213 8.59531 10.6563 8.75 10.567C8.90469 10.4777 9.09532 10.4777 9.25 10.567C9.40468 10.6563 9.5 10.8214 9.5 11V13C9.5 13.1326 9.44733 13.2598 9.35358 13.3536C9.25976 13.4473 9.13262 13.5 9 13.5Z"
      fill={props.fill || '#B33B94'}
    />
  </svg>
);
export default SvgComponent;
