import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'app/components/buttons/Button';
import ExitIcon from 'app/assets/icons/Exit_icon';
import variables from 'app/utils/variables.module.scss';
import './MyAccount.scss';
import { useNavigate } from 'react-router-dom';
import AmplService from '../../services/amplService/amplService';

const LogOutButton = (): React.JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleLogOut = async (): Promise<void> => {
    AmplService.sendEvent(AmplService.EVENTS.ACCOUNT_LOGOUT);
    navigate('/logout');
  };

  return (
    <Button
      classNames="btn action-button"
      buttonContent={
        <div className="button-content">
          <div className="button-icon">
            <ExitIcon height={14} fill={variables.black} />
          </div>
          {t('logOut')}
        </div>
      }
      onClick={handleLogOut}
    />
  );
};

export default LogOutButton;
