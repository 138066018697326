import React from 'react';
import { authRoles } from 'app/auth';
import Cases from 'app/pages/cases/Cases';
import FuseAuthorization from '@fuse/core/FuseAuthorization';
import { MetaData } from 'app/metaData';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from 'app/pages/errorPage/ErrorPage';
import { Navigate } from 'react-router-dom';
const CasesConfig = {
  settings: {
    layout: {
      config: {
        footer: {
          display: true
        }
      }
    }
  },
  auth: authRoles.careTeam,
  routes: [
    {
      path: '/cases/*',
      element: (
        <ErrorBoundary fallback={<ErrorPage />}>
          <FuseAuthorization>
            <MetaData>
              <Cases />
            </MetaData>
          </FuseAuthorization>
        </ErrorBoundary>
      )
    },
    {
      path: '/reloaded_build/*',
      element: <Navigate to="/cases" replace />
    }
  ]
};

export default CasesConfig;
