import useStore from 'app/mobxStore';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ROLES } from 'app/consts';
import ErrorMonitor from 'app/services/errorMonitor/errorMonitor';

const DEBUG_REDIRECT = false;

const useRedirectTo = (): (() => void) => {
  const {
    rootStore: { userStore }
  } = useStore();

  const userRoles = userStore.loggedInUser.data.roles;
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const redirectRoute = (): void => {
    const { pathname } = location;
    // LoggedInUser is guest
    // Redirect to Login Page
    if (!userRoles || userRoles.length === 0) {
      const curParams = new URLSearchParams(searchParams);
      if (DEBUG_REDIRECT) {
        ErrorMonitor.captureMessage('navigate to login');
      }

      navigate(`/login`);
      curParams.set('redirectTo', pathname);
      setSearchParams(curParams);
      return;
    }

    // LoggedInUser is member
    // LoggedInUser must be on unAuthorized page or just logged in
    // Redirect to dashboard or redirectUrl
    const redirectUrl = (
      localStorage.getItem('redirectTo') ? localStorage.getItem('redirectTo') : '/'
    ) as string;
    const redirectSearch = (
      localStorage.getItem('redirectToSearch') ? localStorage.getItem('redirectToSearch') : ''
    ) as string;
    const needRedirect = redirectUrl === '/';
    localStorage.removeItem('redirectTo');
    localStorage.removeItem('redirectToSearch');

    if (userRoles.find(r => r.role === ROLES.NEED_PWA) !== undefined) {
      if (DEBUG_REDIRECT) {
        ErrorMonitor.captureMessage('navigate to add-to-home-screen');
      }

      navigate('/add-to-home-screen');
      return;
    }

    if (DEBUG_REDIRECT) {
      ErrorMonitor.captureMessage('navigate to cases');
    }

    navigate(needRedirect ? '/cases' : redirectUrl);

    if (redirectSearch !== '') {
      const curParams = new URLSearchParams(redirectSearch);
      setSearchParams(curParams);
    }
  };

  return redirectRoute;
};

export default useRedirectTo;
