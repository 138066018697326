import useRoutes from 'app/hooks/useRoutes';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import React from 'react';

const Router = (): React.JSX.Element => {
  const parsedRoutes = useRoutes();
  const router = createBrowserRouter(parsedRoutes);

  return <RouterProvider router={router} />;
};

export default Router;
