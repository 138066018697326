import React from 'react';
import Button from '../buttons/Button';
import InviteUserIcon from 'app/assets/icons/InviteUser_icon';
import ArrowRight from 'app/assets/icons/ArrowRight_icon';
import variables from 'app/utils/variables.module.scss';
import { t } from 'i18next';
import AmplService from '../../services/amplService';

interface IProps {
  setIsInviteDialogOpen: (value: boolean) => void;
}

const InviteUserButton = (props: IProps): React.JSX.Element => {
  const { setIsInviteDialogOpen } = props;

  const handleInviteUser = async (): Promise<void> => {
    setIsInviteDialogOpen(true);
    AmplService.sendEvent(AmplService.EVENTS.MENU_INVITE);
  };

  return (
    <Button
      classNames="btn action-button invite-user-button"
      onClick={handleInviteUser}
      buttonContent={
        <div className="button-content">
          <div className="button-icon">
            <InviteUserIcon />
          </div>
          {t('inviteDialogTitle')}
        </div>
      }
      endIcon={<ArrowRight stroke={variables.black} />}
    />
  );
};
export default InviteUserButton;
