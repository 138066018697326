import type { ILoggedInUser, IRootStore, IUserStore } from './types';
import LoggedInUser from './LoggedInUser';
import { flow, makeAutoObservable } from 'mobx';
import apiService from '../services/apiService';
import { log } from 'debug';

class UserStore implements IUserStore {
  rootStore: IRootStore;
  loggedInUser: ILoggedInUser;
  inviteId: string | null = null;

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this, {
      rootStore: false
    });
    this.rootStore = rootStore;
    this.loggedInUser = new LoggedInUser(this);
  }

  resetUser(): void {
    this.loggedInUser = new LoggedInUser(this);
  }

  inviteUser = flow(function* (this: IUserStore, newSite: boolean) {
    try {
      const id = yield apiService.inviteUser(newSite);
      this.setInviteId(id);
      return id;
    } catch (error) {
      log(error);
    }
  });

  setInviteId(id: string | null): void {
    this.inviteId = id;
  }
}

export default UserStore;
