import type { ISelectOption, IUserSelectOption } from 'app/mobxStore/types';

export const enum FORM_FIELDS_ENUM {
  SURGEON = 'surgeon',
  DATE = 'date',
  FOLLOWERS = 'followers',
  SERVICE = 'service',
  PROCEDURE = 'procedure',
  TITLE = 'title',
  DESCRIPTION = 'description',
  ROOM = 'room',
  PATIENT_INITIALS = 'patientInitials',
  MRN = 'mrn'
}

export const enum SERVER_FORM_FIELDS_ENUM {
  SURGEON = 'attendingId',
  SERVICE = 'siteId',
  DATE = 'caseDate',
  PROCEDURE = 'procedureId',
  TITLE = 'displayId',
  DESCRIPTION = 'description',
  ROOM = 'room',
  PATIENT_INITIALS = 'patientInitials',
  MRN = 'mrn'
}

export interface IFormData {
  [FORM_FIELDS_ENUM.TITLE]: string;
  [FORM_FIELDS_ENUM.DESCRIPTION]: string;
  [FORM_FIELDS_ENUM.SURGEON]: IUserSelectOption | undefined;
  [FORM_FIELDS_ENUM.DATE]: Date;
  [FORM_FIELDS_ENUM.FOLLOWERS]: IUserSelectOption[];
  [FORM_FIELDS_ENUM.SERVICE]: ISelectOption | undefined;
  [FORM_FIELDS_ENUM.PROCEDURE]: ISelectOption | undefined;
  [FORM_FIELDS_ENUM.ROOM]: string;
  [FORM_FIELDS_ENUM.PATIENT_INITIALS]: string;
  [FORM_FIELDS_ENUM.MRN]: string;
}

export interface IAllFormData extends IFormData {
  indexInDay: number;
}

export const enum COMMENT_FORM_FIELDS_ENUM {
  COMMENT = 'comment'
}

export interface ICommentFormData {
  [COMMENT_FORM_FIELDS_ENUM.COMMENT]: string;
}

export const enum FEEDBACK_FORM_FIELDS_ENUM {
  RATING = 'rating',
  TEXT = 'text'
}

export interface IFeedbackFormData {
  [FEEDBACK_FORM_FIELDS_ENUM.RATING]: number;
  [FEEDBACK_FORM_FIELDS_ENUM.TEXT]: string;
}
