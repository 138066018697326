import React, { type MouseEvent } from 'react';
import type { ICommentData, IFullCase } from '../../mobxStore/types';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import Button from 'app/components/buttons/Button';
import GoodIcon from 'app/assets/icons/GoodTwo_icon';
import variables from 'app/utils/variables.module.scss';
import { useRootStore } from '../../mobxStore';
import AmplService from '../../services/amplService';

interface IProps {
  comment: ICommentData;
  openedCase: IFullCase;
}

const Likes = (props: IProps): React.JSX.Element => {
  const { userStore } = useRootStore();
  const { comment, openedCase } = props;
  const currentUserId = userStore.loggedInUser.data.id;
  const curUserMetas = comment.metaData.find(mt => mt.createdById === currentUserId);
  const isLikedByUser = curUserMetas?.reaction === 'like';
  const likeCount = comment.metaData.filter(mt => mt.reaction === 'like').length;

  const handleClick = async (e: MouseEvent): Promise<void> => {
    const value = isLikedByUser ? '' : 'like';
    void openedCase.commentsStore.commentLike(comment.id, value);
    AmplService.sendCaseEvent(AmplService.EVENTS.CHAT_LIKE, openedCase.basicCase);
  };

  const othersLiked = (isLikedByUser && likeCount > 1) || (!isLikedByUser && likeCount > 0);

  return (
    <Button
      classNames={clsx('btn like-button', {
        liked: isLikedByUser,
        othersLiked
      })}
      buttonContent={
        <div className="button-content">
          <GoodIcon
            stroke={isLikedByUser ? variables.white : variables.black}
            fill={isLikedByUser ? variables.white : 'transparent'}
          />
          {othersLiked && <div className="count">{likeCount}</div>}
        </div>
      }
      onClick={handleClick}
    />
  );
};

export default observer(Likes);
