import type { IDynamicField, IDynamicFieldValues, ISelectOption } from '../../mobxStore/types';
import type { Control } from 'react-hook-form/dist/types/form';
import type { ControllerRenderProps } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import OtherChip from './OtherChip';
import { observer } from 'mobx-react-lite';

interface IProps {
  allValues: IDynamicFieldValues;
  control: Control<any>;
  dField: IDynamicField;
  field: ControllerRenderProps<any, string>;
  option: ISelectOption;
  toggleValue: (
    option: ISelectOption,
    value: string[],
    setValue: (...event: any[]) => void
  ) => void;
  isOptionSelected: (option: ISelectOption, value: string[]) => boolean;
}

const getOtherValues = (allValues: IDynamicFieldValues, dField: IDynamicField): string[] => {
  return allValues[`${dField.id}-other`];
};

const getOtherValue = (otherValues: string[], index: number): string => {
  return otherValues[index];
};

const OtherChips = (props: IProps): React.JSX.Element => {
  const { dField, allValues, field, option, isOptionSelected, toggleValue } = props;
  const values = getOtherValues(allValues, dField);
  const valuesLength = values.length;
  const [curLength, setCurLength] = useState(values.length);
  const [isAddingNewOption, setIsAddingNewOption] = useState(false);
  useEffect(() => {
    if (isAddingNewOption) {
      return;
    }
    setCurLength(valuesLength);
  }, [valuesLength, isAddingNewOption]);

  const onEditEnd = (): void => {
    setIsAddingNewOption(false);
  };
  const onEditStart = (idx: number): void => {
    if (idx === curLength) {
      setIsAddingNewOption(true);
    }
  };
  return (
    <>
      {values.slice(0, curLength).map((value, index) => {
        return (
          <OtherChip
            onEditStart={() => {}}
            onEditEnd={onEditEnd}
            value={getOtherValue(values, index)}
            index={index}
            key={index}
            control={props.control}
            dField={dField}
            field={field}
            option={option}
            toggleValue={toggleValue}
            isOptionSelected={isOptionSelected}
          />
        );
      })}
      <OtherChip
        onEditStart={onEditStart}
        onEditEnd={onEditEnd}
        value={getOtherValue(values, curLength) ?? ''}
        index={curLength}
        key={curLength}
        control={props.control}
        dField={dField}
        field={field}
        option={option}
        toggleValue={toggleValue}
        isOptionSelected={isOptionSelected}
      />
    </>
  );
};

export default observer(OtherChips);
