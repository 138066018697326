import * as React from 'react';
const SvgComponent = props => (
  <svg
    className="svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 13"
    fill={props.fill || 'none'}
    {...props}
  >
    <path
      d="M0.59082 5.50011C0.81527 4.28116 1.44486 3.17375 2.37753 2.35744C3.31019 1.54113 4.49123 1.06377 5.72918 1.00276C6.96712 0.941757 8.18938 1.30068 9.19778 2.02134C10.2062 2.74199 10.9416 3.78213 11.2848 4.97311"
      stroke={props.stroke || '#767676'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5002 1V5L7.53125 4.507"
      stroke={props.stroke || '#767676'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.4088 7.5C11.1844 8.71895 10.5548 9.82636 9.62214 10.6427C8.68948 11.459 7.50843 11.9363 6.27049 11.9973C5.03254 12.0584 3.81029 11.6994 2.80188 10.9788C1.79348 10.2581 1.05805 9.21798 0.714844 8.027"
      stroke={props.stroke || '#767676'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.5 12V8L4.469 8.493"
      stroke={props.stroke || '#767676'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgComponent;
