import { useCallback, useEffect, useState } from 'react';
import apiService from 'app/services/apiService';
import { useDebounce } from '@fuse/hooks';
import type { ICaseByDescriptionData, IProcedureSelectOption } from 'app/mobxStore/types';
import ErrorMonitor from '../../../services/errorMonitor/errorMonitor';

const NO_MATCH = 'NOMATCH!';
const cervicalRegEx = /cervical|[Cc]\d/;
const thoracicRegEx = /thorac|[Tt]\d/;
const lumbarRegEx = /lumbar|[Ll]\d/;
const sacralRegEx = /sacr|[Ss]\d/;
const CERVICAL = 'cervical';
const THORAC = 'thorac';
const LUMBAR = 'lumbar';
const SACR = 'sacr';
const ANTERIOR = 'anterior';
const POSTERIOR = 'posterior';
const CRANI = 'crani';

const termToProc: Record<string, string> = {
  meningioma: 'extra-axial tumor',
  'ENCEPHALO DURO ARTERIO SYNANGIOSIS': 'EDAS'
};

const stripProcName = (proc: string): string => {
  return proc.toLowerCase().replace('-', '');
};

// filter only strings that contains a list of terms, case insensitive
const procWeight = (
  termsArr: string[],
  procName: string,
  termsCount: Record<string, number>,
  sumTermsCount: number,
  count: number,
  sumCount: number
): number => {
  const w = termsArr.reduce((acc, term) => {
    // The more times a term appears in the procedure name, the less weight it has
    const termsWeight = stripProcName(procName).includes(term)
      ? (sumTermsCount - termsCount[term]) / sumTermsCount
      : 0;
    return acc + termsWeight;
  }, 0);

  // The more times a procedure was done, the more weight it has
  const countWeight = sumCount > 0 ? count / sumCount : 0;
  return w + countWeight;
};

const filterByTerm = (str: string, term: RegExp): boolean => {
  const found = str.match(term);
  if (found === null) {
    return false;
  }
  return found.length > 0;
};

const filterBySpecificTerms = (
  procs: IProcedureSelectOption[],
  terms: string
): { ps: IProcedureSelectOption[]; done: boolean } => {
  for (const [specificTerm, specificProc] of Object.entries(termToProc)) {
    if (terms.toLowerCase().includes(specificTerm.toLowerCase())) {
      const ps = procs.filter(p => stripProcName(p.label).includes(specificProc.toLowerCase()));
      if (ps.length !== 1) {
        ErrorMonitor.captureException(
          new Error(`Unexpected result for specific term: ${specificTerm}`)
        );
      }
      return {
        ps,
        done: true
      };
    }
  }

  return {
    ps: [],
    done: false
  };
};

const filterByNeuroTerms = (
  procs: IProcedureSelectOption[],
  termsStr: string
): IProcedureSelectOption[] => {
  const termsStrLower = termsStr.toLowerCase();

  const [cervical, thoracic, lumbar, sacral] = [
    cervicalRegEx,
    thoracicRegEx,
    lumbarRegEx,
    sacralRegEx
  ].map(regEx => filterByTerm(termsStrLower, regEx));
  const anterior = termsStrLower.includes(ANTERIOR);
  const posterior = termsStrLower.includes(POSTERIOR);
  const craniotomy = termsStrLower.includes(CRANI);

  const arrToFilter: string[] = [];
  if (!cervical) {
    arrToFilter.push(CERVICAL);
  }
  if (!thoracic) {
    arrToFilter.push(THORAC);
  }
  if (!lumbar) {
    arrToFilter.push(LUMBAR);
  }
  if (!sacral) {
    arrToFilter.push(SACR);
  }

  const ps =
    arrToFilter.length === 4 && !craniotomy
      ? procs
      : procs.filter(proc => {
          return !arrToFilter.some(term => stripProcName(proc.label).includes(term));
        });

  const termToFilter = getTermToFilter(anterior, posterior);

  if (termToFilter === '') {
    return ps;
  }
  return ps.filter(proc => !stripProcName(proc.label).includes(termToFilter));
};

const getTermToFilter = (anterior: boolean, posterior: boolean): string => {
  if (anterior) {
    return POSTERIOR;
  }
  if (posterior) {
    return ANTERIOR;
  }
  return '';
};

const filterByTermsAndOccurences = (
  procs: IProcedureSelectOption[],
  termsStr: string,
  siteId: string,
  attendingId: string
): IProcedureSelectOption[] => {
  const termsArr = termsStr
    .split(/[\s,.(){}|\\?+*^$-]/)
    .filter(
      term =>
        term !== '' &&
        term.length > 2 &&
        !['undefined', 'null', 'with', 'for', 'and'].includes(term)
    )
    .map(t => t.toLowerCase());

  // if we didn't find any procedures that this attending did, just use all procedures
  const suggestions = procs.filter(
    proc => termsArr.length === 0 || termsArr.some(term => stripProcName(proc.label).includes(term))
  );
  // Count how many times each term appears in all procedures
  const termsCount: Record<string, number> = {};

  // let sumTermsCount = 0;
  const sumTermsCount = termsArr.reduce((acc, term) => {
    termsCount[term] = suggestions.reduce((acc, proc) => {
      const incr = stripProcName(proc.label).includes(term) ? 1 : 0;
      return acc + incr;
    }, 0);
    return acc + termsCount[term];
  }, 0);

  // Sum all counts per site
  const sumCount = suggestions.reduce((acc, proc) => {
    const count = proc?.countPerSiteAndAtt?.get(siteId)?.get(attendingId) ?? 0;
    return acc + count;
  }, 0);

  const suggestionsWithWeight = suggestions.map(proc => {
    const count = proc?.countPerSiteAndAtt?.get(siteId)?.get(attendingId) ?? 0;
    return {
      ...proc,
      weight: procWeight(termsArr, proc.label, termsCount, sumTermsCount, count, sumCount)
    };
  });

  // sort by weights and then by count per site
  suggestionsWithWeight.sort((a, b) => {
    if (b.weight === undefined || a.weight === undefined) {
      return 0;
    }
    return b.weight - a.weight;
  });

  return suggestionsWithWeight;
};

const filterByPastCases = async (
  procedures: IProcedureSelectOption[],
  ps: IProcedureSelectOption[],
  description: string,
  displayId: string,
  siteId: string
): Promise<IProcedureSelectOption[]> => {
  // Find past cases with the same description or display id. Replace empty description or display
  // id with NO_MATCH so it won't match cases with empty desc/dispId
  const desc = description === null || description === '' ? NO_MATCH : description;
  const dispId = displayId === null || displayId === '' ? NO_MATCH : displayId;
  const nodes: ICaseByDescriptionData[] = await apiService.getCasesByDescriptionOrDisplayId(
    desc,
    dispId,
    siteId
  );

  // If found one match, ignore all other procedures and just return that one
  const newPs =
    nodes.length === 1
      ? []
      : ps.filter(proc => {
          return !nodes.some((node: ICaseByDescriptionData) => {
            return node.procedureId === proc.value;
          });
        });
  const nodesToUnshift = nodes.map((node: ICaseByDescriptionData) => {
    const proc = procedures.find(p => p.value === node.procedureId);

    if (proc === undefined) {
      ErrorMonitor.captureException(
        new Error(`Procedure not found in procedures list: ${node.procedureId}`)
      );
      return {
        value: node.procedureId,
        label: node.procedureTitle,
        pastCasesMatch: true,
        isUserTemplate: false
      };
    }

    return {
      value: node.procedureId,
      label: node.procedureTitle,
      pastCasesMatch: true,
      isUserTemplate: proc.isUserTemplate
    };
  });
  newPs.unshift(...nodesToUnshift);

  return newPs;
};

const getOptions = async (
  procedures: IProcedureSelectOption[],
  displayId: string,
  description: string,
  siteId: string,
  attendingId: string
): Promise<IProcedureSelectOption[]> => {
  if (siteId === '') {
    return [];
  }
  let terms = '';

  if (description !== '' || displayId !== '') {
    terms = ''.concat(description, ' ', displayId);
  }

  let { ps, done } = filterBySpecificTerms(procedures, terms);
  if (done) {
    return ps;
  }

  ps = filterByNeuroTerms(procedures, terms);
  ps = filterByTermsAndOccurences(ps, terms, siteId, attendingId);
  ps = await filterByPastCases(procedures, ps, description, displayId, siteId);
  return ps;
};

const useProcedureSuggestions = ({
  procedures,
  procedureTitle,
  title,
  description,
  siteId,
  attendingId
}: {
  procedures: IProcedureSelectOption[];
  procedureTitle: string;
  title: string;
  description: string;
  siteId: string;
  attendingId: string;
}): { procSuggestions: IProcedureSelectOption[]; loaded: boolean } => {
  const initSuggestions: IProcedureSelectOption[] = [];
  const [procSuggestions, setProcSuggestions] = useState(initSuggestions);
  const [loaded, setLoaded] = useState(false);

  const asyncGetOptions = useCallback(
    async (
      p: IProcedureSelectOption[],
      tit: string,
      desc: string,
      sid: string,
      attId: string
    ): Promise<void> => {
      const ps = await getOptions(p, tit, desc, sid, attId);
      setProcSuggestions(ps);
      setLoaded(true);
    },
    []
  );
  const debouncedGetOptions = useDebounce(asyncGetOptions, 750, {
    leading: false,
    trailing: true
  }).debounceFunc;

  useEffect(() => {
    if (procedureTitle === '') {
      const runDebounceGetOptions = async (): Promise<void> => {
        debouncedGetOptions(procedures, title, description, siteId, attendingId);
      };
      void runDebounceGetOptions();
      return;
    }

    setProcSuggestions([]);
    setLoaded(true);
  }, [title, description, siteId, attendingId]);

  useEffect(() => {
    if (procedureTitle === '') {
      const f = async (): Promise<void> => {
        await asyncGetOptions(procedures, title, description, siteId, attendingId);
      };
      void f();
    }
  }, []);

  return { procSuggestions, loaded };
};

export default useProcedureSuggestions;
