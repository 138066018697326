import { gql } from '@apollo/client';
import { SITE_FULL_DATA } from './fragments/site';

const UPLOADCARE_KEY = 'uploadcare_key';

const getAll = gql`
  query GetSites {
    sites(order_by: { created_at: asc }, where: { is_active: { _eq: true } }) {
      ...SiteFullData
    }
  }
  ${SITE_FULL_DATA}
`;

const getAllowedSites = gql`
  query GetAllowedSites($sites_ids: [uuid!]!) {
    sites(
      order_by: { created_at: asc }
      where: { is_active: { _eq: true }, id: { _in: $sites_ids } }
    ) {
      ...SiteFullData
    }
  }
  ${SITE_FULL_DATA}
`;

const getDepAdminSitesMeta = gql`
  query AdminGetSitesMeta {
    sites(order_by: { created_at: asc }, where: { is_active: { _eq: true } }) {
      ...SiteFullData
    }
  }
  ${SITE_FULL_DATA}
`;

const getMetas = gql`
  query GetMetas($keys: [String!]!) {
    meta(where: { key: { _in: $keys } }) {
      key
      value
    }
  }
`;

const getCareTeamUploadcareKey = gql`
    query CareTeamUploadcareKey {
        meta_by_pk(key: "${UPLOADCARE_KEY}") {
            value
        }
    }
`;

const setTimezone = gql`
  mutation CaseTeamSetTimezone($site_id: uuid!, $timezone: String!) {
    update_sites_by_pk(pk_columns: { id: $site_id }, _set: { timezone: $timezone }) {
      id
      timezone
    }
  }
`;

const mutations = {
  setTimezone
};

const queries = {
  getAll,
  getDepAdminSitesMeta,
  getCareTeamUploadcareKey,
  getAllowedSites,
  getMetas
};

const api = {
  mutations,
  queries
};

export default api;
