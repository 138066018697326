import type { ILoggedInUser, ILoggedInUserData, IRoleData, IUserStore } from './types';
import { makeAutoObservable } from 'mobx';
import { ROLES } from 'app/consts';

const emptyRoles: IRoleData[] = [];

class LoggedInUser implements ILoggedInUser {
  store: IUserStore;
  loggedIn = false;
  id = '';
  data: ILoggedInUserData = {
    nickName: '',
    avatar: '',
    id: '',
    followAll: false,
    roles: emptyRoles,
    firstName: '',
    lastName: '',
    isNurseLeader: false,
    phone: '',
    email: '',
    isActive: false,
    vendor: '',
    reminderDays: [],
    onboardingDone: false
  };

  wrongPasswordError = false;

  constructor(store: IUserStore) {
    makeAutoObservable(this, {
      store: false
    });
    this.store = store;
  }

  setData(data: ILoggedInUserData): void {
    this.data = data;
  }

  setLoggedIn(loggedIn: boolean): void {
    this.loggedIn = loggedIn;
  }

  setRoles(roles: ROLES[]): void {
    this.data.roles = roles.map(r => ({ role: r }));
  }

  setWrongPasswordError(wrongPasswordError: boolean): void {
    this.wrongPasswordError = wrongPasswordError;
  }

  isRole(role: ROLES): boolean {
    return this.data.roles.find(r => r.role === role) !== undefined;
  }

  get isAttending(): boolean {
    return this.isRole(ROLES.ATTENDING);
  }

  get isInviter(): boolean {
    return this.isRole(ROLES.INVITER);
  }

  get isVendor(): boolean {
    return this.isRole(ROLES.VENDOR_REP);
  }
}

export default LoggedInUser;
