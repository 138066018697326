import * as React from 'react';
const SvgComponent = props => (
  <svg
    className="svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill={props.fill || 'none'}
    {...props}
  >
    <path
      d="M17.5757 2.80273V5.83303H14.5454"
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.3333 9.99984C18.3333 14.6022 14.6023 18.3332 9.99992 18.3332C5.39754 18.3332 1.66659 14.6022 1.66659 9.99984C1.66659 5.39746 5.39754 1.6665 9.99992 1.6665C13.0841 1.6665 15.777 3.34197 17.2179 5.83238"
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.002 5L10.0015 10.0037L13.5345 13.5368"
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgComponent;
