import React from 'react';
import { observer } from 'mobx-react-lite';
import { useRootStore } from 'app/mobxStore';
import Button from 'app/components/buttons/Button';
import RadarIcon from 'app/assets/icons/RadarTwo_icon';
import variables from 'app/utils/variables.module.scss';
import './OrBoardHeader.scss';

const HuddleRateButton = (): React.JSX.Element => {
  const { caseStore } = useRootStore();

  if (!caseStore.casesLoadingDone || isNaN(caseStore.huddleRate)) {
    return <></>;
  }

  const huddleRate = Math.round(caseStore.huddleRate * 100);

  return (
    <Button
      classNames="menu-button huddle-rate"
      buttonText={`${huddleRate}%`}
      startIcon={<RadarIcon stroke={variables.success} />}
    />
  );
};

export default observer(HuddleRateButton);
