import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import './Chip.scss';

interface IProps {
  classNames?: string;
  text: string;
  color?: string;
  onClick?: () => void;
}

const Chip = (props: IProps): React.JSX.Element => {
  const { classNames, text, color, onClick } = props;
  const { t } = useTranslation();

  return (
    <button
      className={clsx('chip', [classNames], [color])}
      onClick={e => {
        if (!onClick) {
          return;
        }
        e.stopPropagation();
        onClick();
      }}
    >
      <div className="chip-content">{t(text)}</div>
    </button>
  );
};

export default Chip;
