// CaseRoom.tsx
import React, { useState, useEffect } from 'react';

import TextField from '@mui/material/TextField';
import { FORM_FIELDS_ENUM, type IFormData } from './types'; // Adjust the import path
import { useTranslation } from 'react-i18next';
import type { Control } from 'react-hook-form/dist/types/form';
import { useController } from 'react-hook-form';

interface IProps {
  control: Control<IFormData>;
}

const FONT_SIZE = 10;
const INPUT_WIDTH = 60;

const CaseRoom: React.FC<IProps> = ({ control }) => {
  const { t } = useTranslation();
  const { field } = useController({
    name: FORM_FIELDS_ENUM.ROOM,
    control
  });

  const [roomInputWidth, setRoomInputWidth] = useState(INPUT_WIDTH);

  useEffect(() => {
    if (field.value && field.value.length * FONT_SIZE > INPUT_WIDTH) {
      setRoomInputWidth(field.value.length * FONT_SIZE);
    } else {
      setRoomInputWidth(INPUT_WIDTH);
    }
  }, [field.value]);

  return (
    <div className="case-info-chip">
      <TextField
        className="case-info-chip-input"
        variant="standard"
        {...field}
        InputProps={{
          style: { width: `${roomInputWidth}px`, minWidth: `${INPUT_WIDTH}px` },
          disableUnderline: true
        }}
        placeholder={t('room').toString()}
      />
    </div>
  );
};

export default CaseRoom;
