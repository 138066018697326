import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationPanel from 'app/components/confirmationPanel/ConfirmationPanel';

interface IProps {
  isShowPanel: boolean;
  setIsShowPanel: (isShowPanel: boolean) => void;
  handleConfirm: () => void;
}

const CaseSubmitEmptyPanel = (props: IProps): React.JSX.Element => {
  const { isShowPanel, setIsShowPanel, handleConfirm } = props;
  const { t } = useTranslation();

  const handleSubmit = async (): Promise<void> => {
    await handleClose();
    handleConfirm();
  };

  const handleClose = async (): Promise<void> => {
    setIsShowPanel(false);
  };

  return (
    <ConfirmationPanel
      title={t('caseSubmitEmptyPanelTitle')}
      buttons={[
        {
          classNames: ['delete-case-confirm-button'],
          buttonText: t('caseSubmitEmptyPanelYes'),
          onClick: handleSubmit,
          variant: 'outlined',
          closeOnClick: true,
          key: 'yes'
        },
        {
          buttonText: t('caseSubmitEmptyPanelNo'),
          onClick: handleClose,
          variant: 'secondary',
          closeOnClick: true,
          key: 'no'
        }
      ]}
      isShowPanel={isShowPanel}
      setIsShowPanel={isTrue => {
        if (isTrue) {
          return;
        }
        void handleClose();
      }}
    />
  );
};

export default CaseSubmitEmptyPanel;
