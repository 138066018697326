import React, { useEffect, useState } from 'react';
import type { ICase } from 'app/mobxStore/types';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import variables from 'app/utils/variables.module.scss';
import 'app/components/orBoard/caseListItem/CaseListItem.scss';

import MoreIcon from 'app/assets/icons/More_icon';
import TrashIcon from 'app/assets/icons/Trash_icon';
import PlusIcon from 'app/assets/icons/Plus_icon';
import MinusIcon from 'app/assets/icons/Minus_icon';
import CancelIcon from 'app/assets/icons/Cancel_icon';
import PostponeIcon from 'app/assets/icons/Postpone_icon';
import useFollow from '../../../../../utils/form/useFollow';
import clsx from 'clsx';
import { getUserRoleInCase } from '../../../../../utils/form/shared';
import { useRootStore } from '../../../../mobxStore';
import { USER_CASE_ROLES } from '../../../../consts';
import CaseActionPanels, {
  ActionPanelType
} from 'app/components/case/caseActions/CaseActionPanels';
import PostponeDatePicker from 'app/components/case/caseActions/PostponeDatePicker';

const CustomMenu = styled(Menu)({
  li: {
    minHeight: 44,
    paddingRight: 50,
    fontFamily: 'Inter',
    fontWeight: 400,
    '.svg-icon': {
      height: 15,
      width: 15,
      marginRight: 12
    },
    '.item-text': {
      fontSize: 14,
      lineHeight: '14px'
    }
  }
}) as typeof Menu;

interface IProps {
  case: ICase;
  dateToPostponeCase?: Date;
  setDateToPostponeCase: (date: Date) => void;
}

const CaseListItemMenu = (props: IProps): React.JSX.Element => {
  const { liteNonSurgeon, userStore } = useRootStore();
  const { case: kase, dateToPostponeCase, setDateToPostponeCase } = props;
  const { t } = useTranslation();

  const { toggleFollow, isFollowed } = useFollow({ case: props.case });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleDeleteClick = (event: React.MouseEvent<HTMLLIElement>): void => {
    event.stopPropagation();
    handleCaseAction(ActionPanelType.DELETE, kase);
    setAnchorEl(null);
  };

  const handleFollowClick = (event: React.MouseEvent<HTMLLIElement>): void => {
    event.stopPropagation();
    void toggleFollow();
    setAnchorEl(null);
  };

  const handlePostponeClick = (event: React.MouseEvent<HTMLLIElement>): void => {
    event.stopPropagation();
    handleCaseAction(ActionPanelType.POSTPONE, kase);
    setAnchorEl(null);
  };

  const handleCancelClick = (event: React.MouseEvent<HTMLLIElement>): void => {
    event.stopPropagation();
    handleCaseAction(ActionPanelType.CANCEL, kase);
    setAnchorEl(null);
  };

  const handleClose = (event: React.MouseEvent<HTMLLIElement>): void => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const [caseToApplyAction, setCaseToApplyAction] = useState<ICase | null>(null);
  const [isPostponeDatePickerOpen, setIsPostponeDatePickerOpen] = useState(false);
  const [actionPanelToDisplay, setActionPanelToDisplay] = useState('');

  const handleCaseAction = (action: string, kase: ICase): void => {
    setCaseToApplyAction(kase);
    if (action === ActionPanelType.POSTPONE) {
      setIsPostponeDatePickerOpen(true);
      return;
    }
    setActionPanelToDisplay(action);
  };

  const handlePostponeDateSelected = (date: Date): void => {
    setDateToPostponeCase(date);
    setActionPanelToDisplay(ActionPanelType.POSTPONE);
  };

  // trigger postpone action when new date is selected
  useEffect(() => {
    if (dateToPostponeCase) {
      if (kase.data.caseDate.getTime() === dateToPostponeCase.getTime()) {
        return;
      }
      setCaseToApplyAction(kase);
      setDateToPostponeCase(dateToPostponeCase);
      setActionPanelToDisplay(ActionPanelType.POSTPONE);
    }
  }, [dateToPostponeCase]);

  const userRoleInCase = getUserRoleInCase(kase.data, userStore.loggedInUser.data);
  const showFollow = userRoleInCase !== USER_CASE_ROLES.ATTENDING;

  return (
    <div className="case-list-item-menu">
      <IconButton className={clsx(['menu-button', 'open-case-menu-button'])} onClick={handleClick}>
        <MoreIcon fill={variables.black} />
      </IconButton>
      <CustomMenu
        className="case-list-item-menu-panel"
        anchorEl={anchorEl}
        style={{ transform: 'translateX(-6px)' }}
        open={open}
        onClose={handleClose}
      >
        {!liteNonSurgeon && (
          <MenuItem className="cancel-case-button" onClick={handleCancelClick}>
            <CancelIcon />
            <div className="item-text">{t('caseCancelled')}</div>
          </MenuItem>
        )}
        {!liteNonSurgeon && (
          <MenuItem className="postpone-case-button" onClick={handlePostponeClick}>
            <PostponeIcon />
            <div className="item-text">{t('casePostponed')}</div>
          </MenuItem>
        )}
        {showFollow && (
          <MenuItem className="toggle-follow-button" onClick={handleFollowClick}>
            {isFollowed ? <MinusIcon /> : <PlusIcon />}
            <div className="item-text">{isFollowed ? t('leave') : t('join')}</div>
          </MenuItem>
        )}
        {!liteNonSurgeon && (
          <MenuItem className="delete-case-button" onClick={handleDeleteClick}>
            <TrashIcon fill={variables.black} />
            <div className="item-text">{t('delete')}</div>
          </MenuItem>
        )}
      </CustomMenu>
      <CaseActionPanels
        caseToApplyAction={caseToApplyAction}
        setCaseToApplyAction={setCaseToApplyAction}
        actionPanelToDisplay={actionPanelToDisplay}
        setActionPanelToDisplay={setActionPanelToDisplay}
        dateToPostponeCase={dateToPostponeCase}
      />
      {caseToApplyAction && (
        <PostponeDatePicker
          isPostponeDatePickerOpen={isPostponeDatePickerOpen}
          setIsPostponeDatePickerOpen={setIsPostponeDatePickerOpen}
          selectPostponeDate={handlePostponeDateSelected}
          caseToApplyAction={caseToApplyAction}
          defaultDate={new Date(kase.data.caseDate)}
        />
      )}
    </div>
  );
};

export default CaseListItemMenu;
