import * as React from 'react';
const SvgComponent = props => (
  <svg
    className="svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1200 1200"
    fill={props.fill || 'none'}
    {...props}
  >
    <path
      d="m600 990.01c-7.957 0.007813-15.594-3.1523-21.211-8.7891l-480-480c-7.4062-7.6133-10.234-18.574-7.4375-28.82 2.793-10.242 10.797-18.246 21.043-21.043 10.246-2.7969 21.203 0.035156 28.816 7.4375l480 480c5.625 5.6289 8.7852 13.258 8.7852 21.215 0 7.9531-3.1602 15.586-8.7852 21.211s-13.254 8.7852-21.211 8.7891z"
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m600 990.01c-7.957-0.003906-15.586-3.1641-21.211-8.7891s-8.7852-13.258-8.7852-21.211c0-7.957 3.1602-15.586 8.7852-21.215l480-480c7.6133-7.4023 18.57-10.234 28.816-7.4375 10.246 2.7969 18.25 10.801 21.043 21.043 2.7969 10.246-0.03125 21.207-7.4375 28.82l-480 480c-5.6172 5.6367-13.254 8.7969-21.211 8.7891z"
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m599.98 990.01c-10.395-0.015625-20.039-5.4023-25.496-14.246s-5.9531-19.883-1.3086-29.18l240-480c3.5-7.207 9.7461-12.719 17.336-15.297 7.5898-2.5742 15.895-2.0078 23.066 1.5781 7.168 3.5859 12.605 9.8906 15.098 17.512 2.4883 7.6172 1.8242 15.918-1.8438 23.047l-240 480c-2.4922 4.9844-6.3242 9.1797-11.066 12.109-4.7461 2.9297-10.211 4.4805-15.785 4.4766z"
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m600.02 990.01c-5.5742 0.003906-11.039-1.5469-15.785-4.4766-4.7422-2.9297-8.5742-7.125-11.066-12.109l-240-480c-4.6484-9.5664-3.8828-20.879 2.0078-29.734 5.8945-8.8516 16.035-13.926 26.652-13.328 10.621 0.59375 20.133 6.7656 24.996 16.223l240 480c4.6445 9.2969 4.1484 20.336-1.3086 29.18s-15.102 14.23-25.496 14.246z"
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m120 510.01c-7.957-0.003906-15.586-3.1641-21.211-8.7891s-8.7852-13.258-8.7852-21.211c0-7.957 3.1602-15.586 8.7852-21.215l240-240c7.6133-7.4023 18.57-10.234 28.816-7.4375 10.246 2.7969 18.25 10.801 21.043 21.043 2.7969 10.246-0.03125 21.207-7.4375 28.82l-240 240c-5.6172 5.6367-13.254 8.7969-21.211 8.7891z"
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m1080 510.01c-7.957 0.007813-15.594-3.1523-21.211-8.7891l-240-240c-7.4062-7.6133-10.234-18.574-7.4375-28.82 2.793-10.242 10.797-18.246 21.043-21.043 10.246-2.7969 21.203 0.035156 28.816 7.4375l240 240c5.625 5.6289 8.7852 13.258 8.7852 21.215 0 7.9531-3.1602 15.586-8.7852 21.211s-13.254 8.7852-21.211 8.7891z"
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m359.98 510.01c-10.395-0.015625-20.039-5.4023-25.496-14.246s-5.9531-19.883-1.3086-29.18l120-240c3.5-7.207 9.7461-12.719 17.336-15.297 7.5898-2.5742 15.895-2.0078 23.066 1.5781 7.168 3.5859 12.605 9.8906 15.098 17.512 2.4883 7.6172 1.8242 15.918-1.8438 23.047l-120 240c-2.4922 4.9844-6.3242 9.1797-11.066 12.109-4.7461 2.9297-10.211 4.4805-15.785 4.4766z"
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m840.02 510.01c-5.5742 0.003906-11.039-1.5469-15.785-4.4766-4.7422-2.9297-8.5742-7.125-11.066-12.109l-120-240c-3.668-7.1289-4.332-15.43-1.8438-23.047 2.4922-7.6211 7.9297-13.926 15.098-17.512 7.1719-3.5859 15.477-4.1523 23.066-1.5781 7.5898 2.5781 13.836 8.0898 17.336 15.297l120 240c4.6445 9.2969 4.1484 20.336-1.3086 29.18s-15.102 14.23-25.496 14.246z"
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m840 270.01h-480c-10.719 0-20.621-5.7188-25.98-15-5.3594-9.2852-5.3594-20.719 0-30 5.3594-9.2852 15.262-15 25.98-15h480c10.719 0 20.621 5.7148 25.98 15 5.3594 9.2812 5.3594 20.715 0 30-5.3594 9.2812-15.262 15-25.98 15z"
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m1080 510.01h-960c-10.719 0-20.621-5.7188-25.98-15-5.3594-9.2852-5.3594-20.719 0-30 5.3594-9.2852 15.262-15 25.98-15h960c10.719 0 20.621 5.7148 25.98 15 5.3594 9.2812 5.3594 20.715 0 30-5.3594 9.2812-15.262 15-25.98 15z"
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgComponent;
