import { useController } from 'react-hook-form';
import React, { useState, type MouseEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import type { Control } from 'react-hook-form/dist/types/form';
import type { ISelectOption } from 'app/mobxStore/types';
import { FORM_FIELDS_ENUM, type IFormData } from './types';
import clsx from 'clsx';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import ModalScreen from 'app/components/modalScreen/ModalScreen';
// import Paper from '@mui/material/Paper';
import Button from 'app/components/buttons/Button';
import variables from 'app/utils/variables.module.scss';
import ArrowLeftIcon from 'app/assets/icons/ArrowLeft_icon';
import ServiceIcon from 'app/assets/icons/Service_icon';
import CloseIcon from 'app/assets/icons/Close_icon';

interface IProps {
  control: Control<IFormData>;
  options: ISelectOption[];
  isReadOnly?: boolean;
}

const ServiceSelect = (props: IProps): React.JSX.Element => {
  const { isReadOnly, control, options } = props;
  const {
    field: { value, ref, onBlur, onChange }
  } = useController({
    rules: { required: true },
    name: FORM_FIELDS_ENUM.SERVICE,
    control
  });
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState('');
  const [isOpen, seIsOpen] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const filterOptions = createFilterOptions({
    stringify: (option: ISelectOption) => option.label
  });

  const handleClose = async (e: MouseEvent): Promise<void> => {
    seIsOpen(false);
    setIsInputFocused(false);
  };
  useEffect(() => {
    if (!isOpen) {
      setInputValue('');
      setIsInputFocused(false);
    }
  }, [isOpen]);
  const getButtonText = (service: ISelectOption | undefined): string => {
    if (service === undefined) {
      return 'service';
    }
    return service.label;
  };

  const content = (
    <div className="modal-content">
      <div className="autocomplete-container">
        <Autocomplete
          ref={ref}
          freeSolo
          onBlur={onBlur}
          open
          disablePortal
          autoHighlight={true}
          onChange={(e, selectedValue) => {
            setInputValue('');
            onChange(selectedValue);
            seIsOpen(false);
          }}
          options={options}
          filterOptions={filterOptions}
          inputValue={inputValue}
          onInputChange={(event, value) => {
            setInputValue(value);
          }}
          ListboxProps={{
            // set list height to screen height - input container plus 16px list padding
            style: {
              maxHeight: 'calc(100vh - 124px)',
              overflow: 'auto'
            }
          }}
          sx={{
            '& input': {
              width: '100%',
              height: 44,
              backgroundColor: variables.grey2,
              borderRadius: 44,
              border: 'none',
              padding: '0 16px',
              boxSizing: 'border-box',
              fontSize: 14,
              color: variables.black,
              '&::placeholder': {
                color: variables.grey5
              },
              '&.focused': {
                outline: `1px solid ${variables.primaryPurple}`
              },
              '&:focus-visible': {
                outline: `1px solid ${variables.primaryPurple}`
              }
            },
            '& .input-container': {
              position: 'relative',
              padding: '32px 24px',
              display: 'flex',
              '& .back-button': {
                flexShrink: 0,
                marginRight: '8px',
                '.svg-icon': {
                  height: 14
                }
              },
              '& .clear-button': {
                height: '42px',
                width: '42px',
                backgroundColor: variables.white,
                position: 'absolute',
                right: 25,
                top: 33
              }
            }
          }}
          // PaperComponent={({ children }) => (
          //   <Paper
          //     style={{
          //       marginTop: 10,
          //       width: '100%',
          //       height: 'auto',
          //       boxShadow: 'none'
          //     }}
          //   >
          //     {children}
          //   </Paper>
          // )}
          renderInput={params => {
            return (
              <div ref={params.InputProps.ref} className="input-container">
                <Button
                  classNames="btn circle-button back-button"
                  startIcon={<ArrowLeftIcon stroke={variables.grey6} height={10} />}
                  onClick={handleClose}
                />
                <input
                  type="text"
                  onClick={() => {
                    setIsInputFocused(true);
                  }}
                  {...params.inputProps}
                  className={clsx('text-input', { focused: isInputFocused })}
                  placeholder={t('searchService').toString()}
                />
                {inputValue !== '' && (
                  <Button
                    classNames="btn circle-button clear-button"
                    startIcon={<CloseIcon height={10} stroke={variables.grey6} />}
                    onClick={async (e: MouseEvent) => {
                      setInputValue('');
                    }}
                  />
                )}
              </div>
            );
          }}
          renderOption={(props, option: ISelectOption) => {
            return (
              <li {...props} className="list-option">
                {option.label}
              </li>
            );
          }}
        />
      </div>
    </div>
  );

  const btnText = getButtonText(value);
  return (
    <div className="service-select">
      <Button
        classNames={clsx('chip-button', { 'has-value': value !== undefined })}
        disabled={isReadOnly}
        buttonText={btnText}
        startIcon={<ServiceIcon width={18} />}
        onClick={async (e: MouseEvent) => {
          seIsOpen(true);
        }}
      />
      <ModalScreen
        isOpen={isOpen}
        classNames={'full-height service-modal'}
        content={content}
        handleClose={() => {
          seIsOpen(false);
        }}
      />
    </div>
  );
};

export default ServiceSelect;
