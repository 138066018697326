import * as React from 'react';
const SvgComponent = props => (
  <svg
    className="svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
    fill={props.fill || 'none'}
    {...props}
  >
    <path
      d="M1 17.5C0.867387 17.5 0.740237 17.4473 0.64642 17.3536C0.55267 17.2598 0.5 17.1326 0.5 17V13C0.5 12.8213 0.595313 12.6563 0.75 12.567C0.904687 12.4777 1.09532 12.4777 1.25 12.567C1.40468 12.6563 1.5 12.8214 1.5 13V17C1.5 17.1326 1.44733 17.2598 1.35358 17.3536C1.25977 17.4473 1.13262 17.5 1 17.5Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M4.99999 17.5H0.99999C0.82134 17.5 0.656307 17.4047 0.56699 17.25C0.477673 17.0953 0.477667 16.9047 0.56699 16.75C0.656313 16.5953 0.821357 16.5 0.99999 16.5H4.99999C5.17864 16.5 5.34367 16.5953 5.43299 16.75C5.52231 16.9047 5.52231 17.0953 5.43299 17.25C5.34367 17.4047 5.17862 17.5 4.99999 17.5Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M4.99994 17.5C4.86732 17.5 4.74017 17.4473 4.64642 17.3535C4.55267 17.2598 4.5 17.1326 4.5 17C4.5 16.8674 4.55267 16.7403 4.64642 16.6465L16.6464 4.6465C16.7733 4.52306 16.9559 4.47593 17.1267 4.52254C17.2975 4.56909 17.4309 4.70249 17.4774 4.87326C17.524 5.04402 17.4769 5.22664 17.3534 5.35352L5.35344 17.3535C5.25982 17.4475 5.13255 17.5001 4.99994 17.5Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M17 5.50001C16.8674 5.50014 16.7401 5.44747 16.6465 5.35353L12.6465 1.35353C12.5231 1.22664 12.4759 1.04403 12.5225 0.873261C12.5691 0.702494 12.7025 0.569094 12.8733 0.522544C13.044 0.475929 13.2266 0.523065 13.3535 0.646503L17.3535 4.6465C17.4473 4.74025 17.4999 4.8674 17.4999 5.00002C17.4999 5.13264 17.4473 5.25979 17.3535 5.35354C17.2598 5.44729 17.1326 5.50001 17 5.50001Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M0.999937 13.5C0.86732 13.5 0.74017 13.4473 0.64642 13.3535C0.55267 13.2598 0.5 13.1326 0.5 13C0.5 12.8674 0.55267 12.7402 0.64642 12.6465L12.6464 0.6465C12.7733 0.523063 12.9559 0.475933 13.1267 0.522541C13.2975 0.569091 13.4309 0.702491 13.4774 0.873258C13.524 1.04402 13.4769 1.22664 13.3534 1.35352L1.35344 13.3535C1.25982 13.4475 1.13255 13.5001 0.999937 13.5Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M15 7.50001C14.8674 7.50014 14.7401 7.44747 14.6465 7.35353L10.6465 3.35353C10.5231 3.22664 10.4759 3.04403 10.5225 2.87326C10.5691 2.70249 10.7025 2.56909 10.8733 2.52254C11.044 2.47593 11.2266 2.52307 11.3535 2.6465L15.3535 6.6465C15.4473 6.74025 15.4999 6.8674 15.4999 7.00002C15.4999 7.13264 15.4473 7.25979 15.3535 7.35354C15.2598 7.44729 15.1326 7.50001 15 7.50001Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M4.99995 17.5001C4.86733 17.5002 4.74005 17.4475 4.64643 17.3536L0.646432 13.3536C0.550534 13.2603 0.496042 13.1324 0.49513 12.9986C0.494154 12.8649 0.546954 12.7363 0.641551 12.6417C0.736147 12.5471 0.864734 12.4943 0.998517 12.4953C1.13231 12.4962 1.26017 12.5507 1.35347 12.6466L5.35347 16.6466C5.44722 16.7403 5.49989 16.8675 5.49989 17.0001C5.49989 17.1327 5.44722 17.2598 5.35347 17.3536C5.25972 17.4473 5.13257 17.5001 4.99995 17.5001Z"
      fill={props.fill || '#B33B94'}
    />
  </svg>
);
export default SvgComponent;
