import * as React from 'react';
const SvgComponent = props => (
  <svg
    className="svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fill={props.fill || 'none'}
    {...props}
  >
    <path
      d="M5.08787 6.94241L11.2743 3.88054M5.08787 9.05736L11.2743 12.1192M4.92165 8C4.92165 9.11252 4.04376 10.0144 2.96083 10.0144C1.87789 10.0144 1 9.11252 1 8C1 6.88748 1.87789 5.98561 2.96083 5.98561C4.04376 5.98561 4.92165 6.88748 4.92165 8ZM15 3.01439C15 4.1269 14.1221 5.02878 13.0392 5.02878C11.9562 5.02878 11.0783 4.1269 11.0783 3.01439C11.0783 1.90187 11.9562 1 13.0392 1C14.1221 1 15 1.90187 15 3.01439ZM15 12.9856C15 14.0981 14.1221 15 13.0392 15C11.9562 15 11.0783 14.0981 11.0783 12.9856C11.0783 11.8731 11.9562 10.9712 13.0392 10.9712C14.1221 10.9712 15 11.8731 15 12.9856Z"
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgComponent;
