import * as React from 'react';
const SvgComponent = props => (
  <svg
    className="svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 13"
    fill={props.fill || 'none'}
    {...props}
  >
    <path
      d="M2.50025 11.0021C2.36763 11.0021 2.24048 10.9494 2.14666 10.8556C2.05291 10.7618 2.00024 10.6347 2.00024 10.5021V4.50206C2.00024 4.32341 2.09556 4.15838 2.25024 4.06907C2.40493 3.97975 2.59556 3.97974 2.75024 4.06907C2.90493 4.15839 3.00024 4.32343 3.00024 4.50206V10.5021C3.00024 10.6347 2.94757 10.7618 2.85382 10.8556C2.76001 10.9494 2.63286 11.0021 2.50025 11.0021Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M6.50025 11.0021C6.36763 11.0021 6.24048 10.9494 6.14666 10.8556C6.05291 10.7618 6.00024 10.6347 6.00024 10.5021V4.50206C6.00024 4.32341 6.09556 4.15838 6.25024 4.06907C6.40493 3.97975 6.59556 3.97974 6.75024 4.06907C6.90493 4.15839 7.00024 4.32343 7.00024 4.50206V10.5021C7.00024 10.6347 6.94757 10.7618 6.85382 10.8556C6.76001 10.9494 6.63286 11.0021 6.50025 11.0021Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M10.5002 11.0021C10.3676 11.0021 10.2405 10.9494 10.1467 10.8556C10.0529 10.7618 10.0002 10.6347 10.0002 10.5021V4.50206C10.0002 4.32341 10.0956 4.15838 10.2502 4.06907C10.4049 3.97975 10.5956 3.97974 10.7502 4.06907C10.9049 4.15839 11.0002 4.32343 11.0002 4.50206V10.5021C11.0002 10.6347 10.9476 10.7618 10.8538 10.8556C10.76 10.9494 10.6329 11.0021 10.5002 11.0021Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M16.5002 5.00208H0.500234C0.321584 5.00208 0.156551 4.90676 0.0672341 4.75208C-0.0220825 4.59739 -0.0220892 4.40676 0.0672341 4.25208C0.156557 4.09739 0.321601 4.00208 0.500234 4.00208H16.5002C16.6789 4.00208 16.8439 4.09739 16.9332 4.25208C17.0226 4.40676 17.0226 4.59739 16.9332 4.75208C16.8439 4.90676 16.6789 5.00208 16.5002 5.00208Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M16.5001 5.00228C16.4226 5.00228 16.3461 4.98418 16.2769 4.94935L8.27685 0.949348C8.15693 0.89082 8.06534 0.786783 8.02249 0.660415C7.97971 0.533983 7.98922 0.395698 8.04892 0.276365C8.10862 0.156963 8.2135 0.066465 8.34032 0.024865C8.46714 -0.0166717 8.60535 -0.00586515 8.7241 0.0550082L16.7241 4.05501C16.8595 4.12272 16.957 4.24804 16.9891 4.39596C17.0214 4.54387 16.9848 4.69837 16.8898 4.81621C16.7947 4.93398 16.6514 5.00241 16.5001 5.00227L16.5001 5.00228Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M0.500492 5.00227C0.349125 5.00241 0.205825 4.93398 0.110842 4.81621C0.0157902 4.69837 -0.0207331 4.54387 0.0114286 4.39596C0.0436553 4.24804 0.141115 4.12271 0.276529 4.05501L8.27653 0.0550077C8.39528 -0.00586397 8.53343 -0.0166722 8.66025 0.0248645C8.78707 0.0664661 8.89201 0.156961 8.95171 0.276364C9.01141 0.395701 9.02092 0.533981 8.97808 0.660414C8.93531 0.786781 8.8437 0.890815 8.72378 0.949348L0.723779 4.94935C0.654442 4.98418 0.578024 5.00227 0.500492 5.00227Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M16.5002 11.0021H0.500234C0.321584 11.0021 0.156551 10.9068 0.0672341 10.7521C-0.0220825 10.5974 -0.0220892 10.4068 0.0672341 10.2521C0.156557 10.0974 0.321601 10.0021 0.500234 10.0021H16.5002C16.6789 10.0021 16.8439 10.0974 16.9332 10.2521C17.0226 10.4068 17.0226 10.5974 16.9332 10.7521C16.8439 10.9068 16.6789 11.0021 16.5002 11.0021Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M16.5002 13.0021H0.500234C0.321584 13.0021 0.156551 12.9068 0.0672341 12.7521C-0.0220825 12.5974 -0.0220892 12.4068 0.0672341 12.2521C0.156557 12.0974 0.321601 12.0021 0.500234 12.0021H16.5002C16.6789 12.0021 16.8439 12.0974 16.9332 12.2521C17.0226 12.4068 17.0226 12.5974 16.9332 12.7521C16.8439 12.9068 16.6789 13.0021 16.5002 13.0021Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M0.500247 13.0021C0.367631 13.0021 0.240481 12.9494 0.146664 12.8556C0.0529142 12.7618 0.000244141 12.6347 0.000244141 12.5021V10.5021C0.000244141 10.3234 0.0955575 10.1584 0.250244 10.0691C0.404931 9.97975 0.595561 9.97974 0.750244 10.0691C0.904928 10.1584 1.00024 10.3234 1.00024 10.5021V12.5021C1.00024 12.6347 0.947574 12.7618 0.853824 12.8556C0.760009 12.9494 0.632864 13.0021 0.500247 13.0021Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M16.5002 13.0021C16.3676 13.0021 16.2405 12.9494 16.1467 12.8556C16.0529 12.7618 16.0002 12.6347 16.0002 12.5021V10.5021C16.0002 10.3234 16.0956 10.1584 16.2502 10.0691C16.4049 9.97975 16.5956 9.97974 16.7502 10.0691C16.9049 10.1584 17.0002 10.3234 17.0002 10.5021V12.5021C17.0002 12.6347 16.9476 12.7618 16.8538 12.8556C16.76 12.9494 16.6329 13.0021 16.5002 13.0021Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M14.3657 11.0021C14.2331 11.0021 14.106 10.9494 14.0121 10.8556C13.9184 10.7618 13.8657 10.6347 13.8657 10.5021V4.50206C13.8657 4.32341 13.961 4.15838 14.1157 4.06907C14.2704 3.97975 14.461 3.97974 14.6157 4.06907C14.7704 4.15839 14.8657 4.32343 14.8657 4.50206V10.5021C14.8657 10.6347 14.8131 10.7618 14.7193 10.8556C14.6255 10.9494 14.4983 11.0021 14.3657 11.0021Z"
      fill={props.fill || '#B33B94'}
    />
  </svg>
);
export default SvgComponent;
