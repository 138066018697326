import * as React from 'react';
const SvgComponent = props => (
  <svg
    className="svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill={props.fill || 'none'}
    {...props}
  >
    <path
      d="M7.91667 8.33333C9.5275 8.33333 10.8333 7.0275 10.8333 5.41667C10.8333 3.80584 9.5275 2.5 7.91667 2.5C6.30583 2.5 5 3.80584 5 5.41667C5 7.0275 6.30583 8.33333 7.91667 8.33333Z"
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.66675 17V17.5H14.1667V17C14.1667 15.1331 14.1667 14.1997 13.8035 13.4867C13.4839 12.8595 12.9739 12.3495 12.3467 12.0299C11.6337 11.6666 10.7002 11.6666 8.83341 11.6666H7.00008C5.13325 11.6666 4.19983 11.6666 3.48678 12.0299C2.85957 12.3495 2.34964 12.8595 2.03006 13.4867C1.66675 14.1997 1.66675 15.1331 1.66675 17Z"
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M15.8333 5.41663V10.4166" stroke={props.stroke || '#B33B94'} />
    <path d="M13.3333 7.91663H18.3333" stroke={props.stroke || '#B33B94'} />
    <path
      d="M15.8333 5.41663V10.4166M13.3333 7.91663H18.3333"
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgComponent;
