import React, { type SyntheticEvent } from 'react';
import Rating from '@mui/material/Rating';
import HeartFullIcon from 'app/assets/icons/HeartFull_icon';
import HeartEmptyIcon from 'app/assets/icons/HeartEmpty_icon';

import './Feedback.scss';
import { observer } from 'mobx-react-lite';
import type { Control } from 'react-hook-form/dist/types/form';
import { FEEDBACK_FORM_FIELDS_ENUM, type IFeedbackFormData } from '../fields/types';
import { useController } from 'react-hook-form';

interface IProps {
  control: Control<IFeedbackFormData>;
}

const RatingEditable = (props: IProps): React.JSX.Element => {
  const { field } = useController({
    name: FEEDBACK_FORM_FIELDS_ENUM.RATING,
    control: props.control
  });

  const [rating, setRating] = React.useState<number | null>(field.value);

  const handleChange = (event: SyntheticEvent<Element, Event>, newValue: number | null): void => {
    const valToSet = rating === newValue ? 0 : newValue;
    setRating(valToSet);
    field.onChange(valToSet);
  };

  return (
    <Rating
      className="rating"
      max={5}
      icon={<HeartFullIcon />}
      emptyIcon={<HeartEmptyIcon />}
      sx={{
        '& .MuiRating-icon': {
          display: 'flex',
          justifyContent: 'center'
        }
      }}
      {...field}
      onChange={handleChange}
      value={rating}
    />
  );
};

export default observer(RatingEditable);
