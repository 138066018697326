import React, { useEffect, useState } from 'react';
import type { ICase, ICaseFollowerData } from 'app/mobxStore/types';
import { ROLES } from 'app/consts';
import { Trans } from 'react-i18next';
import UserAvatarGroup from 'app/components/userAvatarGroup/UserAvatarGroup';
import TooltipWrapper from 'app/components/tooltipWrapper/TooltipWrapper';
import EyeIcon from 'app/assets/icons/Eye_icon';

import { observer } from 'mobx-react-lite';
import { parseUserToSelectOption } from 'app/mobxStore/MetaDataStore';
import { useRootStore } from 'app/mobxStore';
import { getClickedviewersTooltip, setClickedViewersTooltip } from 'app/mobxStore/storage';

interface IProps {
  kase: ICase;
}

const CaseListItemsViewerGroup = (props: IProps): React.JSX.Element => {
  const {
    kase: { data: caseData }
  } = props;
  const { caseStore } = useRootStore();
  const [isShowViewersTooltip, setIsShowViewersTooltip] = useState(false);

  useEffect(() => {
    if (getClickedviewersTooltip()) {
      return;
    }
    const firstCaseWithViewers = caseStore.sortedCases.find(
      c => c.data.caseFollowers.length > 0 && c.data.caseFollowers.some(f => f.lastSeen)
    );
    const shouldShowViewersTooltip =
      firstCaseWithViewers && firstCaseWithViewers.id === caseData.id;
    if (shouldShowViewersTooltip) {
      setIsShowViewersTooltip(true);
    }
  }, []);

  const getCaseViewers = (): ICaseFollowerData[] => {
    const viewers = caseData.caseFollowers.filter(follower => {
      return follower.lastSeen;
    });

    const getIsCaseSurgeon = (follower: ICaseFollowerData): boolean => {
      return follower.user.id === caseData.attending?.id;
    };

    viewers.sort((a, b) => {
      // sort by case attending
      if (getIsCaseSurgeon(a)) {
        return -1;
      }
      if (getIsCaseSurgeon(b)) {
        return 1;
      }

      // sort by has role attending
      const aIsAtt = a.user.roles.find(role => role.role === ROLES.ATTENDING) !== undefined;
      const bIsAtt = b.user.roles.find(role => role.role === ROLES.ATTENDING) !== undefined;
      if (aIsAtt && !bIsAtt) {
        return -1;
      }
      if (!aIsAtt && bIsAtt) {
        return 1;
      }
      if (aIsAtt && bIsAtt) {
        return a.user.nickName.localeCompare(b.user.nickName);
      }

      // sort by is nurse leader
      if (a.user.isNurseLeader && !b.user.isNurseLeader) {
        return -1;
      }
      if (!a.user.isNurseLeader && b.user.isNurseLeader) {
        return 1;
      }
      return a.user.nickName.localeCompare(b.user.nickName);
    });

    return viewers;
  };

  const caseViewers = getCaseViewers();

  const handleCloseTooltip = (): void => {
    setIsShowViewersTooltip(false);
  };

  if (caseViewers.length === 0) {
    return <></>;
  }

  const viewersGroup = (
    <div className="viewers-group-container">
      <EyeIcon className="eye-icon" />
      <UserAvatarGroup
        users={caseViewers.map(f => parseUserToSelectOption(f.user))}
        maxUsers={8}
        size="medium"
      />
    </div>
  );

  if (!isShowViewersTooltip) {
    return viewersGroup;
  }

  return (
    <TooltipWrapper
      open={true}
      name={'viewers-tooltip'}
      handleCloseTooltip={handleCloseTooltip}
      handleSetTooltipSeen={() => {
        setClickedViewersTooltip(true);
      }}
      comp={viewersGroup}
      offset={[0, -4]}
      isCloseOnClickAway={true}
      tooltipContent={
        <Trans
          i18nKey="viewersTooltipText"
          components={{
            bold: <span style={{ fontWeight: 600 }} />
          }}
        />
      }
    />
  );
};

export default observer(CaseListItemsViewerGroup);
