import React from 'react';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import variables from 'app/utils/variables.module.scss';
import './Spinner.scss';

interface IProps {
  color?: string;
  barColor?: string;
  size?: number;
}

const Spinner = (props: IProps): React.JSX.Element => {
  const { barColor, size, color } = props;

  return (
    <div className="spinner-container">
      <CircularProgress
        variant="determinate"
        value={100}
        size={size ?? 60}
        sx={{
          color: barColor ?? variables.yellow2
        }}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        size={size ?? 60}
        sx={{
          color: color ?? variables.primaryPurple,
          position: 'absolute',
          top: 0,
          left: 0,
          animationDuration: '800ms',
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
            strokeDasharray: '30px, 200px'
          }
        }}
      />
    </div>
  );
};

export default Spinner;
