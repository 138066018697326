import React from 'react';
import { authRoles } from 'app/auth';
import WhatsNew from 'app/pages/whatsNew/WhatsNew';
import FuseAuthorization from '@fuse/core/FuseAuthorization';
import { MetaData } from 'app/metaData';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from 'app/pages/errorPage/ErrorPage';
import { Navigate } from 'react-router-dom';
const WhatsNewConfig = {
  settings: {
    layout: {
      config: {
        footer: {
          display: true
        }
      }
    }
  },
  auth: authRoles.notGuest,
  routes: [
    {
      path: '/whatsnew/*',
      element: (
        <ErrorBoundary fallback={<ErrorPage />}>
          <FuseAuthorization>
            <MetaData>
              <WhatsNew />
            </MetaData>
          </FuseAuthorization>
        </ErrorBoundary>
      )
    },
    {
      path: '/reloaded_build/*',
      element: <Navigate to="/whatsnew" replace />
    }
  ]
};

export default WhatsNewConfig;
