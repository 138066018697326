import React from 'react';
import { observer } from 'mobx-react-lite';
import type { ICase } from 'app/mobxStore/types';
import CaseDeletePanel from './CaseDeletePanel';
import CasePostponePanel from './CasePostponePanel';
import CaseCancelPanel from './CaseCancelPanel';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { SERVER_FORM_FIELDS_ENUM } from '../../fields/types';

export enum ActionPanelType {
  DELETE = 'delete',
  POSTPONE = 'postpone',
  CANCEL = 'cancel'
}

interface IProps {
  caseToApplyAction: ICase | null;
  setCaseToApplyAction: (kase: ICase | null) => void;
  actionPanelToDisplay: string;
  setActionPanelToDisplay: (actionPanelToDisplay: string) => void;
  dateToPostponeCase: Date | undefined;
}

const CaseActionPanels = (props: IProps): React.JSX.Element => {
  const {
    caseToApplyAction,
    setCaseToApplyAction,
    actionPanelToDisplay,
    setActionPanelToDisplay,
    dateToPostponeCase
  } = props;

  const handlePanelConfirm = async (type: string): Promise<void> => {
    if (caseToApplyAction === null) {
      return;
    }

    const onDelete = async (): Promise<void> => {
      await caseToApplyAction.deleteCase();
      caseToApplyAction.store.setIsHuddleDialogOpen(false);
      toast.success(t('case_deleted'));
      await caseToApplyAction.store.loadAllCases(true);
    };

    const onCancel = async (): Promise<void> => {
      await caseToApplyAction.cancelCase();
      caseToApplyAction.store.setIsHuddleDialogOpen(false);
      toast.success(t('case_canceled'));
      await caseToApplyAction.store.loadAllCases(true);
    };

    const onPostpone = async (): Promise<void> => {
      if (!dateToPostponeCase) {
        throw new Error('Date to postpone case is not set');
      }

      await caseToApplyAction.updateCaseMetaDataToServer({
        [SERVER_FORM_FIELDS_ENUM.DATE]: dateToPostponeCase
      });
      caseToApplyAction.setDate(dateToPostponeCase);
      toast.success(t('case_postponed'));
      await caseToApplyAction.store.loadAllCases(true);
    };

    switch (type) {
      case ActionPanelType.DELETE:
        await onDelete();
        break;
      case ActionPanelType.POSTPONE:
        await onPostpone();
        break;
      case ActionPanelType.CANCEL:
        await onCancel();
        break;
    }
    setCaseToApplyAction(null);
  };

  return (
    <div
      onClick={e => {
        // stop click propagation to parent elements (opening the case when closing the panel)
        e.stopPropagation();
      }}
    >
      <CaseDeletePanel
        isShowPanel={actionPanelToDisplay === ActionPanelType.DELETE}
        setIsShowPanel={setActionPanelToDisplay}
        handleDeleteConfirm={async () => {
          await handlePanelConfirm(ActionPanelType.DELETE);
        }}
      />
      {caseToApplyAction && dateToPostponeCase && (
        <CasePostponePanel
          isShowPanel={actionPanelToDisplay === ActionPanelType.POSTPONE}
          setIsShowPanel={setActionPanelToDisplay}
          handlePostponeConfirm={async () => {
            await handlePanelConfirm(ActionPanelType.POSTPONE);
          }}
          origCaseDate={caseToApplyAction.data.caseDate}
          dateToPostponeCase={dateToPostponeCase}
        />
      )}
      {caseToApplyAction && (
        <CaseCancelPanel
          isShowPanel={actionPanelToDisplay === ActionPanelType.CANCEL}
          setIsShowPanel={setActionPanelToDisplay}
          handleCancelConfirm={async () => {
            await handlePanelConfirm(ActionPanelType.CANCEL);
          }}
          caseDate={caseToApplyAction.data.caseDate}
        />
      )}
    </div>
  );
};

export default observer(CaseActionPanels);
