import React, { type MouseEvent, useRef, useState } from 'react';
import clsx from 'clsx';
import Button from './Button';

interface IProps {
  id: string;
  isDisabled?: boolean;
  title?: string;
  content?: React.JSX.Element;
  submitFunction: () => Promise<void>;
  classNames?: string;
  withTransition?: boolean;
}
const SubmitButton = (props: IProps): React.JSX.Element => {
  const { isDisabled, title, content, submitFunction, classNames, withTransition } = props;
  const isSubmitting = useRef(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const handleTransition = (state: string): void => {
    if (withTransition === true) {
      if (state === 'out') {
        setIsTransitioning(true);
        return;
      }
      setTimeout(() => {
        setIsTransitioning(false);
      }, 180);
    }
  };

  const handleSubmit = async (e: MouseEvent): Promise<void> => {
    handleTransition('out');
    e.preventDefault();
    if (isSubmitting.current) {
      handleTransition('in');
      return;
    }
    isSubmitting.current = true;
    setSubmitDisabled(true);
    await submitFunction();
    isSubmitting.current = false;
    setSubmitDisabled(false);
    handleTransition('in');
  };

  const disabled = isDisabled === true || submitDisabled;
  return (
    <Button
      id={props.id}
      classNames={clsx([classNames, { isTransitioning }, 'primary'])}
      buttonText={title}
      buttonContent={content}
      onClick={handleSubmit}
      disabled={disabled}
    />
  );
};

export default SubmitButton;
