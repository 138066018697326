import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useAuth0 } from '@auth0/auth0-react';
import { getLogoutClicked, setLogoutClicked } from '../../mobxStore/storage';
import AmplService from '../../services/amplService';
import urlParams from '../../services/urlParams';
import { useLocation } from 'react-router-dom';

const LOGIN_PROMPT = 'login';

const UNLogin = (): React.JSX.Element => {
  const { loginWithRedirect } = useAuth0();
  const { search } = useLocation();
  useEffect(() => {
    const redirectToParam = urlParams.get('redirectTo') ?? '';
    const redirectTo = redirectToParam.toString();
    if (redirectTo && redirectTo !== '/logout' && redirectTo.startsWith('/')) {
      localStorage.setItem('redirectTo', redirectTo.toString());
      const searchParams = new URLSearchParams(search);
      searchParams.delete('redirectTo');
      const redirectToSearch = searchParams.toString();
      localStorage.setItem('redirectToSearch', redirectToSearch);
    } else {
      localStorage.removeItem('redirectTo');
      localStorage.removeItem('redirectToSearch');
    }
    const isLogoutClicked = getLogoutClicked();
    AmplService.sendEvent(AmplService.EVENTS.LOGIN_START);

    if (isLogoutClicked) {
      setLogoutClicked(false);
      void loginWithRedirect({
        authorizationParams: {
          prompt: LOGIN_PROMPT
        }
      });
      return;
    }

    void loginWithRedirect({});
  }, []);
  return <div style={{ backgroundColor: '#fff' }}></div>;
};

export default observer(UNLogin);
