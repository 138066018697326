import { FIELD_TYPE, type IDynamicField, type IDynamicFieldValue } from 'app/mobxStore/types';
import type { FunctionComponent } from 'react';
import TextField from 'app/components/dfields/TextField';
import DynamicSelect from 'app/components/dfields/DynamicSelect';
import Chips from 'app/components/dfields/Chips';
import Checkbox from 'app/components/dfields/Checkbox';
import DynamicRadioGroup from 'app/components/dfields/DynamicRadioGroup';
import Info from 'app/components/dfields/InfoBox';
import TextDisplayWidget from 'app/components/dfields/TextDisplayWidget';
import ChipsDisplayWidget from 'app/components/dfields/ChipsDisplayWidget';
import { OTHER_OPTION } from 'utils/form/shared';

const inputs = {
  text: TextField,
  select: DynamicSelect,
  chips: Chips,
  checkbox: Checkbox,
  radio: DynamicRadioGroup,
  info: Info
};

const displayWidgets = {
  text: TextDisplayWidget,
  chips: ChipsDisplayWidget
};
export const getTagName = (field: IDynamicField): FunctionComponent<any> => {
  return field.editMode ? getEditableTagName(field) : getReadOnlyTagName(field);
};
const getEditableSelectTagName = (field: IDynamicField): FunctionComponent<any> => {
  if (field.multiple && field.options.length > 0) {
    // Multi-select is Chips
    return inputs.chips;
  }
  // if (!field.multiple && field.options.length > 0 && field.options.length < 4) {
  //   // Single-select with 3 or less options is a Radio button group
  //   return inputs.radio;
  // }
  return inputs[field.type];
};

const getReadOnlySelectTagName = (field: IDynamicField): FunctionComponent<any> => {
  return displayWidgets.chips;
};

const getEditableTagName = (field: IDynamicField): FunctionComponent<any> => {
  return field.type === FIELD_TYPE.SELECT ? getEditableSelectTagName(field) : inputs[field.type];
};

const getReadOnlyTagName = (field: IDynamicField): FunctionComponent<any> => {
  if (field.type === FIELD_TYPE.SELECT) {
    return getReadOnlySelectTagName(field);
  }
  if (field.type === FIELD_TYPE.TEXT) {
    return displayWidgets.text;
  }
  return inputs[field.type];
};

export const isOtherSelected = (field: IDynamicField, fieldValue: IDynamicFieldValue): boolean => {
  if (field.type !== FIELD_TYPE.SELECT) {
    return false;
  }
  if (!field.hasOther) {
    return false;
  }
  return fieldValue.some(v => v === OTHER_OPTION);
};
