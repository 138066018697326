import type { IRoleData } from '../mobxStore/types';
import ErrorMonitor from '../services/errorMonitor/errorMonitor';
import variables from '../utils/variables.module.scss';

export const enum FORM_STAGE {
  BRIEF = 'brief',
  DEBRIEF = 'debrief'
}

export const enum CASE_STATE {
  NEW = 'new',
  IN_BRIEF = 'in_brief',
  BRIEF_IN_REVIEW = 'brief_in_review',
  READY = 'ready',
  IN_DEBRIEF = 'in_debrief',
  DEBRIEF_IN_REVIEW = 'debrief_in_review',
  COMPLETED = 'completed',
  ARCHIVED = 'archived'
}

export const CASES_STATE_ORDER: CASE_STATE[] = [
  CASE_STATE.NEW,
  CASE_STATE.IN_BRIEF,
  CASE_STATE.BRIEF_IN_REVIEW,
  CASE_STATE.READY,
  CASE_STATE.COMPLETED,
  CASE_STATE.ARCHIVED
];

export const caseStateDebriefPlus = (state: CASE_STATE): boolean => {
  return [
    CASE_STATE.IN_DEBRIEF,
    CASE_STATE.DEBRIEF_IN_REVIEW,
    CASE_STATE.COMPLETED,
    CASE_STATE.ARCHIVED
  ].includes(state);
};

export const caseStateInBriefToReady = (state: CASE_STATE): boolean => {
  return [CASE_STATE.IN_BRIEF, CASE_STATE.BRIEF_IN_REVIEW, CASE_STATE.READY].includes(state);
};

export const caseStateInBriefAndReview = (state: CASE_STATE): boolean => {
  return [CASE_STATE.IN_BRIEF, CASE_STATE.BRIEF_IN_REVIEW].includes(state);
};

export const enum USER_CASE_ROLES {
  ATTENDING = 'attending',
  CONTRIBUTOR = 'contributor',
  NONE = 'none'
}

export const enum ROLES {
  GUEST = 'guest',
  ANESTHESIA = 'anesthesia',
  ANESTHESIA_RESIDENT = 'anesthesia_resident',
  CRNA = 'crna',
  APP = 'app',
  SURGICAL_FELLOW = 'surgical_fellow',
  RESIDENT = 'resident',
  NEUROPHYSIOLOGIST = 'neurophysiologist',
  NURSE = 'nurse',
  NURSE_MANAGER = 'nurse_manager',
  CST = 'cst',
  ORSTAFF = 'orstaff',
  ATTENDING = 'attending',
  VENDOR_REP = 'vendor_rep',
  OTHER = 'other',
  CARE_TEAM = 'care_team',
  INVITER = 'inviter',
  NEED_PWA = 'need_pwa',
  CURRENT_USER = 'current_user'
  /*
   * When adding roles, also consider if need to add to:
   * - ROLE_TITLES
   * - CARE_TEAM_ROLES
   */
}

export enum ROLE_GROUPS {
  ANESTHESIA,
  APPS,
  NURSES_AND_CST,
  SURGICAL_FELLOWS_AND_RESIDENTS,
  SURGEONS,
  VENDOR_REPS,
  OTHER
}

export interface RoleGroupMeta {
  title: string;
  color: string;
}

export const ROLES_GROUPS_META_MAP: { [key in ROLE_GROUPS]: RoleGroupMeta } = {
  [ROLE_GROUPS.ANESTHESIA]: { title: 'Anesthesia', color: variables.pink1 },
  [ROLE_GROUPS.APPS]: { title: 'APPs', color: variables.yellow1 },
  [ROLE_GROUPS.NURSES_AND_CST]: { title: 'Nurses and CST', color: variables.green },
  [ROLE_GROUPS.SURGICAL_FELLOWS_AND_RESIDENTS]: {
    title: 'Surgical fellows and Residents',
    color: variables.yellow1
  },
  [ROLE_GROUPS.SURGEONS]: { title: 'Surgeons', color: variables.blue },
  [ROLE_GROUPS.VENDOR_REPS]: { title: 'Vendor reps', color: variables.red1 },
  [ROLE_GROUPS.OTHER]: { title: 'Other', color: variables.yellow4 }
};

export const ROLES_META: { [key in ROLES]?: { title: string; group: ROLE_GROUPS } } = {
  [ROLES.ANESTHESIA]: { title: 'Anesthesiologist', group: ROLE_GROUPS.ANESTHESIA },
  [ROLES.ANESTHESIA_RESIDENT]: {
    title: 'Anesthesia resident',
    group: ROLE_GROUPS.ANESTHESIA
  },
  [ROLES.CRNA]: {
    title: 'CRNA ',
    group: ROLE_GROUPS.ANESTHESIA
  },
  [ROLES.APP]: { title: 'APP', group: ROLE_GROUPS.APPS },
  [ROLES.SURGICAL_FELLOW]: {
    title: 'Surgical fellow',
    group: ROLE_GROUPS.SURGICAL_FELLOWS_AND_RESIDENTS
  },
  [ROLES.RESIDENT]: {
    title: 'Surgical resident',
    group: ROLE_GROUPS.SURGICAL_FELLOWS_AND_RESIDENTS
  },
  [ROLES.NEUROPHYSIOLOGIST]: {
    title: 'Neurophysiologist',
    group: ROLE_GROUPS.NURSES_AND_CST
  },
  [ROLES.NURSE]: { title: 'Nurse', group: ROLE_GROUPS.NURSES_AND_CST },
  [ROLES.NURSE_MANAGER]: { title: 'Nursing management', group: ROLE_GROUPS.NURSES_AND_CST },
  [ROLES.CST]: { title: 'Surgical tech', group: ROLE_GROUPS.NURSES_AND_CST },
  [ROLES.ORSTAFF]: { title: 'OR staff', group: ROLE_GROUPS.NURSES_AND_CST },
  [ROLES.ATTENDING]: { title: 'Surgeon', group: ROLE_GROUPS.SURGEONS },
  [ROLES.VENDOR_REP]: { title: 'Vendor rep', group: ROLE_GROUPS.VENDOR_REPS },
  [ROLES.OTHER]: { title: 'Other', group: ROLE_GROUPS.OTHER }
};

const getRoleTitle = (role: ROLES): string => {
  return ROLES_META[role]?.title ?? role;
};

export const getFirstCareTeamRole = (roles: IRoleData[]): ROLES | undefined => {
  for (const role of roles) {
    if (CARE_TEAM_ROLES.includes(role.role)) {
      return role.role;
    }
  }
  ErrorMonitor.captureException(new Error(`No care team role found in ${JSON.stringify(roles)}`));
  return undefined;
};

export const getFirstCareTeamRoleTitle = (roles: IRoleData[]): string => {
  const role = getFirstCareTeamRole(roles);
  if (role === undefined) {
    return '';
  }
  return getRoleTitle(role);
};

const getRoleGroupMeta = (role: ROLES): RoleGroupMeta => {
  if (ROLES_META[role] === undefined) {
    console.log('No role meta found', role);
    ErrorMonitor.captureException(new Error('No role meta found'));
    return ROLES_GROUPS_META_MAP[ROLE_GROUPS.OTHER];
  }
  const group = ROLES_META[role]?.group;
  if (group === undefined) {
    console.log('No group found for role:', role);
    ErrorMonitor.captureException(new Error('No group found for role'));
    return ROLES_GROUPS_META_MAP[ROLE_GROUPS.OTHER];
  }

  return ROLES_GROUPS_META_MAP[group];
};

export const getFirstCareTeamRoleGroupMeta = (roles: IRoleData[]): RoleGroupMeta => {
  for (const role of roles) {
    if (CARE_TEAM_ROLES.includes(role.role)) {
      return getRoleGroupMeta(role.role);
    }
  }
  ErrorMonitor.captureException(new Error(`No care team role found in ${JSON.stringify(roles)}`));
  return ROLES_GROUPS_META_MAP[ROLE_GROUPS.OTHER];
};

export const CARE_TEAM_ROLES: ROLES[] = [
  ROLES.ANESTHESIA,
  ROLES.ANESTHESIA_RESIDENT,
  ROLES.CRNA,
  ROLES.APP,
  ROLES.SURGICAL_FELLOW,
  ROLES.RESIDENT,
  ROLES.NEUROPHYSIOLOGIST,
  ROLES.NURSE,
  ROLES.NURSE_MANAGER,
  ROLES.CST,
  ROLES.ORSTAFF,
  ROLES.ATTENDING,
  ROLES.VENDOR_REP,
  ROLES.OTHER
];

export const enum DayOfWeek {
  Monday = 'Mon',
  Tuesday = 'Tue',
  Wednesday = 'Wed',
  Thursday = 'Thu',
  Friday = 'Fri',
  Saturday = 'Sat',
  Sunday = 'Sun'
}

export const dayNames: DayOfWeek[] = [
  DayOfWeek.Monday,
  DayOfWeek.Tuesday,
  DayOfWeek.Wednesday,
  DayOfWeek.Thursday,
  DayOfWeek.Friday,
  DayOfWeek.Saturday,
  DayOfWeek.Sunday
];
