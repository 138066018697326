import * as React from 'react';
const SvgComponent = props => (
  <svg
    className="svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 6 10"
    fill={props.fill || 'none'}
    {...props}
  >
    <path
      d="M6 1C6 1.55228 5.55228 2 5 2C4.44772 2 4 1.55228 4 1C4 0.447715 4.44772 0 5 0C5.55228 0 6 0.447715 6 1Z"
      fill={props.stroke || '#65708F'}
    />
    <path
      d="M2 1C2 1.55228 1.55228 2 1 2C0.447715 2 0 1.55228 0 1C0 0.447715 0.447715 0 1 0C1.55228 0 2 0.447715 2 1Z"
      fill={props.stroke || '#65708F'}
    />
    <path
      d="M6 5C6 5.55228 5.55228 6 5 6C4.44772 6 4 5.55228 4 5C4 4.44772 4.44772 4 5 4C5.55228 4 6 4.44772 6 5Z"
      fill={props.stroke || '#65708F'}
    />
    <path
      d="M2 5C2 5.55228 1.55228 6 1 6C0.447715 6 0 5.55228 0 5C0 4.44772 0.447715 4 1 4C1.55228 4 2 4.44772 2 5Z"
      fill={props.stroke || '#65708F'}
    />
    <path
      d="M6 9C6 9.55228 5.55228 10 5 10C4.44772 10 4 9.55228 4 9C4 8.44772 4.44772 8 5 8C5.55228 8 6 8.44772 6 9Z"
      fill={props.stroke || '#65708F'}
    />
    <path
      d="M2 9C2 9.55228 1.55228 10 1 10C0.447715 10 0 9.55228 0 9C0 8.44772 0.447715 8 1 8C1.55228 8 2 8.44772 2 9Z"
      fill={props.stroke || '#65708F'}
    />
  </svg>
);
export default SvgComponent;
