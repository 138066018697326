import * as React from 'react';
const SvgComponent = props => (
  <svg
    className="svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21 24"
    fill={props.fill || 'none'}
    {...props}
  >
    <path
      d="M19.9319 6.61126C20.8855 5.65829 20.8855 4.11203 19.9319 3.15906C18.9784 2.20609 17.4311 2.20609 16.4776 3.15906L12.0511 7.58277C11.0975 8.53574 11.0975 10.082 12.0511 11.035C13.0047 11.9879 14.5519 11.9879 15.5055 11.035L19.9319 6.61126Z"
      fill="#EF3F3F"
    />
    <path
      d="M0.71615 6.61126C-0.237414 5.65829 -0.237414 4.11203 0.71615 3.15906C1.66971 2.20609 3.21694 2.20609 4.1705 3.15906L8.59697 7.58277C9.55053 8.53574 9.55053 10.082 8.59697 11.035C7.6434 11.9879 6.09618 11.9879 5.14262 11.035L0.71615 6.61126Z"
      fill="#B93894"
    />
    <path
      d="M0.71615 18.9109C-0.237414 19.8639 -0.237414 21.4101 0.71615 22.3631C1.66971 23.3161 3.21694 23.3161 4.1705 22.3631L8.59697 17.9394C9.55053 16.9864 9.55053 15.4402 8.59697 14.4872C7.6434 13.5342 6.09618 13.5342 5.14262 14.4872L0.71615 18.9109Z"
      fill="#F8A28A"
    />
    <path
      d="M19.9319 18.9109C20.8855 19.8639 20.8855 21.4101 19.9319 22.3631C18.9784 23.3161 17.4311 23.3161 16.4776 22.3631L12.0511 17.9394C11.0975 16.9864 11.0975 15.4402 12.0511 14.4872C13.0047 13.5342 14.5519 13.5342 15.5055 14.4872L19.9319 18.9109Z"
      fill="#FEC20F"
    />
    <path
      d="M10.324 4.88721C11.6744 4.88721 12.7692 3.79317 12.7692 2.44361C12.7692 1.09404 11.6744 0 10.324 0C8.97363 0 7.87891 1.09404 7.87891 2.44361C7.87891 3.79317 8.97363 4.88721 10.324 4.88721Z"
      fill="#3EBDA5"
    />
  </svg>
);
export default SvgComponent;
