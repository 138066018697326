import MomentAdapter from '@date-io/moment';
import { CASE_STATE } from 'app/consts';
import type { ICase, ICaseBasicData, IFieldValuesCount, ILoggedInUser } from './types';
import { toZonedTime } from 'date-fns-tz';

export const isDateInPast = (date: Date, tz: string): boolean => {
  const dateLib = new MomentAdapter();
  const today = toZonedTime(new Date(), tz);
  const now = dateLib.moment(today);

  const momentDate = dateLib.date(date);

  if (momentDate === null) {
    throw new Error('momentDate is null');
  }
  return now.startOf('day').isAfter(momentDate.endOf('day'));
};

export const formDataToCaseState = (caseDate: Date, tz: string): CASE_STATE => {
  const isCaseInPast = isDateInPast(caseDate, tz);
  if (isCaseInPast) {
    return CASE_STATE.COMPLETED;
  }
  return CASE_STATE.BRIEF_IN_REVIEW;
};

export const isCurrentUserToHandleCase = (
  caseData: ICaseBasicData,
  currentUser: ILoggedInUser
): boolean =>
  (caseData.attendingId === currentUser.data.id &&
    [CASE_STATE.BRIEF_IN_REVIEW, CASE_STATE.COMPLETED].includes(caseData.state)) ||
  currentUser.data.isNurseLeader;
export const isInstalled = (): boolean => {
  return isStandAlone() || localStorage.getItem('emulate-installed') !== '';
};

export const isStandAlone = (): boolean => {
  if ('standalone' in navigator) {
    // @ts-expect-error Available on Apple's iOS Safari only.
    return navigator.standalone;
  }
  return true;
};

export const isCaseOfUser = (c: ICase, userId: string): boolean => {
  return c.data.attendingId === userId || c.data.caseFollowers.some(f => f.userId === userId);
};

export const getDateKey = (date: Date): string => {
  return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
};

export const updateFvcMap = (
  fvc: IFieldValuesCount[],
  map: Map<string, Map<string, number>>
): void => {
  map.clear();
  fvc.forEach(f => {
    const entry = map.get(f.fieldId);
    if (entry === undefined) {
      const newEntry = new Map();
      newEntry.set(f.value, f.count);
      map.set(f.fieldId, newEntry);
      return;
    }
    const fieldEntry = entry.get(f.value);
    const newValue = (fieldEntry ?? 0) + f.count;
    entry.set(f.value, newValue);
    map.set(f.fieldId, entry);
  });
};
