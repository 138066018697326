import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CASE_ASSET_FULL_DATA = gql`
  fragment CaseAssetFullData on GetCaseAssetsOutput {
    created_at
    description
    external_id
    id
    token
    meta
  }
`;
