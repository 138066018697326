import * as React from 'react';
const SvgComponent = props => (
  <svg
    className="svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill={props.fill || 'none'}
    {...props}
  >
    <path
      d="M3.4375 2H16.5625C16.7642 2 16.8985 2.00041 16.9959 2.00619C16.9996 2.08457 17 2.19208 17 2.35V17.65C17 17.8079 16.9996 17.9154 16.9959 17.9938C16.8985 17.9996 16.7642 18 16.5625 18H3.4375C3.23581 18 3.10152 17.9996 3.00414 17.9938C3.00045 17.9154 3 17.8079 3 17.65V2.35C3 2.19208 3.00044 2.08457 3.00413 2.00619C3.10152 2.00041 3.23581 2 3.4375 2ZM16.9828 1.88313C16.9828 1.8831 16.983 1.88376 16.9834 1.8852L16.9828 1.88313ZM16.9828 18.1169C16.9828 18.1168 16.983 18.1161 16.9834 18.1148L16.9828 18.1169ZM3.01718 18.1169C3.01716 18.1169 3.01696 18.1162 3.0166 18.1148L3.01718 18.1169ZM3.01718 1.88313C3.0172 1.88316 3.01702 1.88387 3.01661 1.88519L3.01718 1.88313ZM2.88317 2.02043C2.88262 2.02061 2.88232 2.02069 2.88232 2.02068L2.88317 2.02043Z"
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 11H13"
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 14H10"
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 6H12"
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 8V4"
      stroke={props.stroke || '#B33B94'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgComponent;
