import type {
  ICareTeamDefaults,
  ICaseBasicData,
  ICaseReferencedData,
  IDynamicField,
  IGetFieldValuesCount,
  IRoleData
} from 'app/mobxStore/types';
import { getBriefFields } from './brief';

export const getFormFields = (
  caseData: ICaseBasicData,
  referencedData: ICaseReferencedData,
  userRoles: IRoleData[],
  editMode: boolean,
  getFieldValuesCount: (fieldId: string) => IGetFieldValuesCount,
  savedDefaults: ICareTeamDefaults
): IDynamicField[] => {
  return getBriefFields(
    caseData,
    referencedData,
    userRoles,
    editMode,
    getFieldValuesCount,
    savedDefaults
  );
};

export const getTextFieldValue = (field: IDynamicField): string => {
  if (field.value.length === 0) {
    return '';
  }
  return field.value[0];
};

export const getIntraopText = (caseData: ICaseBasicData): string => {
  if (!caseData.isReady) {
    return '';
  }
  return caseData.intraopText ?? '';
};
