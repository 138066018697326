import * as Sentry from '@sentry/react';
import { isStringSet } from '../helper';
import { Integrations } from '@sentry/tracing';
import AmplService from '../amplService/amplService';

if (!isStringSet(process.env.REACT_APP_SENTRY_DNS)) {
  throw new Error('REACT_APP_SENTRY_DNS is not defined');
}

class ErrorMonitor {
  init(): void {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DNS,
      integrations: [new Integrations.BrowserTracing()],
      enabled: process.env.REACT_APP_APP_ENV !== 'LOCAL'
      // tracesSampleRate: 1.0,
    });
  }

  captureException(error: Error, extras: any = {}): void {
    Sentry.captureException(error, { extra: extras });
    AmplService.sendEvent(AmplService.EVENTS.ERROR, {
      [AmplService.ATTRIBUTES.ERROR_MESSAGE]: error.message
    });
  }

  captureMessage(message: string, extras: any = {}): void {
    Sentry.captureMessage(message, { extra: extras });
  }

  addBreadcrumb(param: Sentry.Breadcrumb): void {
    Sentry.addBreadcrumb(param);
  }

  setUser(id: string, email: string | undefined): void {
    Sentry.setUser({ id, email });
  }

  setTag(tag: string, value: string): void {
    Sentry.setTag(tag, value);
  }
}

const instance = new ErrorMonitor();

export default instance;
