import { getAudPrefix } from '../../../utils/audPrefix';
import urlParams from '../urlParams';
import { getAud, getEnvVarPerAud, getEnvVarWithAssert } from '../helper';

const overrideConnectionKey = '257d14b2-c83a-41d3-a5ad-449bd85c0904';

const appDomain = getEnvVarWithAssert('REACT_APP_DOMAIN');
const appAuth0Domain = getEnvVarWithAssert('REACT_APP_AUTH0_DOMAIN');
const clientId = getEnvVarPerAud('REACT_APP_AUTH0_CLIENT_ID_%AUD%', true) ?? '';

const getAllowedConnections = (): string[] => {
  const localStorageVal = localStorage.getItem(overrideConnectionKey);
  const envVarVal = getEnvVarPerAud(`REACT_APP_AUTH0_ALLOWED_CONNECTIONS_%AUD%`, false);
  const defaultVal = 'Username-Password-Authentication';

  const allowedConnectionsString = localStorageVal ?? envVarVal ?? defaultVal;

  return allowedConnectionsString.split(',');
};

const first = window.location.host.split('.')[0];
const aud = getAud(first);
const allowedConnections = getAllowedConnections();
const email = urlParams.get('email');
const loginHint = email === null ? '' : (email as string);
const AUTH_CONFIG: {
  aud: string;
  audPrefix: string;
  domain: string;
  clientId: string;
  callbackUrl: string;
  allowedConnections: string[];
  connection: string;
  loginHint: string;
} = {
  audPrefix: getAudPrefix(),
  aud: `https://${aud}.${appDomain}/api`,
  domain: appAuth0Domain,
  clientId,
  callbackUrl: `${window.location.protocol}//${window.location.host}/callback/`,
  allowedConnections,
  connection: allowedConnections[0],
  loginHint
};

export default AUTH_CONFIG;
