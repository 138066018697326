import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationPanel from 'app/components/confirmationPanel/ConfirmationPanel';
import { useRootStore } from '../../../mobxStore';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import type { ICase } from '../../../mobxStore/types';
import { lite } from '../../../services/apiService/apiService';

const invitePanelShowKey = 'invitePanelShowKey';

const countHuddledCases = (cases: ICase[]): number => {
  const ready = cases.filter(c => c.data.isReady);
  return ready.length;
};

function doNotShowAgain(): void {
  const futureTime = new Date();
  futureTime.setFullYear(futureTime.getFullYear() + 50); // Set fifty years in the future
  localStorage.setItem(invitePanelShowKey, futureTime.getTime().toString());
}

const checkShowAndSetStorage = (cases: ICase[]): boolean => {
  if (!lite) {
    return false;
  }

  const nofHuddles = countHuddledCases(cases);
  if (nofHuddles < 2) {
    return false;
  }

  if (nofHuddles === 2) {
    localStorage.setItem(invitePanelShowKey, new Date().getTime().toString());
    return true;
  }

  const lastShown = localStorage.getItem(invitePanelShowKey);
  if (!lastShown) {
    throw new Error('lastShown is not set but nofHuddles > 2');
  }

  // calculate number of days since last shown
  const lastShownDate = new Date(parseInt(lastShown));
  const now = new Date();
  const diff = now.getTime() - lastShownDate.getTime();
  const days = diff / (1000 * 60 * 60 * 24);

  if (days < 2) {
    return false;
  }

  // set a far date in the future to avoid showing the panel again
  doNotShowAgain();

  return true;
};
const SuggestToInvitePanel = (): React.JSX.Element => {
  const { metaDataStore, caseStore } = useRootStore();
  const [isShowPanel, setIsShowPanel] = React.useState(false);
  const [caseWasOpen, setCaseWasOpen] = React.useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (metaDataStore.careTeamOptions.length > 1) {
      return;
    }

    if (!caseStore.recentlyHuddledCase && !caseWasOpen) {
      return;
    }
    if (caseStore.recentlyHuddledCase) {
      setCaseWasOpen(true);
      return;
    }

    if (!checkShowAndSetStorage(caseStore.items)) {
      return;
    }

    setCaseWasOpen(false);
    setIsShowPanel(true);
    localStorage.setItem(invitePanelShowKey, new Date().getTime().toString());
  }, [caseStore.recentlyHuddledCase]);

  const handleNo = async (): Promise<void> => {
    if (isAskingForFirstTime()) {
      toast.success(t('inviteToHuddlePanelToast'));
    } else {
      doNotShowAgain();
    }
    handleClose();
  };

  const handleYes = async (): Promise<void> => {
    caseStore.setIsInviteDialogOpen(true);
    doNotShowAgain();
    handleClose();
  };

  const handleClose = (): void => {
    setIsShowPanel(false);
  };

  const isAskingForFirstTime = (): boolean => {
    return caseStore.items.length === 2;
  };

  const getNoButtonText = (): string => {
    return isAskingForFirstTime()
      ? 'inviteToHuddlePanelButtonLater'
      : 'inviteToHuddlePanelButtonNo';
  };

  return (
    <ConfirmationPanel
      title={t('inviteToHuddlePanelTitle')}
      buttons={[
        {
          buttonText: t(getNoButtonText()),
          onClick: handleNo,
          variant: 'outlined',
          key: 'yes',
          closeOnClick: true
        },
        {
          buttonText: t('inviteToHuddlePanelButtonYes'),
          onClick: handleYes,
          variant: 'secondary',
          key: 'no',
          closeOnClick: true
        }
      ]}
      isShowPanel={isShowPanel}
      setIsShowPanel={setIsShowPanel}
    />
  );
};

export default observer(SuggestToInvitePanel);
