import { useTranslation } from 'react-i18next';

import './EmptyList.scss';
import { useRootStore } from '../../../mobxStore';
import { CaseStatusEnum } from '../../../mobxStore/types';

const useEmptyList = (): {
  text: string;
  onClick: (() => Promise<void>) | undefined;
} => {
  const { t } = useTranslation();
  const { caseStore } = useRootStore();

  const selectedStatusFilter = caseStore.selectedStatusFilter;

  if (selectedStatusFilter === CaseStatusEnum.UPCOMING) {
    return {
      text: t('emptyStateNoHuddlesHere'),
      onClick: undefined
    };
  }

  const onClick = async (): Promise<void> => {
    caseStore.setSelectedStatusFilter(CaseStatusEnum.UPCOMING);
  };

  const hasUpcomingHuddles = caseStore.hasCaseInStatus(CaseStatusEnum.UPCOMING);

  if (hasUpcomingHuddles) {
    return {
      text: t('emptyStateHuddlesUpcoming'),
      onClick
    };
  }

  return {
    text: t('emptyStateNoHuddlesSoon'),
    onClick: undefined
  };
};

export default useEmptyList;
