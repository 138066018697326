import React, { type MouseEvent, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import type { IDynamicFieldValues, IFullCase } from '../../mobxStore/types';
import { useRootStore } from '../../mobxStore';
import Button from 'app/components/buttons/Button';
import PencilIcon from 'app/assets/icons/Pencil_icon';
import variables from 'app/utils/variables.module.scss';
import { observer } from 'mobx-react-lite';
import Lottie from 'lottie-react';
import LottiePreferences from 'app/assets/lotties/lottie-preferences.json';
import Restore_icon from 'app/assets/icons/Restore_icon';
import EditTemplateModel from 'app/components/huddleTemplate/EditTemplateModel';
import AmplService from '../../services/amplService';

interface IProps {
  hasSavedPreference: boolean;
  loadTemplate?: () => Promise<void>;
  briefFormAllValues?: IDynamicFieldValues;
  briefFormReset?: (values: IDynamicFieldValues) => void;
}

const HuddleTemplate = (props: IProps): React.JSX.Element => {
  const { hasSavedPreference, loadTemplate, briefFormAllValues, briefFormReset } = props;
  const { caseStore, userStore } = useRootStore();
  const openedCase = caseStore.openedCase as IFullCase;
  const isAttending = userStore.loggedInUser.data.id === openedCase.basicCase.data.attending?.id;
  const isNurseLeader = userStore.loggedInUser.data.isNurseLeader;
  const canEditPreferences = isAttending || isNurseLeader;
  const [undoValues, setUndoValues] = React.useState<IDynamicFieldValues | null>(null);
  const { t } = useTranslation();
  const preferencesAnimationRef = useRef<any>(null);

  if (!loadTemplate || !briefFormAllValues || !briefFormReset) {
    throw new Error('loadTemplate, briefFormAllValues, briefFormReset are required');
  }

  const getTitle = (): string => {
    if (hasSavedPreference) {
      return isAttending
        ? t('hasSavedPreferenceMine')
        : t('hasSavedPreferenceNotMine', {
            attending: openedCase.basicCase.data.attending?.nickName
          });
    }
    return t('noSavedPreference', { procedure: openedCase.basicCase.data.procedureTitle });
  };

  const handlePlayAnimation = (): void => {
    if (preferencesAnimationRef.current !== null) {
      preferencesAnimationRef.current.play();
    }
  };
  const handleStopAnimation = (): void => {
    if (preferencesAnimationRef.current !== null) {
      preferencesAnimationRef.current.stop();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      handlePlayAnimation();
    }, 500);
  }, []);

  const setDialogOpen = async (e: MouseEvent): Promise<void> => {
    caseStore.setIsEditTemplateDialogOpen(true);
    handleStopAnimation();
    AmplService.sendCaseEvent(AmplService.EVENTS.CASE_EDIT_MY_PREFERENCES, openedCase.basicCase);
  };

  const handleCloseEditDialog = async (): Promise<void> => {
    caseStore.setIsEditTemplateDialogOpen(false);
    setTimeout(() => {
      handlePlayAnimation();
    }, 1000);
  };

  const applyPrefs = async (): Promise<void> => {
    setUndoValues(briefFormAllValues);
    await loadTemplate();
  };

  const onClickUndo = async (): Promise<void> => {
    if (undoValues === null) {
      return;
    }
    briefFormReset(undoValues);
    setUndoValues(null);
    AmplService.sendCaseEvent(AmplService.EVENTS.CASE_UNDO_USE_PREFERENCES, openedCase.basicCase);
  };

  const getMainButton = (): React.JSX.Element => {
    // @todo this was && and changed to ||. Tested before commit?
    if (!canEditPreferences || !hasSavedPreference) {
      return <></>;
    }

    if (undoValues) {
      return (
        <Button
          id="use-preferences-undo-button"
          onClick={onClickUndo}
          classNames="primary outlined use-button"
          buttonText={t('undo') ?? ''}
          startIcon={<Restore_icon stroke={variables.primary} height={20} />}
          disabled={!hasSavedPreference}
        />
      );
    }

    return (
      <Button
        id="use-preferences-button"
        onClick={async (): Promise<void> => {
          void applyPrefs();
          AmplService.sendCaseEvent(AmplService.EVENTS.CASE_USE_PREFERENCES, openedCase?.basicCase);
        }}
        classNames="primary outlined use-button use"
        buttonText={(isAttending ? t('useMyPreferences') : t('useNotMyPreferences')) ?? ''}
        startIcon={
          <Lottie
            animationData={LottiePreferences}
            loop={2}
            initialSegment={[130, 240]}
            autoplay={false}
            lottieRef={preferencesAnimationRef}
            onComplete={() => {
              handleStopAnimation();
            }}
          />
        }
        disabled={!hasSavedPreference}
      />
    );
  };

  const getEditButton = (): React.JSX.Element => {
    if (!canEditPreferences) {
      return <></>;
    }

    if (!hasSavedPreference) {
      return (
        <Button
          id="edit-template-button"
          startIcon={<PencilIcon />}
          onClick={setDialogOpen}
          classNames="primary outlined use-button initial"
          buttonText={t('editPreferences') ?? ''}
        />
      );
    }

    return (
      <Button
        id="edit-template-button"
        classNames="circle-button outlined edit-button"
        startIcon={<PencilIcon />}
        onClick={setDialogOpen}
      />
    );
  };

  return (
    <div className="huddle-template">
      <div className="title">{getTitle()}</div>
      <div className="buttons">
        {getMainButton()}
        {getEditButton()}
      </div>
      <EditTemplateModel handleClose={handleCloseEditDialog} applyPrefs={applyPrefs} />
    </div>
  );
};

export default observer(HuddleTemplate);
