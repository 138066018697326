import type {
  ICase,
  ICaseCommentsStore,
  ICaseReferencedData,
  IFullCase,
  IPlanFeedbackData,
  IProcedure
} from './types';
import { flow, makeAutoObservable } from 'mobx';
import CaseCommentsStore from './CaseCommentsStore';
import apiService from '../services/apiService';

class FullCase implements IFullCase {
  basicCase: ICase;
  referencedData: ICaseReferencedData;
  commentsStore: ICaseCommentsStore;
  constructor(referencedData: ICaseReferencedData, basicCase: ICase) {
    makeAutoObservable(this, {
      commentsStore: false
    });
    this.referencedData = referencedData;
    this.commentsStore = new CaseCommentsStore(this);
    this.basicCase = basicCase;
  }

  setReferencedData = (referencedData: ICaseReferencedData): void => {
    this.referencedData = referencedData;
  };

  setProcedure(procedure: IProcedure): void {
    this.referencedData.procedureData = procedure;
    this.basicCase.data.procedureId = procedure.id;
    this.basicCase.data.procedureTitle = procedure.name;
  }

  updatePlanFeedback(rating: number, text: string): void {
    void this.upsertPlanFeedbackToServer(rating, text);
    this.upsertPlanFeedbackToStore(rating, text);
  }

  deletePlanFeedbackFromStore(): void {
    this.referencedData.planFeedbacks = this.referencedData.planFeedbacks.filter(
      pf => pf.user.id !== this.basicCase.store.rootStore.userStore.loggedInUser.data.id
    );
  }

  upsertPlanFeedbackToServer = flow(function* (this: IFullCase, rating: number, text: string) {
    yield apiService.upsertPlanFeedback(this.basicCase.id, rating, text);
  });

  upsertPlanFeedbackToStore(rating: number, text: string): void {
    this.referencedData.planFeedbacks = this.referencedData.planFeedbacks.filter(
      pf => pf.user.id !== this.basicCase.store.rootStore.userStore.loggedInUser.data.id
    );

    this.referencedData.planFeedbacks.push({
      caseId: this.basicCase.id,
      rating,
      text,
      user: this.basicCase.store.rootStore.userStore.loggedInUser.data
    });
  }

  deletePlanFeedbackFromServer = flow(function* (this: IFullCase) {
    yield apiService.deletePlanFeedback(
      this.basicCase.id,
      this.basicCase.store.rootStore.userStore.loggedInUser.data.id
    );
  });

  getCurrentUserPlanFeedback(): IPlanFeedbackData {
    const feedback = this.referencedData.planFeedbacks.find((fb: IPlanFeedbackData) =>
      this.basicCase.store.rootStore.isLoggedInUserId(fb.user.id)
    );
    if (feedback) {
      return feedback;
    }

    return {
      caseId: this.basicCase.id,
      rating: 0,
      text: '',
      user: this.basicCase.store.rootStore.userStore.loggedInUser.data
    };
  }
}

export default FullCase;
