import React from 'react';
import { authRoles } from 'app/auth';
import UNLogin from './UNLogin';

const LoginConfig = {
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: '/login',
      element: <UNLogin />
    }
  ]
};

export default LoginConfig;
