import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const USER_FULL_DATA = gql`
  fragment userFullData on users {
    id
    nick_name
    avatar
    first_name
    last_name
    roles {
      role
    }
    user_sites {
      site_id
    }
    follow_all
    is_nurse_leader
    is_active
    vendor
  }
`;
