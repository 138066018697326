import React from 'react';
import { observer } from 'mobx-react-lite';
import { getDate } from 'date-fns';
import WeekDayComponent from './WeekDayComponent';
import './WeekDayPicker.scss';

interface IProps {
  weekDays: Date[];
  handleClickDay: (day: Date) => void;
}

const Weekgroup = (props: IProps): React.JSX.Element => {
  const { weekDays, handleClickDay } = props;

  return (
    <div className="week">
      {weekDays.map((day: Date) => (
        <WeekDayComponent handleClickDay={handleClickDay} key={getDate(day)} day={day} />
      ))}
    </div>
  );
};

export default observer(Weekgroup);
