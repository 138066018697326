import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';

const UNRegister = (): React.JSX.Element => {
  const { loginWithRedirect } = useAuth0();
  const params = useParams();
  const inviteId = params.inviteId as string;

  useEffect(() => {
    void loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
        scope: `email inviteId:${inviteId}`
      }
    });
  }, []);
  return <div style={{ backgroundColor: '#fff' }}></div>;
};

export default observer(UNRegister);
