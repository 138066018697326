import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ChiefyLogoIcon from 'app/assets/icons/ChiefyLogo_icon';
import ShareIcon from '../../assets/share_icon.png';
import './Landing.scss';

const AddToHomeScreen = (): React.JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="page-container">
      <div className="add-to-home-screen-page">
        <div className="header">
          <div className="logo-container">
            <ChiefyLogoIcon className="logo" />
          </div>
          <div className="header-text">
            <div className="title">{t('chiefy')}</div>
            <div className="subtitle">{t('simplyAlign')}</div>
          </div>
        </div>
        <div className="content">
          <div className="text title">
            <Trans i18nKey="addToHomeSteps" />
          </div>
          <div className="step-container">
            <div className="step-number">1</div>
            <div className="text">
              <Trans
                i18nKey="addToHomeStep1"
                components={{
                  bold: <strong />,
                  share: <img className="share-icon" src={ShareIcon} />
                }}
              />
            </div>
          </div>
          <div className="step-container">
            <div className="step-number">2</div>
            <div className="text">
              <Trans i18nKey="addToHomeStep2" components={{ bold: <strong /> }} />
            </div>
          </div>
          <div className="text subtitle">
            <Trans i18nKey="launchChiefy" components={{ bold: <strong /> }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddToHomeScreen;
