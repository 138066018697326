import React from 'react';
import NewCaseFab from 'app/components/orBoard/newCaseFab/NewCaseFab';
import DailyHuddleStats from '../casesList/DailyHuddleStats';

import './EmptyList.scss';
import { ReactComponent as PenguinSkating } from '../../../assets/penguins/penguin_skating_animated.svg';
import Button from 'app/components/buttons/Button';
import { observer } from 'mobx-react-lite';
import useEmptyList from './useEmptyList';
import useEmptyListLiteNonSurgeon from './useEmptyListLiteNonSurgeon';
import { useRootStore } from '../../../mobxStore';

const EmptyList = (): React.JSX.Element => {
  const { text: textDefault, onClick: onClickDefault } = useEmptyList();
  const { text: textNhns, onClick: onClickNhns } = useEmptyListLiteNonSurgeon();
  const { liteNonSurgeon } = useRootStore();

  const text = liteNonSurgeon ? textNhns : textDefault;
  const onClick = liteNonSurgeon ? onClickNhns : onClickDefault;

  return (
    <div className="empty-list">
      <DailyHuddleStats />
      <div className="empty-container">
        <div className="image-container">
          <PenguinSkating />
        </div>
        <div className="text">{text}</div>
        {onClick && (
          <Button
            classNames="primary nav-button"
            buttonText="emptyStateTakeMeThere"
            onClick={onClick}
          />
        )}
      </div>
      <NewCaseFab />
    </div>
  );
};

export default observer(EmptyList);
