import React from 'react';
import ModalScreen from 'app/components/modalScreen/ModalScreen';
import Button from 'app/components/buttons/Button';

import './ConfirmationPanel.scss';
import clsx from 'clsx';

interface IButton {
  buttonText: string;
  onClick: () => Promise<void>;
  variant: 'outlined' | 'secondary';
  key: string;
  closeOnClick: boolean;
  classNames?: string[];
}

interface IProps {
  title: string | React.JSX.Element | null;
  text?: string | null;
  isShowPanel: boolean;
  setIsShowPanel: (isShowPanel: boolean) => void;
  buttons: IButton[];
}

const ConfirmationPanel = (props: IProps): React.JSX.Element => {
  const { title, text, isShowPanel, setIsShowPanel, buttons } = props;

  const handleClick = async (button: IButton): Promise<void> => {
    await button.onClick();
    if (button.closeOnClick) {
      handleClose();
    }
  };

  const handleClose = (): void => {
    setIsShowPanel(false);
  };

  const getCmp = (): React.JSX.Element | null => {
    return (
      <div className="discard-panel">
        <div className="title">{title}</div>
        {text && <div className="text">{text}</div>}
        {buttons.map(button => {
          return (
            <Button
              key={button.key}
              classNames={clsx([
                'btn',
                button.variant,
                button.key,
                button.classNames ? button.classNames : []
              ])}
              buttonText={button.buttonText}
              onClick={async () => {
                await handleClick(button);
              }}
            />
          );
        })}
      </div>
    );
  };
  const cmp = getCmp();

  return <ModalScreen isOpen={isShowPanel} content={cmp} handleClose={handleClose} />;
};

export default ConfirmationPanel;
