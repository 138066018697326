import React, { type MouseEvent, useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import type { IFullCase, IUserSelectOption } from 'app/mobxStore/types';
import type { AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete';
import { getFirstCareTeamRoleGroupMeta, getFirstCareTeamRoleTitle } from 'app/consts';
import type { FilterOptionsState } from '@mui/base/useAutocomplete/useAutocomplete';
import { useRootStore } from '../../mobxStore';
import sx from './panelSx';
import _ from 'lodash';
import PanelInput from './PanelInput';
import PanelListItem from './PanelListItem';
import FollowersDiscardPanel from './FollowersDiscardPanel';
import AmplService from '../../services/amplService/amplService';
import ErrorMonitor from '../../services/errorMonitor/errorMonitor';

interface IProps {
  options: IUserSelectOption[];
  onChange: (value: IUserSelectOption[]) => void;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

const PanelContent = (props: IProps): React.JSX.Element => {
  const { options, onChange, isOpen, setIsOpen } = props;
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [initValue, setInitValue] = useState<IUserSelectOption[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<IUserSelectOption[]>([]);
  const [isDirty, setIsDirty] = useState(false);
  const [isShowDiscardPanel, setIsShowDiscardPanel] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const { userStore, caseStore } = useRootStore();
  const openedCase = caseStore.openedCase as IFullCase;
  if (
    options.length === 0 ||
    (options.length === 1 && options[0].userId === userStore.loggedInUser.data.id)
  ) {
    return <></>;
  }

  const filterOptions = (
    options: IUserSelectOption[],
    state: FilterOptionsState<IUserSelectOption>
  ): IUserSelectOption[] => {
    const filtered = options.filter(option => {
      // do not display the attending user in the list
      const doNotDisplay = option.userId === openedCase.basicCase.data.attendingId;
      if (doNotDisplay) {
        return null;
      }
      const words = state.inputValue.toLowerCase().split(' ');
      const groupMeta = getFirstCareTeamRoleGroupMeta(option.roles);
      const optionString =
        (option.firstName ?? '') +
        (option.lastName ?? '') +
        (option.label ?? '') +
        (getFirstCareTeamRoleTitle(option.roles) ?? '') +
        groupMeta.title;
      return words.every(word => optionString.toLowerCase().includes(word));
    });

    return filtered;
  };

  const handleClose = (): void => {
    setIsOpen(false);
    setIsInputFocused(false);
  };

  const handleApply = async (e: MouseEvent): Promise<void> => {
    onChange(selectedUsers);
    handleClose();
  };

  const handleBack = async (e: MouseEvent): Promise<void> => {
    if (isDirty) {
      setIsShowDiscardPanel(true);
      return;
    }
    handleClose();
  };

  const handleDiscardConfirm = (): void => {
    setSelectedUsers(initValue);
    handleClose();
  };

  const handleOnchange = (newValue: IUserSelectOption[]): void => {
    const prev = selectedUsers;
    setSelectedUsers(newValue);
    const isClean =
      _.differenceWith(newValue, initValue, _.isEqual).length === 0 &&
      _.differenceWith(initValue, newValue, _.isEqual).length === 0;
    setIsDirty(!isClean);

    const removedUsers = _.differenceWith(prev, newValue, _.isEqual);
    const addedUsers = _.differenceWith(newValue, prev, _.isEqual);
    if (addedUsers.length + removedUsers.length !== 1) {
      console.log('removedUsers', removedUsers, 'addedUsers', addedUsers);
      ErrorMonitor.captureException(new Error('Unexpected number of users added/removed'));
      return;
    }
    if (removedUsers.length > 0) {
      AmplService.sendEvent(AmplService.EVENTS.FOLLOWERS_SELECT_PANEL_REMOVE);
    }
    if (addedUsers.length > 0) {
      AmplService.sendEvent(AmplService.EVENTS.FOLLOWERS_SELECT_PANEL_ADD);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setInputValue('');
      setIsInputFocused(false);
    }
    const users: React.SetStateAction<IUserSelectOption[]> = [];
    options.forEach(option => {
      const isCaseFollowed = openedCase?.basicCase.data.caseFollowers.some(
        cf => cf.userId === option.userId
      );
      if (isCaseFollowed) {
        users.push(option);
      }
    });
    if (selectedUsers.length === 0) {
      setSelectedUsers(users);
    }
    setInitValue(users);
  }, [isOpen]);

  const getGroupTitleByFollowing = (option: IUserSelectOption): string => {
    const isCaseFollowed = openedCase?.basicCase.data.caseFollowers.some(
      cf => cf.userId === option.userId
    );
    if (isCaseFollowed) {
      return ' Following';
    }
    const grp = getFirstCareTeamRoleGroupMeta(option.roles);
    return grp.title;
  };

  const sortOptions = (a: IUserSelectOption, b: IUserSelectOption): number => {
    const aGroup = getGroupTitleByFollowing(a);
    const bGroup = getGroupTitleByFollowing(b);
    return aGroup.localeCompare(bGroup);
  };

  return (
    <>
      <div className="modal-content">
        <div className="autocomplete-container">
          <Autocomplete
            multiple
            open
            disablePortal
            autoHighlight={true}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            options={options.sort(sortOptions)}
            value={selectedUsers}
            filterOptions={filterOptions}
            inputValue={inputValue}
            onChange={(event, newValue) => {
              handleOnchange(newValue);
            }}
            onInputChange={(event, value) => {
              setInputValue(value);
            }}
            ListboxProps={{
              // set list height to screen height - input container plus 16px list padding
              style: {
                maxHeight: 'calc(100vh - 108px)',
                overflow: 'auto'
              }
            }}
            sx={sx}
            renderTags={() => null}
            renderInput={params => (
              <PanelInput
                params={params}
                isDirty={isDirty}
                handleBack={handleBack}
                handleApply={handleApply}
                setIsInputFocused={setIsInputFocused}
                isInputFocused={isInputFocused}
                setInputValue={setInputValue}
                inputValue={inputValue}
              />
            )}
            groupBy={option => getGroupTitleByFollowing(option)}
            renderOption={(
              props,
              option: IUserSelectOption,
              state: AutocompleteRenderOptionState
            ) => <PanelListItem {...props} option={option} state={state} />}
          />
        </div>
      </div>
      <FollowersDiscardPanel
        isShowPanel={isShowDiscardPanel}
        setIsShowPanel={setIsShowDiscardPanel}
        handleDiscardConfirm={handleDiscardConfirm}
      />
    </>
  );
};

export default PanelContent;
