import type { IDynamicField } from '../../mobxStore/types';

export const fieldNeedsAttention = (
  dField: IDynamicField,
  currentUserId: string,
  currentUserLastSeen: Date | null
): boolean => {
  const isCurrentUser = dField.updatedBy?.id === currentUserId;
  if (!dField.createdAt || isCurrentUser) {
    return false;
  }

  if (!currentUserLastSeen) {
    return true;
  }

  const createdAt = new Date(dField.createdAt).getTime();
  const lastSeen = new Date(currentUserLastSeen).getTime();

  return createdAt > lastSeen;
};
