import * as React from 'react';
const SvgComponent = props => (
  <svg
    className="svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fill={props.fill || 'none'}
    {...props}
  >
    <path
      d="M3 5.49999C2.86739 5.49999 2.74024 5.44732 2.64642 5.35357C2.55267 5.25976 2.5 5.13261 2.5 4.99999V0.99999C2.5 0.82134 2.59531 0.656307 2.75 0.56699C2.90469 0.477673 3.09532 0.477667 3.25 0.56699C3.40468 0.656313 3.5 0.821357 3.5 0.99999V4.99999C3.5 5.13261 3.44733 5.25976 3.35358 5.35357C3.25977 5.44732 3.13262 5.49999 3 5.49999Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M13 5.49999C12.8674 5.49999 12.7402 5.44732 12.6464 5.35357C12.5527 5.25976 12.5 5.13261 12.5 4.99999V0.99999C12.5 0.82134 12.5953 0.656307 12.75 0.56699C12.9047 0.477673 13.0953 0.477667 13.25 0.56699C13.4047 0.656313 13.5 0.821357 13.5 0.99999V4.99999C13.5 5.13261 13.4473 5.25976 13.3536 5.35357C13.2598 5.44732 13.1326 5.49999 13 5.49999Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M15 3.5H0.99999C0.82134 3.5 0.656307 3.40469 0.56699 3.25C0.477673 3.09531 0.477667 2.90468 0.56699 2.75C0.656313 2.59532 0.821357 2.5 0.99999 2.5H15C15.1786 2.5 15.3437 2.59531 15.433 2.75C15.5223 2.90469 15.5223 3.09532 15.433 3.25C15.3437 3.40468 15.1786 3.5 15 3.5Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M15 15.5C14.8674 15.5 14.7402 15.4473 14.6464 15.3536C14.5527 15.2598 14.5 15.1326 14.5 15V2.99999C14.5 2.82134 14.5953 2.65631 14.75 2.56699C14.9047 2.47767 15.0953 2.47767 15.25 2.56699C15.4047 2.65631 15.5 2.82136 15.5 2.99999V15C15.5 15.1326 15.4473 15.2598 15.3536 15.3536C15.2598 15.4473 15.1326 15.5 15 15.5Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M15 15.5H0.99999C0.82134 15.5 0.656307 15.4047 0.56699 15.25C0.477673 15.0953 0.477667 14.9047 0.56699 14.75C0.656313 14.5953 0.821357 14.5 0.99999 14.5H15C15.1786 14.5 15.3437 14.5953 15.433 14.75C15.5223 14.9047 15.5223 15.0953 15.433 15.25C15.3437 15.4047 15.1786 15.5 15 15.5Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M1 15.5C0.867387 15.5 0.740237 15.4473 0.64642 15.3536C0.55267 15.2598 0.5 15.1326 0.5 15V2.99999C0.5 2.82134 0.595313 2.65631 0.75 2.56699C0.904687 2.47767 1.09532 2.47767 1.25 2.56699C1.40468 2.65631 1.5 2.82136 1.5 2.99999V15C1.5 15.1326 1.44733 15.2598 1.35358 15.3536C1.25977 15.4473 1.13262 15.5 1 15.5Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M8.99999 9.5H6.99999C6.82134 9.5 6.65631 9.40469 6.56699 9.25C6.47767 9.09531 6.47767 8.90468 6.56699 8.75C6.65631 8.59532 6.82136 8.5 6.99999 8.5H8.99999C9.17864 8.5 9.34367 8.59531 9.43299 8.75C9.52231 8.90469 9.52231 9.09532 9.43299 9.25C9.34367 9.40468 9.17862 9.5 8.99999 9.5Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M13 9.5H11C10.8213 9.5 10.6563 9.40469 10.567 9.25C10.4777 9.09531 10.4777 8.90468 10.567 8.75C10.6563 8.59532 10.8214 8.5 11 8.5H13C13.1786 8.5 13.3437 8.59531 13.433 8.75C13.5223 8.90469 13.5223 9.09532 13.433 9.25C13.3437 9.40468 13.1786 9.5 13 9.5Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M4.99999 11.5H2.99999C2.82134 11.5 2.65631 11.4047 2.56699 11.25C2.47767 11.0953 2.47767 10.9047 2.56699 10.75C2.65631 10.5953 2.82136 10.5 2.99999 10.5H4.99999C5.17864 10.5 5.34367 10.5953 5.43299 10.75C5.52231 10.9047 5.52231 11.0953 5.43299 11.25C5.34367 11.4047 5.17862 11.5 4.99999 11.5Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M8.99999 11.5H6.99999C6.82134 11.5 6.65631 11.4047 6.56699 11.25C6.47767 11.0953 6.47767 10.9047 6.56699 10.75C6.65631 10.5953 6.82136 10.5 6.99999 10.5H8.99999C9.17864 10.5 9.34367 10.5953 9.43299 10.75C9.52231 10.9047 9.52231 11.0953 9.43299 11.25C9.34367 11.4047 9.17862 11.5 8.99999 11.5Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M13 11.5H11C10.8213 11.5 10.6563 11.4047 10.567 11.25C10.4777 11.0953 10.4777 10.9047 10.567 10.75C10.6563 10.5953 10.8214 10.5 11 10.5H13C13.1786 10.5 13.3437 10.5953 13.433 10.75C13.5223 10.9047 13.5223 11.0953 13.433 11.25C13.3437 11.4047 13.1786 11.5 13 11.5Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M4.99999 13.5H2.99999C2.82134 13.5 2.65631 13.4047 2.56699 13.25C2.47767 13.0953 2.47767 12.9047 2.56699 12.75C2.65631 12.5953 2.82136 12.5 2.99999 12.5H4.99999C5.17864 12.5 5.34367 12.5953 5.43299 12.75C5.52231 12.9047 5.52231 13.0953 5.43299 13.25C5.34367 13.4047 5.17862 13.5 4.99999 13.5Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M8.99999 13.5H6.99999C6.82134 13.5 6.65631 13.4047 6.56699 13.25C6.47767 13.0953 6.47767 12.9047 6.56699 12.75C6.65631 12.5953 6.82136 12.5 6.99999 12.5H8.99999C9.17864 12.5 9.34367 12.5953 9.43299 12.75C9.52231 12.9047 9.52231 13.0953 9.43299 13.25C9.34367 13.4047 9.17862 13.5 8.99999 13.5Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M15 7.5H0.99999C0.82134 7.5 0.656307 7.40469 0.56699 7.25C0.477673 7.09531 0.477667 6.90468 0.56699 6.75C0.656313 6.59532 0.821357 6.5 0.99999 6.5H15C15.1786 6.5 15.3437 6.59531 15.433 6.75C15.5223 6.90469 15.5223 7.09532 15.433 7.25C15.3437 7.40468 15.1786 7.5 15 7.5Z"
      fill={props.fill || '#B33B94'}
    />
  </svg>
);
export default SvgComponent;
