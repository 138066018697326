import { gql } from '@apollo/client';
import { USER_FULL_DATA } from './fragments/user';

const getAll = gql`
  query GetUsers {
    users(
      order_by: { nick_name: asc }
      where: { is_active: { _eq: true }, roles: { role: { _eq: "attending" } } }
    ) {
      ...userFullData
    }
  }
  ${USER_FULL_DATA}
`;
const getAllowedUsers = gql`
  query GetAllowedUsers($sites_ids: [uuid!]!) {
    users(
      order_by: { nick_name: asc }
      where: {
        roles: {
          role: {
            _in: [
              "anesthesia"
              "anesthesia_resident"
              "crna"
              "app"
              "surgical_fellow"
              "resident"
              "neurophysiologist"
              "nurse"
              "nurse_manager"
              "cst"
              "orstaff"
              "attending"
              "vendor_rep"
              "other"
            ]
          }
        }
        user_sites: { site_id: { _in: $sites_ids } }
      }
    ) {
      ...userFullData
    }
  }
  ${USER_FULL_DATA}
`;

const getCurrentUser = gql`
  query GetCurrentUser($user_id: uuid!) {
    users_by_pk(id: $user_id) {
      ...userFullData
    }
  }
  ${USER_FULL_DATA}
`;

const getCurrentUserMetaData = gql`
  query CurrentUserGetMetaData($user_id: uuid!) {
    users_by_pk(id: $user_id) {
      email
      phone
      reminder_days
      onboarding_done
    }
  }
`;

const updateUser = gql`
  mutation CurrentUserUpdate($user_id: uuid!, $user: users_set_input!) {
    update_users_by_pk(pk_columns: { id: $user_id }, _set: $user) {
      ...userFullData
    }
  }
  ${USER_FULL_DATA}
`;

const updateUserRole = gql`
  mutation CurrentUserUpdateRole($user_id: uuid!, $old_role: role_enum!, $new_role: role_enum!) {
    delete_user_roles(where: { user_id: { _eq: $user_id }, role: { _eq: $old_role } }) {
      affected_rows
    }
    insert_user_roles_one(object: { user_id: $user_id, role: $new_role }) {
      user_id
    }
  }
`;

const deleteUserRole = gql`
  mutation CurrentUserDeleteRole($user_id: uuid!, $role: role_enum!) {
    delete_user_roles(where: { user_id: { _eq: $user_id }, role: { _eq: $role } }) {
      affected_rows
    }
  }
`;

const getIdHash = gql`
  query CareTeamGetIdHash {
    hashId {
      hash
    }
  }
`;

const inviteUser = gql`
  mutation InviterInviteUser($new_site: Boolean) {
    insert_invites_one(object: { new_site: $new_site }) {
      id
    }
  }
`;

const updateUserNickname = gql`
  mutation CurrentUserUpdateUserNickname($user_id: uuid!, $nickname: String) {
    update_users_by_pk(pk_columns: { id: $user_id }, _set: { nick_name: $nickname }) {
      id
    }
  }
`;

const mutations = {
  updateUserNickname,
  updateUser,
  updateUserRole,
  deleteUserRole,
  inviteUser
};

const queries = {
  getAll,
  getAllowedUsers,
  getCurrentUser,
  getIdHash,
  getCurrentUserMetaData
};

const api = {
  queries,
  mutations
};

export default api;
