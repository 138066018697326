import React, { useState } from 'react';
import { useRootStore } from 'app/mobxStore';
import SurgeonSelect from 'app/components/fields/SurgeonSelect';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import HuddleTabs from './HuddleTabs';
import FollowersSelect from 'app/components/fields/FollowersSelect';
import CaseStatusChip from 'app/components/caseStatusChip/CaseStatusChip';
import CaseDate from 'app/components/fields/CaseDate';
import { FORM_FIELDS_ENUM, type IFormData } from 'app/components/fields/types';
import type { Control, FormState } from 'react-hook-form/dist/types/form';
import CaseTitle from '../fields/CaseTitle';
import { isDateInPast } from '../../mobxStore/caseStoreHelper';
import './FillCaseMetaForm.scss';
import { lite } from '../../services/apiService/apiService';
import type { ICase, IUserDataWithRoles, IUserSelectOption } from '../../mobxStore/types';
import { parseUserToSelectOption } from '../../mobxStore/MetaDataStore';
import { useTranslation } from 'react-i18next';
import { ROLES } from '../../consts';
import CaseRoom from '../fields/CaseRoom';
import Button from 'app/components/buttons/Button';
import PencilIcon from 'app/assets/icons/Pencil_icon';
import CasePatientName from '../fields/CasePatientName';
import CasePatientNumber from '../fields/CasePatientNumber';

interface IProps {
  control: Control<IFormData>;
  formState: FormState<IFormData>;
  allValues: IFormData;
  handleClose: () => Promise<void>;
  loadTemplate?: () => Promise<void>;
  isCommentsHeader?: boolean;
}

const parseSurgeonsOptions = (
  surgeonsOptions: IUserSelectOption[],
  openedCase: ICase
): IUserSelectOption[] => {
  const isAttendingNotActive = openedCase.data.attending?.isActive === false;
  if (!isAttendingNotActive) {
    return surgeonsOptions;
  }
  const attending = openedCase.data.attending as IUserDataWithRoles;
  return [parseUserToSelectOption(attending), ...surgeonsOptions];
};

const FormHeader = (props: IProps): React.JSX.Element => {
  const { handleClose, allValues, control, isCommentsHeader } = props;
  const { caseStore, metaDataStore, liteNonSurgeon, userStore } = useRootStore();
  const openedCase = caseStore.openedCase;
  const { t } = useTranslation();
  const [isTitleEditable, setIsTitleEditable] = useState(!isCommentsHeader);
  const [dateToPostponeCase, setDateToPostponeCase] = useState<Date>();

  // unused expand functionality
  // const [isExpanded, setIsExpanded] = useState(!isCommentsHeader);
  const isExpanded = true;

  if (openedCase === null) {
    return <></>;
  }

  const siteId = allValues[FORM_FIELDS_ENUM.SERVICE]?.value;
  const surgeonsOptions = siteId
    ? metaDataStore.getSurgeonsOptionsBySite(siteId)
    : metaDataStore.surgeonsOptions;

  const getCareTeamOptions = (): IUserSelectOption[] => {
    const careTeamOptions = siteId
      ? metaDataStore.getCareTeamOptionsBySite(siteId)
      : metaDataStore.careTeamOptions;
    if (lite) {
      return careTeamOptions.filter(
        option => option.roles.find(r => r.role === ROLES.ATTENDING) === undefined
      );
    }
    return careTeamOptions;
  };

  const parsedSurgeonsOptions = parseSurgeonsOptions(surgeonsOptions, openedCase.basicCase);
  const isFollowersSelectReadyOnly = isDateInPast(
    openedCase.basicCase.data.caseDate,
    openedCase.basicCase.store.rootStore.tz
  );

  const handleToggleEditableTitle = (): void => {
    setIsTitleEditable(!isTitleEditable);
  };

  const getPatientInfo = (): React.JSX.Element | null => {
    const initials = openedCase.basicCase.data.patientInitials;
    const mrn = openedCase.basicCase.data.mrn;

    if (!initials && !mrn) {
      return null;
    }

    return (
      <div className="patient-info">
        {initials && <div className="initials">{initials}</div>}
        {mrn && <div className="number">{mrn}</div>}
      </div>
    );
  };

  const indexInDayForAttending = caseStore.getCaseIndexInDayForAttending(openedCase.basicCase.data);
  const showPatientInfoChips =
    !lite && !userStore.loggedInUser.isVendor && userStore.loggedInUser.data.isNurseLeader;
  const showPatientInfoSubtitle =
    !lite && !userStore.loggedInUser.isVendor && !userStore.loggedInUser.data.isNurseLeader;

  return (
    <div className={clsx('form-header', { expanded: isExpanded })}>
      <HuddleTabs
        handleClose={handleClose}
        dateToPostponeCase={dateToPostponeCase}
        setDateToPostponeCase={setDateToPostponeCase}
      />
      <div
        className="title-container"
        onBlur={() => {
          if (isCommentsHeader && isTitleEditable) {
            setTimeout(() => {
              handleToggleEditableTitle();
            });
          }
        }}
      >
        {isTitleEditable ? (
          <CaseTitle control={control} isFocusOnInit={isCommentsHeader} />
        ) : (
          <div
            className="title-read-only-container"
            onClick={() => {
              handleToggleEditableTitle();
            }}
          >
            <div className="title-read-only">{openedCase.basicCase.data.displayId}</div>
            <Button
              classNames="btn circle-button edit-title-button"
              startIcon={<PencilIcon />}
              disableTouchRipple
            />
          </div>
        )}
      </div>
      {showPatientInfoSubtitle && getPatientInfo()}
      <div className="selects">
        <div className="selects-row">
          <SurgeonSelect
            control={control}
            options={parsedSurgeonsOptions}
            allValues={allValues}
            isReadOnly={lite}
          />
          <div className="case-info-chip">{`${t('caseNumber')}${indexInDayForAttending}`}</div>
          <CaseRoom control={control} />
          {showPatientInfoChips && (
            <>
              <CasePatientName control={control} />
              <CasePatientNumber control={control} />
            </>
          )}
          {!liteNonSurgeon && (
            <CaseDate
              control={control}
              allValues={allValues}
              setDateToPostponeCase={setDateToPostponeCase}
              isReadOnly={liteNonSurgeon}
            />
          )}
        </div>
      </div>
      {isExpanded && (
        <FollowersSelect
          control={control}
          options={getCareTeamOptions()}
          isReadOnly={isFollowersSelectReadyOnly}
        />
      )}
      {openedCase && <CaseStatusChip case={openedCase.basicCase} />}
    </div>
  );
};

export default observer(FormHeader);
