import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRootStore } from '../../../mobxStore';

const useIsNeedOnboarding = (): void => {
  const navigate = useNavigate();
  const { userStore } = useRootStore();

  useEffect(() => {
    if (!userStore.loggedInUser.data.onboardingDone) {
      navigate('/onboarding');
    }
  }, []);
};

export default useIsNeedOnboarding;
