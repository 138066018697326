import {
  type ICareTeamDefaults,
  type ICareTeamDefaultsStore,
  type IDynamicField,
  type IDynamicFieldValues,
  type IFieldRecommendation,
  IFieldRecommendationType,
  type IRootStore
} from './types';
import { flow, makeAutoObservable } from 'mobx';
import apiService from '../services/apiService';
import { submitDefault, submitRawDefault } from '../../utils/form/submit';
import { getIsPatientField } from '../../utils/form/shared';
import ErrorMonitor from '../services/errorMonitor/errorMonitor';

const BRIEF_FORM = 'brief';

class CareTeamDefaultsStore implements ICareTeamDefaultsStore {
  rootStore: IRootStore;
  defaults: ICareTeamDefaults[];

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this, {
      rootStore: false
    });
    this.rootStore = rootStore;
    this.defaults = [];
  }

  hasDefault(userId: string, procedureId: string): boolean {
    return this.defaults.some(
      d => d.procedureId === procedureId && d.form === BRIEF_FORM && d.userId === userId
    );
  }

  getDefault(userId: string, procedureId: string): ICareTeamDefaults {
    const def = this.defaults.find(
      d => d.procedureId === procedureId && d.form === BRIEF_FORM && d.userId === userId
    );

    if (def) {
      return def;
    }

    return {
      procedureId,
      form: BRIEF_FORM,
      userId,
      values: {},
      stopInsights: {}
    };
  }

  setDefaults(defaults: ICareTeamDefaults[]): void {
    this.defaults = defaults;
  }

  loadDefaults = flow(function* (this: ICareTeamDefaultsStore) {
    try {
      const defaults: ICareTeamDefaults[] = yield apiService.getCareTeamAllDefaults();

      // remove patient fields if we added them in the past
      defaults.forEach(d => {
        Object.keys(d.values).forEach(fieldId => {
          if (getIsPatientField(fieldId)) {
            d.values[fieldId] = { id: fieldId, values: [], otherValues: [] };
          }
        });
      });

      this.setDefaults(defaults);
    } catch (error) {
      ErrorMonitor.captureException(error as Error);
    }
  });

  saveDefault = flow(function* (
    this: ICareTeamDefaultsStore,
    userId: string,
    procedureId: string,
    fields: IDynamicField[],
    values: IDynamicFieldValues
  ) {
    try {
      yield submitDefault(userId, procedureId, fields, values);
      yield this.loadDefaults();
    } catch (error) {
      ErrorMonitor.captureException(error as Error);
    }
  });

  saveRawDefault = flow(function* (
    this: ICareTeamDefaultsStore,
    userId: string,
    procedureId: string,
    rawDefault: ICareTeamDefaults
  ) {
    try {
      yield submitRawDefault(userId, procedureId, rawDefault.values);
      yield this.loadDefaults();
    } catch (error) {
      ErrorMonitor.captureException(error as Error);
    }
  });

  handleFieldRecommendation = flow(function* (
    this: ICareTeamDefaultsStore,
    suggestTemplate: IFieldRecommendation,
    ok: boolean
  ) {
    const kase = this.rootStore.caseStore.openedCase;
    if (!kase?.basicCase.data.procedureId) {
      // @todo sentry + toast
      return;
    }
    const savedDefault = this.getDefault(
      kase.basicCase.data.attendingId,
      kase.basicCase.data.procedureId
    );
    if (suggestTemplate.recommendation === IFieldRecommendationType.NONE) {
      return;
    }

    const { values } = savedDefault;
    if (ok) {
      let fieldValues;

      switch (suggestTemplate.recommendation) {
        case IFieldRecommendationType.ADD:
          if (values[suggestTemplate.fieldId] && suggestTemplate.multi) {
            fieldValues = [...values[suggestTemplate.fieldId].values, suggestTemplate.value];
          } else {
            fieldValues = [suggestTemplate.value];
            values[suggestTemplate.fieldId] = {
              id: suggestTemplate.fieldId,
              values: [],
              otherValues: []
            };
          }
          break;

        case IFieldRecommendationType.REMOVE:
          fieldValues = values[suggestTemplate.fieldId].values.filter(
            v => v !== suggestTemplate.value
          );
          break;

        default:
          // @todo replace with Sentry, toast and return
          throw new Error('Unexpected recommendation');
      }

      values[suggestTemplate.fieldId].values = fieldValues;
      console.log('submitCareTeamDefaults', kase.basicCase.data.procedureId, values);
      yield apiService.submitCareTeamDefaults(
        kase.basicCase.data.attendingId,
        kase.basicCase.data.procedureId ?? '',
        values
      );
    } else {
      savedDefault.stopInsights[suggestTemplate.fieldId] = [
        ...(savedDefault.stopInsights[suggestTemplate.fieldId] || []),
        suggestTemplate.value
      ];

      console.log(
        'submitCareTeamDefaultsStopInsights',
        kase.basicCase.data.procedureId,
        savedDefault.stopInsights
      );
      yield apiService.submitCareTeamDefaultsStopInsights(
        kase.basicCase.data.attendingId,
        kase.basicCase.data.procedureId ?? '',
        savedDefault.stopInsights
      );
    }
    yield this.loadDefaults();
  });
}

export default CareTeamDefaultsStore;
