import React from 'react';
import clsx from 'clsx';
import Button from '@mui/material/Button';

interface ButtonProps {
  classNames?: string;
  startIcon?: React.JSX.Element;
  onClick?: () => void;
  buttonContent?: React.JSX.Element;
}

const FabButton = (props: ButtonProps): React.JSX.Element => {
  const { classNames, startIcon, onClick, buttonContent } = props;

  return (
    <Button
      id="new-case-fab-button"
      className={clsx('btn fab', [classNames])}
      startIcon={startIcon}
      onClick={onClick}
    >
      {buttonContent}
    </Button>
  );
};

export default FabButton;
