import type { Control } from 'react-hook-form/dist/types/form';
import { useController } from 'react-hook-form';
import React, { useEffect, useRef, useState } from 'react';
import { FORM_FIELDS_ENUM, type IFormData } from './types';
import { useTranslation } from 'react-i18next';
import TextInput from '@mui/material/TextField';
import Button from 'app/components/buttons/Button';
import PencilIcon from 'app/assets/icons/Pencil_icon';

interface IProps {
  control: Control<IFormData>;
  isReadOnly?: boolean;
  isFocusOnInit?: boolean;
}

const CaseTitle = (props: IProps): React.JSX.Element => {
  const { control, isReadOnly, isFocusOnInit } = props;
  const { field } = useController({
    name: FORM_FIELDS_ENUM.TITLE,
    control
  });
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [active, setActive] = useState(document.activeElement);
  const showEditTitleButton = !isReadOnly && inputRef.current !== active;

  const handleFocusIn = (e: any): any => {
    setActive(document.activeElement);
  };

  useEffect(() => {
    document.addEventListener('focusin', handleFocusIn);
    return () => {
      document.removeEventListener('focusin', handleFocusIn);
    };
  }, []);

  useEffect(() => {
    if (isFocusOnInit) {
      inputRef.current?.focus();
    }
  }, [inputRef]);

  const onEditTitleButtonClick = async (): Promise<void> => {
    inputRef.current?.focus();
  };

  return (
    <>
      <TextInput
        inputRef={inputRef}
        // when the input is focused, the cursor should be at the end of the text
        onFocus={e => {
          e.currentTarget.setSelectionRange(
            e.currentTarget.value.length,
            e.currentTarget.value.length
          );
        }}
        variant="standard"
        disabled={isReadOnly}
        className="case-title"
        {...field}
        placeholder={t('writeATitle').toString()}
        multiline
        InputProps={{
          disableUnderline: true
        }}
      />
      {showEditTitleButton && (
        <Button
          classNames="btn circle-button edit-title-button"
          startIcon={<PencilIcon />}
          onClick={onEditTitleButtonClick}
          disableTouchRipple
        />
      )}
    </>
  );
};

export default CaseTitle;
