import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import type { Control } from 'react-hook-form/dist/types/form';
import type { IDynamicField } from 'app/mobxStore/types';
import { useController } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import TextInput from '@mui/material/TextField';
import './OtherTextField.scss';

interface IProps {
  control: Control<any>;
  field: IDynamicField;
  needAutoFocus: boolean;
  setFocus: (id: string) => void;
}

const OtherSingleTextField = (props: IProps): React.JSX.Element => {
  const { setFocus, needAutoFocus, control, field: dField } = props;
  const fieldName = `${dField.id}-other`;
  const { field } = useController({
    name: fieldName,
    control
  });
  const { value, onChange, onBlur, ref } = field;
  useEffect(() => {
    if (needAutoFocus) {
      setFocus(fieldName);
    }
  }, [needAutoFocus]);
  return (
    <FormControl>
      <div className="other-text-field-container single">
        <TextInput
          className="other-text-field"
          inputRef={ref}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
        />
      </div>
    </FormControl>
  );
};

export default observer(OtherSingleTextField);
