import { gql } from '@apollo/client';

import { COMMENT_FULL_DATA } from './fragments/comment';

const create = gql`
  mutation CareTeamCreateComment($object: comments_insert_input!) {
    insert_comments_one(object: $object) {
      ...CommentFullData
    }
  }
  ${COMMENT_FULL_DATA}
`;
const updateComment = gql`
  mutation CareTeamUpdateComment($id: uuid!, $comment: String) {
    update_comments_by_pk(pk_columns: { id: $id }, _set: { comment: $comment }) {
      ...CommentFullData
    }
  }
  ${COMMENT_FULL_DATA}
`;

const deleteComment = gql`
  mutation CareTeamDeleteComment($id: uuid!) {
    delete_comments_by_pk(id: $id) {
      id
    }
  }
`;

const upsertCommentMetaDatas = gql`
  mutation CareTeamUpsertCommentMetaData(
    $objects: [comment_meta_datas_insert_input]!
    $col: comment_meta_datas_update_column!
  ) {
    insert_comment_meta_datas(
      objects: $objects
      on_conflict: { constraint: comment_meta_datas_pkey, update_columns: [$col] }
    ) {
      returning {
        updated_at
      }
    }
  }
`;

const queries = {};

const mutations = {
  create,
  deleteComment,
  updateComment,
  upsertCommentMetaDatas
};

const api = {
  queries,
  mutations
};

export default api;
