import React, { type MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Control } from 'react-hook-form/dist/types/form';
import type { IFormData } from 'app/components/fields/types';
import type { ICase, IProcedureSelectOption } from 'app/mobxStore/types';
import Grow from '@mui/material/Grow';
import AddTemplatePanelContent from './AddTemplatePanelContent';
import Button from 'app/components/buttons/Button';
import ModalScreen from 'app/components/modalScreen/ModalScreen';
import PencilIcon from 'app/assets/icons/Pencil_icon';

import './AddTemplate.scss';
import AmplService from '../../services/amplService/amplService';
import { useRootStore } from '../../mobxStore';

interface IProps {
  control: Control<IFormData>;
  options: IProcedureSelectOption[];
  allValues: IFormData;
  isIn: boolean;
  onChange: (option: IProcedureSelectOption | null) => void;
  isOpenBySelectOption: boolean;
  setIsOpenBySelectOption: (isOpen: boolean) => void;
}

const AddTemplatePanel = (props: IProps): React.JSX.Element => {
  const {
    control,
    options,
    allValues,
    isIn,
    onChange,
    isOpenBySelectOption,
    setIsOpenBySelectOption
  } = props;
  const { t } = useTranslation();
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const { openedCase } = useRootStore().caseStore;

  const handleOpenPanel = (state: boolean): void => {
    if (isOpenBySelectOption) {
      setIsOpenBySelectOption(false);
    }
    setIsPanelOpen(state);
  };

  useEffect(() => {
    if (isOpenBySelectOption) {
      handleOpenPanel(true);
    }
  }, [isOpenBySelectOption]);

  return (
    <div className="add-template-panel">
      <Grow in={isIn} timeout={800} style={{ transformOrigin: '0 0 0' }}>
        <div className="suggestion-button-container">
          <Button
            classNames="chip-button suggestion add-template-button"
            buttonContent={<p>{t('addYourOwnTemplate')}</p>}
            endIcon={<PencilIcon />}
            onClick={async (e: MouseEvent) => {
              handleOpenPanel(true);
              AmplService.sendCaseEvent(
                AmplService.EVENTS.CASE_ADD_YOUR_OWN_TEMPLATE,
                openedCase?.basicCase as ICase
              );
            }}
          />
        </div>
      </Grow>
      <ModalScreen
        isOpen={isPanelOpen}
        classNames={'full-height procedure-template-modal'}
        content={
          <AddTemplatePanelContent
            control={control}
            options={options}
            allValues={allValues}
            setIsOpen={handleOpenPanel}
            onChange={onChange}
          />
        }
        handleClose={() => {
          handleOpenPanel(false);
        }}
      />
    </div>
  );
};

export default AddTemplatePanel;
