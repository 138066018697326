import React from 'react';
import { useRootStore } from 'app/mobxStore';
import { useTranslation } from 'react-i18next';
import Button from 'app/components/buttons/Button';
import EnablePushButton from 'app/components/enablePush/EnablePushButton';
import InviteUserButton from '../inviteUser/InviteUserButton';
import AccountSettingsButton from '../accountSettings/AccountSettingsButton';
import LogOutButton from './LogOutButton';
import ContactUsButton from './ContactUsButton';
import SendLogsButton from './SendLogsButton';
import VersionButton from './VersionButton';
import NewSiteInviteButton from './NewSiteInviteButton';
import ArrowLeftIcon from 'app/assets/icons/ArrowLeft_icon';
import variables from 'app/utils/variables.module.scss';
import './MyAccount.scss';
import { observer } from 'mobx-react-lite';

const MyAccount = (): React.JSX.Element => {
  const { caseStore, userStore } = useRootStore();
  const userData = userStore.loggedInUser.data;
  const { t } = useTranslation();

  const handleClose = async (): Promise<void> => {
    caseStore.setIsMyAccountDialogOpen(false);
  };

  return (
    <div className="my-account-container">
      <div className="header">
        <Button
          classNames="btn circle-button close-button"
          startIcon={<ArrowLeftIcon height={14} stroke={variables.grey6} />}
          onClick={handleClose}
        />
        <div className="title">{t('myAccount')}</div>
      </div>
      <div className="user">
        <AccountSettingsButton />
        <div className="user-name">{userData.nickName}</div>
      </div>
      <div className="actions">
        {userStore.loggedInUser.isInviter && (
          <InviteUserButton
            setIsInviteDialogOpen={() => {
              caseStore.setIsInviteDialogOpen(true);
            }}
          />
        )}
        <EnablePushButton />
        <ContactUsButton />
        {userStore.loggedInUser.isInviter && (
          <NewSiteInviteButton
            setIsInviteDialogOpen={() => {
              caseStore.setIsNewSiteInvite(true);
              caseStore.setIsInviteDialogOpen(true);
            }}
          />
        )}
        <SendLogsButton />
        <LogOutButton />
      </div>
      <div className="bottom">
        <div className="links">
          <a className="link" href={t('privacy_policy_url') ?? ''}>
            {t('privacy')}
          </a>
          <a className="link" href={t('terms_of_use_url') ?? ''}>
            {t('terms')}
          </a>
        </div>
        <VersionButton />
      </div>
    </div>
  );
};

export default observer(MyAccount);
