import AppContext from 'app/AppContext';
import React, { useContext, useEffect, useState } from 'react';
import useStore from 'app/mobxStore';
import { matchRoutes, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import type { IRoleData } from '../../../app/mobxStore/types';
import redirectRoute from 'app/utils/useRedirectTo';

const hasPermission = (authArr: string[] | null | undefined, userRole: IRoleData[]): boolean => {
  /**
   * If auth array is not defined
   * Pass and allow
   */
  if (authArr === null || authArr === undefined) {
    // console.info("auth is null || undefined:", authArr);
    return true;
  }
  if (authArr.length === 0) {
    /**
     * if auth array is empty means,
     * allow only user role is guest (null or empty[])
     */
    // console.info("auth is empty[]:", authArr);
    return !userRole || userRole.length === 0;
  }
  /**
   * Check if user has grants
   */
  return authArr.some(r => userRole.find(ur => ur.role === r));
};

interface IProps {
  children: React.ReactNode;
}

const FuseAuthorization = (props: IProps): React.JSX.Element => {
  const appContext = useContext(AppContext);
  const {
    rootStore: { userStore }
  } = useStore();

  // @ts-expect-error fuse leftovers
  const { routes } = appContext;

  const [accessGranted, setAccessGranted] = useState(true);
  const userRoles = userStore.loggedInUser.data.roles;
  const location = useLocation();
  const redirect = redirectRoute();

  useEffect(() => {
    if (!accessGranted) {
      redirect();
    }
  }, [accessGranted]);

  useEffect(() => {
    const { pathname } = location;
    const allMatched = matchRoutes(routes, pathname);
    if (allMatched === null) {
      return;
    }
    const matched = allMatched[0];
    // @ts-expect-error fuse leftovers
    setAccessGranted(matched ? hasPermission(matched.route.auth, userRoles) : false);
  }, [userRoles, location]);
  if (!accessGranted) {
    return <div style={{ backgroundColor: '#fff' }}></div>;
  }
  return <>{props.children}</>;
};

export default observer(FuseAuthorization);
