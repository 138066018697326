import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'app/components/buttons/Button';
import oneSignalService from 'app/services/oneSignalService';
import { isInstalled } from '../../mobxStore/caseStoreHelper';
import { useRootStore } from '../../mobxStore';
import RemindIcon from 'app/assets/icons/Remind_icon';
import { observer } from 'mobx-react-lite';
import variables from 'app/utils/variables.module.scss';
import AmplService from '../../services/amplService/amplService';

const EnablePushButton = (): React.JSX.Element => {
  const { t } = useTranslation();
  const { userStore } = useRootStore();
  const currentUserId = userStore.loggedInUser.data.id;
  const [isOptIn, setIsOptedIn] = useState<boolean>(true);

  const enablePush = async (): Promise<void> => {
    void oneSignalService.showOneSignalPopup(
      currentUserId,
      userStore.loggedInUser.data.email,
      true
    );
    AmplService.sendEvent(AmplService.EVENTS.ACCOUNT_ENABLE_NOTIFICATIONS);
  };

  useEffect(() => {
    const isOptIn = async (): Promise<void> => {
      const is = await oneSignalService.isOptedIn();
      setIsOptedIn(is);
    };
    void isOptIn();
  }, []);

  if (!isInstalled() || !oneSignalService.enabled() || isOptIn) {
    return <></>;
  }
  return (
    <Button
      classNames="btn action-button"
      onClick={enablePush}
      buttonContent={
        <div className="button-content">
          <div className="button-icon">
            <RemindIcon height={14} stroke={variables.black} />
          </div>
          {t('enablePush')}
        </div>
      }
    />
  );
};

export default observer(EnablePushButton);
