import React, { type MouseEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { CaseStatusEnum } from '../../../mobxStore/types';
import { useRootStore } from '../../../mobxStore';
import clsx from 'clsx';
import Button from 'app/components/buttons/Button';

const NonSurgeonCasesToggle = (): React.JSX.Element => {
  const { caseStore } = useRootStore();

  return (
    <div className="cases-toggle">
      <Button
        classNames={clsx('cases-toggle-button primary', {
          unselected: caseStore.selectedStatusFilter !== CaseStatusEnum.CURRENT,
          needsAttention: caseStore.hasNeedAttentionCurrent()
        })}
        buttonText="current"
        onClick={async (e: MouseEvent) => {
          caseStore.setSelectedStatusFilter(CaseStatusEnum.CURRENT);
        }}
      />
      <Button
        classNames={clsx('cases-toggle-button primary', {
          unselected: caseStore.selectedStatusFilter !== CaseStatusEnum.UPCOMING,
          needsAttention: caseStore.hasNeedAttentionUpcoming()
        })}
        buttonText="upcoming"
        onClick={async (e: MouseEvent) => {
          caseStore.setSelectedStatusFilter(CaseStatusEnum.UPCOMING);
        }}
      />
    </div>
  );
};

export default observer(NonSurgeonCasesToggle);
