import React, { type MouseEvent } from 'react';
import { useRootStore } from 'app/mobxStore';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import Button from 'app/components/buttons/Button';
import { ICasesFilter } from '../../../mobxStore/types';

const MyCasesToggle = (): React.JSX.Element => {
  const { caseStore } = useRootStore();
  const selectedFilter = caseStore.casesFilter;
  const catchUpCasesCount = caseStore.filteredCases(true).length;

  const handleClick = async (filter: ICasesFilter): Promise<void> => {
    caseStore.setCasesFilter(filter);
  };

  return (
    <div className="cases-toggle">
      <Button
        classNames={clsx('cases-toggle-button primary', {
          selected: selectedFilter === ICasesFilter.CATCH_UP
        })}
        buttonText="catchUp"
        buttonContent={
          catchUpCasesCount > 0 ? (
            <span className="badge cases-count-badge">{catchUpCasesCount}</span>
          ) : undefined
        }
        onClick={async (e: MouseEvent) => {
          void handleClick(ICasesFilter.CATCH_UP);
        }}
      />
      <Button
        classNames={clsx('cases-toggle-button primary', {
          selected: selectedFilter === ICasesFilter.MY_CASES
        })}
        buttonText="myCases"
        onClick={async (e: MouseEvent) => {
          void handleClick(ICasesFilter.MY_CASES);
        }}
      />
      <Button
        classNames={clsx('cases-toggle-button primary', {
          selected: selectedFilter === ICasesFilter.ALL_CASES
        })}
        buttonText="allCases"
        onClick={async (e: MouseEvent) => {
          void handleClick(ICasesFilter.ALL_CASES);
        }}
      />
    </div>
  );
};

export default observer(MyCasesToggle);
