import apiService from 'app/services/apiService';
import type {
  ICasesByProcedureData,
  IMeta,
  IProcCountPerSurgeon,
  IProcCountSiteToSurgeon,
  IProcedureData,
  IProcedureDataWithCount
} from './types';

const CACHED_PROCEDURES_KEY = 'cachedProcedures';
const CACHED_UPLOADCARE_KEY = 'cachedUploadcareKey';
const CACHED_ONT_VERSION_KEY = 'cachedOntVersion';
const CACHED_METAS_KEY = 'cachedMetas';

export const IS_SAML = 'is_saml';
export const SPECIALTIES = 'specialties';
export const FLATFILE = 'flatfile';
export const loadMetas = async (curOntVer: string): Promise<IMeta[]> => {
  if (checkIfCacheValid(curOntVer) && getItemFromCache(CACHED_METAS_KEY) !== '') {
    return JSON.parse(getItemFromCache(CACHED_METAS_KEY));
  }
  const meta = await apiService.getMetas([IS_SAML, SPECIALTIES, FLATFILE]);
  localStorage.setItem(CACHED_METAS_KEY, JSON.stringify(meta));
  return meta;
};

const checkIfCacheValid = (curOntVer: string): boolean => {
  // const curCachedOntVersion = localStorage.getItem(CACHED_ONT_VERSION_KEY);
  // return curCachedOntVersion === curOntVer;
  return false;
};

const getItemFromCache = (key: string): string => localStorage.getItem(key) ?? '';
const processSp = (spr: string): string[] => {
  return JSON.parse(spr);
};

export const isTrue = (meta: IMeta): boolean => meta?.value === 'true';
export const loadProcedures = async (
  curOntVer: string,
  specialties: string
): Promise<IProcedureData[]> => {
  if (checkIfCacheValid(curOntVer) && getItemFromCache(CACHED_PROCEDURES_KEY) !== '') {
    return JSON.parse(getItemFromCache(CACHED_PROCEDURES_KEY));
  }
  const proceduresUnsorted = await loadSp(specialties);
  if (proceduresUnsorted.length > 0) {
    localStorage.setItem(CACHED_PROCEDURES_KEY, JSON.stringify(proceduresUnsorted));
  }
  return proceduresUnsorted;
};

export const getProceduresByCasesMap = async (
  isCareTeam: boolean
): Promise<Map<string, IProcCountSiteToSurgeon>> => {
  const proceduresByCases: ICasesByProcedureData[] = isCareTeam
    ? await apiService.getCasesByProcedureId()
    : [];
  const proceduresByCasesMap = proceduresByCases.reduce(
    (map: Map<string, IProcCountSiteToSurgeon>, obj: ICasesByProcedureData) => {
      if (!map.has(obj.procedureId)) {
        // const init: ICasesByProcedureData[] = [];
        map.set(obj.procedureId, new Map());
      }
      const siteMap = map.get(obj.procedureId) as IProcCountSiteToSurgeon;
      if (!siteMap.has(obj.siteId)) {
        siteMap.set(obj.siteId, new Map());
      }
      const userMap = siteMap.get(obj.siteId) as IProcCountPerSurgeon;
      userMap.set(obj.userId, obj.count);
      return map;
    },
    new Map()
  );
  return proceduresByCasesMap;
};

export const loadAndProcessProcedures = async (
  curOntVersion: string,
  isCareTeam: boolean,
  specialties: string
): Promise<IProcedureDataWithCount[]> => {
  const proceduresUnsorted = await loadProcedures(curOntVersion, specialties);
  const proceduresByCasesMap = await getProceduresByCasesMap(isCareTeam);

  const proceduresWithCount = [];
  proceduresWithCount.push(
    ...proceduresUnsorted.map(p => ({
      ...p,
      countPerSiteAndAtt: proceduresByCasesMap.get(p.id) as Map<string, Map<string, number>>
    }))
  );
  return proceduresWithCount;
};

const loadSp = async (spr: string): Promise<IProcedureData[]> => {
  const ontVersion = apiService.currentOntVersion();
  const specialitiesToLoad = processSp(spr);
  const specialitiesPromises = specialitiesToLoad.map(
    async sp => await apiService.getOnt(`procedures-by-speciality/${sp}.json`, ontVersion)
  );
  const spValues = await Promise.all(specialitiesPromises);
  const procedures = [];
  for (const spVal of spValues) {
    // eslint-disable-next-line no-await-in-loop
    const prFile = await fetch(spVal.signedUrl);
    // eslint-disable-next-line no-await-in-loop
    const prJson: {
      id: string;
      name: string;
      procedures: Array<{ id: string; name: string }>;
    } = await prFile.json();
    procedures.push(
      ...prJson.procedures.map(p => ({
        ...p,
        specialtyId: prJson.id,
        specialtyTitle: prJson.name
      }))
    );
  }

  return procedures;
};

export const getUploadcareKey = async (curOntVer: string): Promise<string> => {
  let uploadcareKey;

  if (checkIfCacheValid(curOntVer) && getItemFromCache(CACHED_UPLOADCARE_KEY) !== '') {
    uploadcareKey = getItemFromCache(CACHED_UPLOADCARE_KEY);
  } else {
    uploadcareKey = await apiService.getCareTeamUploadcareKey();
    localStorage.setItem(CACHED_UPLOADCARE_KEY, uploadcareKey);
  }
  return uploadcareKey;
};

export const setOntVersionCache = (curOntVer: string): void => {
  if (!checkIfCacheValid(curOntVer)) {
    localStorage.setItem(CACHED_ONT_VERSION_KEY, curOntVer);
  }
};
