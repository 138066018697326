import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { useController } from 'react-hook-form';
import { FORM_FIELDS_ENUM, type IFormData } from './types';
import type { Control } from 'react-hook-form/dist/types/form';

const FONT_SIZE = 10;
const INPUT_WIDTH = 60;

interface IProps {
  control: Control<IFormData>;
}

const CasePatientName = (props: IProps): JSX.Element => {
  const { control } = props;
  const { t } = useTranslation();

  const { field } = useController({
    name: FORM_FIELDS_ENUM.PATIENT_INITIALS,
    control
  });
  const [roomInputWidth, setRoomInputWidth] = useState(INPUT_WIDTH);

  useEffect(() => {
    if (field.value === '' || field.value === null) {
      setRoomInputWidth(INPUT_WIDTH);
    } else {
      setRoomInputWidth(field.value.length * FONT_SIZE);
    }
  }, [field.value]);

  return (
    <div className="case-info-chip">
      <TextField
        className="case-info-chip-input"
        variant="standard"
        {...field}
        InputProps={{
          style: { width: `${roomInputWidth}px`, minWidth: `${INPUT_WIDTH}px` },
          disableUnderline: true
        }}
        placeholder={t('patientName').toString()}
      />
    </div>
  );
};

export default CasePatientName;
