import { useEffect, useRef } from 'react';

const useTimeout = (callback: any, delay: number): void => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    let timer: string | number | NodeJS.Timeout | undefined;

    if (delay && callback && typeof callback === 'function') {
      // eslint-disable-next-line @typescript-eslint/no-implied-eval
      timer = setTimeout(callbackRef.current, delay || 0);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [callback, delay]);
};

export default useTimeout;
