import React, { type MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';
import type { Control } from 'react-hook-form/dist/types/form';
import type { IProcedureSelectOption, ISelectOption, IUserSelectOption } from 'app/mobxStore/types';
import { FORM_FIELDS_ENUM, type IFormData } from 'app/components/fields/types';
import clsx from 'clsx';
import Autocomplete from '@mui/material/Autocomplete';
import ModalScreen from 'app/components/modalScreen/ModalScreen';
import Button from 'app/components/buttons/Button';
import ArrowLeftIcon from 'app/assets/icons/ArrowLeft_icon';
import CloseIcon from 'app/assets/icons/Close_icon';
import variables from 'app/utils/variables.module.scss';
import type { FilterOptionsState } from '@mui/base/useAutocomplete/useAutocomplete';
import AmplService from 'app/services/amplService/amplService';

interface IProps {
  control: Control<IFormData>;
  required: boolean;
  handleClose: (e: MouseEvent) => void;
  handleOnChange: (selected: IProcedureSelectOption) => void;
  options: ISelectOption[];
  inputValue: string;
  setInputValue: (inputValue: string) => void;
  procSuggestionsFiltered: ISelectOption[];
  isInputFocused: boolean;
  setIsInputFocused: (isInputFocused: boolean) => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  sendEvent: (event: string, data?: Record<string, unknown>) => void;
}

const ProcedureSelectPanel = (props: IProps): React.JSX.Element | null => {
  const {
    control,
    required,
    handleClose,
    handleOnChange,
    options,
    inputValue,
    setInputValue,
    procSuggestionsFiltered,
    isInputFocused,
    setIsInputFocused,
    isOpen,
    setIsOpen,
    sendEvent
  } = props;
  const { t } = useTranslation();
  const [isDeleting, setIsDeleting] = useState(false);

  const {
    field: { ref, onBlur }
  } = useController({
    rules: { required },
    name: FORM_FIELDS_ENUM.PROCEDURE,
    control
  });

  const filterOptions = (
    options: ISelectOption[],
    state: FilterOptionsState<IUserSelectOption>
  ): ISelectOption[] => {
    const filtered = options.filter(option => {
      const words = state.inputValue.toLowerCase().split(' ');
      const wordsToIgnore = [
        'and',
        'or',
        'the',
        'of',
        'in',
        'a',
        'an',
        'on',
        'at',
        'for',
        'with',
        'to',
        'from',
        'by',
        'nor',
        'but',
        'as'
      ];
      return words.every(
        w =>
          wordsToIgnore.includes(w) ||
          option.label.toLowerCase().includes(w) ||
          option.value === 'add'
      );
    });

    // show 2 to 6 suggestions, depending on ohw many other results are there
    const nofSuggestions = Math.max(2, 6 - filtered.length);
    const slicedArray = procSuggestionsFiltered.slice(0, nofSuggestions);

    // remove suggestions from filtered results
    const filteredWithoutSuggestions = filtered.filter(opt => {
      return !slicedArray.some(sugg => {
        return opt.value === sugg.value;
      });
    });

    // combine suggestions and filtered results
    const combined = slicedArray.concat(filteredWithoutSuggestions);
    return combined;
  };

  const content = (
    <div className="modal-content">
      <div className="autocomplete-container">
        <Autocomplete
          ref={ref}
          onBlur={onBlur}
          freeSolo
          open
          disablePortal
          autoHighlight={true}
          onChange={(e, selectedValue) => {
            const selected = selectedValue as IProcedureSelectOption;
            handleOnChange(selected);
          }}
          options={options}
          filterOptions={filterOptions}
          inputValue={inputValue}
          onInputChange={(event, value) => {
            /* If we're starting a delete, send an event */
            if (inputValue.length > value.length) {
              if (!isDeleting) {
                setIsDeleting(true);
                sendEvent(AmplService.EVENTS.SEARCH_TEMPLATE_DELETE_INPUT);
              }
            } else {
              setIsDeleting(false);
            }

            setInputValue(value);
          }}
          ListboxProps={{
            // set list height to screen height - input container plus 16px list padding
            style: {
              maxHeight: 'calc(100vh - 124px)',
              overflow: 'auto'
            }
          }}
          sx={{
            '& input': {
              width: '100%',
              height: 44,
              backgroundColor: variables.grey2,
              borderRadius: 44,
              border: 'none',
              padding: '0 16px',
              boxSizing: 'border-box',
              fontSize: 14,
              color: variables.black,
              '&::placeholder': {
                color: variables.grey5
              },
              '&.focused': {
                outline: `1px solid ${variables.primaryPurple}`
              },
              '&:focus-visible': {
                outline: `1px solid ${variables.primaryPurple}`
              }
            },
            '& .input-container': {
              position: 'relative',
              padding: '32px 24px',
              display: 'flex',
              height: 108,
              boxSizing: 'border-box',
              borderBottom: '1px solid',
              borderColor: variables.grey2,
              '& .back-button': {
                flexShrink: 0,
                marginRight: '8px',
                '.svg-icon': {
                  height: 14
                }
              },
              '& .clear-button': {
                height: '42px',
                width: '42px',
                backgroundColor: variables.white,
                position: 'absolute',
                right: 25,
                top: 33
              }
            }
          }}
          groupBy={option =>
            procSuggestionsFiltered.includes(option) ? t('suggetionsForYou') : t('searchResults')
          }
          renderInput={params => {
            return (
              <div ref={params.InputProps.ref} className="input-container">
                <Button
                  classNames="btn circle-button back-button"
                  startIcon={<ArrowLeftIcon stroke={variables.grey6} height={10} />}
                  onClick={async (e: MouseEvent): Promise<void> => {
                    handleClose(e);
                    sendEvent(AmplService.EVENTS.SEARCH_TEMPLATE_BACK);
                  }}
                />
                <input
                  type="text"
                  onClick={() => {
                    setIsInputFocused(true);
                  }}
                  {...params.inputProps}
                  className={clsx('text-input', { focused: isInputFocused })}
                  placeholder={t('searchProcedure').toString()}
                />
                {inputValue !== '' && (
                  <Button
                    classNames="btn circle-button clear-button"
                    startIcon={<CloseIcon height={10} stroke={variables.grey6} />}
                    onClick={async (e: MouseEvent) => {
                      setInputValue('');
                    }}
                  />
                )}
              </div>
            );
          }}
          renderOption={(props, option: ISelectOption) => {
            return (
              <li {...props} className="list-option">
                {option.label}
              </li>
            );
          }}
        />
      </div>
    </div>
  );

  useEffect(() => {
    if (!isOpen) {
      setInputValue('');
      setIsInputFocused(false);
    }
  }, [isOpen]);

  return (
    <ModalScreen
      isOpen={isOpen}
      classNames={'full-height procedure-modal'}
      content={content}
      handleClose={() => {
        setIsOpen(false);
        sendEvent(AmplService.EVENTS.SEARCH_TEMPLATE_BACK);
      }}
    />
  );
};

export default ProcedureSelectPanel;
