import * as React from 'react';
const SvgComponent = props => (
  <svg
    className="svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 14"
    fill={props.fill || 'none'}
    {...props}
  >
    <path
      d="M8.00013 13.7692C7.83691 13.7694 7.68026 13.7045 7.56504 13.5889L5.1035 11.1274C4.95157 10.9712 4.89357 10.7464 4.95093 10.5363C5.00823 10.3261 5.17241 10.1619 5.38258 10.1046C5.59276 10.0473 5.81752 10.1053 5.97368 10.2572L8.43522 12.7187C8.5506 12.8341 8.61543 12.9906 8.61543 13.1538C8.61543 13.317 8.5506 13.4735 8.43522 13.5889C8.31984 13.7043 8.16335 13.7692 8.00013 13.7692Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M7.99983 13.7692C7.83661 13.7692 7.68012 13.7043 7.56473 13.5889C7.44935 13.4735 7.38452 13.317 7.38452 13.1538C7.38452 12.9906 7.44935 12.8341 7.56473 12.7187L10.0263 10.2572C10.1824 10.1053 10.4072 10.0473 10.6174 10.1046C10.8275 10.1619 10.9917 10.3261 11.049 10.5363C11.1064 10.7464 11.0484 10.9712 10.8965 11.1274L8.43492 13.5889C8.31969 13.7045 8.16305 13.7694 7.99983 13.7692Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M5.53845 11.3077H0.615372C0.395495 11.3077 0.192377 11.1904 0.0824492 11C-0.027479 10.8096 -0.0274872 10.575 0.0824492 10.3846C0.192386 10.1942 0.395516 10.0769 0.615372 10.0769H5.53845C5.75833 10.0769 5.96144 10.1942 6.07137 10.3846C6.1813 10.575 6.18131 10.8096 6.07137 11C5.96144 11.1904 5.75831 11.3077 5.53845 11.3077Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M15.3846 11.3077H10.4616C10.2417 11.3077 10.0386 11.1904 9.92864 11C9.81871 10.8096 9.8187 10.575 9.92864 10.3846C10.0386 10.1942 10.2417 10.0769 10.4616 10.0769H15.3846C15.6045 10.0769 15.8076 10.1942 15.9176 10.3846C16.0275 10.575 16.0275 10.8096 15.9176 11C15.8076 11.1904 15.6045 11.3077 15.3846 11.3077Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M0.615389 11.3076C0.452168 11.3076 0.295676 11.2428 0.180209 11.1274C0.0648247 11.012 0 10.8555 0 10.6922V0.846085C0 0.626208 0.117309 0.42309 0.307692 0.313162C0.498076 0.203234 0.732698 0.203226 0.923077 0.313162C1.11346 0.423099 1.23077 0.626228 1.23077 0.846085V10.6922C1.23077 10.8555 1.16594 11.012 1.05056 11.1274C0.935095 11.2428 0.778609 11.3076 0.615389 11.3076Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M15.3846 1.46148H0.615372C0.395495 1.46148 0.192377 1.34417 0.0824492 1.15379C-0.027479 0.963406 -0.0274872 0.728785 0.0824492 0.538405C0.192386 0.348026 0.395516 0.230713 0.615372 0.230713H15.3846C15.6045 0.230713 15.8076 0.348022 15.9175 0.538405C16.0275 0.728789 16.0275 0.96341 15.9175 1.15379C15.8076 1.34417 15.6045 1.46148 15.3846 1.46148Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M15.3847 11.3076C15.2215 11.3076 15.065 11.2428 14.9495 11.1274C14.8341 11.012 14.7693 10.8555 14.7693 10.6922V0.846085C14.7693 0.626208 14.8866 0.42309 15.077 0.313162C15.2674 0.203234 15.502 0.203226 15.6924 0.313162C15.8827 0.423099 16.0001 0.626228 16.0001 0.846085V10.6922C16.0001 10.8555 15.9352 11.012 15.8198 11.1274C15.7044 11.2428 15.5479 11.3076 15.3847 11.3076Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M12.923 3.92303H3.0768C2.85692 3.92303 2.6538 3.80572 2.54388 3.61534C2.43395 3.42495 2.43394 3.19033 2.54388 2.99995C2.65381 2.80957 2.85694 2.69226 3.0768 2.69226H12.923C13.1428 2.69226 13.3459 2.80957 13.4559 2.99995C13.5658 3.19034 13.5658 3.42496 13.4559 3.61534C13.3459 3.80572 13.1428 3.92303 12.923 3.92303Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M12.923 6.38458H3.0768C2.85692 6.38458 2.6538 6.26727 2.54388 6.07689C2.43395 5.8865 2.43394 5.65188 2.54388 5.4615C2.65381 5.27112 2.85694 5.15381 3.0768 5.15381H12.923C13.1428 5.15381 13.3459 5.27112 13.4559 5.4615C13.5658 5.65188 13.5658 5.88651 13.4559 6.07689C13.3459 6.26727 13.1428 6.38458 12.923 6.38458Z"
      fill={props.fill || '#B33B94'}
    />
    <path
      d="M7.99988 8.84613H3.0768C2.85692 8.84613 2.6538 8.72882 2.54388 8.53843C2.43395 8.34805 2.43394 8.11343 2.54388 7.92305C2.65381 7.73267 2.85694 7.61536 3.0768 7.61536H7.99988C8.21975 7.61536 8.42287 7.73267 8.5328 7.92305C8.64273 8.11343 8.64273 8.34805 8.5328 8.53843C8.42286 8.72881 8.21973 8.84613 7.99988 8.84613Z"
      fill={props.fill || '#B33B94'}
    />
  </svg>
);
export default SvgComponent;
