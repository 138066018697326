import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationPanel from 'app/components/confirmationPanel/ConfirmationPanel';
import { isToday, isTomorrow } from '../../../mobxStore/timeHelper';
import { useRootStore } from '../../../mobxStore';

interface IProps {
  isShowPanel: boolean;
  setIsShowPanel: (isShowPanel: string) => void;
  handleCancelConfirm: () => void;
  caseDate: Date;
}

const CaseCancelPanel = (props: IProps): React.JSX.Element => {
  const { isShowPanel, setIsShowPanel, handleCancelConfirm, caseDate } = props;
  const { tz } = useRootStore();
  const { t } = useTranslation();

  const handleCancelCase = async (): Promise<void> => {
    handleClose();
    handleCancelConfirm();
  };

  const handleCancel = async (): Promise<void> => {
    handleClose();
  };

  const handleClose = (): void => {
    setIsShowPanel('');
  };

  const shouldNotify = (caseDate: Date, tz: string): boolean => {
    return isToday(caseDate, tz) || isTomorrow(caseDate, tz);
  };

  const isNotificationRequired = shouldNotify(caseDate, tz);
  const text = isNotificationRequired
    ? t('caseCancelPanelTextWithNotify')
    : t('caseCancelPanelTextWithoutNotify');
  const okButtonText = isNotificationRequired
    ? t('caseCancelButtonOKWithNotify')
    : t('caseCancelButtonOKWithoutNotify');

  return (
    <ConfirmationPanel
      title={t('caseCancelPanelTitle')}
      text={text}
      buttons={[
        {
          buttonText: okButtonText,
          onClick: handleCancelCase,
          variant: 'outlined',
          closeOnClick: true,
          key: 'yes'
        },
        {
          buttonText: t('back'),
          onClick: handleCancel,
          variant: 'secondary',
          closeOnClick: true,
          key: 'no'
        }
      ]}
      isShowPanel={isShowPanel}
      setIsShowPanel={isTrue => {
        if (isTrue) {
          return;
        }
        handleClose();
      }}
    />
  );
};

export default CaseCancelPanel;
