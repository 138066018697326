import React, { type MouseEvent } from 'react';
import { useRootStore } from 'app/mobxStore';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { isSameMonth } from 'date-fns';
import { type DayProps, useDayRender } from 'react-day-picker';
import Button from 'app/components/buttons/Button';
import 'react-day-picker/dist/style.css';
import MomentAdapter from '@date-io/moment';
import { toZonedTime } from 'date-fns-tz';

const DayComponent = (props: {
  dayProps: DayProps;
  month: Date;
  handleClickDay: (day: Date) => void;
  selectedDay: Date;
  hideHasCases?: boolean;
}): React.JSX.Element => {
  const { dayProps, month, handleClickDay, hideHasCases } = props;
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const dayRender = useDayRender(dayProps.date, dayProps.displayMonth, buttonRef);
  const { caseStore, metaDataStore } = useRootStore();
  const day = dayProps.date.toLocaleDateString('locale');
  const dayNum = dayProps.date.getDate();
  const isHaveCases = caseStore.hasCaseInDateByFilter(dayProps.date);
  const isHaveNeedsAttentionCases = caseStore.hasNeedAttentionFilteredCasesInDay(dayProps.date);
  const isHaveUnreadMessages = caseStore.hasUnreadMessagesFilteredCasesInDay(dayProps.date);

  const sameMonth = isSameMonth(dayProps.date, month);
  const dateLib = new MomentAdapter();
  const defaultDate = toZonedTime(new Date(), metaDataStore.tz);
  defaultDate.setHours(24, 0, 0, 0);
  const fixedDate = dateLib.moment(defaultDate).subtract(1, 'days');
  const isToday = dayProps.date.toDateString() === fixedDate.toDate().toDateString();
  return (
    <div
      className={clsx('day-container', {
        selected: dayRender.activeModifiers.selected,
        hasCases: !(hideHasCases ?? false) && isHaveCases,
        hasNeedsAttention: isHaveNeedsAttentionCases || isHaveUnreadMessages,
        today: isToday,
        outside: !sameMonth
      })}
      id={day}
    >
      <Button
        disabled={dayRender.activeModifiers.disabled}
        buttonRef={buttonRef}
        classNames="day-button"
        onClick={async (e: MouseEvent) => {
          handleClickDay(dayProps.date);
        }}
        buttonContent={<div className="day-number">{dayNum}</div>}
      />
    </div>
  );
};

export default observer(DayComponent);
