import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'app/components/buttons/Button';
import './MyAccount.scss';
import apiService from '../../services/apiService';
import preval from 'preval.macro';
import { toast } from 'react-toastify';
import { getIsDebugMode, setIsDebugMode } from '../../mobxStore/storage';

const VersionButton = (): React.JSX.Element => {
  const { t } = useTranslation();
  const [clicked, setClicked] = useState(0);

  const getVersion = (): string => {
    const ontVersion = apiService.currentOntVersion();
    const buildVersion: string = preval`module.exports = new Date();`;

    const version = `${t('version')} ${buildVersion.substring(0, 19)}-${ontVersion}`;
    const dbg = getIsDebugMode() ? ' DEBUG' : '';

    return version + dbg;
  };

  const onClick = async (): Promise<void> => {
    setClicked(prevClicked => prevClicked + 1);
    if (clicked === 4) {
      const mode = getIsDebugMode();
      const newMode = !mode;
      setIsDebugMode(newMode);
      if (newMode) {
        toast.success(t('devModeEnabled'));
      } else {
        toast.success(t('devModeDisabled'));
      }
      setClicked(0);
    }
  };

  return (
    <Button classNames="version" buttonContent={<div>{getVersion()}</div>} onClick={onClick} />
  );
};

export default VersionButton;
