import React from 'react';
import SubmitButton from 'app/components/buttons/SubmitButton';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import PaperPlaneIcon from 'app/assets/icons/PaperPlane_icon';
import variables from 'app/utils/variables.module.scss';

interface IProps {
  handleSubmit: () => Promise<void>;
  withTransition?: boolean;
  label: string;
}

const Submit = (props: IProps): React.JSX.Element => {
  const { handleSubmit, label } = props;

  const { t } = useTranslation();

  return (
    <>
      <SubmitButton
        id="brief-form-submit-button"
        classNames={clsx('fab right-align submit')}
        submitFunction={handleSubmit}
        content={
          <div className="button-content">
            <PaperPlaneIcon fill={variables.white} />
            {t(label)}
          </div>
        }
      />
    </>
  );
};

export default observer(Submit);
