import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getLastWhatsNewViewed, setLastWhatsNewViewed } from 'app/mobxStore/storage';
import { useTranslation } from 'react-i18next';
import { useRootStore } from '../../../mobxStore';

const useIsNeedWhatsNew = (): void => {
  const { t } = useTranslation();
  const { userStore } = useRootStore();
  const curWhatsNewVersion = t('whatsNewVersion');
  const lastWhatsNewVersion = getLastWhatsNewViewed();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    /* First time that a user sees the WhatsNew code, should not see it. Just prepare for next time */
    if (lastWhatsNewVersion === null) {
      setLastWhatsNewViewed(parseInt(t('whatsNewVersion')));
      return;
    }

    const needWhatsNew =
      userStore.loggedInUser.data.onboardingDone && lastWhatsNewVersion < curWhatsNewVersion;
    if (needWhatsNew) {
      localStorage.setItem('redirectTo', location.pathname);
      localStorage.setItem('redirectToSearch', location.search);
      navigate('/whatsnew');
    }
  }, []);
};

export default useIsNeedWhatsNew;
