import React from 'react';
import { useRootStore } from '../../mobxStore';
import Button from 'app/components/buttons/Button';
import './Invite.scss';
import AmplService from '../../services/amplService';

const CreateInviteButton = (): React.JSX.Element => {
  const { userStore, caseStore } = useRootStore();

  const handleCreateInvite = async (): Promise<void> => {
    const newSite = caseStore.isNewSiteInvite;
    const inviteId = await userStore.inviteUser(newSite);
    AmplService.sendEvent(AmplService.EVENTS.INVITE_CREATE, {
      [AmplService.ATTRIBUTES.INVITE_ID]: inviteId,
      [AmplService.ATTRIBUTES.IS_NEW_SITE]: newSite
    });
  };

  return (
    <Button
      classNames="btn primary create-invite-button"
      buttonText="createInvite"
      onClick={handleCreateInvite}
    />
  );
};

export default CreateInviteButton;
