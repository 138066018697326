import * as React from 'react';
const SvgComponent = props => (
  <svg
    className="svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 26"
    fill={props.fill || 'none'}
    {...props}
  >
    <path
      d="M9.66667 5.5V7.9M17.1667 5.5V7.9M5.5 10.7H20.5M8.83333 6.7H17.1667C19.0076 6.7 20.5 8.13269 20.5 9.9V17.3C20.5 19.0673 19.0076 20.5 17.1667 20.5H8.83333C6.99238 20.5 5.5 19.0673 5.5 17.3V9.9C5.5 8.13269 6.99238 6.7 8.83333 6.7Z"
      stroke={props.color || '#B33B94'}
    />
    <circle cx="9" cy="14" r="1" fill={props.color || '#B33B94'} />
    <circle cx="13" cy="14" r="1" fill={props.color || '#B33B94'} />
    <circle cx="17" cy="14" r="1" fill={props.color || '#B33B94'} />
    <circle cx="9" cy="17" r="1" fill={props.color || '#B33B94'} />
    <circle cx="13" cy="17" r="1" fill={props.color || '#B33B94'} />
  </svg>
);
export default SvgComponent;
