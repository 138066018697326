import { ROLES } from 'app/consts';
import { DynamicField, OTHER_OPTION } from './shared';
import {
  FIELD_TYPE,
  FIELD_VARIANT,
  type ICareTeamDefaults,
  type ICaseBasicData,
  type ICaseFullData,
  type ICaseReferencedData,
  type IDynamicField,
  type IDynamicFieldValues,
  type IGetFieldValuesCount,
  type IPMMFields,
  type IProcedure,
  type IProcessedField,
  type IRoleData,
  type IServerProcessedField
} from 'app/mobxStore/types';
import { submitPmmField } from './submit';
import { getOptions } from './dynamicOptions';
import ErrorMonitor from '../../app/services/errorMonitor/errorMonitor';

export const getDefaultsFromProcedure = (procedureData: IProcedure): IPMMFields[] => {
  const pData = procedureData;
  if (pData === undefined || pData === null) {
    return [];
  }
  const { procedureFieldDefaults: fieldDefaults } = pData;
  const defaults = [];
  for (const { fieldId, values } of fieldDefaults) {
    defaults.push({
      id: fieldId,
      values,
      otherValues: []
    });
  }

  return defaults;
};

export const processFieldValues = (kase: ICaseFullData): Record<string, IProcessedField> => {
  const { caseFieldValues } = kase;
  const pData = kase.procedureData;
  if (pData === undefined || pData === null) {
    // return empty Record
    return {};
  }

  const {
    subType: {
      form: { formFields },
      procedureSubTypeFieldOptions: fieldOptions
    }
  } = pData;
  const fieldValues: Record<string, IProcessedField> = {};
  const defParsedOptions: string[] = [];
  for (const { field } of formFields) {
    const fv: IProcessedField = {
      id: field.id,
      name: field.name,
      label: field.name,
      type: [FIELD_TYPE.CHIPS, FIELD_TYPE.SELECT].includes(field.type)
        ? FIELD_TYPE.SELECT
        : FIELD_TYPE.TEXT,
      multiple: field.type === FIELD_TYPE.CHIPS,
      hasOther: field.hasOther,
      parsedOptions: defParsedOptions,
      otherValue: [],
      value: []
    };

    const fOpt = fieldOptions.find(
      opt => opt.fieldId === field.id && (opt.clientId === '' || opt.clientId === 'default')
    );
    if (fv.type === FIELD_TYPE.TEXT) {
      fv.parsedOptions = [];
    } else if (fOpt !== undefined) {
      const { options } = fOpt;
      fv.parsedOptions = field.hasOther ? [...options, OTHER_OPTION] : [...options];
    } else {
      // eslint-disable-next-line no-continue
      continue;
    }
    let defaults: string[] = [];
    let otherValue;

    const fieldValue = caseFieldValues.find(val => val.fieldId === field.id);
    if (fieldValue !== undefined) {
      defaults = fieldValue.values;
      otherValue = fieldValue.otherValues.length > 0 ? fieldValue.otherValues : [];
      fv.createdAt = fieldValue.createdAt;
      fv.updatedAt = fieldValue.updatedAt;
      fv.updatedBy = fieldValue.updatedByUser;
    }

    if (otherValue !== undefined) {
      fv.otherValue = otherValue;
    }
    fv.value = defaults;
    fieldValues[field.id] = fv;
  }

  return fieldValues;
};

const getFieldHint = (fieldId: string): string => {
  switch (fieldId) {
    case DynamicField.COMMENTS_TO_ANESTH:
      return 'comments_to_anesthesia_hint';
    case DynamicField.COMMENTS_TO_NURSING:
      return 'comments_to_nursing_hint';
    case DynamicField.ESTIMATED_CASE_LENGTH:
      return 'estimated_case_length_hint';
    case DynamicField.INSTRUCTIONS_FOR_2ND_PART:
      return 'second_part_of_the_case_hint';
  }
  return '';
};

export const processPMMFields = (
  caseData: ICaseBasicData,
  referencedData: ICaseReferencedData,
  userRoles: IRoleData[],
  editMode: boolean,
  getFieldValuesCount: (fieldId: string) => IGetFieldValuesCount,
  savedDefaults: ICareTeamDefaults
): IDynamicField[] => {
  const processedFields: IDynamicField[] = [];
  if (referencedData.fieldValues === undefined) {
    return [];
  }

  Object.keys(referencedData.fieldValues).forEach(fieldId => {
    if (referencedData.fieldValues === undefined) {
      return;
    }
    const field = referencedData.fieldValues[fieldId];
    if (field === undefined || referencedData.procedureData === undefined) {
      return;
    }
    const fvc = getFieldValuesCount(fieldId);
    const f: IDynamicField = {
      hasOther: field.hasOther,
      otherFieldProps: {
        value: field.otherValue
      },
      name: field.name,
      label: field.label,
      id: fieldId,
      isPmm: true,
      multiline: field.type === FIELD_TYPE.TEXT,
      options: getOptions(
        field.parsedOptions,
        fvc,
        field.otherValue,
        savedDefaults?.values[fieldId]
      ),
      value: field.value,
      type: field.type,
      multiple: field.multiple,
      placeholder: getFieldHint(fieldId),
      withHint: getFieldHint(fieldId) !== '',
      disabled: false,
      variant: FIELD_VARIANT.STANDARD,
      editMode,
      createdAt: field.createdAt,
      update: async (values: IDynamicFieldValues): Promise<IServerProcessedField> => {
        ErrorMonitor.captureException(new Error('edit mode should not call update'));
        return {
          caseId: caseData.id,
          fieldId,
          values: '',
          otherValues: ''
        };
      },
      updatedAt: field.updatedAt,
      updatedBy: field.updatedBy
    };
    if (editMode) {
      const update = async (values: IDynamicFieldValues): Promise<IServerProcessedField> => {
        return await submitPmmField(caseData.id, f, values);
      };
      f.update = update;
    }

    // Show comments to anesth/nursing only to relevant department. Show on top for anesth/nursing roles.
    if (f.id === DynamicField.COMMENTS_TO_ANESTH) {
      if (userRoles.some(r => [ROLES.ANESTHESIA, ROLES.ANESTHESIA_RESIDENT].includes(r.role))) {
        processedFields.unshift(f);
      } else {
        processedFields.push(f);
      }
    } else if (f.id === DynamicField.COMMENTS_TO_NURSING) {
      if (userRoles.some(r => [ROLES.NURSE, ROLES.NURSE_MANAGER].includes(r.role))) {
        processedFields.unshift(f);
      } else {
        processedFields.push(f);
      }
    } else {
      processedFields.push(f);
    }
  });

  return processedFields;
};
